import React from 'react';
import CustomerSearchWidget from '../../../components/CustomerSearchWidget';
import CustomerProfileViewPage from './CustomerProfileViewPage';
import CustomerProfileEditPage from './CustomerProfileEditPage';
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import { useSelector } from 'react-redux';
import AgentProfileViewPage from "./AgentProfileViewPage";

export default function CustomersProfilePage() {
    TitleEffect(PageTitle("Customers Profile"));
    const customer = useSelector(state => state.customers.current);
    const isEditing = useSelector(state => state.customers.isEditing);

    return <>
        <nav><CustomerSearchWidget /></nav>

        <main className={customer === null || customer === undefined || customer?.id === undefined ? "not-found centeredcontent" : ""}>
            { customer === null || customer === undefined || customer?.id === undefined ? 
                <div><h1>Customer not Selected.</h1></div> :
                    (customer.userType == "Agent" ? <AgentProfileViewPage /> :
                        (isEditing ? <CustomerProfileEditPage /> :
                            <CustomerProfileViewPage />))
            }
        </main>
    </>
}
