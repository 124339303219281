import React, {useEffect, useState} from 'react';
import CustomerSearchWidget from '../../../components/CustomerSearchWidget';
import {
    setType,
    setAmount,
    setCurrency,
    setReason,
    saveCustomerAdjustment
} from '../../../shared/store/slices/adjustments'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getCustomerBalance } from '../../../shared/store/slices/customers'
import { ConvertToMinorUnits } from "../../../shared/utils";

export default function AdjustmentsPage() {
    const dispatch = useDispatch();
    const { type, amount, currency, reason } = useSelector(state => state.adjustments);
    const customer = useSelector(state => state.customers.current);
    const history = useHistory();
    const [resultMessage, setResultMessage] = useState('');

    useEffect(() => {
        if (!customer?.email) {
            history.push("/customer/search");
        }

        if (customer?.id){
            dispatch(getCustomerBalance({
                userId: customer.id
            }));
        }
    }, []);

    function handleChangeType(event) {
        dispatch(setType(event.target.value));
    }

    function handleChangeAmount(event) {
        dispatch(setAmount(event.target.value));
    }

    function handleChangeReason(event) {
        dispatch(setReason(event.target.value));
    }

    function handleAdjust(event) {
        dispatch(saveCustomerAdjustment({
            customerId: customer.id,
            type: type,
            amount: ConvertToMinorUnits(amount, customer.balance?.currency),
            currency: customer.balance?.currency.code,
            reason: reason,
            method: "adjustment"
        }))
            .then(response => {
                if(response.payload?.responseCode == "Ok"){
                    let message = customer.balance?.currency?.symbol + amount + " was " + (type == "Credit" ? "added." : "removed.");
                    setResultMessage(message);

                    //update displayed balance 
                    dispatch(getCustomerBalance({
                        userId: customer.id
                    }));
                }else if(response.payload?.responseCode == "Processing"){
                    setResultMessage("Adjustment is in process.");
                }else{
                    setResultMessage("Adjustment was not successful.");
                }
            });
            //after saveCustomerAdjustment, type, amount and reason will be reset within extraReducers
    }

    function handleCancel() {
        history.push("/customers");
    }

    return <>
        <nav><CustomerSearchWidget /></nav>
        <main className="pendingexchanges">
	    <div className="customerlegend">
		<div className="customerlegend1">{ `${customer.givenName} ${customer.familyName}` }</div>
		<div className="customerlegend2">{ customer.email }</div>
		<div className="customerlegend3">Available Balance: { customer.balance?.currency.symbol }{ customer.balance?.formattedAmount }</div>
	    </div>
            <div className="adjustmentgrid">
                <div className="adjustmentgrid1">
                    <select id="adjustmenttype" value={ type } onChange={ handleChangeType }>
                        <option value="Credit">+</option>
                        <option value="Debit">-</option>
                    </select>
                </div>
                <div className="adjustmentgrid2">
                    <input id="adjustmentamount" placeholder="Enter amount" value={amount} onChange={ handleChangeAmount }/><span className="adjustmentamount">{ customer.balance?.currency.code }</span>
                </div>
                <div className="adjustmentgrid3">
                    <select id="adjustmentreason" value={ reason } onChange={ handleChangeReason }>
                        <option value="" disabled>Reason</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className="adjustmentgrid4">
                    <button className={ "buttonprimary primary savecancel" + ((!amount || !reason) ? " inactive" : "" ) } disabled={ !amount || !reason } onClick={ handleAdjust }>Adjust</button>
                    <button className={ "buttonprimary secondary savecancel cancel" } onClick={ handleCancel }>Cancel</button>
                </div>
                <div style={{ gridArea: '3 / 1 / 3 / 4' }}>{ resultMessage }</div>
            </div>
        </main>
    </>
}
