import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import {
    saveSetting,
    reset,
    getSetting
} from '../../../shared/store/slices/controlpanel'
import { useDispatch, useSelector } from 'react-redux';
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import MerchantSearchWidget from '../../../components/MerchantSearchWidget';


export default function BuyAndSendPage() {
    TitleEffect(PageTitle("Buy & Send"));

    const [current, setCurrent] = useState({ name: 'Cashier:Enabled', value: '' });
    const [enabled, setEnabled] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSetting({ settingname: 'Cashier:Enabled' }))
            .then((response) => {
                if(response?.payload !== null && response?.payload !== undefined)
                    setCurrent({...current, value: response.payload.value });
                    setEnabled(response.payload.value === 'True');
            });  
    }, []);

    const onChange = (checked) => {
        setEnabled(checked);
        setCurrent({...current, value: checked === true ? 'True' : 'False' });
    }

    const handleSave = (event) => {
        dispatch(saveSetting({ id: "buy+send", setting: current })).then(() => {
            dispatch(reset);
        });
    }

    const handleCancel = (event) => {
        dispatch(reset);
    }

    return <>
        <nav><MerchantSearchWidget /></nav>
        <main>    
            <div style={{"textAlign": "left"}}>
                <h1 className="controlpanelheader">Control panel</h1>
                <div className="controlpanel">
                    <div className="controlpanellabel">Buy+Send</div>
                    <div className={enabled === false ? "controlpaneloff on" : "controlpaneloff"}>OFF</div>
                    <div>
                        <label className="switch control">
                            <Switch checked={enabled} onChange={onChange} />
                        </label>
                    </div>
                    <div className={enabled === true ? "controlpanelon on" : "controlpanelon"}>ON</div>
                </div>
                <div className="profilegrid5">
                    <button className="buttonprimary primary savecancel" onClick={(e) => {handleSave(e)}}>Save</button>
                    <button className="buttonprimary secondary savecancel cancel" onClick={(e) => {handleCancel(e)}}>Cancel</button>
                </div>
            </div>
        </main>
    </>
}