import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Table.module.css';
import { setPostFxAmount, exchangeApprove, exchangeDecline, loadExchanges } from '../../shared/store/slices/exchanges'
import AntdTable from '../../components/AntdTable';
import { formatter } from '../../shared/utils';
import NoData from '../../components/NoData';
import {decode} from "html-entities";


export default function ExchangeListing(props) {
    const { exchanges, loading } = useSelector(state => state.exchanges);
    const { isLoading } = useSelector(state => state.userInterface);
    const dispatch = useDispatch();

    const headers = [
        {title: "Timestamp", width: 160, align: 'left'},
        {title: "Merchant",  width: 0},
        {title: "From Site",      width: 0},
        {title: "Pre-Fx Amount",    width: 130, align: 'right'},
        {title: "Currency",  width: 100, align: 'right'},
        {title: "To Site",    width: 0},
        {title: "Post-Fx Amount",    width: 0},
        {title: "Currency",    width: 100, align: 'right'},
        {title: "",          width: 225, sticky: 'right'},
    ];
    
    function handleChangeAmount(event) {
        dispatch(setPostFxAmount({ index: event.target.getAttribute('data-index'), value: event.target.value }));
    }

    function handleApprove(event) {
        const postFxAmount = exchanges[event.target.getAttribute('data-index')].postFxAmount;
        
        if (!isNaN(postFxAmount) && !isLoading) {
            dispatch(exchangeApprove({ transactionId: event.target.getAttribute('data-txid'), postFxAmount: postFxAmount }))
                .then(() => {
                    dispatch(loadExchanges({ status: 'Pending' }));
                });
        }
    }

    function handleDecline(event) {
        if (!isLoading) {
            dispatch(exchangeDecline({ transactionId: event.target.getAttribute('data-txid') }))
                .then(() => {
                    dispatch(loadExchanges({ status: 'Pending' }));
                });
        }
    }

    const data = exchanges.map((exchange, index) => {
        const { txid, mid, merchant, fromSid, fromSite, fromCurrency, fromSymbol, amount, toSid, toSite, toCurrency, status, createdOn, postFxAmount } = exchange;

        return {
            'key': txid,
            'data': [
                { className: 'tdstyle transcell', children: <>{ createdOn.replace('T', ' ').replace('Z', '') }</> },
                { className: 'tdstyle transcell', children: <>{ merchant } <span className='midlabel1'>MID:</span><span className='midlabel2'>{ mid }</span></> },
                { className: 'tdstyle transcell', children: <>{ fromSite } <span className='midlabel1'>SID:</span><span className='midlabel2'>{ fromSid }</span></> },
                { className: '', children: decode(fromSymbol) + formatter.format(amount) },
                { className: '', children: fromCurrency },
                { className: '', children: <>{ toSite } <span className='midlabel1'>SID:</span><span className='midlabel2'>{ toSid }</span></> },
                { className: '', children: <input type="text" placeholder="Enter amount" className="postFxAmount" data-index={ index } onChange={ handleChangeAmount } /> },
                { className: '', children: toCurrency },
                { className: '', children: <>
                        <button className={ "buttonprimary primary navbutton tablebutton" + (isNaN(postFxAmount) ? " inactive" : "") }
                            data-index={ index } data-txid={ txid }
                            onClick={ handleApprove }>Approve</button>
                        <button className="buttonprimary secondary navbutton tablebutton"
                            data-index={ index } data-txid={ txid }
                            onClick={ handleDecline }>Decline</button>
                    </> }
            ]
        }
    });

    return exchanges?.length
        ? <AntdTable width={ 1300 } headers={ headers } data={ data } />
        : ( loading === false
            ? <NoData />
            : null
          );
}
