import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'antd/dist/antd.css';
import {
    reset,
    clear,
    loadCustomerOrAgentAuditLogs,
    setSearchIncludeAdminEvents,
    setSearchIncludeUserEvents,
    setSearchEndDate,
    setSearchStartDate,
    setSearchMostRecentFirst, setSearchEventType
} from '../../shared/store/slices/auditLogs'
import {DatePicker} from "antd";
import moment from "moment";

export default function ActivitySearchWidget() {
    const dispatch = useDispatch();
    const { current } = useSelector(state => state.customers);
    const { searchStartDate, searchEndDate, searchIncludeAdminEvents, searchIncludeUserEvents,
        searchMostRecentFirst, searchEventType } = useSelector(state => state.auditLogs);
    
    function handleSearchStartDateChange(event) {
        dispatch(setSearchStartDate(event ? new Date(new Date(event).toDateString()).toISOString() : ""));
    }

    function handleSearchEndDateChange(event) {
        dispatch(setSearchEndDate(event ? new Date(new Date(event).toDateString()).toISOString() : ""));
    }

    function handleSearchIncludeUserEventsChange(event) {
        dispatch(setSearchIncludeUserEvents(event.target.checked));
    }

    function handleSearchIncludeAdminEventsChange(event) {
        dispatch(setSearchIncludeAdminEvents(event.target.checked));
    }

    function handleMostRecentFirstChange(event) {
        dispatch(setSearchMostRecentFirst(event.target.checked));
    }

    function handleSearchEventTypeChange(event) {
        dispatch(setSearchEventType(event.target.value));
    }

    function performSearch() {
        dispatch(clear());
        if (current.userType === "Customer") {
            dispatch(loadCustomerOrAgentAuditLogs({userId: current.id,
                includeAdminEvents: false, includeUserEvents: searchIncludeUserEvents,
                startDate: searchStartDate, endDate: searchEndDate,
                orderBy: searchMostRecentFirst ? "DESC" : "ASC", eventType: searchEventType}));
        } else {
            dispatch(loadCustomerOrAgentAuditLogs({agentId: current.id,
                includeAdminEvents: searchIncludeAdminEvents, includeUserEvents: searchIncludeUserEvents,
                startDate: searchStartDate, endDate: searchEndDate,
                orderBy: searchMostRecentFirst ? "DESC" : "ASC", eventType: searchEventType}));
        }
    }
    
    useEffect(() => {
        if (current?.id) {
            performSearch();
        }

        return () => {
            dispatch(reset());
        }
    }, []);

    function performGo() {
    }

    function handleSubmit(event) {
        event.preventDefault();
        performSearch();
    }

    //TODO - Make this dropdown dynamic - only show values relative to checkboxes
    return <form onSubmit={ handleSubmit }>
        <div id="activitysearchnav" className="activitysearch">
            <div className="navcell navcellstartdatepickerlabel">Start Date</div>
            <div className="navcell navcellstartdatepicker activitydatepicker">
                <DatePicker onChange={handleSearchStartDateChange} value={moment(searchStartDate ? searchStartDate : undefined)}  />
            </div>
            <div className="navcell navcellenddatepickerlabel">End Date</div>
            <div className="navcell navcellenddatepicker activitydatepicker">
                <DatePicker  onChange={handleSearchEndDateChange}  value={moment(searchEndDate ? searchEndDate : undefined)}  />
            </div>
            <div className="navcell checkboxcell">
                <div className="navcell activitycheckbox" hidden={current.userType === "Customer"}>
                    <input type="checkbox" name="includeAdmin"  onChange={ handleSearchIncludeAdminEventsChange } checked={searchIncludeAdminEvents && current.userType != "Customer"}></input>Admin Events
                </div>
                <div className="navcell activitycheckbox" hidden={current.userType === "Customer"}>
                    <input type="checkbox" name="includeUser" onChange={ handleSearchIncludeUserEventsChange } checked={searchIncludeUserEvents}></input>User Events
                </div>
                <div className="navcell activitycheckbox">
                    <input type="checkbox" name="dateSort" onChange={ handleMostRecentFirstChange } checked={searchMostRecentFirst}></input>Most Recent First
                </div>
            </div>
            <div className="navcell activityevent">Event Type</div>
            <div className="navcell  activityselect" style={{"width": "75px"}}>
                <select name="parameter" id="parameter" value={ searchEventType } onChange={ handleSearchEventTypeChange }>
                    <option value="" disabled>Select User Event Type</option>
                    <option value="ALL">ALL</option>
                    <option value="CUSTOMER_INITIATES_IDVERIFICATION">IDVERIFICATION INIT</option>
                    <option value="CUSTOMER_INITIATES_IDVERIFICATION_QRCODE_SCAN">QRCODE SCAN</option>
                    <option value="CUSTOMER_LOGIN_INCORRECT_USERNAME_PASSWORD">INCORRECT UN/PW</option>
                    <option value="CUSTOMER_LOGIN_SUCCESS">LOGIN SUCCESS</option>
                    <option value="CUSTOMER_LOGIN_USER_TEMPORARILY_DISABLED">LOGIN USER DISABLED</option>
                    <option value="CUSTOMER_LOGOUT">LOGOUT</option>
                    <option value="FOURSTOP_REGISTRATION_SCORE_RECEIVED">PHONE SCORE RECEIVED</option>
                    <option value="CUSTOMER_REGISTERED_ADDRESS">REGISTERED ADDRESS</option>
                    <option value="CUSTOMER_REGISTERED_EMAIL_PW">REGISTERED EMAIL/PW</option>
                    <option value="CUSTOMER_REGISTERED_NAME_DOB">REGISTERED NAME/DOB</option>
                    <option value="CUSTOMER_REGISTERED_PHONE">REGISTERED PHONE</option>
                    <option value="CUSTOMER_UPDATED_PASSWORD">UPDATED PW</option>
                    <option value="CUSTOMER_UPDATED_PROFILE">SELF-UPDATED PROFILE</option>
                    <option value="USER_DETAILS_UPDATE">AGENT/APP-UPDATED PROFILE</option>
                    <option value="CUSTOMER_VERIFIED_EMAIL">VERIFIED EMAIL</option>
                    <option value="CUSTOMER_VERIFIED_PHONE">VERIFIED PHONE</option>
                    <option value="WALLET_COOKIE_ALLOW_OR_DENY">COOKIE SELECTION</option>
                    <option value="TEMPORARY_DISABLED_REMOVED">TEMP DISABLED REMOVED</option>
                    <option value="USER_ROLES_PERMS_DELETE">ROLES/PERMS REMOVED</option>
                    <option value="USER_ROLES_PERMS_CREATE">ROLES/PERMS ADDED</option>
                    <option value="approve-batch">APPROVE BATCH</option>
                    <option value="approve-batch-transactions">APPROVE BATCH TXNS</option>
                    <option value="approve-user-document">APPROVE USER DOCS</option>
                    <option value="approve-user-document-approved">APPROVE USER DOCS APPROVED</option>
                    <option value="DCX_PAYID_NOTIFICATION">DCX PAYID NOTIFICATION</option>
                    <option value="decline-batch-transactions">DECLINE BATCH TXNS</option>
                    <option value="deny-user-document-rejected">USER DOCUMENT REJECTED</option>
                    <option value="DocumentUpload">DOC UPLOAD</option>
                    <option value="DUPLICATE_UNSOLICITED_PAYMENT_NOTIFICATION">DUPLICATE UNSOLICITED PAYMENT</option>
                    <option value="reject-user-document">REJECTED USER DOCUMENT</option>
                    <option value="remove-from-queue">REMOVED FROM QUEUE</option>
                    <option value="remove-required-document">REMOVED REQUIRED DOCUMENT</option>
                    <option value="request-new-document">REQUEST NEW DOCUMENT</option>
                    <option value="save-settings">SAVE SETTINGS</option>
                    <option value="save-user-details">SAVE USER DETAILS</option>
                    <option value="send-batch">SEND BATCH</option>
                    <option value="split-batch">SPLIT BATCH</option>
                    <option value="TOKENIZER_PAN_WRITE">TOKENIZER PAN WRITE</option>
                    <option value="view-user-profile">VIEW USER PROFILE</option>
                    <option value="CASHIER_PAYMENT_REQUEST">CASHIER PAYMENT REQUEST</option>
                    <option value="sendCode">SEND CODE</option>
                    <option value="verifyCode">VERIFY CODE</option>
                    <option value="cancel-transaction">CANCEL TXN</option>
                    <option value="customer-adjustment">CUSTOMER ADJUSTMENT</option>
                    <option value="WALLET_BUY_AND_SEND_APPROVED">BnS APPROVED</option>
                    <option value="TX-BATCH-APPROVED">TXN BATCH APPROVED</option>
                    <option value="refund-transaction">REFUND TXN</option>
                    <option value="SECURITY-TXN-REJECTED">SECURITY TXN REJECTED</option>
                    <option value="WALLET_WITHDRAWAL_INITIATED">WALLET WITHDRAWAL INITIATED</option>
                    <option value="BATCH-TX-ITEM-SENT">BATCH TXN ITEM SENT</option>
                    <option value="SECURITY-TXN-APPROVED">SECURITY TXN APPROVED</option>
                    <option value="KEYCLOAK_ADMIN_UPDATE">KEYCLOAK ADMIN UPDATE</option>
                    <option value="KEYCLOAK_ADMIN_CREATE">KEYCLOAK ADMIN CREATE</option>
                    <option value="KEYCLOAK_ADMIN_ACTION">KEYCLOAK ADMIN ACTION</option>
                    <option value="KEYCLOAK_ADMIN_DELETE">KEYCLOAK ADMIN DELETE</option>
                </select>
            </div>
    
            <div className="activitynavcellsearchbutton activitysearch">
                <button className="buttonprimary secondary navbutton" onClick={ performGo }>Search</button></div>
            </div>
    </form>
}
