import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { InitPeriodStatement, GetPeriodStatement, SaveMerchantAdjustment } from '../../GatewayAPI';
import { loadMerchantTree } from './merchants';
import { startLoading, stopLoading } from './userInterface';


export const init = createAsyncThunk(
    'periodStatement/init',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await InitPeriodStatement(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);

export const loadPeriodData = createAsyncThunk(
    'periodStatement/loadPeriodData',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await GetPeriodStatement(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);


export const periodStatementSlice = createSlice({
    name: 'periodStatement',

    initialState: {
        initData: {},
        periodStart: "",
        periodEnd: "",
        isReady: false,
        periodData: {},
        sites: {},
        site: ""
    },

    reducers: {
        ready: (state) => {
            state.isReady = true;
        },
        busy: (state) => {
            state.isReady = false;
        },
        changePeriod: (state, action) => {
            state.periodStart = new Date(action.payload.period_start).toISOString();
            state.periodEnd = new Date(action.payload.period_end).toISOString();
        },
        clearData: (state) => {
            state.periodData = {};
        },
        changeSite: (state, action) => {
            state.site = action.payload;
        },
    },

    extraReducers: {
        [init.fulfilled]: (state, action) => {
            state.initData = action.payload;
            state.periodStart = new Date(action.payload.period_start).toISOString();
            state.periodEnd = new Date(action.payload.period_end).toISOString();
        },
        [init.rejected]: (state, action) => {
            state.initData = {};
            state.periodStart = "";
            state.periodEnd = "";
        },
        [loadPeriodData.fulfilled]: (state, action) => {
            state.periodData = action.payload;
        },
        [loadPeriodData.rejected]: (state, action) => {
            state.periodData = {};
        },
        [loadMerchantTree.fulfilled]: (state, action) => {
            Object.entries(action.payload || {})
                .map(([mid, merchant]) => {
                    Object.entries(merchant.children || {})
                        .map(([rid, retailer]) => {
                            Object.entries(retailer.children || {})
                                .map(([sid, site]) => {
                                    state.sites[sid] = site;
                                });
                        });
                });
        }
    }
})

// Action creators are generated for each case reducer function
export const { ready, busy, changePeriod, changeSite, clearData } = periodStatementSlice.actions;

export default periodStatementSlice.reducer;
