import React, { useEffect } from "react";
import { monthNames, comparePeriods } from "../../shared/utils";
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as ArrowLeft } from './images/arrow-left-white.svg';
import { ReactComponent as ArrowRight } from './images/arrow-right-white.svg';
import {
    previousPeriod,
    nextPeriod,
    selectGateway,
    selectDescriptor
} from '../../shared/store/slices/gatewaySummary';
import css from './index.module.css';

export default function Navigation(props) {
    const { period, periodMin, periodMax, gateway, gateways, descriptors, descriptor } = useSelector(state => state.gatewaySummary);
    const dispatch = useDispatch();

    const periodCaption = monthNames[period.month - 1] + " " + period.year;

    useEffect(() => {
        const desc = Object.entries(descriptors);

        if (!desc.length)
            return;

        dispatch(selectDescriptor(desc[0][0]));
    }, [descriptors]);

    function handleGatewayChange(event) {
        dispatch(selectGateway(event.target.value));
        dispatch(selectDescriptor(""));
    }

    function handleDescriptorChange(event) {
        dispatch(selectDescriptor(event.target.value));
    }

    function handlePrevPeriod() {
        dispatch(previousPeriod());
    }

    function handleNextPeriod() {
        dispatch(nextPeriod());
    }

    return <nav>
        <div className={ `${css.gatewaySummary} ${css.darknav}` }>
            <div className={ css.gatewaypicker }>
                <select className={ css.darknavselect } value={ gateway } onChange={ handleGatewayChange }>
                    <option value="pgid0" key="gateway_pgid0">Choose a Gateway</option>
                    { Object.entries(gateways).map(([value, name]) => <option value={value} key={`gateway_${value}`}>{name}</option>) }
                </select>
            </div>
            <div className={ css.gatewaypicker }>
                <select className={ css.darknavselect } value={ descriptor } onChange={ handleDescriptorChange }>
                    { descriptors !== undefined && Object.entries(descriptors).length
                        ? Object.entries(descriptors).map(([value, name]) => <option value={value} key={`descriptor_${value}`}>{name}</option>)
                        : <option value="plid0" key="descriptor_rid0">No gateway chosen</option>
                    }
                </select>
            </div>
            <div className={ css.gobutton }>
                <button className={ css.gobutton }>Go</button>
            </div>
            { period !== ""
                ? <div className={ `${css.cell} ${css.labelperiod} admin` }>
                    <div className={ css.rangeselect }>
                        <div>
                            <span className="vanishinglabel">Settlement period: </span>
                            <span className="rangeDescription">{periodCaption}</span>
                        </div>
                        <div>
                            <button className={ css.arrowButton } onClick={handlePrevPeriod} disabled={ comparePeriods(period, periodMin) === 0 }>
                                <ArrowLeft className="arrowswhite" alt="Prev Button" />
                            </button>
                            <button className={ css.arrowButton } onClick={handleNextPeriod} disabled={ comparePeriods(period, periodMax) === 0 }>
                                <ArrowRight className="arrowswhite" alt="Next Button" />
                            </button>
                        </div>
                    </div>
                </div>
                : null
            }
        </div>
    </nav>;
}
