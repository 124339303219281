function AccountStatusMessage(accountStatus) {
    if (accountStatus === undefined || accountStatus === null)
        return "";
    switch (accountStatus) {
        case "CountryBlocked":
            return "Country Blocked";
        case "DuplicateAccount":
            return "Duplicate Account";
        case "FixedPhone":
            return "Fixed Phone Number";
        case "IPCountryMismatch":
            return "IP Country Mismatch";
        case "OFACMatch":
            return "OFAC Match";
        case "RestrCtryLogin":
            return "Restr. Ctry. Login";
        case "UserRequest":
            return "User Request";
        case "None":
        case "Fraud":
        case "Investigating":
        case "Risk":
        case "Suspect":
        case "VOIP":
        case "VPN":
            return accountStatus;
        default:
            console.warn("Unknown account status encountered ".concat(accountStatus));
            return accountStatus;
    }
}
export { AccountStatusMessage };
