import { ApiPostRequestSync, ApiGetRequestSync } from "../../shared/API";

export const GetCustomerProfile = (user, customerId) => {
    return ApiGetRequestSync("Profile/customerprofile/"+customerId, user);
}

export const GetAgentProfile = (user, customerId) => {
    return ApiGetRequestSync("Profile/agentprofile/"+customerId, user);
}

export const GetCustomerProfileByEmail = (user, realm, email) => {
    return ApiGetRequestSync("Profile/customerprofilebyemail/"+realm+"/"+email, user);
}

export const SaveCustomerProfile = (user, customer) => {
    return ApiPostRequestSync("Profile/save-customer", user, customer);
}

export const AddCustomerComment = (user, comment) => {
    return ApiPostRequestSync("Comments/add-comment", user, comment);
}

export const GetCustomerComments = (user, customerId, documentId) => {
    documentId = documentId !== null && documentId !== undefined ? documentId : "";
    return ApiGetRequestSync("Comments/comments-list?agentId=&customerId="+customerId+"&documentId="+documentId, user);
}

export const GetRequiredActions = (user, customerId) => {
    return ApiGetRequestSync("Profile/list-required-actions/"+customerId, user);   
}