import React from "react";

export default function AdjustmentSection(props) {
    const { subSections, id, currency, totalFn } = props; 
    
    let dataRows = [];
    let subTotal = [];
    let amount = 0;

    for (var i=0; i<subSections.length; i++) {
        let subSection = subSections[i];

        dataRows[i] = [];
        subTotal[i] = 0;

        for (var j=0; j<subSection.data.length; j++) {
            let row = subSection.data[j];

            dataRows[i].push(<tr className={j % 2 === 1 ? "grey" : ""} key={`${id}_row_${i}_${j}`}>
                <td>{ row.description }</td>
                <td>
                    { ((typeof row.rateFee == "string") || (typeof row.rateFee == "undefined"))
                        ? row.rateFee
                        : row.rateFee.toFixed(2) + " " + currency }
                </td>
                <td>{ row.txCount }</td>
                <td>
                    {(amount = subSection.amountFn(row)) !== 0 ? (amount.toFixed(2) + " " + currency) : ""}
                </td>
            </tr>);

            subTotal[i]+= Number.parseFloat(row.amount);
        }
    }

    return <div id={id} className="statementSection">
        {subSections.map((subSection, index) =>
            <div className="statementSubsection" key={`${id}_subsection_${index}`}>
                <div className="statementHeader"><h2>{subSection.title}</h2></div>
                <table className="statementSectionTable">
                    <tbody>
                        {dataRows[index]}
                        <tr className="spacer">
                            <td colSpan="5">&nbsp;</td>
                        </tr>
                        { index === subSections.length - 1
                            ? <tr className="sectionTotal">
                                <td colSpan="4">
                                    { subSection.totalTitle }
                                </td>
                                <td className={ totalFn(subTotal) < 0 ? "blue negative" : "blue" }>
                                    { totalFn(subTotal) < 0 ? "-" : "" }{ Math.abs(totalFn(subTotal)).toFixed(2) } { currency }
                                </td>
                            </tr>
                            : null
                        }
                    </tbody>
                </table>
            </div>
        )}
    </div>;
}
