// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._9jrRz_LH4UB5yV0NkQ_Q{
    height: auto; 
    padding: 11px 11px;
}
.qAvdMUZAoCrHjdNj2fPz{
    width: auto;
}
.ICaLjeBVm1n0amS1RYsA{
    padding-right: 15px;
}
.pEjwOEwJRLf2N45Dm9dl{
    padding: 0 8px;
}
.xO4yjzeJaWIum4xF7Y9I{
    display: flex; align-items: center; line-height: 2.8rem;
}
.FSmxTKR6cLSutT_gKuGK {
    margin-top: 16px;
}
.XMdWX5xUt4pKOp0ZnkwN {
    margin-right: 5px;
}
.bXi53pytF2wty_Mb6vnN {
    margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/domain/Wallets/LimitsGroup/limitsgroup.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa,EAAE,mBAAmB,EAAE,mBAAmB;AAC3D;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB","sourcesContent":[".textareaLimit{\n    height: auto; \n    padding: 11px 11px;\n}\n.widthAuto{\n    width: auto;\n}\n.paddingRight15px{\n    padding-right: 15px;\n}\n.padding08px{\n    padding: 0 8px;\n}\n.controlsBox{\n    display: flex; align-items: center; line-height: 2.8rem;\n}\n.marginTop16px {\n    margin-top: 16px;\n}\n.marginRight5px {\n    margin-right: 5px;\n}\n.marginTop5px {\n    margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textareaLimit": `_9jrRz_LH4UB5yV0NkQ_Q`,
	"widthAuto": `qAvdMUZAoCrHjdNj2fPz`,
	"paddingRight15px": `ICaLjeBVm1n0amS1RYsA`,
	"padding08px": `pEjwOEwJRLf2N45Dm9dl`,
	"controlsBox": `xO4yjzeJaWIum4xF7Y9I`,
	"marginTop16px": `FSmxTKR6cLSutT_gKuGK`,
	"marginRight5px": `XMdWX5xUt4pKOp0ZnkwN`,
	"marginTop5px": `bXi53pytF2wty_Mb6vnN`
};
export default ___CSS_LOADER_EXPORT___;
