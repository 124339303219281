import React from "react";

export default function Total(props) {
    let total = 0;

    return <div id="statementtotal">
        <table className="statementTotalTable">
            <tbody>
                <tr className="sectionHeader">
                    <td colSpan="5">{props.title}</td>
                    <td className={ (total = props.total()) < 0 ? "blue negative" : "blue"}>{total.toFixed(2)} {props.currency}</td>
                </tr>
            </tbody>
        </table>
    </div>
}
