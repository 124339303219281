import React from "react";
import { useSelector } from 'react-redux';
import ButtonSpinner from '../../components/ButtonSpinner';
import css from './index.module.css';

export default function Buttons(props) {
    const { onAdjust, onSettle, onTransfer } = props;
    const { runningCriticalProcess } = useSelector(state => state.userInterface);
    const exchangeLoading = false; //useSelector(state => state.exchange.isLoading);
    const payoutsLoading = false;  //useSelector(state => state.payouts.isLoading);
    const settlementLoading = false; //useSelector(state => state.settlement.isLoading);

    return <div className={ css.accountbuttons }>
        <button className={ `${css.buttonprimary} ${css.primary} ${css.accountbutton} ` + (!runningCriticalProcess ? "" : css.inactive) }
            disabled={ runningCriticalProcess }
            onClick={ onAdjust }>
            <ButtonSpinner visible={ exchangeLoading } />Adjust
        </button>
        <button className={ `${css.buttonprimary} ${css.primary} ${css.accountbutton} ` + (!runningCriticalProcess ? "" : css.inactive) }
            disabled={ runningCriticalProcess }
            onClick={ onSettle }>
            <ButtonSpinner visible={ settlementLoading } />Settle
        </button>
        <button className={ `${css.buttonprimary} ${css.primary} ${css.accountbutton} ` + (!runningCriticalProcess ? "" : css.inactive) }
            disabled={ runningCriticalProcess }
            onClick={ onTransfer }>
            <ButtonSpinner visible={ payoutsLoading } />Transfer
        </button>
    </div>;
}
