import React from "react";
import { useSelector } from 'react-redux';
import { isArray, isObject } from '../../shared/utils';

function DetailsRow(props) {
    const { name, title, value } = props;

    const valueHtml = isArray(value) ? value.map(piece => {
        if (isObject(piece)) {
            switch (piece.type) {
                case 'link': return <a href={ piece.url } key={ `${name}_${piece.caption}` }>{ piece.caption }</a>;
                case 'span': return <span className={ piece.class } key={ `${name}_${piece.caption}` }>{ piece.caption }</span>;
                default: return piece;
            }
        } else {
            return piece;
        }
    }) : value;

    return <div className="detailssubgrid" key={ name }>
        <div className="detailssubgrid1">{ title }</div>
        <div className="detailssubgrid2">{ valueHtml }</div>
    </div>
}

export default function Details(props) {
    const { cardDetails, personalDetails, merchantDetails, payDetails } = useSelector(state => state.transactionDetails);

    const transactionRows = cardDetails.map(DetailsRow);
    const personalRows = personalDetails.map(DetailsRow);
    const merchantRows = merchantDetails.map(DetailsRow);
    const paymentRows = payDetails.map(DetailsRow);

    return <div className="txdetailscontainer">
        <h3 className="detailsheader">Details</h3>
        <div className="detailsgrid">
            <div className="detailsgrid1">
                <div><h4 className="detailssub">Transaction</h4></div>
                { transactionRows }
            </div>
            <div className="detailsgrid2">
                <div><h4 className="detailssub">Personal</h4></div>
                { personalRows }
            </div>
            <div className="detailsgrid3">
                <div><h4 className="detailssub">Merchant</h4></div>
                { merchantRows }
            </div>
            <div className="detailsgrid4">
                <div><h4 className="detailssub">Payment</h4></div>
                { paymentRows }
            </div>
        </div>
    </div>
}
