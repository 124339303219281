import React, { useState, useEffect } from "react";
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from 'react-redux';
import styles from './configuration.module.css';
import { deleteSetting, getAllSettings, 
         loadSettings, 
         saveAllSettings
} from "../../../shared/store/slices/controlpanel";
import { DownOutlined } from '@ant-design/icons';
import { Tree } from 'antd';

function ConfigurationPage(props) {

    const { allSettings } = useSelector(state => state.controlpanel);;
    const dispatch = useDispatch();
    const [ treeSettings, setTreeSettings ] = useState([]);
    const [ selectedNode, setSelectedNode ] = useState('');
    const [ selectedSettings, setSelectedSettings] = useState([]);
    const [ removed, setRemovedSetting ] = useState(undefined);

    TitleEffect(PageTitle("Wallets | Configuration"));

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if(selectedNode && selectedNode.length > 0) {
            
            const selecteds = allSettings.filter(st => st.name.split(':')[0] === selectedNode);
            if(selecteds && selecteds.length > 0)
                setSelectedSettings(selecteds.map((st, i) => {
                    return {
                        name: st.name,
                        value: st.value,
                        readOnly: true
                    }
                }));
            else
                setSelectedSettings([]);
        }
        
    },[selectedNode])

    useEffect(() => {
        if(removed){

            const currentNode = selectedNode;
            setSelectedNode('clear');

            let isExisting = allSettings.filter(st => st.name === removed[0].name);
            if(isExisting && isExisting.length > 0)
                dispatch(deleteSetting({ setting: removed[0] })).then(() => {
                    load();
                });
            else
                load();
            
            setTimeout(() =>  {
                setSelectedNode(currentNode);
            }, 2000);
        }
    }, [removed])

    const load = () => {
        dispatch(getAllSettings())
            .then((response) => {
                if(response?.payload !== null && response?.payload !== undefined)
                    dispatch(loadSettings(response.payload));
            });
    }

    const handleSaveAll = (event) => {

        let changedSettings = [...selectedSettings];
        
        allSettings.forEach((item, idx) => {
            const item2 = selectedSettings.find(i2 => i2.name === item.name);
            if(!item2)
                changedSettings.push(item);
        });


        dispatch(saveAllSettings({ settings: changedSettings })).then(() => {
            load();
        });
    }

    const handleChange = (event) => {

        let entry = event.target.getAttribute("data-entry");
        let idx = event.target.getAttribute("data-idx");
        let value = event.target.value;

        let updatedSelectedSettings = [...selectedSettings];
        let existing = updatedSelectedSettings[idx];

        if(existing) {

            let updated = {
                name: existing.name,
                value: existing.value,
                readOnly: false
            }

            if(entry === 'name'){
                updated.name = value;

                let alreadyIn = allSettings.find(st => st.name === value);
                if(alreadyIn) {
                    updated.name = updated.name+'_repeated';
                    event.target.value = updated.name+'_repeated';
                }
            }

            if(entry === 'value'){
                updated.value = value;
            }
            updatedSelectedSettings[idx] = updated;
            setSelectedSettings(updatedSelectedSettings);
        }
    }

    const handleDelete = (index) => {

        let updatedSelectedSettings = [...selectedSettings];
        let removed = updatedSelectedSettings.splice(index, 1);
        setRemovedSetting(removed);
    }
    
    const handleAddNew = (event) => {
        setSelectedSettings(selectedSettings => [...selectedSettings, { name: '', value: '', readOnly: false }] );
    }

    const onSelect = (selectedKeys, event) => {
        setSelectedSettings([]);
        setSelectedNode(event.node.title);
    };

    useEffect(() => {
        if(allSettings.length > 0) {

            var currentKey = allSettings.map((k, v) => {
                return k.name.split(':')[0]
            });

            currentKey = [...new Set(currentKey)];
            let childrenNodes = [];
            currentKey.forEach((node, index) => {
                childrenNodes.push({
                    title: node,
                    key: '0-0-'+index,
                    switcherIcon: <DownOutlined />
                })
            })
            
            setTreeSettings([{
                title: 'Configuration',
                key: '0-0',
                switcherIcon: <DownOutlined />,
                children: childrenNodes
            }]);

        } else {
            setTreeSettings([]);
        }
    }, [allSettings])

    return (
        <>
            <main className={ "searchtransactions" }>
                <div id="breadcrumbs"><a href="">Main</a> {">"} <a href="">Wallets</a> {">"} Configuration</div>
                <div className={ styles.alignLeft }>
                    <div className={ "adminblock walletconfig" }>
                        <div className="walletconfig natvree">
                            <Tree
                                showLine={true}
                                showIcon={false}
                                defaultExpandAll={true}
                                defaultExpandedKeys={['0-0']}
                                onSelect={onSelect}
                                treeData={treeSettings} />
                        </div>
                        <div className="walletconfig leaf">
                            {
                                selectedSettings.map((st, idx) => {
                                    return <>
                                        <div className="namepair" key={`leaf_${idx}`}>
                                            <div className={ styles.paddingRight15px }><input readOnly={ st.readOnly } type="text" className="antlike" defaultValue={ st.name } data-key={st.name} data-entry="name" data-idx={idx} onChange={ handleChange }/></div>
                                            <div className={ `fullwidth ${styles.paddingRight15px}` }><input type="text" className="antlike fullwidth" defaultValue={ st.value } data-key={st.value} data-entry="value" data-idx={idx} onChange={ handleChange }/></div>
                                            <div className={ styles.paddingRight15px }><button className={`buttonsecondary secondary ${styles.widthAuto}`} data-key={st.name} onClick={() => { handleDelete(idx) }}>Delete</button></div>
                                        </div>
                                    </>
                                })
                            }
                            {
                                selectedSettings.length > 0 && <>
                                    <div className={styles.marginTop16px}>
                                        <button className={ `buttonsecondary primary ${ styles.widthAuto } ${ styles.marginRight5px }` } onClick={ handleSaveAll }>Save</button>
                                        <button className={ `buttonsecondary secondary ${ styles.widthAuto }` } onClick={ handleAddNew }>+ Custom</button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>);
}

export default ConfigurationPage;
