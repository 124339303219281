import { ApiPostRequestSync, ApiGetRequestSync } from "../../shared/API";

export const SearchProfiles = (user, params) => {
    return ApiPostRequestSync("Profile/Search", user, params);
}

export const FindWalletTransactions = (user, params) => {
    return ApiPostRequestSync("Transactions/find-transactions", user, params);
}

export const LoadWalletTransaction = (user, transactionId) => {
    return ApiGetRequestSync("Transactions/details/" + transactionId, user);
}