import React from 'react';

function InputDateRange(props) {
    const { name, id, value, onChange } = props;

    function handleOnChange(event) {
        if (onChange) {
            const newEvent = new CustomEvent("change", {
                detail: {
                    type: event.target.value,
                    from: null,
                    to: null
                }
            });

            onChange(newEvent);
        }
    }

    return <select name={name} id={id} value={value.type ?? ""} onChange={handleOnChange}>
        <option value="" disabled>Date Range</option>
        <option value="today">Today</option>
        <option value="yesterday">Yesterday</option>
        <option value="last7">Last 7 days</option>
        <option value="last14">Last 14 days</option>
        <option value="currentMonth">Month to date</option>
        <option value="last30">Last 30 days</option>
        <option value="lastMonth">Last month</option>
        <option value="last90">Last 90 days</option>
        <option value="last3Months">Last 3 months</option>
    </select>
}

export default InputDateRange;
