import React, { useState } from "react";
import ReactJson from 'react-json-view'
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as ButtonX } from "../../assets/images/button-x.svg";
import { hideLogView } from '../../shared/store/slices/transactionDetails';

function LogOverlayView(props) {

    const { displayLog, logView } = useSelector(state => state.transactionDetails);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(hideLogView());
    }

    return (logView?.length > 0 && displayLog ?
        <div id="overlay" style={{ left:'25%', width: '50%', height: '75%', top:'125px' }}>
            <div></div>
            <div>
                <ButtonX id="button-x" onClick={() => { handleClose(); }} />
            </div>
            <div style={{ marginLeft:'20px' }}>
                <ReactJson src={JSON.parse(logView)} />
            </div>
        </div> : <div></div>);
}

export default LogOverlayView;