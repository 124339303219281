import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import Routes from '../shared/routes';
import store from '../shared/store/store'; 
import userManager from '../shared/userManager';
import '../assets/css/App.css';
import AdminProvider from "./AdminProvider";
import StandardPage from "./StandardPage";
import {BrowserRouter} from "react-router-dom";

export default function App() {

    return (
        <Provider store={store}>
            <OidcProvider store={store} userManager={userManager}>
                <AdminProvider>
                    <BrowserRouter>
                       <StandardPage>
                           <Routes />
                       </StandardPage>
                    </BrowserRouter>
                </AdminProvider>
            </OidcProvider>
        </Provider>
    );
}
