import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import React from "react";

function LogOutPage() {
  TitleEffect(PageTitle("LoggedOut"));

  return (<main className="not-found centeredcontent">
    <div>
      <h1>Successfully Logged out</h1>
    </div>
  </main>);
}

export default LogOutPage;