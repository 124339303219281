import React from "react";
import { useDispatch } from 'react-redux';
import { ReactComponent as IconLog } from './images/txicon-log.svg';
import {
    showOverlay
} from '../../shared/store/slices/userInterface';
import {
    setWalletDetailsLog
} from '../../shared/store/slices/transactionDetails';
import styles from './Table.wallet.module.css';
import NoData from "../../components/NoData";

export default function Listing(props) {
    const { walletTransactionDetails } = props;
    const dispatch = useDispatch();

    const headers = [
        {title: "Created On",        width: 0},
        {title: "TX Event ID",       width: 0},
        {title: "Event Type",        width: 0},
        {title: "Gateway Reference", width: 0},
        {title: "Comment",           width: 0},
        {title: "Log",               width: 0},
        {title: "Created By",        width: 0}
    ];
    
    function handleClickApiLog(log) {
        dispatch(setWalletDetailsLog(log));
        dispatch(showOverlay("logs_api"));
    }

    return walletTransactionDetails !== null && walletTransactionDetails !== undefined && walletTransactionDetails?.events?.length > 0 ?
    <div style={{width: "100%", overflowX: "auto", whiteSpace: "nowrap"}}>
        <table className={`${styles.table} ant-table-fixed`} style={{ tableLayout: "fixed" }}>
            <thead>
                <tr>
                    {headers.map((hd) => {
                        return <th key={hd.title}>{ hd.title }</th>   
                    })}
                </tr>
            </thead>
            <tbody>
                {walletTransactionDetails.events.map((evt, idx) => {
                    return <tr key={idx}>
                        <td>{evt.createdOn}</td>
                        <td>{evt.id}</td>
                        <td>{evt.status}</td>
                        <td>{evt.gatewayReference}</td>
                        <td>{evt.comment}</td>
                        <td className={evt.log?.length > 0 ? 'txicons' : ''}>{ evt.log?.length > 0 ? <IconLog className="txiconlog" onClick={() => { handleClickApiLog(evt.log) }} /> : null }</td>
                        <td>{evt.createdBy}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </div> : <NoData />
}
