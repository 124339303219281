import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateSearchText,
    updateParameter,
    updateDateRange,
    updateAdvancedFilter,
    updateCreatedOnSortOrder,
    TransactionWalletSearchQuery
} from '../../shared/store/slices/transactionSearch'

import {
    getAvailableCurrencies,
    getAvailableMethods,
} from "../../shared/store/slices/payouts";

import InputDateRange from './InputDateRange';
import { DateRange, FormatIso8601 } from '../../shared/helpers/TimeHelper';
import 'antd/dist/antd.css';
import { Popover } from 'antd';
import TransactionWalletAdvancedFilterOptions from './TransactionWalletAdvancedFilterOptions';

function TransactionWalletFilterPane() {
    const { search, sort, dateRange, types, amount, currencies, methods } = useSelector(state => state.transactionSearch.criteria);
    const { advancedFilterVisible, filtersDoSearch } = useSelector(state => state.transactionSearch);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAvailableCurrencies());
        dispatch(getAvailableMethods());
    }, [dispatch]);

    useEffect(() => {
        if (filtersDoSearch)
            performSearch();
    }, [filtersDoSearch]);

    function handleTextChange(event) {
        dispatch(updateSearchText(event.target.value));
    }

    function handleParameterChange(event) {
        dispatch(updateParameter(event.target.value));
    }

    function handleCreatedOnSortOrderChange(event) {
        dispatch(updateCreatedOnSortOrder(event.target.value));
    }
    
    function handleDateRangeChange(event) {
        dispatch(updateDateRange(event.detail));
    }

    function handleAdvancedFilterClick() {
        dispatch(updateAdvancedFilter());
    }

    function performSearch() {
        if (dateRange.type) {
            const range = DateRange(dateRange.type);
            const query = {
                from: FormatIso8601(range.start),
                to: FormatIso8601(range.end),
                page_size: 40,
                page: 0
            };

            if (search.parameter && search.text) {
                query[search.parameter] = search.text;
            }

            if (sort.sortOrder) {
                query['sortOrder'] = sort.sortOrder;
            }

            if (amount.operation && amount.value) {
                query['amount_operator'] = amount.operation;
                query['amount'] = amount.value;
            }

            if (currencies) {
                query['currencies'] = currencies;
            }

            if (types) {
                query['types'] = types;
            }

            if (methods) {
                query['methods'] = methods;
            }

            dispatch(TransactionWalletSearchQuery(query));
        }
    }
    
    function handleSubmit(event){
        event.preventDefault();
        performSearch();
    }

    return <form onSubmit={ handleSubmit }>
        <div id="transsearchnav">
            <div className="navcell navcellsearchbar">
                <input type="text" id="transsearchbox" name="search" placeholder="Search" value={search.text} onChange={handleTextChange} />
            </div>
    
            <div className="navcell  navcellparameter" style={{ "display": "flex", "alignItems": "baseline" }}>
                <select name="parameter" id="parameter" value={search.parameter ?? ""} onChange={handleParameterChange}>
                    <option value="" disabled>Parameter</option>
                    <option value="transaction_id">Wallet TX ID</option>
                    <option value="email">Email Address</option>
                    <option value="merchant_id">Merchant ID</option>
                </select>
            </div>
    
            <div className="navcell navcellrange" style={{ "display": 'flex', "alignItems": 'inherit' }}>
                <InputDateRange name="merchant" id="merchant" value={dateRange} onChange={handleDateRangeChange} />
            </div>
    
            <div className="navcell navcellsort" style={{ "display": 'flex', "alignItems": 'inherit' }}>
                <select name="sortOrder" id="sortOrder" value={sort.sortOrder ?? ""} onChange={handleCreatedOnSortOrderChange}>
                    <option value="" disabled>Sort By</option>
                    <option value="desc">Most Recent First</option>
                    <option value="asc">Oldest First</option>
                </select>
            </div>
    
            <Popover content={<TransactionWalletAdvancedFilterOptions />} title="Filter by" trigger="click" visible={advancedFilterVisible} onVisibleChange={handleAdvancedFilterClick}>
                <div className="navcell navcellfilter"><button className="buttonprimary secondary navbutton" type="button">Filters</button></div>
            </Popover>
    
            <div className="navcellsearchbutton"><button className="buttonprimary primary navbutton" type="submit">Search</button></div>
        </div>
    </form>
}

export default TransactionWalletFilterPane;
