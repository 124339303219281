import React from 'react';
import { Comment, Form, Button, Collapse, Input } from 'antd';
import moment from 'moment';

export default function TreeCommentComponent({ data, parentId = '0', idx = 0, openReply, onchangeReply, isReplying, replyCommentObj, submittingReply, addReply, closeReply }) {

    const { Panel } = Collapse;
    const { TextArea } = Input;
    const items = 
        data.filter((item) => ((item?.parentId === parentId) || parentId === '0' && (item?.parentId === null || item?.parentId === undefined)))
        .sort((a, b) => (a.commentDate < b.commentDate ? 1 : -1));
    if(!items.length) return null;
    
    return (
        <>
          {items.map((comment) => (
            <>
            <Collapse defaultActiveKey={[idx]} ghost name={'collpse_'+comment.id}>
                <Panel header={comment.agentNameComment + ' ' + moment(comment.commentDate).format('DD/MM/YYYY HH:mm:ss')} key={idx}>
                <Comment
                    actions={[<span name={"comment-nested-reply-to-"+comment.id} onClick={() => { openReply(comment.id); }}>Reply to</span>]}
                    content={comment.comment}>
                    {isReplying === comment.id && 
                    <>
                    <Form.Item>
                        <TextArea name={'txt_'+comment.id} rows={4} onChange={onchangeReply} value={replyCommentObj.comment} />
                    </Form.Item>
                    <Form.Item>
                        <Button name={'btn_'+comment.id} htmlType="submit" loading={submittingReply} onClick={() => { addReply(comment.id); }} className="buttonprimary primary navbutton">Reply</Button>
                        <Button name={'btn_c_'+comment.id} htmlType="reset" onClick={() => { closeReply() }} className="navbutton">Cancel</Button>
                    </Form.Item>
                    </>}
                    <TreeCommentComponent
                        data={data} 
                        parentId={comment.id} 
                        idx={ idx + 1} 
                        openReply={openReply} 
                        onchangeReply={onchangeReply}
                        isReplying={isReplying}
                        replyCommentObj={replyCommentObj}
                        submittingReply={submittingReply}
                        addReply={addReply} />
                </Comment>
                </Panel>
            </Collapse>
          </>))}
        </>
      );
}