import '../assets/css/Menu.css';
import React, { useEffect, useContext, useRef } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { switchMenu, setMenuOnOpen, setStowedPosition, setActionCount, addSubMenuOptions } from '../shared/store/slices/menu';
import userManager from '../shared/userManager';
import { isObject } from '../shared/utils';
import {AdminChannelContext} from "./AdminProvider";
import {getApprovedBatches, getWithdrawalBatchesForApproval, getWithdrawalTransactionsPending}
    from "../shared/store/slices/payouts";
import {loadPendingAdjustments} from "../shared/store/slices/adjustments";
import { getGroups } from '../shared/store/slices/limits';

export default function Menu() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const context = useContext(AdminChannelContext);
    const currentCustomer = useSelector(state => state.customers.current);
    const { user } = useSelector(state => state.oidc);
    const authenticated = user && !user.expired;

    const { Main_Menu, currentMenu, menuOnOpen, stowedPositions, ws_connectionState } = useSelector(state => state.menu);
    const { currentNotification, totalNotificationsReceived } = useSelector(state => state.menu.notifications);

    const viewportRef = useRef();
    useEffect(() => {
        if (!context || ws_connectionState !== 'Connected' || !authenticated){
            return;
        }

        initializeActionCounts();
    }, [user, context, ws_connectionState]);

    useEffect(() => {
        if(currentMenu && currentMenu === 'Wallets')
            dispatch(getGroups())
                .then(response => {
                    if(response && response.payload) {
                        let subMenuOptions = {
                            option: "Main_Menu.Wallets.Limits",
                            items: []
                        };
                        response.payload.forEach((l, idx) => {
                            subMenuOptions.items.push({
                                name: l.name,
                                href: "/wallets/limits/" + l.id
                            });
                        });

                        dispatch(addSubMenuOptions(subMenuOptions));
                    }
                });
    }, [currentMenu])

    function initializeActionCounts(){
        // dispatch(getDocumentsPendingQueue())
        //     .then (response => {
        //         dispatch(setActionCount({
        //             ActionCategory: "SecurityPendingDocumentReview",
        //             CountRemaining: response.payload.length
        //         }));
        //     });

        dispatch(getWithdrawalTransactionsPending())
            .then (response => {
                dispatch(setActionCount({
                    ActionCategory: "SecurityPendingWalletWithdrawals",
                    CountRemaining: response.payload.length
                }));
            });

        dispatch(getWithdrawalBatchesForApproval())
            .then (response => {
                dispatch(setActionCount({
                    ActionCategory: "AccountingPendingWithdrawalQueueLevelOne",
                    CountRemaining: response.payload.length
                }));
            });

        dispatch(getApprovedBatches())
            .then (response => {
                dispatch(setActionCount({
                    ActionCategory: "AccountingPendingWithdrawalQueueLevelTwo",
                    CountRemaining: response.payload.length
                }));
            });

        dispatch(loadPendingAdjustments())
            .then (response => {
                dispatch(setActionCount({
                    ActionCategory: "AccountingPendingAdjustments",
                    CountRemaining: response.payload.length
                }));
            });
    }

    useEffect(() => {
        console.log({"CurrentNotification" : currentNotification, "TotalNotifications" : totalNotificationsReceived });
        if (currentNotification) {
            currentNotification.forEach((notification) => {
                switch (notification.actionCategory) {
                    case "SecurityPendingDocumentReview":
                    case "SecurityPendingWalletWithdrawals":
                    case "AccountingPendingWithdrawalQueueLevelOne":
                    case "AccountingPendingWithdrawalQueueLevelTwo":
                    case "AccountingPendingAdjustments":{
                        dispatch(setActionCount({
                            ActionCategory: notification.actionCategory,
                            CountRemaining: notification.countRemaining
                        }));
                        break;
                    }
                    default:
                        break;
                }
            });
        }
    }, [totalNotificationsReceived]);

    useEffect(() => {
        var activeMenu = undefined;
        if (location.pathname === "/callback"){
            return;
        }

        function findActiveMenu(menu, parent) {
            Object.entries(menu).map(([id, option]) => {
                if (option.subMenu) {
                    findActiveMenu(option.subMenu, id)
                }

                if ((option.href === location.pathname) ||
                    (option.altRoutes && option.altRoutes.indexOf(location.pathname) !== -1) ||
                    (option.subRoutes && new RegExp(option.subRoutes).test(location.pathname))) {
                    activeMenu = parent;
                }

                return null;
            });
        }

        findActiveMenu(Main_Menu, undefined);

        if (activeMenu !== undefined) {
            dispatch(setStowedPosition({menu: currentMenu, position: 'left '}));
            dispatch(setStowedPosition({menu: activeMenu, position: ''}));
            dispatch(switchMenu(activeMenu));
        }
    }, [location.pathname]);

    function overrideOption(id, specs) {
        if (id === 'Current_Customer') {
            return {
                caption: "Current: " + currentCustomer.email,
                className: (currentCustomer && currentCustomer.email) ? "" : "hidden",
                skipSeparator: !(currentCustomer && currentCustomer.email)
            };
        }

        return {};
    }

    function handleMenuOpen() {
        //Need to reset scroll position of menu-viewport used to scroll the different submenus sideways,
        //otherwise when main menu is fully scrolled down, the submenu will also be scrolled all the way
        //down and it will appear to have no options, but they are at the very top
        viewportRef.current.scrollTop = 0;

        dispatch(switchMenu(menuOnOpen));
        dispatch(setStowedPosition({menu: menuOnOpen, position: ''}));
    }

    function stowAll(immediate = false) {
        for (var subMenuId in subMenus) {
            var position;

            if ((subMenus[currentMenu] && subMenus[currentMenu].ancestors.indexOf(subMenuId) !== -1) || (subMenuId === currentMenu)) {
                position = 'left';
            } else {
                position = 'right' + (immediate ? ' no-transition' : '');
            }

            dispatch(setStowedPosition({menu: subMenuId, position: position}));
        }
    }

    function handleMenuClose() {
        dispatch(setStowedPosition({menu: currentMenu, position: 'left'}));

        setTimeout(() => stowAll(true), 310);
        setTimeout(() => stowAll(), 350);

        dispatch(setMenuOnOpen(currentMenu));
        dispatch(switchMenu('none'));
    }

    function handleMenuToggle() {
        currentMenu !== 'none' ? handleMenuClose() : handleMenuOpen();
    }

    function handleSubMenuOpen(event) {
        viewportRef.current.scrollTop = 0;

        const nextMenu = event.target.getAttribute('submenu') || 'Main_Menu';

        dispatch(setStowedPosition({menu: currentMenu, position: 'left'}));
        dispatch(setStowedPosition({menu: nextMenu, position: ''}));
        dispatch(switchMenu(nextMenu));
    }

    function handleSubMenuClose(event) {
        viewportRef.current.scrollTop = 0;

        const nextMenu = event.target.getAttribute('parentmenu') || 'Main_Menu';

        dispatch(setStowedPosition({menu: currentMenu, position: 'right'}));

        subMenus[currentMenu]?.ancestors.forEach(ancestor => {
            if (ancestor !== 'Main_Menu') {
                dispatch(setStowedPosition({menu: ancestor, position: 'right'}));
            }
        });

        dispatch(setStowedPosition({menu: nextMenu, position: ''}));
        dispatch(switchMenu(nextMenu));
    }

    function handleLogin(event) {
        event.preventDefault();
        userManager.signinRedirect({
            data: {
                path: "/home"
            }
        });
    }

    function handleLogout() {
        event.preventDefault();

        setTimeout(() => {
            dispatch(switchMenu('Main_Menu'));
            dispatch(setMenuOnOpen('Main_Menu'));
        }, 100);

        userManager.signoutRedirect({ id_token_hint: user.id_token });
        userManager.removeUser();
        history.push('/logged-out');
    }

    const subMenus = {};

    function extractSubMenus(menu, ancestors) {
        Object.entries(menu).map(([id, option]) => {
            if (option.subMenu) {
                const subMenu = { ...option.subMenu };

                subMenu.ancestors = ancestors;
                subMenus[id] = subMenu;

                extractSubMenus(option.subMenu, [...ancestors, id])
            }

            return null;
        });
    }

    if (authenticated) {
        extractSubMenus(Main_Menu, ['Main_Menu']);

        return <div id="mainmenu">
            <div className="menuslidebutton" onClick={ handleMenuToggle } />
            <div id="menu-viewport" ref={ viewportRef }>
                <div id="Main_Menu" className={ "menu-content" + (currentMenu === 'Main_Menu' ? "" : (" stowed " + stowedPositions['Main_Menu'])) }>
                    <ul key="Main_Menu_options" className="menu">
                        { Object.entries(Main_Menu).map(([id, option]) => {
                            if (isObject(option)) {
                                var overrides = overrideOption(id, option);

                                var className = (overrides.className || option.className || "");
                                var caption = overrides.caption || option.caption || id.replaceAll('_', ' ');
                                var active = ((location.pathname === option.href) ||
                                              (option.altRoutes && (option.altRoutes.indexOf(location.pathname) !== -1)) ||
                                              (option.subRoutes && new RegExp(option.subRoutes).test(location.pathname))) ? " active" : "";
                                var actionCount = (option.actionCount && option.actionCount !== 0)
                                    ? <div className="alertcounter" key={ `${id}_count` } >{option.actionCount}</div>
                                    : '';
                                var li;

                                if (option.subMenu) {
                                    li = <li key={ id } className={ "menulink " + className } onClick={ handleSubMenuOpen } submenu={ id }>
                                        { actionCount }{ caption }
                                    </li>;
                                }
                                else if (option.signOut) {
                                    li = <li key={ id } className={ "menulink " + className + " nodisclosure" } onClick={ handleLogout } >
                                        { actionCount }{ caption }
                                    </li>;
                                }
                                else if (option.href) {
                                    li = <li key={ id } className={ className }>
                                        <Link to={ option.href } className={ "menulink " + className + " nodisclosure " + active }>{ actionCount }{ caption }</Link>
                                    </li>;
                                }
                                else if (option.action) {
                                    li = <li key={ id } className={ "menulink " + className } onClick={ option.action }>
                                        { actionCount }{ caption }
                                    </li>;
                                }
                                else {
                                    li = <li key={ id } className={ "menulink " + className + " nodisclosure " + (overrides.liClassName || "") }>
                                        { actionCount }{ caption }
                                    </li>;
                                }

                                return <>
                                    { li }
                                    <li key={ `${id}_sep` } className="separator" />
                                </>
                            }

                            return null;
                        })}
                    </ul>
                </div>

                { Object.entries(subMenus).map(([id, subMenu]) => {
                    var subMenuItems = [];

                    Object.entries(subMenu.ancestors).map(([idx, ancestor]) => {
                        var caption = subMenus[ancestor]?.caption || ancestor.replace("_", " ");

                        subMenuItems.push(
                            <li className="menulink back" key={ `${id}_${ancestor}_${idx}_back` } onClick={ handleSubMenuClose } parentmenu={ ancestor }>
                                { caption }
                            </li>
                        );

                        subMenuItems.push(<li className="separator" key={ `${id}_${ancestor}_${idx}_sep` } />);

                        return null;
                    });

                    Object.entries(subMenu).map(([idx, option]) => {
                        if (isObject(option)) {
                            var overrides = overrideOption(idx, option);

                            var className = (overrides.className || option.className || "");
                            var caption = overrides.caption || option.caption || idx.replaceAll('_', ' ');
                            var active = ((location.pathname === option.href) ||
                                          (option.altRoutes && (option.altRoutes.indexOf(location.pathname) !== -1)) ||
                                          (option.subRoutes && new RegExp(option.subRoutes).test(location.pathname))) ? " active" : "";
                            var actionCount = option.actionCount ? <div className="alertcounter" key={ `${id}_${idx}_count` }>{option.actionCount}</div> : '';
                            var li;

                            if (option.subMenu) {
                                li = <li key={ `${id}_${idx}` } className={ "menulink " + className } onClick={ handleSubMenuOpen } submenu={ idx }>
                                    { actionCount }{ caption }
                                </li>;
                            }
                            else if (option.href) {
                                li = <li key={ `${id}_${idx}` } className = { className } id={ `${id}_${idx}` }>
                                    <Link key={ `${id}_${idx}_link` }
                                        to={ option.href }
                                        className={ "menulink " + className + " nodisclosure" + active }>
                                        { actionCount }{ caption }
                                    </Link>
                                </li>;
                            }
                            else if (option.action) {
                                li = <li key={ `${id}_${idx}` } className={ "menulink " + className } onClick={ option.action }>
                                    { actionCount }{ caption }
                                </li>;
                            }
                            else if (option.signOut) {
                                li = <li key={ `${id}_${idx}` } className={ "menulink " + className + " nodisclosure" } onClick={ handleLogout } >
                                    { actionCount }{ caption }
                                </li>;
                            }
                            else {
                                li = <li key={ `${id}_${idx}` } className={ "menulink " + className + " nodisclosure " }>
                                    { actionCount }{ caption }
                                </li>;
                            }

                            subMenuItems.push(li);
                            if (!overrides.skipSeparator)
                                subMenuItems.push(<li key={ `${id}_${idx}_sep` } className="separator" />);
                        }

                        return null;
                    });

                    return <div id={ id } key={ `${id}_content` } className={ "submenu-content" + (currentMenu === id ? "" : (" stowed " + stowedPositions[id])) }>
                        <ul key={ `${id}_options` } className="menu">
                            { subMenuItems }
                        </ul>
                    </div>
                }) }
            </div>
        </div>
    } else {
        return <div id="mainmenu">
            <div className="menuslidebutton" onClick={ handleMenuToggle } />
            <div id="menu-viewport" ref={ viewportRef }>
                <div id="Main_Menu" className={ "menu-content" + (currentMenu === 'Main_Menu' ? "" : (" stowed " + stowedPositions['Main_Menu'])) }>
                    <ul key="Main_Menu_options" className="menu">
                        <li key='signin' className={ "menulink nodisclosure boldtext" } onClick={ handleLogin }>
                            Sign In
                        </li>
                        <li key="separator" className="separator" />
                    </ul>
                </div>
            </div>
        </div>
    }
}