import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from './userInterface';
import { SearchProfiles } from "../../../domain/services/search.svc";
import {
    GetCustomerProfile,
    GetAgentProfile,
    SaveCustomerProfile,
    AddCustomerComment,
    GetCustomerComments,
    GetRequiredActions,
    GetCustomerProfileByEmail
} from "../../../domain/services/customer.svc";
import { GetCustomerBalance } from "../../../domain/services/adjustments.svc";

export const searchUsers = createAsyncThunk(
    'customers/searchUsers',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await SearchProfiles(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getCustomerBalance = createAsyncThunk(
    'customers/getCustomerBalance',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await GetCustomerBalance(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getCustomerProfile = createAsyncThunk(
    'customers/getCustomerProfile',
    async (params, { rejectWithValue, dispatch, requestId, getState}) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await GetCustomerProfile(user, params.customerId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getAgentProfile = createAsyncThunk(
    'customers/getAgentProfile',
    async (params, { rejectWithValue, dispatch, requestId, getState}) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await GetAgentProfile(user, params.customerId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getCustomerProfileByEmail = createAsyncThunk(
    'customers/getCustomerProfileByEmail',
    async (params, { rejectWithValue, dispatch, requestId, getState}) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await GetCustomerProfileByEmail(user, params.realm, params.email)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const saveCustomerProfile = createAsyncThunk(
    'customers/saveCustomerProfile',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await SaveCustomerProfile(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getCustomerComments = createAsyncThunk(
    'customers/getCustomerComments',
    async (params, { rejectWithValue, dispatch, requestId, getState}) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await GetCustomerComments(user, params.customerId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const saveCustomerComment = createAsyncThunk(
    'customers/saveCustomerComment',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await AddCustomerComment(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getRequiredActions = createAsyncThunk(
    'customers/getRequiredActions',
    async (params, { rejectWithValue, dispatch, requestId, getState}) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await GetRequiredActions(user, params.customerId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const customersSlice = createSlice({
    name: 'customers',

    initialState: {
        customers: [],
        current: {},
        comments: [],
        requiredActions: [],
        searchText: '',
        searchBy: 'email',
        showMe: 'customers',
        loading: undefined,
        overlayContent: "",
        apiError: "",
        signUpFromDate: "",
        signUpEndDate: "",
        signUpPage: 1,
        signUpPageSize: 20,
        completedSignUpCount: 0,
        inRealm: '',
        isEditing: false
    },

    reducers: {
        reset: (state) => {
            state.customers = [];
            state.comments = [];
            state.requiredActions = [];
            state.current = {};
            state.searchText = '';
            state.searchBy = 'email';
            state.showMe = 'customers';
            state.inRealm = '';
            state.isEditing = false;
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload;
        },
        setSearchBy: (state, action) => {
            state.searchBy = action.payload;
        },
        setShowMe: (state, action) => {
            state.showMe = action.payload;
        },
        setCurrent: (state, action) => {
            const id = action.payload;
            const customer = state.customers.filter(customer => customer.id === id);

            state.current = customer[0];
        },
        setOverlayContent: (state, action) => {
            state.overlayContent = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload
        },
        setCurrentCustomerProfile: (state, action) => {
            state.current = action.payload;
        },
        ready: (state) => {
            state.isReady = true;
        },
        setSignUpDateRange: (state, action) => {
            state.signUpFromDate = action.payload[0];
            state.signUpEndDate = action.payload[1];
        },
        setSignUpPage: (state, action) => {
            state.signUpPage = action.payload;
        },
        setSignUpPageSize: (state, action) => {
            state.signUpPageSize = action.payload;
        },
        setInRealm: (state, action) => {
            state.inRealm = action.payload;
        },
        loadComments: (state, action) => {
            state.comments = action.payload;
        },
        loadRequiredActionsList: (state, action) => {
            state.requiredActions = action.payload;
        }
    },

    extraReducers: {
        [searchUsers.pending]: (state) => {
            state.loading = true;
        },
        [searchUsers.fulfilled]: (state, action) => {
            state.customers = action.payload.userList || [];
            state.completedSignUpCount = state.completedSignUpCount === 0 ? action.payload.completedSignUpCount : state.completedSignUpCount;
            state.current = {};
            state.loading = false;
        },
        [searchUsers.rejected]: (state) => {
            state.loading = false;
        },
        [getCustomerBalance.fulfilled]: (state, action) => {
            state.current.balance = action.payload;
        },
        [getCustomerBalance.rejected]: () => {
        }
    }
})

// Action creators are generated for each case reducer function
export const { reset,
    setSearchText,
    setSearchBy,
    setShowMe,
    ready,
    setSignUpDateRange,
    setSignUpPage,
    setSignUpPageSize,
    setIsEditing,
    setCurrentCustomerProfile,
    loadComments,
    loadRequiredActionsList,
    setInRealm
} = customersSlice.actions;

export default customersSlice.reducer;
