import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { getBatchTransactions,
         loadBatchTransactions } from "../../shared/store/slices/payouts";
import { Table } from 'antd';
import FormatTime from '../../shared/helpers/FormatTime';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import moment from "moment";
import { getCustomerProfileByEmail, setCurrentCustomerProfile } from '../../shared/store/slices/customers';

function BatchTransactionsPage(props) {

    TitleEffect(PageTitle("Withdrawal TX View | Accounting "));

    const { batchId } = useParams();
    const { isLoading } = useSelector(state => state.userInterface);
    const { batchTransactions } = useSelector(state => state.payouts);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [searchParameter, setSearchParameter] = useState('email');
    const [searchTerm, setSearchTerm] = useState('');

    const docparameterStyle = {
        display: 'flex',
        alignItems: 'baseline'
    }

    const [batchTransactionsList, setBatchTransactionsList] = useState([]);

    const goToCustomerProfile = (realm, email) => {
        //TODO Ticket 4041 - Return values from batch queries to submit a profile (userID or email + realm)
        dispatch(getCustomerProfileByEmail({
            realm: realm || "wallet88",
            email: email
        }))
        .then((response) =>{
            dispatch(setCurrentCustomerProfile(response.payload));
            history.push("/customer/profile");
        });
    }

    const columns = [{ 
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Email',
            dataIndex: 'Email',
            render: (text, record) => {
                return <a href="#" className="emaillink" onClick={() => { goToCustomerProfile(record.realm, record.email); }}>{ record.email }</a>
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.email.localeCompare(b.email) }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Name',
            dataIndex: 'Name',
            render: (text, record) => {
                return <a href="#" className="emaillink" onClick={() => { goToCustomerProfile(record.realm, record.email); }}>{ record.name }</a>
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.name.localeCompare(b.name) }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Amount',
            dataIndex: 'Amount',
            render: (text, record) => {
                return record.formattedAmount;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.amount - b.amount }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Currency', 
            dataIndex: 'Currency',
            render: (text, record) => {
                return record.currency;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.currency.localeCompare(b.currency) }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Method', 
            dataIndex: 'Method',
            render: (text, record) => {
                return record.method;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.method.localeCompare(b.method) }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Country', 
            dataIndex: 'Country',
            render: (text, record) => {
                return record.country;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.country.localeCompare(b.country) }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Timestamp', 
            dataIndex: 'CreatedOn',
            render: (text, record) => {
                return FormatTime(record.createdOn);
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (dateA,dateB) => { return moment(dateA.createdOn).diff(moment(dateB.createdOn)) }
        },
    ];

    const handleSearchParameter = (event) => {
        setSearchParameter(event.target.value);
    }

    const handleSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleFilter = () => {
        setIsLoadingData(true);
        filterList();
        setIsLoadingData(false);
    }

    const filterList = () => {
        if(searchTerm !== '') {
            setIsLoadingData(true);
            switch (searchParameter) {
                case 'email':
                    setBatchTransactionsList(batchTransactions.filter(d => d.userEmail.indexOf(searchTerm) >= 0));
                    break;
                case 'ctry':
                    setBatchTransactionsList(batchTransactions.filter(d => d.userCountry.indexOf(searchTerm) >= 0));
                    break;
                case 'name':
                    setBatchTransactionsList(batchTransactions.filter(d => d.userName.indexOf(searchTerm) >= 0));
                    break;
            }
        } else {
            setBatchTransactionsList(batchTransactions);
        }
    }

    const loadData = () => {
        setIsLoadingData(true);
        dispatch(getBatchTransactions({ batchId: batchId }))
        .then((response => {
            batch(()=>{
                dispatch(loadBatchTransactions(response.payload));
                setBatchTransactionsList(response.payload);
                setIsLoadingData(false);
            })
            
        }));
    }

    useEffect(()=> {
        loadData();
        filterList();
    }, [])

    return <div>
        <nav>
            <div id="docsearch">
                <div className="navcell docsearchbar">
                    <input type="text" id="transsearchbox" name="search" value={searchTerm} onChange={handleSearchTerm} />
                </div>
                <div className="navcell docparameter" style={docparameterStyle}>
                    <select name="parameter" id="parameter" value={searchParameter} onChange={handleSearchParameter}>
                        <option value="email">Email</option>
                        <option value="name">Name</option>
                        <option value="ctry">Country</option>
                    </select>
                </div>
                <div className="docsearchbutton">
                    <button className="buttonprimary primary navbutton" onClick={handleFilter}>Search</button>
                </div>
            </div>
        </nav>
        <main className="searchtransactions">
            <Table 
                loading={isLoading || isLoadingData} 
                rowKey={r => r.id} 
                columns={columns} 
                dataSource={batchTransactionsList} 
                pagination={false}
                scroll={{x : "auto"}}
            />
            <div className="text-center"><p id="nomoretx">The end</p></div>
        </main>
    </div>
}

export default BatchTransactionsPage;