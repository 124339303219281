// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.tU6swH5CEKb0m2axBJxM {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(8, auto);
  row-gap: 15px;
  column-gap: 15px;

  grid-template-areas:
    "a a"
    "b b"
    "c c"
    "d d"
    "e e"
    "f f"
    "h i";

  width: 350px;
}

div.tU6swH5CEKb0m2axBJxM>.J0NA4hkBWe3i6ciwu78W {
  text-align: left;
}

div.tU6swH5CEKb0m2axBJxM>:nth-child(1) {
  grid-area: a;
}

div.tU6swH5CEKb0m2axBJxM>:nth-child(2) {
  grid-area: b;
}

div.tU6swH5CEKb0m2axBJxM>:nth-child(3) {
  grid-area: c;
}

div.tU6swH5CEKb0m2axBJxM>:nth-child(4) {
  grid-area: d;
}

div.tU6swH5CEKb0m2axBJxM>:nth-child(5) {
  grid-area: e;
}

div.tU6swH5CEKb0m2axBJxM>:nth-child(6) {
  grid-area: f;
}

div.tU6swH5CEKb0m2axBJxM>:nth-child(7) {
  grid-area: h;
}

div.tU6swH5CEKb0m2axBJxM>:nth-child(8) {
  grid-area: i;
}`, "",{"version":3,"sources":["webpack://./src/domain/TransactionPage/TransactionAdvancedFilterOptions.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mCAAmC;EACnC,aAAa;EACb,gBAAgB;;EAEhB;;;;;;;SAOO;;EAEP,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":["div.advfilters {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-template-rows: repeat(8, auto);\n  row-gap: 15px;\n  column-gap: 15px;\n\n  grid-template-areas:\n    \"a a\"\n    \"b b\"\n    \"c c\"\n    \"d d\"\n    \"e e\"\n    \"f f\"\n    \"h i\";\n\n  width: 350px;\n}\n\ndiv.advfilters>.ant-select-selector {\n  text-align: left;\n}\n\ndiv.advfilters>:nth-child(1) {\n  grid-area: a;\n}\n\ndiv.advfilters>:nth-child(2) {\n  grid-area: b;\n}\n\ndiv.advfilters>:nth-child(3) {\n  grid-area: c;\n}\n\ndiv.advfilters>:nth-child(4) {\n  grid-area: d;\n}\n\ndiv.advfilters>:nth-child(5) {\n  grid-area: e;\n}\n\ndiv.advfilters>:nth-child(6) {\n  grid-area: f;\n}\n\ndiv.advfilters>:nth-child(7) {\n  grid-area: h;\n}\n\ndiv.advfilters>:nth-child(8) {\n  grid-area: i;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advfilters": `tU6swH5CEKb0m2axBJxM`,
	"ant-select-selector": `J0NA4hkBWe3i6ciwu78W`
};
export default ___CSS_LOADER_EXPORT___;
