export function noop() {
}

export function isObject(candidate) {
    return typeof candidate === 'object' && !Array.isArray(candidate) && candidate !== null;
}

export function isArray(candidate) {
    return Array.isArray(candidate);
}

export const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Set', 'Oct', 'Nov', 'Dec'];

export function buildDateString(date) {
    return date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1).toString().padStart(2, '0') + "-" + date.getUTCDate().toString().padStart(2, '0');
}

export function buildQueryString(data) {
    return Object.entries(data).map(([name, value]) => encodeURIComponent(name) + '=' + encodeURIComponent(value)).join('&');
}

export function monthYear(date) {
    date = date || new Date();

    return {month: date.getMonth() + 1, year: date.getFullYear()};
}

export function monthsAgo(monthYearObject, months) {
    let monthThen = monthYearObject.month - 4;

    if (monthThen >= 1) {
        return {month: monthThen, year: monthYearObject.year};
    }
    else {
        return {month: 12 + monthThen, year: monthYearObject.year - 1};
    }
}

export function buildMonthYearString(date) {
    return date.month.toString().padStart(2, '0') + "-" + date.year.toString().padStart(4, '0');
}

export function setDateTimeState(newDate, oldDate) {
    //Only change the state if newDate is a Date, and... oldDate is not a Date or the date/times they represent are different.
    if ((Object.prototype.toString.call(newDate) === '[object Date]') && ((Object.prototype.toString.call(oldDate) !== '[object Date]') || (newDate.getTime() !== oldDate.getTime()))) {
        return newDate;
    }
    else {
        return oldDate;
    }
}

export function comparePeriods(periodA, periodB) {
    const perA = periodA.year * 100 + periodA.month;
    const perB = periodB.year * 100 + periodB.month;

    return (perA < perB) ? -1 : ((perA > perB) ? 1 : 0);
}

export function getPrevPeriod(period) {
    return {
        'year': (+period.year - (+period.month === 1)).toString(),
        'month': ((+period.month + 10) % 12 + 1).toString().padStart(2, '0')
    }
}

export function getNextPeriod(period) {
    return {
        'year': (+period.year + (+period.month === 12)).toString(),
        'month': (+period.month % 12 + 1).toString().padStart(2, '0')
    }
}

export const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
export const fxFormatter = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 4 });

export function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ConvertToMajorUnits(amount, currency) {
    return (amount / Scalar(currency.precision));
}

export function ConvertToMinorUnits(amount, currency) {
    return Math.round(amount * Scalar(currency.precision));
}

function Scalar(precision) {
    return Math.pow(10, precision);
}
