import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import styles from './BatchAdvancedFilterOptions.module.css';
import {
    updateDateRange,
    updateAdvancedFilter,
    updateWorking,
    applyWorking
} from '../../shared/store/slices/payouts';
import CurrencyAmountFilter from '../../components/CurrencyAmountFilter';

const { SHOW_CHILD } = TreeSelect;

const CurrencyListTreeData = (cs) => {
    if (!cs)
        return [];

    return cs.map(el => {
        return {
            title: el.code,
            value: el.code,
            key: el.code
        };
    });
}

const UserListTreeData = (cs) => {
    if (!cs)
        return [];

    return cs.map(el => {
        return {
            title: el.value,
            value: el.value,
            key: el.value
        };
    });
}

function ObjectIdNameTreeData(ms) {
    if (!ms)
        return [];

    return ms.map(el => {
        return {
            title: el.value,
            value: el.key,
            key: el.key
        }
    });
}

export default function BatchAdvancedFilterOptions() {

    const { currenciesList,
            methodsList,
            approvedByList,
            sentByList,
            working } = useSelector(state => state.payouts);

    const dispatch = useDispatch();

    function handleAmountChange(a) {
        dispatch(updateWorking({ amount: a }));
    }

    function selectCurrencies(cs) {
        dispatch(updateWorking({ currencies: cs }));
    }

    function selectMethods(ms) {
        dispatch(updateWorking({ methods: ms }));
    }

    function selectApprovedBy(ab) {
        dispatch(updateWorking({ approvedby: ab }));
    }

    function selectSentBy(sb) {
        dispatch(updateWorking({ sentby: sb }));
    }

    function handleCancel() {
        dispatch(updateAdvancedFilter(false));
    }

    function handleApply() {
        dispatch(applyWorking());
        dispatch(updateAdvancedFilter(false));
    }

    const handleDateRangeChange = (e) => {
        dispatch(updateDateRange(e.detail));
    }

    return ( <div className={styles.advfilters}>
        <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Method / Gateway" treeData={ObjectIdNameTreeData(methodsList)} onChange={selectMethods} value={working.methods} style={{ width: '350px' }} />
        <CurrencyAmountFilter value={working.amount} onChange={handleAmountChange} />
        <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Currency" treeData={CurrencyListTreeData(currenciesList)} onChange={selectCurrencies} value={working.currencies} style={{ width: '350px' }} />
        <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Approved By" treeData={UserListTreeData(approvedByList)} onChange={selectApprovedBy} value={working.approvedby} style={{ width: '350px' }} />
        <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Sent By" treeData={UserListTreeData(sentByList)} onChange={selectSentBy} value={working.sentby} style={{ width: '350px' }} />
        <button className="buttonprimary primary navbutton" onClick={handleApply}>Apply</button>
        <button className="buttonprimary secondary navbutton" onClick={handleCancel}>Cancel</button>
    </div>);
}