import React from "react";
import moment from "moment";
import { 
    getDocumentDetails, 
    loadDocumentDetails, 
    setShowApproveRejectButton, 
    setShowRequestNewDocButton } from "../../../shared/store/slices/documents";
import { useDispatch } from "react-redux";

function CustomerDocumentsList(props) {

    const { userDocuments } = props;
    const dispatch = useDispatch();

    const showModal = (event, documentId) => {
        event.preventDefault();
        dispatch(getDocumentDetails({ documentId: documentId }))
            .then((response => {
                dispatch(loadDocumentDetails(response.payload));
                dispatch(setShowRequestNewDocButton(false));
                dispatch(setShowApproveRejectButton(false));
            }));
    }
    
    const RenderDocumentLink = (props) => {
        return <>
            <div key={props.type}>
                {props.docs?.map((doc, idx) => {
                    return (<a key={doc.id} href="#" className="emaillink" onClick={(e) => { showModal(e, doc.id); }}>{
                        (idx > 0 ? ', ' : '' ) + doc.userDocumentTypeLabel + (idx > 0 ? ' (' + moment(doc?.uploadDate).format("DD/MM/YY") + ')':'')
                    } </a>)
                })}
            </div>
        </>
    }

    return <>
        <RenderDocumentLink docs={userDocuments?.filter(d => d.userDocumentTypeLabel === "Photo ID" )} type={"rqa_photoid_1"} />
        <RenderDocumentLink docs={userDocuments?.filter(d => d.userDocumentTypeLabel === "Bill" )} type={"rqa_bill_1"} />
        <RenderDocumentLink docs={userDocuments?.filter(d => d.userDocumentTypeLabel === "Selfie" )} type={"rqa_selfie_1"} />
    </>

}

export default CustomerDocumentsList;