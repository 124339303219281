import React, { useEffect } from 'react';
import MerchantSearchWidget from '../../components/MerchantSearchWidget';
import ExchangeListing from './ExchangeListing';
import { loadExchanges } from '../../shared/store/slices/exchanges'
import { useDispatch} from 'react-redux';

export default function ExchangesPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadExchanges({ status: 'Pending' }));
    }, [dispatch]);

    return <>
        <nav><MerchantSearchWidget /></nav>
        <main className="pendingexchanges">
            <ExchangeListing />
        </main>
    </>
}
