import React from 'react';
import CustomerSearchWidget from '../../../components/CustomerSearchWidget';
import {
    setSearchText,
    setSearchBy,
    setShowMe,
    searchUsers
} from '../../../shared/store/slices/customers'
import { useDispatch, useSelector } from 'react-redux';
import SearchListing from './SearchListing';
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";

export default function CustomersPage() {
    TitleEffect(PageTitle("Customers"));

    const dispatch = useDispatch();
    const { customers, searchText, searchBy, showMe } = useSelector(state => state.customers);

    function handleChangeText(event) {
        dispatch(setSearchText(event.target.value));
    }

    function handleChangeBy(event) {
        dispatch(setSearchBy(event.target.value));
    }

    function handleChangeShowMe(event) {
        dispatch(setShowMe(event.target.value));
    }

    function handleSearch(event) {
        dispatch(searchUsers({
            searchText: searchText,
            searchBy: searchBy,
            showMe: showMe
        }));
    }

    return <>
        <nav><CustomerSearchWidget /></nav>
        <main className="pendingsettlements">
            <SearchListing isSignupPage={false} />
        </main>
    </>
}
