import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Table.module.css';
import { settlementApprove, settlementDecline, loadSettlements } from '../../shared/store/slices/settlements'
import AntdTable from '../../components/AntdTable';
import { formatter } from '../../shared/utils';
import NoData from '../../components/NoData';
import {decode} from "html-entities";


export default function SettlementListing(props) {
    const { settlements, loading } = useSelector(state => state.settlements);
    const { isLoading } = useSelector(state => state.userInterface);
    const dispatch = useDispatch();

    const headers = [
        {title: "Timestamp", width: 160, align: 'left'},
        {title: "Merchant",  width: 160},
        {title: "Site",      width: 160},
        {title: "Amount",    width: 130},
        {title: "Currency",  width: 100},
        {title: "Method",    width: 100},
        {title: "",          width: 225, sticky: 'right'},
    ];

    function handleApprove(event) {
        if (!isLoading) {
            dispatch(settlementApprove({ transactionId: event.target.getAttribute('data-txid') }))
                .then(() => {
                    dispatch(loadSettlements({ status: 'Pending' }));
                });
        }
    }

    function handleDecline(event) {
        if (!isLoading) {
            dispatch(settlementDecline({ transactionId: event.target.getAttribute('data-txid') }))
                .then(() => {
                    dispatch(loadSettlements({ status: 'Pending' }));
                });
        }
    }

    const data = settlements.map((settlement, index) => {
        const { txid, mid, merchant, sid, site, symbol, amount, currency_code, pay_method, status, createdOn } = settlement;

        return {
            'key': txid,
            'data': [
                { className: 'tdstyle transcell', children: <>{ createdOn.replace('T', ' ').replace('Z', '') }</> },
                { className: 'tdstyle transcell', children: <>{ merchant } <span className='midlabel1'>MID:</span><span className='midlabel2'>{ mid }</span></> },
                { className: 'tdstyle transcell', children: <>{ site } <span className='midlabel1'>SID:</span><span className='midlabel2'>{ sid }</span></> },
                { className: '', children: <>{ decode(symbol) + formatter.format(amount) }</> },
                { className: '', children: currency_code },
                { className: '', children: pay_method },
                { className: '', children: <>
                        <button className="buttonprimary primary navbutton tablebutton" 
                            data-txid={ txid } onClick={ handleApprove }>Approve</button>
                        <button className="buttonprimary secondary navbutton tablebutton"
                            data-txid={ txid } onClick={ handleDecline }>Decline</button>
                    </> }
            ]
        }
    });

    return settlements?.length
        ? <AntdTable width={ 1300 } headers={ headers } data={ data } />
        : ( loading === false
            ? <NoData />
            : null
          );
}
