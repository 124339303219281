// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UN5et0mwgT3me8K5Rlv_ {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  column-gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/CurrencyAmountFilter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,wBAAwB;EACxB,gBAAgB;AAClB","sourcesContent":[".AmountFilter {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  grid-template-rows: auto;\n  column-gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AmountFilter": `UN5et0mwgT3me8K5Rlv_`
};
export default ___CSS_LOADER_EXPORT___;
