import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
    GetLimits,
    GetLimitById,
    SaveLimit,
    DeleteLimit,
    DeleteLimitsFromGroup,
    SaveGroupLimits,
    GetGroups,
    GetLimitByGroup
} from "../../../domain/services/limits.svc";
import { startLoading, stopLoading } from './userInterface';

export const getLimits = createAsyncThunk(
    'limits/getLimits',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await GetLimits(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getLimitById = createAsyncThunk(
    'limits/getLimitById',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await GetLimitById(user, params.limitId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const saveLimit = createAsyncThunk(
    'limits/saveLimit',
    async(params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await SaveLimit(user, params.limit)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
)

export const deleteLimit = createAsyncThunk(
    'limits/deleteLimit',
    async(params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await DeleteLimit(user, params.limit)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
)

export const saveGroupLimit = createAsyncThunk(
    'limits/saveGroupLimit',
    async(params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await SaveGroupLimits(user, params.limit)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
)

export const deleteLimitsFromGroup = createAsyncThunk(
    'limits/deleteLimitsFromGroup',
    async(params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await DeleteLimitsFromGroup(user, params.group)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
)

export const getGroups = createAsyncThunk(
    'limits/getGroups',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await GetGroups(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getLimitByGroup = createAsyncThunk(
    'limits/getLimitByGroup',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await GetLimitByGroup(user, params.group)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const limitsSlice = createSlice({
    name: 'limits',
    initialState: {
        limits: [],
        groups: [],
        selectedLimit:  {
            id: null,
            category: 'Limits',
            groupName: '',
            name: '',
            description: '',
            text: '',
            active: true
        }
    },

    reducers: {
        loadLimits: (state, action) => {
            state.limits = action.payload;
        },
        getLimit: (state, action) => {
            state.selectedLimit = action.payload;
        },
        clearLimits: (state) => {
            state.limits = [];
            state.groups = [];
            state.selectedLimit = {
                id: null,
                category: 'Limits',
                groupName: '',
                name: '',
                description: '',
                text: '',
                active: true
            };
        }
    }
});

// Action creators are generated for each case reducer function
export const { 
    loadLimits, 
    getLimit,
    clearLimits
} = limitsSlice.actions;

export default limitsSlice.reducer;
