import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import ButtonSpinner from '../../components/ButtonSpinner';
import {
    showOverlay,
    hideOverlay,
    setModalTitle,
    setModalContent
} from '../../shared/store/slices/userInterface';
import {
    selectGateway,
    selectDescriptor,
    setProcess,
    setApiError
} from '../../shared/store/slices/gatewaySummary';
import {
    saveDescriptorAdjustment,
    setType,
    setAmount,
    setReason,
    setCounterAccount,
    reset,
    loadDescriptorCounterAccounts
} from '../../shared/store/slices/adjustments';

export default function AdjustForm(props) {
    const { onReload } = props;
    const { gateways, gateway, descriptors, descriptor, summaryData } = useSelector(state => state.gatewaySummary);
    const { runningCriticalProcess } = useSelector(state => state.userInterface);
    const { type, amount, reason, counterAccounts, counterAccount } = useSelector(state => state.adjustments);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reset());
    }, [dispatch]);

    useEffect(() => {
        if (gateway != "" && descriptor != "")
            dispatch(loadDescriptorCounterAccounts({gatewayId: gateway, descriptorId: descriptor}));
    }, [dispatch, gateway, descriptor]);

    function handleChangeGateway(event) {
        dispatch(selectGateway(event.target.value));
        dispatch(selectDescriptor(""));
    }

    function handleChangeDescriptor(event) {
        dispatch(selectDescriptor(event.target.value));
    }

    function handleChangeType(event) {
        dispatch(setType(event.target.value));
    }

    function handleChangeAmount(event) {
        dispatch(setAmount(event.target.value));
    }

    function handleChangeReason(event) {
        dispatch(setReason(event.target.value));
    }

    function handleChangeCounterAccount(event) {
        dispatch(setCounterAccount(event.target.value));
    }

    function handleCancel() {
        dispatch(hideOverlay());
    }

    function handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        dispatch(saveDescriptorAdjustment({
            gatewayId: gateway.replace("pgid", ""),
            descriptorId: descriptor.replace("plid", ""),
            counterAccount: counterAccount,
            type: type,
            amount: amount,
            reason: reason
        }))
            .then(response => {
                dispatch(setProcess("adjustment"));

                if (response.payload && response.payload?.data?.status === "approved") {
                    dispatch(setModalTitle("Adjustment Approved"));
                    dispatch(setModalContent("The adjustment has been approved.<br/><br/>JournalEntryID: " + response.payload.data.entryId));
                    dispatch(showOverlay("confirmation"));
                    onReload();
                }
                else {
                    dispatch(setApiError(response.payload?.message ?? "Unknown error"));
                    dispatch(showOverlay("error"));
                }
            });
    }

    return <form onSubmit={ handleSubmit }>
        <div className="overlaydiv">
            <h3 className="overlay">Adjust</h3>
        </div>
        <div className="overlaydiv">
            <select className="overlayselect" value={ gateway } onChange={ handleChangeGateway }>
                <option value="">On Gateway</option>
                { Object.entries(gateways).length > 0
                    ? Object.entries(gateways).map(([pgid, name]) =>
                        <option value={ pgid } key={ `gateway_${pgid}` }>{ name }</option>
                      )
                    : <option value="">Loading...</option>
                }
            </select>
        </div>
        <div className="overlaydiv">
            <select className="overlayselect" value={ descriptor } onChange={ handleChangeDescriptor }>
                <option value="">Descriptor</option>
                { Object.entries(gateways).length > 0
                    ? Object.entries(descriptors).map(([plid, name]) =>
                        <option value={ plid } key={ `descriptor_${plid}` }>{ name }</option>
                      )
                    : <option value="">Loading...</option>
                }
            </select>
        </div>
        <div className="overlaydiv">
            <select className="overlayselect" value={ counterAccount } onChange={ handleChangeCounterAccount }>
                <option value="">Source / Destination Account</option>
                { Object.entries(counterAccounts ?? {}).length > 0
                    ? Object.entries(counterAccounts).map(([accountId, name]) =>
                        <option value={ accountId } key={ `counterAccount_${accountId}` }>{ name }</option>
                      )
                    : <option value="">Loading...</option>
                }
            </select>
        </div>
        <div className="overlayflex">
            <div className="overlaydiv flex">
                <select className="overlayselect" value={ type } onChange={ handleChangeType }>
                    <option value="Credit">+</option>
                    <option value="Debit">-</option>
                </select>
            </div>
            <div className="overlaydiv flex">
                <input className="overlayinput" placeholder="Enter amount" value={ amount } onChange={ handleChangeAmount } style={{ padding:"6px 9px 7px" }}/>
                <span className="adjustmentamount">{ summaryData?.currency?.currency_code }</span>
            </div>
        </div>
        <div className="overlaydiv">
            <select className="overlayselect" value={ reason } onChange={ handleChangeReason }>
                <option value="" disabled>Reason</option>
                <option value="other">Other</option>
            </select>
        </div>
        <div id="overlaybuttons">
            <div>
                <button type="submit" disabled={ runningCriticalProcess }
                    style={{ minWidth:'120px' }}
                    className={ "buttonprimary primary singleline" + (gateway && descriptor && counterAccount && type && amount && reason && !runningCriticalProcess ? "" : " inactive") }>
                    <ButtonSpinner className="inactiveGray" visible={ runningCriticalProcess } />Adjust
                </button>
            </div>
            <div>
                <button disabled={ runningCriticalProcess }
                    style={{ minWidth:'120px' }}
                    className={ "buttonprimary secondary singleline" + (!runningCriticalProcess ? "" : " inactive") }
                    onClick={ handleCancel }>Cancel
                </button>
            </div>
        </div>
    </form>
}
