import { ApiPostRequestSync } from "../../shared/API";

export const LoadAuditLogs = (user, params) => {
    return ApiPostRequestSync("Audits", user, params);
}

export const LoadEventLogs = (user, params) => {
    return ApiPostRequestSync("Audits/events", user, params);
}

export const LoadAdminEventLogs = (user, params) => {
    return ApiPostRequestSync("Audits/admin-events", user, params);
}
