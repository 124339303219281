import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header';
import Listing from './Listing';
import Details from './Details';
import {
    loadTransactionDetails,
    reset,
    ready
} from '../../shared/store/slices/transactionDetails';
import Overlay from "../../components/Overlay";
import {hideOverlay} from "../../shared/store/slices/userInterface";


function LogsOverlay({ title }) {
    const overlayContent = useSelector(state => state.transactionDetails.overlayContent);
    const dispatch = useDispatch();
    function handleClose() {
        dispatch(hideOverlay());
    }

    return <Overlay visible={true} onClose={handleClose}>
        <h3 className="overlay">{ title }</h3>
        <p>{ overlayContent }</p>
    </Overlay>
}

export default function TransactionDetails() {
    const { transactionId } = useParams();
    const showingOverlay = useSelector(state => state.userInterface.overlay);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reset());
        document.title = "Transaction Details";
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadTransactionDetails({txid: transactionId}))
            .then(() => {
                dispatch(ready());
            });
    }, [dispatch, transactionId]);

    var overlay;
    switch (showingOverlay) {
        case "logs_request": overlay = <LogsOverlay title={ `${transactionId} Request` } />; break;
        case "logs_response": overlay = <LogsOverlay title={ `${transactionId} Response` } />; break;
        case "logs_file": overlay = <LogsOverlay title={ `${transactionId} Log File` } />; break;
        case "logs_api": overlay = <LogsOverlay title={ `${transactionId} API Logs` } />; break;
        default: overlay = null;
    }

    return <>
        { overlay }
        <nav><Header transactionId={ transactionId }/></nav>
        <main className="searchtransactions">
            <Listing />
            <Details />
        </main>
    </>
}