import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import AntdTable from '../../../components/AntdTable';
import {
    getCustomerProfile,
    getAgentProfile,
    setCurrentCustomerProfile
} from '../../../shared/store/slices/customers'
import { useHistory } from "react-router-dom"
import NoData from '../../../components/NoData';
import moment from 'moment';

export default function SearchListing(props) {
    const { isSignupPage } = props;
    const { customers, loading, signUpPage, signUpPageSize } = useSelector(state => state.customers);
    const { showMe } = useSelector(state => state.globalSearch);
    const dispatch = useDispatch();
    const history = useHistory();

    const goToCustomerProfile = (customerId) => {
        switch (showMe) {
            case "customers": {
                dispatch(getCustomerProfile({
                    customerId: customerId
                }))
                    .then((response) => {
                        dispatch(setCurrentCustomerProfile(response.payload));
                        history.push("/customer/profile");
                    });
                break;
            }
            case "agents": {
                dispatch(getAgentProfile({
                    customerId: customerId
                }))
                    .then((response) => {
                        dispatch(setCurrentCustomerProfile(response.payload));
                        history.push("/customer/profile");
                    });
            }
        }
    }

    const headers = [
        {title: "Timestamp",     width: 160, align: 'left'},
        {title: "Email",         width: 160, align: 'left'},
        {title: "First Name",    width: 0,   align: 'left'},
        {title: "Last Name",     width: 0,   align: 'left'},
        {title: "Country",       width: 130, align: 'left'},
        {title: "SignUp Status", width: 160, align: 'left'},
        {title: "Realm",         width: 160, align: 'left'}
    ];

    let customerToShow = [];

    if(customers && customers.length > 0 && isSignupPage){
        const itemStart = (signUpPage - 1) * signUpPageSize;
        customerToShow = customers.slice(itemStart, itemStart + signUpPageSize);
    } else if(customers && customers.length > 0) {
        customerToShow = customers;
    }

    const data = customerToShow?.map((customer, index) => {
        const { id, email, givenName, familyName, address, createdTimestamp, phoneNumberVerified, status, realm} = customer;

        return {
            'key': id,
            'data': [
                { className: 'tdstyle transcell ' + (phoneNumberVerified === "true" ? "" : "grey") , children: moment(createdTimestamp).format("YYYY-MM-DD HH:mm:ss") },
                { className: 'tdstyle transcell', children: <span className="pseudolink" onClick={() => { goToCustomerProfile(id) }}>{ email }</span> },
                { className: 'tdstyle transcell', children: givenName },
                { className: 'tdstyle transcell', children: familyName },
                { className: 'tdstyle transcell', children: address?.country },
                { className: 'tdstyle transcell ' + (status === "Incomplete" ? "grey" : (status === "Complete" || status === "Active" ? "green" : "red")), children: status },
                { className: 'tdstyle transcell', children: realm },
            ]
        }
    }) || [];

    return customerToShow?.length ? <AntdTable headers={ headers } data={ data } /> : ( loading === false ? <NoData /> : null );
}
