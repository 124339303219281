import React from 'react';
import MerchantSearchWidget from './MerchantSearchWidget';
import { ReactComponent as CryingEmoji } from './images/crying-emoji-onwhite.svg';
import TitleEffect from "./TitleEffect";
import PageTitle from "./PageTitle";

export default function NotFound() {
    TitleEffect(PageTitle("404 - Page Not Found"));

    return <>
        <nav><MerchantSearchWidget /></nav>
        <main>
            <div style={{ textAlign:'center', display:'grid', placeContent:'center', height:'calc(89vh - 107px)', minHeight:'221px' }}>
            <h1 className="h1title">Womp womp.</h1>
            <div><CryingEmoji style={{ width:'121px', margin:'18px auto 1rem' }} /></div>
			<p style={{ fontSize:'1.5rem', fontFamily:'NHU-Medium' }}>Nothing to see here.</p>
            </div>
        </main>
    </>
}
