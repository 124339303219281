import React from 'react';
import { useSelector } from 'react-redux';
import { isArray, isObject } from '../../shared/utils';
import FormatTime, { TIME_STYLE_ACTIVITY } from '../../shared/helpers/FormatTime';

export default function ActivityListing() {
    const { auditLogs } = useSelector(state => state.auditLogs);
    const { current } = useSelector(state => state.customers);


    const profiles = [];
    const deltas = [];

    function stringify(item) {
        if (item === "") {
            return "Empty";
        }

        if ((item === null) || (item === undefined)) {
            return "Undefined";
        }

        if (item === true) {
            return "True";
        }

        if (item === false) {
            return "False";
        }

        if (isArray(item)) {
            return "[" + item.join(", ") + "]";
        }

        if (isObject(item)) {
            var entries = Object.entries(item).map(keyValue => `${keyValue[0]}: ${keyValue[1]}`);
            return "{" + entries.join(", ") + "}";
        }

        return item;
    }

    function convertEventName(name){
        switch (name){
            case 'SECURITY-TXN-REJECTED': { return "Withdrawal Declined by Security"; }
            case 'SECURITY-TXN-APPROVED': { return "Withdrawal Approved by Security"; }
            case 'TX-BATCH-APPROVED': { return "Withdrawal Batched by Accounting"; }
            case 'BATCH-TX-ITEM-SENT': { return "Withdrawal Processed by Accounting"; }
            case 'WALLET_WITHDRAWAL_INITIATED': { return "Withdrawal Requested"; }
            case 'WALLET_BUY_AND_SEND_APPROVED': { return "Buy+Send Processed"; }
            case 'WALLET_COOKIE_ALLOW_OR_DENY': { return "Cookies"; }
            default: { return name; }
        }
    }

    function convertIDDocumentStatus(status){
        switch (status){
            case 'DENIED_FRAUD': { return "DECLINED FRAUD"; }
            case 'APPROVED_VERIFIED': { return "APPROVED"; }
            default: { return status; }
        }
    }

    function diff(a, b) {
        let diffs = [];

        for (var key in a) {
            if (isArray(a[key]) && (a[key].length === 1)
                && !isArray(a[key][0]) && !isObject(a[key][0])) {
                a[key] = a[key][0];
            }

            if (isArray(b[key]) && (b[key].length === 1)
                && !isArray(b[key][0]) && !isObject(b[key][0])) {
                b[key] = b[key][0];
            }

            if (isArray(a[key]) && !(isArray(b[key]) || isObject(b[key]))) {
                b[key] = [b[key]];
            }

            if (isArray(b[key]) && !(isArray(a[key]) || isObject(a[key]))) {
                a[key] = [a[key]];
            }

            if (isArray(a[key]) && isArray(b[key])) {
                let changes = 0;

                b[key].map((item) => changes += (a[key].indexOf(item) === -1));
                a[key].map((item) => changes += (b[key].indexOf(item) === -1));

                if (changes) {
                    diffs.push({ 'attr':key, 'from':stringify(a[key]), 'to':stringify(b[key]) });
                }
            }
            else if (isObject(a[key]) && isObject(b[key])) {
                let sub = diff(a[key], b[key]);
                diffs = diffs.concat(sub);
            }
            else if (a[key] !== b[key]) {
                diffs.push({ 'attr':key, 'from':stringify(a[key]), 'to':stringify(b[key]) });
            }
        }

        return diffs;
    }

    for (var i=auditLogs.length - 1; i >= 0; i--) {
        let log = auditLogs[i];

        if (log.event == "UPDATE") {
            const data = JSON.parse(log.data);

            if ((data.resourceType == "User") && data.userRepresentation.id) {
                let user = data.userRepresentation;
                let id = user.id;

                if (!profiles[id]) {
                    deltas[i] = "accountCreated";
                } else {
                    const delta = diff(profiles[id], user);

                    if (delta.length > 0) {
                        deltas[i] = delta;
                    }
                }
                profiles[id] = user;
            }
        }
    }

    return <div className="activity-container">{
        auditLogs.map((log, index) => {
            const { timestamp, transactionId, clientId, createdBy, event, clientResourceType, clientResourceEmail, data, createdByEmail } = log;

            let _data = data;
            try {
                _data = JSON.parse(data || "{}");
            } catch (ex) {}

            const docTypes = {
                PhotoID: "PHOTO ID",
                Bill: "BILL",
                Selfie: "SELFIE"
            };

            var description = null;
            switch (event) {
                case "CUSTOMER_REGISTERED_EMAIL_PW":
                    description = <>
                        <div className="activity"><span className="activity-title">Registration</span></div>
                        <div className="activity">Username(Email) and Password by Customer</div>
                    </>;
                    break;
                case "CUSTOMER_VERIFIED_EMAIL":
                    description = <>
                        <div className="activity"><span className="activity-title">Registration</span></div>
                        <div className="activity">Email Verified by Customer</div>
                    </>;
                    break;
                case "CUSTOMER_REGISTERED_NAME_DOB":
                    description = <>
                        <div className="activity"><span className="activity-title">Registration</span></div>
                        <div className="activity">Name/DOB by Customer</div>
                    </>;
                    break;
                case "CUSTOMER_REGISTERED_ADDRESS":
                    description = <>
                        <div className="activity"><span className="activity-title">Registration</span></div>
                        <div className="activity">Address by Customer</div>
                    </>;
                    break;
                case "FOURSTOP_REGISTRATION_SCORE_RECEIVED":
                    description = <>
                        <div className="activity"><span className="activity-title">Registration</span></div>
                        <div className="activity">Fraudcheck {_data.fraudCheckStatus} by 4Stop</div>
                    </>;
                    break;
                case "CUSTOMER_REGISTERED_PHONE":
                    description = <>
                        <div className="activity"><span className="activity-title">Registration</span></div>
                        <div className="activity">Mobile Number by Customer</div>
                    </>;
                    break;
                case "CUSTOMER_VERIFIED_PHONE":
                    description = <>
                        <div className="activity"><span className="activity-title">Registration</span></div>
                        <div className="activity">Mobile Number Verified by Customer</div>
                    </>;
                    break;
                case "CUSTOMER_UPDATED_PROFILE":
                    description = <>
                        <div className="activity"><span className="activity-title">Profile Change</span></div>
                        <div className="activity"><span className="activity-parameter">Profile Updated by Customer</span></div>
                        {_data.details.updated_occupation &&
                            <div className="activity"><span className="activity-value">{"OCCUPATION:  " +
                                _data.details.previous_occupation + "   ->   " + _data.details.updated_occupation}</span></div>}
                        {_data.details.updated_locality &&
                            <div className="activity"><span className="activity-value">{"LOCALITY:  " +
                                _data.details.previous_locality + "   ->   " + _data.details.updated_locality}</span></div>}
                        {_data.details.updated_industry &&
                            <div className="activity"><span className="activity-value">{"INDUSTRY:  " +
                                _data.details.previous_industry + "   ->   " + _data.details.updated_industry}</span></div>}
                        {_data.details.updated_region &&
                            <div className="activity"><span className="activity-value">{"REGION:  " +
                                _data.details.previous_region + "   ->   " + _data.details.updated_region}</span></div>}
                        {_data.details.updated_street &&
                            <div className="activity"><span className="activity-value">{"STREET:  " +
                                _data.details.previous_street + "   ->   " + _data.details.updated_street}</span></div>}
                        {_data.details.updated_first_name &&
                            <div className="activity"><span className="activity-value">{"FIRST NAME:  " +
                                _data.details.previous_first_name + "   ->   " + _data.details.updated_first_name}</span></div>}
                        {_data.details.updated_postal_code &&
                            <div className="activity"><span className="activity-value">{"POSTAL CODE:  " +
                                _data.details.previous_postal_code + "   ->   " + _data.details.updated_postal_code}</span></div>}
                    </>;
                    break;
                case "CUSTOMER_UPDATED_PASSWORD":
                    description = <>
                                <div className="activity"><span className="activity-title">Password Change</span></div>
                                <div className="activity">Password Updated by Customer</div>
                                </>;
                    break;
                case "CUSTOMER_LOGIN_SUCCESS":
                    description = <>
                        <div className="activity"><span className="activity-title">CUSTOMER LOGIN SUCCESS</span></div>
                        <div className="activity"><span className="activity-parameter">IP: </span>
                            <span className="activity-value-new">{_data.details.ip_address}</span></div>
                    </>;
                    break;
                case "CUSTOMER_LOGIN_INCORRECT_USERNAME_PASSWORD":
                    description = <>
                        <div className="activity"><span className="activity-title">CUSTOMER LOGIN FAILURE</span></div>
                        <div className="activity"><span className="activity-parameter">Invalid Username and Password</span></div>
                        <div className="activity"><span className="activity-parameter">IP: </span>
                            <span className="activity-value-new">{_data.details.ip_address}</span></div>
                    </>;
                    break;
                case "CUSTOMER_LOGOUT":
                    description = <>
                        <div className="activity"><span className="activity-title">CUSTOMER LOGOUT</span></div>
                        <div className="activity">Successful Logout</div>
                    </>;
                    break;
                case "CUSTOMER_LOGIN_USER_TEMPORARILY_DISABLED":
                    description = <>
                        <div className="activity"><span className="activity-title">CUSTOMER LOGIN FAILURE</span></div>
                        <div className="activity">User Temporarily Disabled</div>
                        <div className="activity"><span className="activity-parameter">IP: </span><span className="activity-value-new">{_data.details.ip_address}</span></div>
                    </>;
                    break;

                case 'sendCode':
                case 'verifyCode': {
                    description = <>
                        <div className="activity"><span className="activity-title">{ event.toUpperCase() }</span></div>
                        <div className="activity"><span className="activity-parameter">code</span> <span className="activity-value-new">{ _data }</span></div>
                    </>;
                    break;
                }
                case 'customer-adjustment': {
                    const adjuster = `ADMIN (${ createdBy.substr(0, 8) })`;
                    description = <>
                        <div className="activity"><span className="activity-title">BALANCE</span> ADJUSTED by { adjuster }</div>
                        <div className="activity"><span className="activity">{ _data?.type === "Credit" ? "+" : "-" } { _data?.amount } { current.currency } for { _data?.reason }</span></div>
                    </>;
                    break;
                }
                case 'add-contact': {
                    description = <>
                        <div className="activity"><span className="activity-title">CONTACT INFORMATION</span> ADDED by CUSTOMER</div>
                    </>;
                    break;
                }
                case 'DocumentUpload': {
                    const docType = docTypes[_data?.documenttype] + " DOCUMENT" || "DOCUMENT";
                    description = <>
                        <div className="activity"><span className="activity-title">ID VERIFICATION</span></div>
                        <div className="activity"><span className="activity-parameter">{docType} UPLOADED by CUSTOMER</span></div>
                    </>;
                    break;
                }
                case 'cancel-transaction': {
                    description = <>
                        <div className="activity"><span className="activity-title">TRANSACTION</span> CANCELED by CUSTOMER</div>
                        <div className="activity"><span className="activity-parameter">ID</span> <span className="activity-value-new">{ transactionId }</span></div>
                    </>;
                    break;
                }
                case 'refund-transaction': {
                    description = <>
                        <div className="activity"><span className="activity-title">TRANSACTION</span> REFUNDED by MERCHANT</div>
                        <div className="activity"><span className="activity-parameter">ID</span> <span className="activity-value-new">{ transactionId }</span></div>
                    </>;
                    break;
                }
                case 'approve-user-document': {
                    const docType = docTypes[_data?.documenttype] + " DOCUMENT" || "DOCUMENT";
                    const approver = (_data?.agentemail && _data?.agentemail.includes('@')) ? `ADMIN (${_data?.agentemail})` : `ADMIN`;
                    description = <>
                        <div className="activity"><span className="activity-title">ID Verification</span></div>
                        <div className="activity"><span className="activity-parameter">{docType} APPROVED by { approver }</span></div>
                    </>;
                    break;
                }

                case 'reject-user-document': {
                    const docType = docTypes[_data?.documenttype] + " DOCUMENT" || "DOCUMENT";
                    const rejecter = (_data?.agentemail && _data?.agentemail.includes('@')) ? `ADMIN (${_data?.agentemail})` : `ADMIN`;
                    description = <>
                        <div className="activity"><span className="activity-title">ID Verification</span></div>
                        <div className="activity"><span className="activity-parameter">{docType} REJECTED by { rejecter }</span></div>
                    </>;
                    break;
                }

                case 'request-new-document': {
                    const docType = docTypes[_data?.documenttype] + " DOCUMENT" || "DOCUMENT";
                    const requester = (_data?.agentemail && _data?.agentemail.includes('@')) ? `ADMIN (${_data?.agentemail})` : `ADMIN`;
                    description = <>
                                    <div className="activity"><span className="activity-title">ID Verification</span></div>
                                    <div className="activity"><span className="activity-parameter">New {docType} requested by { requester }</span></div>
                            </>;
                    break;
                }

                case 'WALLET_WITHDRAWAL_INITIATED':{ convertEventName(event) }
                {
                    description = <>
                        <div className="activity"><span className="activity-title">{ convertEventName(event) }</span></div>
                        <div className="activity"><span className="activity-value-new">{ _data.amount + " by " + _data.paymentMethodName }</span></div>
                    </>;
                    break;
                }
                case "WALLET_BUY_AND_SEND_APPROVED":
                {
                    description = <>
                        <div className="activity"><span className="activity-title">{ convertEventName(event) }</span></div>
                        <div className="activity"><span className="activity-value-new">{ _data.amount + " by " + _data.parentTransactionPaymentMethodName }</span></div>
                    </>;
                    break;
                }
                case 'SECURITY-TXN-REJECTED':
                case 'SECURITY-TXN-APPROVED':
                case 'TX-BATCH-APPROVED':
                case 'BATCH-TX-ITEM-SENT':
                {
                    description = <>
                        <div className="activity"><span className="activity-title">{ convertEventName(event) }</span></div>
                        <div className="activity"><span className="activity-value">{ "by " + _data.agentEmail }</span></div>
                    </>;
                    break;
                }
                case "WALLET_COOKIE_ALLOW_OR_DENY":
                {
                    description = <>
                        <div className="activity"><span className="activity-title">{ convertEventName(event) }</span></div>
                        <div className="activity"><span className="activity-value-new">{ _data }</span></div>
                    </>;
                    break;
                }
                case 'LOGIN': {
                    var hasDuplicate =
                        ((auditLogs[index - 1]?.event === 'LOGIN' && auditLogs[index - 1]?.timestamp === timestamp) ||
                         (auditLogs[index + 1]?.event === 'LOGIN' && auditLogs[index + 1]?.timestamp === timestamp))
                         && _data?.details?.deviceId === undefined;

                    if (hasDuplicate) {
                        break;
                    }

                    description = <>
                        <div className="activity"><span className="activity-title">{ event.toUpperCase() }</span></div>
                        <div className="activity"><span className="activity-parameter">ip</span> <span className="activity-value-new">{ _data.ipAddress }</span></div>
                        { _data?.details?.deviceId
                            ? <div className="activity">
                                  <span className="activity-parameter">device id</span> <span className="activity-value-new">{ _data.details.deviceId }</span>
                              </div>
                            : null
                        }
                    </>;
                    break;
                }
                case 'CUSTOMER_INITIATES_IDVERIFICATION':
                {
                    description = <>
                        <div className="activity"><span className="activity-title">ID Verification</span></div>
                        <div className="activity">Jumio Initiated By Customer</div>
                    </>;
                    break;
                }
                case 'CUSTOMER_INITIATES_IDVERIFICATION_QRCODE_SCAN':
                {
                    description = <>
                        <div className="activity"><span className="activity-title">ID Verification</span></div>
                        <div className="activity">Mobile Sign-In via QR Code by Customer</div>
                        <div className="activity">ReferenceID: {_data.linkKey}</div>
                    </>;
                    break;
                }
                case 'CUSTOMER_COMPLETES_IDVERIFICATION':
                {
                    description = <>
                        <div className="activity"><span className="activity-title">ID Verification</span></div>
                        <div className="activity">{ convertIDDocumentStatus( _data.verificationStatus)} {_data.statusReason == "" ?? "(" + _data.statusReason + ")"} By Jumio</div>
                    </>;
                    break;
                }
                case 'LOGIN_ERROR':
                case 'LOGOUT': {
                    var titleLine;
                    if (_data.error)
                        titleLine = <div className="activity"><span className="activity-title">{ event.toUpperCase() + "  -  " + _data.error }</span></div>
                    else
                        titleLine = <div className="activity"><span className="activity-title">{ event.toUpperCase() }</span></div>
                    description = <>
                        {titleLine}
                        <div className="activity"><span className="activity-parameter">ip</span> <span className="activity-value-new">{ _data?.ipAddress }</span></div>
                        { _data?.deviceId
                            ? <div className="activity">
                                <span className="activity-parameter">device id</span> <span className="activity-value-new">{ _data?.deviceId }</span>
                              </div>
                            : null
                        }
                    </>;
                    break;
                }
                case 'UPDATE':
                case 'UPDATE_PROFILE':
                case 'save-user-details': {
                    const delta = deltas[index];
                    const updater = (_data?.authDetails && (_data?.authDetails.userId !== clientId))
                        ? ((_data?.authDetails.adminEmail && _data?.authDetails.adminEmail.includes('@')) ? `ADMIN (${_data?.authDetails.adminEmail})` : `SYSTEM`)
                        : `CUSTOMER`;

                    description = <>
                        <div className="activity"><span className="activity-title">PROFILE UPDATED </span> by { updater }</div>

                        { delta
                            ? ( delta === "accountCreated"
                                ? <div className="activity"><span className="activity-parameter">Account Created</span></div>
                                : delta.map((delta, idx) =>
                                    <div className="activity" key={ `delta_${index}_${idx}` }>
                                        <span className="activity-parameter">{ delta.attr }</span>&nbsp;
                                        <span className="activity-value-old">{ delta.from }</span>&nbsp;&rarr;&nbsp;
                                        <span className="activity-value-new">{ delta.to }</span>
                                    </div>
                                  )
                              )
                            : null
                        }
                    </>;
                    break;
                }
                case 'USER_DETAILS_UPDATE':{
                    let changeList = <></>;
                    if (Array.isArray(_data)) {
                        changeList = _data.map((change, idx) =>
                            <div className="activity" key={`changes_${index}_${idx}`}>
                                <span className="activity-value-old">OLD: {change.oldText}</span>&nbsp;&rarr;&nbsp;
                                <span className="activity-value-new">NEW: {change.newText}</span>
                            </div>
                        )
                    } else {
                        changeList =
                            <div className="activity" key={`changes_${index}`}>
                                <span className="activity-value-old">"No Profile Changes Detected"</span>
                            </div>
                    }
                    description = <>
                        <div className="activity">
                            <span className="activity-title">Profile or Attributes Change</span>
                            {changeList}
                        </div>
                    </>;
                    break;
                }
                case 'USER_ROLES_PERMS_DELETE':{
                    description = <>
                        <div className="activity"><span className="activity-title">Roles/Perms Change</span>
                            {
                                JSON.parse(_data?.representation).map((change, idx) =>
                                    <div className="activity" key={`changes_${index}_${idx}`}>
                                        <span className="activity-parameter">operation</span> <span className="activity-value-new">{ change?.name } REMOVED</span>
                                    </div>
                                )
                            }
                        </div>
                    </>;
                    break;
                }
                case 'USER_ROLES_PERMS_CREATE':{
                    description = <>
                        <div className="activity"><span className="activity-title">Roles/Perms Change</span>
                            {
                                JSON.parse(_data?.representation).map((change, idx) =>
                                    <div className="activity" key={`changes_${index}_${idx}`}>
                                        <span className="activity-parameter">operation</span> <span className="activity-value-new">{ change?.name } ADDED</span>
                                    </div>
                                )
                            }
                        </div>
                    </>;
                    break;
                }
                case 'KK.EVENT.ADMIN.wallet88.SUCCESS.USER.UPDATE':{
                    description = <>
                        <div className="activity"><span className="activity-title">Profile or Attributes Change</span></div>
                        <div className="activity"><span className="activity-parameter">operation</span> <span className="activity-value-new">{ _data?.operationType }</span></div>
                    </>;
                    break;
                }

                default: {
                    description = <>
                        <div className="activity"><span className="activity-title">{ event.toUpperCase() }</span></div>
                        <div className="activity"><span className="activity-parameter">operation</span> <span className="activity-value-new">{ _data?.operationType }</span></div>
                    </>;
                    break;
                }
            }

            if (description === null)
                return null;

            //Display Owning User Email only when in Agent Activity View
            var resourceDescriptor = "";
            if (current.userType === "Agent" ) {
                resourceDescriptor = <div className="activity">
                    <span className="activity-title">ON { clientResourceType }: </span>
                    <span className="activity-value">{ clientResourceEmail ?? clientId }</span>
                </div>;
            }

            //Display Acting User Email only when in User Activity View
            var actorDescriptor = "";
            if (current.userType !== "Agent" ) {
                    actorDescriptor = <div className="activity">
                        <span className="activity-title">DONE/ACTIONED BY : </span>
                        <span className="activity-value">{ createdByEmail == clientResourceEmail ? "CUSTOMER" : createdByEmail ?? ((_data?.authDetails?.adminEmail ?? _data?.adminEmail) ?? "SYSTEM")}</span>
                    </div>;
            }

            return <div className="activity-card" key={ `activity-card-${index}` }>
                <>
                    { description }
                    { resourceDescriptor }
                    { actorDescriptor }
                </>
                <div className="activity"><span className="activity-timestamp">{ FormatTime(timestamp, null, TIME_STYLE_ACTIVITY) }</span></div>
            </div>
        })}
    </div>
}
