import * as React from "react";
import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import { regions, countries, countriesByRegion } from "./Countries";
import 'antd/dist/antd.css';
import './CountryPicker.css';
import { Checkbox } from 'antd';


function MinorRegion(props) {
    const { major, minor, regionCountries, selectedCountries, onChange, onCheckAll, checkedList, indeterminate, checkAll } = props;
    const CheckboxGroup = Checkbox.Group;
    const countryOptions = Object.values(regionCountries);

    const handleChange = (list) => {
        if (onChange != undefined)
            onChange(major, minor, list);
    };

    const handleCheckAll = (e) => {
        if (onCheckAll != undefined)
            onCheckAll(major, minor, e.target.checked);
    };

    return <>
        <div className="minorRegion">
            <div className="header">
                <div className="regionName">{ regions[minor] }</div>
            </div>
            <div className="checkAll">
                <Checkbox indeterminate={indeterminate} onChange={handleCheckAll} checked={checkAll}>
                    All
                </Checkbox>
            </div>
            <CheckboxGroup options={countryOptions} value={selectedCountries} onChange={handleChange} />
        </div>
    </>
}


function abIntersection(a, b) {
    const intersection = [];
    a.map((item) => {
        if (b.indexOf(a) >= 0)
            intersection.push(item);
    });
    return intersection;
}


function MajorRegion(props) {
    const {major, minorRegions, selectedCountries, onChange, onCheckAll, checkedList, indeterminate, checkAll, saveButton } = props;
    const CheckboxGroup = Checkbox.Group;

    function handleCheckAll(e) {
        if (onCheckAll != undefined)
            onCheckAll(major, null, e.target.checked);
    }

    let allChecked = true;
    let allUnchecked = true;
    let allIndeterminate = false;

    const minorRegs = [];
    Object.entries(minorRegions).map(([code, regionCountries]) => {
        allChecked = allChecked && checkAll[code];
        allUnchecked = allUnchecked && !checkAll[code];
        allIndeterminate = allIndeterminate || indeterminate[code];

        const selected = abIntersection(selectedCountries || [], regionCountries);
        minorRegs.push(<MinorRegion key={ major+code } major={ major } minor={ code }
            regionCountries={ regionCountries } selectedCountries={ checkedList[code] || [] }
            onChange={ onChange } onCheckAll={ onCheckAll }
            checkedList={ checkedList[code] || [] } indeterminate={ indeterminate[code] && true } checkAll={ checkAll[code] || false } />);
    });

    allIndeterminate = allIndeterminate || (!allChecked && !allUnchecked);

    return <>
        <div className="majorRegion">
            <div className="header">
                <div className="regionName">{ regions[major] }</div>
            </div>
            <div className="checkAll">
                <Checkbox indeterminate={allIndeterminate} onChange={handleCheckAll} checked={allChecked}>
                    Check All
                </Checkbox>
            </div>
            { minorRegs }
        </div>
        { saveButton || null }
    </>
}

function isIndeterminate(a, b) {
    return a?.length > 0 && a?.length < b?.length;
}

function isAll(a, b) {
    return a?.length == b?.length;
}

export default function CountryPicker(props) {
    const { selectedCountries, onChange, onCheckAll, onSave, saveButtonClassName, showSaveButtonInAllMajorRegions } = props;
    const dispatch = useDispatch();

    const [allCountries, setAllCountries] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState([]);
    const [checkAll, setCheckAll] = useState([]);

    useEffect(() => {
        const _allCountries = [];
        const _checkedList = [];
        const _indeterminate = [];
        const _checkAll = [];

        Object.entries(countries).map(([code, country]) => {
            const { major, minor, name } = country;
            if (_allCountries[major] == undefined) {
                _allCountries[major] = [];
                _checkedList[major] = [];
                _indeterminate[major] = [];
                _checkAll[major] = [];
            }

            if (_allCountries[major][minor] == undefined) {
                _allCountries[major][minor] = [];
                _checkedList[major][minor] = [];
                _indeterminate[major][minor] = true;
                _checkAll[major][minor] = false;
            }

            _allCountries[major][minor].push(name);

            if (selectedCountries.indexOf(code) >= 0) {
                _checkedList[major][minor].push(name);
            }

            _indeterminate[major][minor] = isIndeterminate(_checkedList[major][minor], _allCountries[major][minor]);
            _checkAll[major][minor] = isAll(_checkedList[major][minor], _allCountries[major][minor]);
        });

        setAllCountries(_allCountries);
        setCheckedList(_checkedList);
        setIndeterminate(_indeterminate);
        setCheckAll(_checkAll);
    }, [selectedCountries]);

    const handleChange = (major, minor, list) => {
        const newCheckedList = {...checkedList};
        const newIndeterminate = {...indeterminate};
        const newCheckAll = {...checkAll};

        newCheckedList[major][minor] = list;
        newIndeterminate[major][minor] = isIndeterminate(newCheckedList[major][minor], allCountries[major][minor]);
        newCheckAll[major][minor] = isAll(newCheckedList[major][minor], allCountries[major][minor]);

        setCheckedList(newCheckedList);
        setIndeterminate(newIndeterminate);
        setCheckAll(newCheckAll);

        if (onChange != undefined)
            onChange(newCheckedList);
    };

    function updateState(major, minor, checked) {
        const newCheckedList = {...checkedList};
        const newIndeterminate = {...indeterminate};
        const newCheckAll = {...checkAll};

        newCheckedList[major][minor] = checked ? allCountries[major][minor] : [];
        newIndeterminate[major][minor] = isIndeterminate(newCheckedList[major][minor], allCountries[major][minor]);
        newCheckAll[major][minor] = isAll(newCheckedList[major][minor], allCountries[major][minor]);

        setCheckedList(newCheckedList);
        setIndeterminate(newIndeterminate);
        setCheckAll(newCheckAll);

        if (onCheckAll != undefined)
            onCheckAll(checkedList);
    }

    function handleCheckAll(major, minor, checked) {
        if (minor == null) {
            Object.keys(allCountries[major]).map((minor) => {
                updateState(major, minor, checked);
            });
        } else {
            updateState(major, minor, checked);
        }
    };

    function handleSave() {
        if (onSave != undefined) {
            const newSelectedCountries = [];
            Object.entries(countriesByRegion).map(([major, minorRegions]) => {
                Object.entries(minorRegions).map(([minor, countries]) => {
                    Object.entries(countries).map(([code, country]) => {
                        if (checkedList[major][minor].indexOf(country) >= 0) {
                            newSelectedCountries.push(code);
                        }
                    });
                });
            });

            onSave(newSelectedCountries);
        }
    }

    const saveButton = <button className={ saveButtonClassName } onClick={ handleSave }>Save All</button>

    const majorRegions = [];
    const lastRegion = Object.keys(countriesByRegion).findLast(() => true);

    Object.entries(countriesByRegion).map(([code, minorRegions]) => {
        majorRegions.push(<MajorRegion key={ code } major={ code }
            minorRegions={ minorRegions } selectedCountries={ checkedList[code] || [] }
            onChange={ handleChange } onCheckAll={ handleCheckAll }
            checkedList={ checkedList[code] || [] } indeterminate={ indeterminate[code] || [] } checkAll={ checkAll[code] || [] }
            saveButton={ showSaveButtonInAllMajorRegions || code == lastRegion ? saveButton : null } />);
    });

    return <>
        { majorRegions }
    </>
}
