import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Settlements, SettlementApprove, SettlementDecline } from '../../GatewayAPI.js';
import { startLoading, stopLoading } from './userInterface';


export const loadSettlements = createAsyncThunk(
    'settlements/loadSettlements',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await Settlements(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const settlementApprove = createAsyncThunk(
    'settlements/settlementApprove',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await SettlementApprove(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const settlementDecline = createAsyncThunk(
    'settlements/settlementDecline',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await SettlementDecline(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);


export const settlementsSlice = createSlice({
    name: 'settlements',

    initialState: {
        settlements: [],
        overlayContent: "",
        apiError: "",
        loading: undefined
    },

    reducers: {
        reset: (state) => {
            state.settlements = [];
        },
        setOverlayContent: (state, action) => {
            state.overlayContent = action.payload;
        },
        ready: (state) => {
            state.isReady = true;
        }
    },

    extraReducers: {
        [loadSettlements.pending]: (state) => {
            state.loading = true;
        },
        [loadSettlements.fulfilled]: (state, action) => {
            state.settlements = action.payload || [];
            state.loading = false;
        },
        [loadSettlements.rejected]: (state, action) => {
            state.settlements = [];
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { reset, setOverlayContent, ready } = settlementsSlice.actions;

export default settlementsSlice.reducer;
