import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import App from './components/App';

// you'll need this for older browsers
require("es6-promise").polyfill();

ReactDOM.render(<App />,
    document.getElementById("app")
);