import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {
    setSearchText, setSearchBy, setShowMe, searchUsers, setInRealm
} from '../shared/store/slices/customers'
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom"

export default function CustomerSearchWidget() {
    const { customers, searchText, searchBy, showMe, inRealm } = useSelector(state => state.customers);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
    }, [dispatch]);

    function handleChangeText(event) {
        dispatch(setSearchText(event.target.value));
    }

    function handleChangeBy(event) {
        dispatch(setSearchBy(event.target.value));
    }

    function handleChangeShowMe(event) {
        dispatch(setShowMe(event.target.value));
    }

    function handleChangeInRealm(event) {
        dispatch(setInRealm(event.target.value));
    }

    function handleSearch(event) {
        event.preventDefault();
        dispatch(searchUsers({
            searchText: searchText,
            searchBy: searchBy,
            showMe: showMe,
            inRealm: inRealm == '' ? null : inRealm
        })).then(() => {
            history.push('/customers');
        });
    }

    function handleGo() {
    }

    return <form onSubmit={ handleSearch }>
        <div id="transsearchnav" className="merchantsearch">
            <div className="navcell navcellsearchbar merchantsearch">
                <input type="text" id="transsearchbox" name="search" placeholder="Search" value={ searchText } onChange={ handleChangeText } />
            </div>
    
            <div className="navcell  navcellparameter" style={{"display": "flex", "alignItems": "baseline"}}>
                <select name="parameter" id="parameter" value={ searchBy } onChange={ handleChangeBy }>
                    <option value="" disabled>In</option>
                    <option value="email">Email Address</option>
                    <option value="name">Name</option>
                    <option value="phoneNumber">Mobile</option>
                    <option value="street">Street Address</option>
                    <option value="ip_address">IP Address</option>
                    <option value="deviceId">Device ID</option>
                    <option value="country">Country Code (2)</option>
                    <option value="transactionId">Transaction Id</option>
                    <option value="gatewayTransactionId">Gateway Transaction Id</option>
                </select>
            </div>
    
            <div className="navcell navcellrange" style={{"display": 'flex', "alignItems": 'inherit'}}>
                <select name="parameter" id="parameter" value={ showMe } onChange={ handleChangeShowMe }>
                    <option value="" disabled>Show me</option>
                    <option value="">Customers</option>
                </select>
            </div>

            <div className="navcell navcellfilter" style={{ display:'flex', alignItems:'inherit' }}>
                <select name="realm" id="realm" value={ inRealm } onChange={ handleChangeInRealm }>
                    <option value="none" disabled>In Realm</option>
                    <option value="">All</option>
                    <option value="wallet88">Wallet88</option>
                    <option value="flypto">Flypto</option>
                </select>
            </div>
    
            <div className="navcellsearchbutton merchantsearch">
                <div className="navcellsearchbutton"><button className="buttonprimary primary navbutton" type="submit">Search</button></div>
            </div>
            <div className="navcell navcellexport merchantsearch">
                <div className="navcellsearchbutton"><button className="buttonprimary secondary navbutton" onClick={ handleGo }>Go</button></div>
            </div>
        </div>
    </form>
}
