export const TIME_STYLE_SHORT = "SHORT";
export const TIME_STYLE_LONG = "LONG";
export const TIME_STYLE_ACTIVITY = "ACTIVITY";

const ISO_DATETIME_PATTERN = /^([0-9]{4})-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1])T([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;

const LONG_MONTHS = ["January","February","March","April","May","June","July","August","September","October","November","December"]

function To12Hour(hr) {
    if (hr == 0)
        return 12;

    if (hr < 13)
        return hr;

    return hr - 12;
}

function AmPm(hr) {
    return hr >= 12 ? "PM" : "AM";
}

function AdjustForTimezone(date, timezone) {
  if (timezone == null)
    return date;

  return new Date(date.toLocaleString("en-US", {timeZone: timezone}));
}

function FormatTime(time, timezone = null, style=TIME_STYLE_SHORT) {

    if(time !== null && time !== undefined && time.length > 19) {
        time = time.substr(0, 19);
    }

    const match = time.match(ISO_DATETIME_PATTERN);

    let date = new Date(Date.UTC(parseInt(match[1]), parseInt(match[2]-1), parseInt(match[3]), parseInt(match[4]), parseInt(match[5]), parseInt(match[6]), 0));
    date = AdjustForTimezone(date, timezone);

    const dt = {
        year: date.getFullYear(),
        month: date.getMonth()+1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes().toString().padStart(2, '0'),
        second: date.getSeconds().toString().padStart(2, '0')
    }

    if (style == TIME_STYLE_SHORT) {
        return `${dt.year}-${dt.month < 10 ? '0'.concat(dt.month.toString()) : dt.month }-${dt.day} | ${dt.hour}:${dt.minute}:${dt.second}`;
    }

    if (style == TIME_STYLE_ACTIVITY) {
        return `${dt.month}/${dt.day}/${dt.year.toString().substr(2, 2)} ${To12Hour(dt.hour)}:${dt.minute}:${dt.second} ${AmPm(dt.hour)}`;
    }

    return `${LONG_MONTHS[dt.month]} ${dt.day}, ${dt.year} ${To12Hour(dt.hour)}:${dt.minute}:${dt.second} ${AmPm(dt.hour)}`;
}

export default FormatTime;
