var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ReactComponent as TableFlagIcon } from "../../assets/images/icon-table-flag.svg";
import { ReactComponent as TableFlagRedIcon } from "../../assets/images/icon-table-flag-red.svg";
import { ReactComponent as TableJumioIcon } from "../../assets/images/icon-table-jumio.svg";
import { ReactComponent as TablePhotoIdIcon } from "../../assets/images/icon-table-photoid.svg";
import { ReactComponent as TableSelfieIdIcon } from "../../assets/images/icon-table-selfie.svg";
import { ReactComponent as TablePhoneBillIcon } from "../../assets/images/icon-table-phonebill.svg";
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import { getDocumentData, getPendingDocumentCount, getPendingDocuments } from "../services/documents.svc";
import moment from "moment";
import FormatTime from "../../shared/helpers/FormatTime";
import { useAppSelector } from "../../hooks";
import Overlay from "../../components/Overlay";
import DocumentDetails from "./DocumentDetails";
import RiskIcon from "./RiskIcon";
import DecisionIcon from "./DecisionIcon";
var initialState = {
    details: {
        visible: false
    },
    documentCount: 0,
    documents: [],
    isLoading: false,
    page: {
        current: 1,
        size: 25
    },
    search: {
        term: "",
        mode: "email"
    }
};
function RankRisk(risk) {
    switch (risk) {
        case "fraud":
            return 0;
        case "accepted":
            return 1;
        default:
            return -1;
    }
}
function RankDecision(decision) {
    switch (decision) {
        case "rejected":
            return 0;
        case "approved":
            return 1;
        default:
            return -1;
    }
}
function DocumentsQueuePage() {
    var user = useAppSelector(function (state) {
        return {
            user: state.oidc.user
        };
    }).user;
    var _a = useState(initialState), state = _a[0], setState = _a[1];
    var details = state.details, documentCount = state.documentCount, documents = state.documents, isLoading = state.isLoading, page = state.page, search = state.search;
    TitleEffect(PageTitle("ID Document Verification Queue"));
    function UpdateState(updater) {
        var newState = Object.assign({}, state);
        updater(newState);
        setState(newState);
    }
    function LoadData() {
        if (!(user === null || user === void 0 ? void 0 : user.access_token))
            return;
        UpdateState(function (s) {
            s.isLoading = true;
        });
        var request = {
            accessToken: user === null || user === void 0 ? void 0 : user.access_token,
            queryMode: search.mode,
            queryText: search.term,
            pageCurrent: Math.max(page.current - 1, 0),
            pageSize: page.size
        };
        Promise.all([getPendingDocumentCount(request), getPendingDocuments(request)])
            .then(function (_a) {
            var documentCount = _a[0], documents = _a[1];
            UpdateState(function (s) {
                s.documentCount = documentCount;
                s.documents = documents;
                s.isLoading = false;
            });
        })
            .catch(function (_) {
            UpdateState(function (s) {
                s.documentCount = 0;
                s.documents = [];
                s.isLoading = false;
            });
        });
    }
    function NavigateToCustomer(id) {
    }
    function ShowDocumentDetails(document) {
        UpdateState(function (s) {
            s.details.document = document;
            s.details.visible = true;
        });
    }
    function handleSearchTermChange(evt) {
        UpdateState(function (s) {
            s.search.term = evt.target.value;
        });
    }
    function handleSearchModeChange(evt) {
        UpdateState(function (s) {
            s.search.mode = evt.target.value;
        });
    }
    function handleSearchClick() {
        LoadData();
    }
    function handleCloseOverlay() {
        UpdateState(function (s) {
            s.details.visible = false;
            s.details.document = undefined;
        });
    }
    function handleDocumentUpdate(doc) {
        if (doc.decision !== "approved" && doc.decision !== "rejected" && !doc.newRequested)
            return;
        var newDocs = documents
            .filter(function (d) { return d.id !== doc.id; })
            .map(function (d) {
            var _a, _b, _c;
            var ocid = (_a = d.customer) === null || _a === void 0 ? void 0 : _a.id;
            var ucid = (_b = doc.customer) === null || _b === void 0 ? void 0 : _b.id;
            if (ocid === undefined || ucid === undefined)
                return d;
            if (ocid !== ucid)
                return d;
            var decisions = Object.assign({}, (_c = d["document-decisions"]) !== null && _c !== void 0 ? _c : {});
            switch (doc.type) {
                case "selfie":
                    decisions["selfie-decision"] = doc.decision;
                    break;
                case "governmentId":
                    decisions["government-id-decision"] = doc.decision;
                    break;
                case "utilityBill":
                    decisions["utility-bill-decision"] = doc.decision;
                    break;
                case "other":
                default:
                    break;
            }
            var updated = Object.assign({}, d);
            updated["document-decisions"] = decisions;
            return updated;
        });
        UpdateState(function (s) {
            s.documents = newDocs;
            s.details.visible = false;
            s.details.document = undefined;
        });
    }
    function DocumentLoader() {
        return __awaiter(this, void 0, void 0, function () {
            var request, src;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        request = {
                            accessToken: user === null || user === void 0 ? void 0 : user.access_token,
                            documentId: (_a = details.document) === null || _a === void 0 ? void 0 : _a.id
                        };
                        return [4 /*yield*/, getDocumentData(request)];
                    case 1:
                        src = _f.sent();
                        return [2 /*return*/, {
                                src: src,
                                customer: (_b = details.document) === null || _b === void 0 ? void 0 : _b.customer,
                                decision: (_c = details.document) === null || _c === void 0 ? void 0 : _c.decision,
                                id: (_d = details.document) === null || _d === void 0 ? void 0 : _d.id,
                                type: (_e = details.document) === null || _e === void 0 ? void 0 : _e.type,
                                newRequested: false
                            }];
                }
            });
        });
    }
    useEffect(function () {
        LoadData();
    }, []);
    var columns = [{
            align: 'center',
            className: 'th-legendlabel tdstyle transcell transtable tableicons',
            render: function (_, record) { var _a; return ((_a = record.customer) === null || _a === void 0 ? void 0 : _a.flagged) ? React.createElement(TableFlagRedIcon, { className: "tableflag" }) : undefined; },
            sortDirections: ['descend', 'ascend'],
            sorter: function (a, b) {
                var _a, _b, _c, _d;
                var af = (_b = (_a = a.customer) === null || _a === void 0 ? void 0 : _a.flagged) !== null && _b !== void 0 ? _b : false;
                var bf = (_d = (_c = b.customer) === null || _c === void 0 ? void 0 : _c.flagged) !== null && _d !== void 0 ? _d : false;
                return af < bf ? -1 : (bf < af ? 1 : 0);
            },
            title: function () { return React.createElement(TableFlagIcon, { className: "tableflag" }); }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable tableicons',
            align: 'center',
            title: function () { return React.createElement(TableJumioIcon, { className: "tablejumio" }); },
            render: function (_, record) { var _a, _b; return React.createElement(RiskIcon, { value: (_b = (_a = record.customer) === null || _a === void 0 ? void 0 : _a.registration) === null || _b === void 0 ? void 0 : _b.risk }); },
            sortDirections: ['descend', 'ascend'],
            sorter: function (a, b) {
                var _a, _b, _c, _d;
                var ar = RankRisk((_b = (_a = a.customer) === null || _a === void 0 ? void 0 : _a.registration) === null || _b === void 0 ? void 0 : _b.risk);
                var br = RankRisk((_d = (_c = b.customer) === null || _c === void 0 ? void 0 : _c.registration) === null || _d === void 0 ? void 0 : _d.risk);
                return ar - br;
            }
        }, {
            align: 'center',
            className: 'th-legendlabel tdstyle transcell transtable tableicons',
            title: function () { return React.createElement(TablePhotoIdIcon, { className: "tablephotoid" }); },
            render: function (_, record) { var _a; return React.createElement(DecisionIcon, { value: (_a = record["document-decisions"]) === null || _a === void 0 ? void 0 : _a["government-id-decision"] }); },
            sortDirections: ['descend', 'ascend'],
            sorter: function (a, b) {
                var _a, _b;
                var ad = RankDecision((_a = a["document-decisions"]) === null || _a === void 0 ? void 0 : _a["government-id-decision"]);
                var bd = RankDecision((_b = b["document-decisions"]) === null || _b === void 0 ? void 0 : _b["government-id-decision"]);
                return ad - bd;
            }
        }, {
            align: 'center',
            className: 'th-legendlabel tdstyle transcell transtable tableicons',
            title: function () { return React.createElement(TableSelfieIdIcon, { className: "tableselfie" }); },
            render: function (_, record) { var _a; return React.createElement(DecisionIcon, { value: (_a = record["document-decisions"]) === null || _a === void 0 ? void 0 : _a["selfie-decision"] }); },
            sortDirections: ['descend', 'ascend'],
            sorter: function (a, b) {
                var _a, _b;
                var ad = RankDecision((_a = a["document-decisions"]) === null || _a === void 0 ? void 0 : _a["selfie-decision"]);
                var bd = RankDecision((_b = b["document-decisions"]) === null || _b === void 0 ? void 0 : _b["selfie-decision"]);
                return ad - bd;
            }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable tableicons',
            align: 'center',
            title: function () { return React.createElement(TablePhoneBillIcon, { className: "tablemobilebill" }); },
            render: function (_, record) { var _a; return React.createElement(DecisionIcon, { value: (_a = record["document-decisions"]) === null || _a === void 0 ? void 0 : _a["utility-bill-decision"] }); },
            sortDirections: ['descend', 'ascend'],
            sorter: function (a, b) {
                var _a, _b;
                var ad = RankDecision((_a = a["document-decisions"]) === null || _a === void 0 ? void 0 : _a["utility-bill-decision"]);
                var bd = RankDecision((_b = b["document-decisions"]) === null || _b === void 0 ? void 0 : _b["utility-bill-decision"]);
                return ad - bd;
            }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Email',
            render: function (_, record) {
                var _a;
                return React.createElement("a", { href: "#", className: "emaillink", onClick: function () { var _a; return NavigateToCustomer((_a = record.customer) === null || _a === void 0 ? void 0 : _a.id); } }, (_a = record.customer) === null || _a === void 0 ? void 0 : _a.email);
            },
            sortDirections: ['descend', 'ascend'],
            sorter: function (a, b) {
                var _a, _b, _c, _d;
                var ae = (_b = (_a = a.customer) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : "";
                var be = (_d = (_c = b.customer) === null || _c === void 0 ? void 0 : _c.email) !== null && _d !== void 0 ? _d : "";
                return ae.localeCompare(be);
            }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Name',
            render: function (_, record) {
                var _a;
                return React.createElement("a", { href: "#", className: "emaillink", onClick: function () { var _a; return NavigateToCustomer((_a = record.customer) === null || _a === void 0 ? void 0 : _a.id); } }, (_a = record.customer) === null || _a === void 0 ? void 0 : _a.name);
            },
            sortDirections: ['descend', 'ascend'],
            sorter: function (a, b) {
                var _a, _b, _c, _d;
                var an = (_b = (_a = a.customer) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "";
                var bn = (_d = (_c = b.customer) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "";
                return an.localeCompare(bn);
            }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Doc Type',
            render: function (_, record) { return React.createElement("a", { href: "#", className: "emaillink", onClick: function () { return ShowDocumentDetails(record); } }, record.type); },
            sortDirections: ['descend', 'ascend'],
            sorter: function (a, b) {
                var _a, _b;
                var at = (_a = a.type) !== null && _a !== void 0 ? _a : "";
                var bt = (_b = b.type) !== null && _b !== void 0 ? _b : "";
                return at.localeCompare(bt);
            }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Ctry',
            render: function (_, record) { var _a, _b; return (_b = (_a = record.customer) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.country; },
            sortDirections: ['descend', 'ascend'],
            sorter: function (a, b) {
                var _a, _b, _c, _d, _e, _f;
                var ac = (_c = (_b = (_a = a.customer) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.country) !== null && _c !== void 0 ? _c : "";
                var bc = (_f = (_e = (_d = b.customer) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.country) !== null && _f !== void 0 ? _f : "";
                return ac.localeCompare(bc);
            }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Timestamp',
            render: function (_, record) { return FormatTime(record["created-on"]); },
            sortDirections: ['descend', 'ascend'],
            sorter: function (dateA, dateB) {
                var am = moment(dateA["created-on"]);
                var bm = moment(dateB["created-on"]);
                return am.diff(bm);
            }
        }];
    return React.createElement(React.Fragment, null,
        React.createElement("nav", null,
            React.createElement("div", { id: "docsearch" },
                React.createElement("div", { className: "navcell docsearchbar" },
                    React.createElement("input", { type: "text", id: "transsearchbox", name: "search", value: search.term, onChange: handleSearchTermChange })),
                React.createElement("div", { className: "navcell docparameter", style: { display: 'flex', alignItems: 'baseline' } },
                    React.createElement("select", { name: "parameter", id: "parameter", value: search.mode, onChange: handleSearchModeChange },
                        React.createElement("option", { value: "email" }, "Email"),
                        React.createElement("option", { value: "name" }, "Name"),
                        React.createElement("option", { value: "country" }, "Country"))),
                React.createElement("div", { className: "docsearchbutton" },
                    React.createElement("button", { className: "buttonprimary primary navbutton", onClick: handleSearchClick }, "Search")))),
        React.createElement("main", { className: "searchtransactions" },
            React.createElement(Table, { loading: isLoading, rowKey: function (r) { return r.id; }, columns: columns, dataSource: documents, pagination: {
                    current: page.current,
                    pageSize: page.size,
                    total: documentCount,
                }, scroll: { x: "auto" } })),
        React.createElement(Overlay, { visible: details.visible, onClose: handleCloseOverlay },
            React.createElement(DocumentDetails, { loader: DocumentLoader, onDocumentUpdated: handleDocumentUpdate })));
}
export default DocumentsQueuePage;
