import React, { useState, useEffect } from "react";
import TransactionFilterPane from './TransactionFilterPane';
import TransactionWalletFilterPane from './TransactionWalletFilterPane';
import TransactionListing from './TransactionListing';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import TransactionWalletListing from "./TransactionWalletListing";
import { reset } from "../../shared/store/slices/transactionSearch";
import { useDispatch } from 'react-redux';

function TransactionPage(props) {
    const { aspect } = props;
    const [cleared, setCleared] = useState(false);
    const dispatch = useDispatch();
    const getTitle = (aspect) => {
        const titles = {
            "customer-tx-gateway": "TX History",
            "customer-tx-wallet": "Wallet TX History",
            "tx-gateway": "Transactions",
            "tx-wallet": "Wallet Transactions",
        }

        return titles[aspect] ?? "Transactions";
    };

    TitleEffect(PageTitle(getTitle(aspect)));

    useEffect(() => {
        dispatch(reset());
        setCleared(true);
    }, []);

    let contentFilterPane = aspect === "customer-tx-gateway" || aspect === "tx-gateway" ? <TransactionFilterPane /> : <TransactionWalletFilterPane />;
    return (
        cleared &&
        <>
            <nav>{ contentFilterPane }</nav>
            <main className={ "searchtransactions" }>
            { aspect === "customer-tx-gateway" || aspect === "tx-gateway" ? <TransactionListing aspect={aspect} /> : <TransactionWalletListing aspect={aspect} /> }
            </main>
        </>);
}

export default TransactionPage;
