import React from "react";

export default function Header(props) {
    const { transactionId } = props;

    function requestRefund() {
    }

    return <div className="txdetailsheader">
        <div className="txdetailsheader1">
            <span className="txdetailsheadername">TXID</span> { transactionId }
        </div>
        <div className="txdetailsheader2">
            <button className="buttonprimary primary navbutton" onClick={ requestRefund }>Refund</button>
        </div>
    </div>
}
