import React from "react";

function SearchParameterChoice({value, onChange}) {
  return (<select name="parameter" id="parameter" value={value} onChange={onChange}>
    <option value="">Parameter</option>
    <option value="sid">SID</option>
    <option value="mid">MID</option>
    <option value="rid">RID</option>
    <option value="merchant_name">Merchant</option>
    <option value="merchant_email">Email</option>
    <option value="descriptor">Descriptor</option>
    <option value="gateway_name">Gateway</option>
  </select>);
}

function SearchShowChoice({value, onChange}) {
  return (<select name="merchant" id="merchant" value={value} onChange={onChange}>
    <option value="" disabled>Show me</option>
    <option value="sites">Sites</option>
    <option value="retailers">Retailers</option>
    <option value="merchants">Merchants</option>
  </select>);
}

export default function SearchBar(props) {
  const { query, showResults, onChange, onSearch} = props;
  const style = showResults ? "merchantsearch" : "freshsearch";

  function NotifyChange(updates) {
      if (onChange) {
        onChange({
          ...query,
          ...updates
        });
      }
  }
  function handleTextChange(event) {
    NotifyChange({ text: event.target.value });
  }
  
  function handleParameterChange(event) {
    NotifyChange({ parameter: event.target.value });
  }

  function handleShowChange(event) {
    NotifyChange({ show: event.target.value });
  }

  function PerformSearch(feelingLucky) {
    NotifyChange({ feelingLucky: feelingLucky });
    if (onSearch) {
      onSearch();
    }
  }

  return (<nav>
    <div id="transsearchnav" className={style}>
      <div className={"navcell navcellsearchbar " + style}>
        <input type="text" id="transsearchbox" name="search" placeholder="Search" value={query.text} onChange={handleTextChange} />
      </div>
      <div className={"navcell  navcellparameter " + style} style={{ display: "flex", alignItems: "baseline"}}>
        <SearchParameterChoice value={query.parameter} onChange={handleParameterChange} />
      </div>
      <div className={"navcell navcellrange " + style} style={{display: "flex", alignItems: "inherit"}}>
        <SearchShowChoice value={query.show} onChange={handleShowChange} />
      </div>
      <div className={"navcellsearchbutton " + style}><button className="buttonprimary primary navbutton" onClick={() => PerformSearch(false)}>Search</button></div>
      <div className={"navcell navcellexport " + style}> <button className="buttonprimary secondary navbutton" onClick={() => PerformSearch(true)}>Go</button></div>
    </div>
  </nav>);
}
