import { configureStore } from "@reduxjs/toolkit";
import { reducer as oidcReducer, loadUser } from 'redux-oidc';
import documentsReducer from "./slices/documents";
import merchantsReducer from "./slices/merchants";
import userInterfaceReducer from "./slices/userInterface";
import menuReducer from "./slices/menu";
import userManager from "../userManager";
import payoutsReducer from "./slices/payouts";
import transactionSearchReducer from './slices/transactionSearch';
import transactionDetailsReducer from './slices/transactionDetails';
import settlementsReducer from './slices/settlements';
import exchangesReducer from './slices/exchanges';
import adjustmentsReducer from './slices/adjustments';
import customersReducer from './slices/customers';
import periodStatementReducer from './slices/periodStatement';
import gatewaySummaryReducer from './slices/gatewaySummary';
import globalSearchReducer from './slices/globalSearch';
import auditLogsReducer from './slices/auditLogs';
import controlpanelReducer from "./slices/controlpanel";
import limitsReducer from "./slices/limits";
var store = configureStore({
    reducer: {
        oidc: oidcReducer,
        userInterface: userInterfaceReducer,
        menu: menuReducer,
        documents: documentsReducer,
        merchants: merchantsReducer,
        payouts: payoutsReducer,
        transactionSearch: transactionSearchReducer,
        transactionDetails: transactionDetailsReducer,
        settlements: settlementsReducer,
        exchanges: exchangesReducer,
        adjustments: adjustmentsReducer,
        customers: customersReducer,
        periodStatement: periodStatementReducer,
        gatewaySummary: gatewaySummaryReducer,
        globalSearch: globalSearchReducer,
        auditLogs: auditLogsReducer,
        controlpanel: controlpanelReducer,
        limits: limitsReducer
    },
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["redux-oidc/USER_FOUND"],
                ignoredPaths: ['oidc.user']
            }
        });
    }
});
loadUser(store, userManager);
export default store;
