import React, { useEffect } from 'react';
import MerchantSearchWidget from '../../components/MerchantSearchWidget';
import SettlementListing from './SettlementListing';
import { loadSettlements } from '../../shared/store/slices/settlements'
import { useDispatch} from 'react-redux';

export default function SettlementsPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadSettlements({ status: 'Pending' }));
    }, [dispatch]);

    return <>
        <nav><MerchantSearchWidget /></nav>
        <main className="pendingsettlements">
            <SettlementListing />
        </main>
    </>
}
