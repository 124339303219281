import React, { } from 'react';
import Modal from '../../components/Modal';
import { useSelector } from 'react-redux';

export default function ConfirmationModal(props) {
    const { modalTitle, modalContent } = useSelector(state => state.userInterface);

    return <Modal icon="approved" title={ modalTitle } buttonCaption="Done">
        <span dangerouslySetInnerHTML={{ __html: modalContent }} />
    </Modal>
}
