import React, { useEffect } from "react";
import 'antd/dist/antd.css';
import { TreeSelect, Space, DatePicker } from 'antd';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
    changeSite,
    changePeriod,
    clearData
} from '../../shared/store/slices/periodStatement';
import { ReactComponent as IconDownload } from './images/icon-download.svg';
import moment from 'moment';

export default function Navigation(props) {
    const { onChangePeriod } = props;

    const { initData, periodStart, periodEnd } = useSelector(state => state.periodStatement);
    const { RangePicker } = DatePicker;
    const merchantTree = useSelector(state => state.merchants.tree);
    const dispatch = useDispatch();

    let periodStartValue = "";
    let periodEndValue = "";

    const maxDate = new Date();  //Today

    const periodMaxYr = maxDate.getUTCFullYear();
    const periodMaxMo = maxDate.getUTCMonth();
    const periodMaxDy = maxDate.getUTCDate();

    const maxUTCDate = (periodMaxMo + 1) + `/${periodMaxDy}/${periodMaxYr}`;

    const { TreeNode } = TreeSelect;
    const showLeafIconFalse = false;

    if ((periodStart !== "") && (periodEnd !== "")) {
        //Build the statement period caption
        const perStart = new Date(periodStart);
        const perEnd = new Date(periodEnd);

        const periodStartYr = perStart.getUTCFullYear();
        const periodStartMo = perStart.getUTCMonth();
        const periodStartDy = perStart.getUTCDate();

        const periodEndYr = perEnd.getUTCFullYear();
        const periodEndMo = perEnd.getUTCMonth();
        const periodEndDy = perEnd.getUTCDate();

        periodStartValue = `${periodStartYr}-` + (periodStartMo + 1).toString().padStart(2, '0') + `-${periodStartDy}`;

        if (periodEnd > maxDate.toISOString()) {
            periodEndValue = `${periodMaxYr}-` + (periodMaxMo + 1).toString().padStart(2, '0') + `-${periodMaxDy}`;
        }
        else {
            periodEndValue = `${periodEndYr}-` + (periodEndMo + 1).toString().padStart(2, '0') + `-${periodEndDy}`;
        }

    }

    useEffect(() => {
        dispatch(clearData());
    }, [dispatch]);

    function handleMerchantChange(value, label, extra) {
        batch(() => {
            dispatch(changeSite(value));
            dispatch(changePeriod(initData));
        });
    }

    return <nav>
        <div id="statementnav">
            <div className="cell desc labelmerchant">
                <Space>
                <TreeSelect
                    showSearch
                    style={{ width: 250 }}
                    value={undefined}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Select SID "
                    allowClear={true}
                    treeDefaultExpandAll={false}
                    onChange={handleMerchantChange}
                    filterTreeNode={true}
                    treeNodeFilterProp="title"
                    dropdownMatchSelectWidth={false}
                    treeLine={
                        {
                            showLeafIconFalse,
                        }
                    }
                >
                    { merchantTree
                        ? Object.entries(merchantTree).map(([mid, mContent]) => {
                            var rNodes = null;
                            if(mContent.children){
                                rNodes = Object.entries(mContent.children).map(([rid, rContent]) => {
                                    var sNodes = null;
                                    if(rContent.children){
                                        sNodes = Object.entries(rContent.children).map(([sid, sContent]) => {
                                            return (<TreeNode key={ sid } value={ sid } title={ sContent.name }></TreeNode>)
                                        })
                                    }
                                    return (<TreeNode key={ `${mid}_${rid}` } value={ `${mid}_${rid}` } title={ rContent.name } selectable={ false }>{sNodes}</TreeNode>)
                                })
                            }

                            return (<TreeNode key={ `${mid}` } value={ `${mid}` } title={ mContent.name } selectable={ false }>{rNodes}</TreeNode>)
                        })
                        : null
                    }
                </TreeSelect>
                </Space>
            </div>
            <div className="cell labelperiod admin">
                <Space direction="vertical" size={12}>
                    <RangePicker placeholder="mm/dd/yyyy"
                        value={[ moment(periodStartValue || undefined), moment(periodEndValue || undefined)]}
                        onChange={ onChangePeriod } />
                </Space>
            </div>
            <div className="cell labelbutton admin">
                <button className="buttonsecondary"><IconDownload id="downloadicon" alt="Download Button" />DOWNLOAD</button>
            </div>
        </div>
    </nav>;
}
