import React, { useState, useEffect } from "react";
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import { useDispatch } from 'react-redux';
import styles from './limitsgroup.module.css';
import { getGroups, saveLimit } from "../../../shared/store/slices/limits";
import { Switch } from 'antd';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addSubMenuOptions } from "../../../shared/store/slices/menu";

function LimitsGroupPage(props) {

    const [ selectedLimit, setSelectedLimit ] = useState({
        id: null,
        category: 'Limits',
        groupname: '',
        name: '',
        description: '',
        text: '',
        active: true
    });

    const dispatch = useDispatch();
    const history = useHistory();

    TitleEffect(PageTitle("Wallets | Limit"));

    const handleChange = (event) => {

        let entry = event.target.getAttribute("data-entry");
        let value = event.target.value;

        setSelectedLimit({...selectedLimit, [entry]: value});

        console.log('selectedLimit', selectedLimit);
    }

    const handleSave = (event) => {
        dispatch(saveLimit({ limit: selectedLimit })).then((resp) => {
            retrieveGroups();
            if(resp){
                var b64id = window.btoa(selectedLimit.groupname);
                setTimeout(() => { history.push(`/wallets/limits/${b64id}`); }, 1500);
            }
            else
                setSelectedLimit({
                    id: null,
                    category: 'Limits',
                    groupname: '',
                    name: '',
                    description: '',
                    text: '',
                    active: true
                });
        });
    }

    const retrieveGroups = () => {
    
        dispatch(getGroups())
            .then(response => {
                if(response && response.payload) {
                    let subMenuOptions = {
                        option: "Main_Menu.Wallets.Limits",
                        items: []
                    };
                    response.payload.forEach((l, idx) => {
                        subMenuOptions.items.push({
                            name: l.name,
                            href: "/wallets/limits/" + l.id
                        });
                    });
        
                    dispatch(addSubMenuOptions(subMenuOptions));
                }
            });
    }

    return (
        <>
            <main className={ "searchtransactions" }>
                <div id="breadcrumbs"><a href="">Main</a> {">"} <a href="">Wallets</a> {">"} Limits {">"} </div>
                <div className={ styles.alignLeft }>
                    <div className="adminblock">
                        <div>
                            <input id="regioninput" className="biginputttitle " placeholder="Group Name" defaultValue={selectedLimit.groupname}  data-key={selectedLimit.groupname} data-entry="groupname" onChange={ handleChange }/>
                        </div>
                    </div>
                    <div className="adminblock limitblock">
                        <div className="limitblockgrid">
                            <div className="limitblockgrid1">
                                <div className="spacedrow"><label className="antselectlabel">Name</label></div>
                                <div><input type="text" className="antlike" placeholder="Value" defaultValue={selectedLimit.name} data-key={selectedLimit.name} data-entry="name" onChange={ handleChange }/></div>
                            </div>
                            <div className="limitblockgrid2">
                                <div className="spacedrow"><label className="antselectlabel">Description</label></div>
                                <div><input type="text" className="antlike" placeholder="Value" defaultValue={selectedLimit.description} data-key={selectedLimit.description} data-entry="description" onChange={ handleChange }/></div>
                                <div className={`${styles.controlsBox} ${styles.marginTop5px}` }>
                                    <div>Off</div>
                                    <div className={ styles.padding08px }>
                                        <Switch checked={selectedLimit.active} onChange={(event) => { handleLimitOnChange(event, idx) }} />
                                    </div>
                                    <div>On</div>
                                </div>
                            </div>
                            <div className="limitblockgrid3">
                                <div className="spacedrow"><label className="antselectlabel">Text</label></div>
                                <div><textarea cols="50" rows="10" className={`antlike ${styles.textareaLimit}`} placeholder="Value" defaultValue={selectedLimit.text} data-key={selectedLimit.text} data-entry="text" onChange={ handleChange }></textarea></div>
                            </div>
                            <div className="limitblockgrid4">
                                <div className={`${styles.paddingRight15px}`}>
                                    <button className={ `buttonsecondary primary ${ styles.widthAuto } ${ styles.marginRight5px }` } onClick={ handleSave }>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>);
}

export default LimitsGroupPage;
