import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styles from './Table.wallet.module.css';
import {
    updateParameter,
    updateSearchText,
    updateDateRange,
    updateCreatedOnSortOrder,
    TransactionWalletSearchQuery 
} from '../../shared/store/slices/transactionSearch';
import {
    getCustomerProfile,
    setCurrentCustomerProfile
} from '../../shared/store/slices/customers'

import {Link, useHistory} from 'react-router-dom';
import NoData from '../../components/NoData';
import {DateRange, FormatIso8601} from '../../shared/helpers/TimeHelper';

function handleScroll(evt) {
    const table = evt.target;
    if (table.scrollTop + table.clientHeight > table.scrollHeight * 0.8) {
        if (!loading && previousQuery) {
            const query = Object.assign({}, previousQuery);
            query.page += 1;
            dispatch(TransactionWalletSearchQuery(query));
        }
    }
}

const handleSelectCustomer = (customerId) => {
    dispatch(getCustomerProfile({
        customerId: customerId
    }))
    .then((response) =>{
        dispatch(setCurrentCustomerProfile(response.payload));
        history.push("/customer/profile");
    });
}


function TransactionRow(props) {

    const { created_on, descriptor, id, amount, account, currency,
        method, status, merchant, type, request_amount, fee, customer_id,
        parent_transaction_id, customer_email, created_by_email,aspect
    } = props;

    if (aspect === "customer-tx-wallet") {
        return (
            <tr key={id}>
                <td>{created_on}</td>
                <td><Link to={`/transactions/wallet/${id}`} target={"_blank"}>{id}</Link></td>
                <td>{ parent_transaction_id && parent_transaction_id.length > 0 ? <Link to={`/transactions/wallet/${parent_transaction_id}`} target={"_blank"}>{ parent_transaction_id }</Link> : ""}</td>
                <td>{account}</td>
                <td>{type}</td>
                <td>{method}</td>
                <td>{status.toUpperCase()}</td>
                <td>{request_amount}</td>
                <td>{currency}</td>
                <td>{amount}</td>
                <td>{fee}</td>
                <td>{descriptor}</td>
                <td>{merchant}</td>
                <td>{created_by_email}</td>
            </tr>
        );
    } else {
        return (
            <tr key={id}>
                <td>{created_on}</td>
                <td><Link to={`/transactions/wallet/${id}`} target={"_blank"}>{id}</Link></td>
                <td>{ parent_transaction_id && parent_transaction_id.length > 0 ? <Link to={`/transactions/wallet/${parent_transaction_id}`} target={"_blank"}>{ parent_transaction_id }</Link> : ""}</td>
                <td><span className="pseudolink" data-id={ customer_id } onClick={()=> { handleSelectCustomer(customer_id) }}>{ customer_email }</span></td>
                <td>{account}</td>
                <td>{type}</td>
                <td>{method}</td>
                <td>{status.toUpperCase()}</td>
                <td>{request_amount}</td>
                <td>{currency}</td>
                <td>{amount}</td>
                <td>{fee}</td>
                <td>{descriptor}</td>
                <td>{merchant}</td>
                <td>{created_by_email}</td>
            </tr>
        );
    }
}

function TransactionWalletListing(props) {
    const {aspect} = props;
    const {transactions, loading, previousQuery} = useSelector(state => state.transactionSearch);
    const currentCustomer = useSelector(state => state.customers.current);
    const rows = transactions.map((transaction) => TransactionRow(Object.assign({}, transaction, {aspect: aspect})));
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (aspect === "customer-tx-wallet") {
            if (currentCustomer?.email) {
                const range = DateRange('today');
                const query = {
                    from: FormatIso8601(range.start),
                    to: FormatIso8601(range.end),
                    sort: 'desc',
                    page_size: 40,
                    page: 0,
                    email: currentCustomer.email
                };
                dispatch(TransactionWalletSearchQuery(query));
                dispatch(updateParameter('email'));
                dispatch(updateSearchText(currentCustomer.email));
                dispatch(updateDateRange({type: 'today'}));
                dispatch(updateCreatedOnSortOrder('desc'));
            } else {
                history.push("/transactions/wallet");
            }
        }
    }, [currentCustomer, aspect]);

    let headers = [
        {title: "Created On",         align: 'right'},
        {title: "Wallet TXID",        align: 'right'},
        {title: "Parent Transaction", align: 'right'},
        {title: "Email",              align: 'right'},
        {title: "Account",            align: 'right'},
        {title: "Type",               align: 'right'},
        {title: "Method",             align: 'right'},
        {title: "Status",             align: 'right'},
        {title: "Req. Amt.",          align: 'right'},
        {title: "Currency",           align: 'right'},
        {title: "Amount",             align: 'right'},
        {title: "Fee",                align: 'right'},
        {title: "Descriptor",         align: 'right'},
        {title: "Merchant",           align: 'right'},
        {title: "Created By",         align: 'right'}
    ];

    if (aspect === "customer-tx-wallet") {
        headers = headers.filter(hd => hd.title != 'Email');
    }

    return transactions?.length
        ? <div style={{width: "100%", overflowX: "auto", whiteSpace: "nowrap"}}>
            <table className={`${styles.table} ant-table-fixed`} style={{ tableLayout: "fixed" }} onScroll={handleScroll}>
                <thead>
                    <tr>
                        {headers.map((hd) => {
                            return <th key={hd.title}>{ hd.title }</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
          </div>
        : ( loading === false
            ? <NoData />
            : null
          );
}

export default TransactionWalletListing;
