import React, { useEffect, useCallback } from "react";
import Navigation from './Navigation';
import Body from './Body';
import { useSelector, useDispatch } from 'react-redux';
import {
    changePeriod,
    loadGateways,
    loadDescriptors,
    loadGatewaySummary
} from '../../shared/store/slices/gatewaySummary';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import Overlay from "../../components/Overlay";
import AdjustForm from "./AdjustForm";
import ConfirmationModal from "./ConfirmationModal";
import ProcessingModal from "./ProcessingModal";
import ErrorModal from "./ErrorModal";
import { showOverlay } from '../../shared/store/slices/userInterface';
import { loadDescriptorCounterAccounts } from "../../shared/store/slices/adjustments";


export default function GatewaySummary(props) {
    TitleEffect(PageTitle("Gateway Summary"));

    const { gateway, descriptor, period, process, apiError } = useSelector(state => state.gatewaySummary);
    const showingOverlay = useSelector(state => state.userInterface.overlay);

    const dispatch = useDispatch();

    const maxDate = new Date();  //Today

    const periodMaxYr = maxDate.getUTCFullYear();
    const periodMaxMo = maxDate.getUTCMonth();
    const periodMaxDy = maxDate.getUTCDate();

    useEffect(() => {
        dispatch(loadGateways());
    }, [dispatch]);

    useEffect(() => {
        if (gateway != "")
            dispatch(loadDescriptors({gatewayId: gateway}));
    }, [gateway]);

    useEffect(() => {
        if (gateway != "" && descriptor != "")
            dispatch(loadGatewaySummary({gatewayId: gateway, descriptorId: descriptor, period: period}));
    }, [gateway, descriptor, period]);

    const reloadData = useCallback(() => {
        if (gateway != "" && descriptor != "")
            dispatch(loadGatewaySummary({gatewayId: gateway, descriptorId: descriptor, period: period}));
    }, [dispatch, gateway, descriptor, period]);

    function datesToStrings(periodStart, periodEnd) {
        const perStart = new Date(periodStart);
        const perEnd = new Date(periodEnd);

        const periodStartYr = perStart.getUTCFullYear();
        const periodStartMo = perStart.getUTCMonth();
        const periodStartDy = perStart.getUTCDate();

        const periodEndYr = perEnd.getUTCFullYear();
        const periodEndMo = perEnd.getUTCMonth();
        const periodEndDy = perEnd.getUTCDate();

        const periodStartValue = `${periodStartYr}-` + (periodStartMo + 1).toString().padStart(2, '0') + `-${periodStartDy}`;
        let periodEndValue;

        if (periodEnd > maxDate.toISOString()) {
            periodEndValue = `${periodMaxYr}-` + (periodMaxMo + 1).toString().padStart(2, '0') + `-${periodMaxDy}`;
        }
        else {
            periodEndValue = `${periodEndYr}-` + (periodEndMo + 1).toString().padStart(2, '0') + `-${periodEndDy}`;
        }

        return {
            periodStart: periodStartValue,
            periodEnd: periodEndValue
        }
    }

    function handleChangePeriod(dates) {
        const range = datesToStrings(dates[0], dates[1] || dates[0]);
        dispatch(changePeriod({period_start: range.periodStart, period_end: range.periodEnd}));
    }

    function handleAdjustRequest() {
        if (gateway != "" && descriptor != "")
            dispatch(loadDescriptorCounterAccounts({gatewayId: gateway, descriptorId: descriptor}));

        dispatch(showOverlay("adjust"));
    }

    var overlay;

    switch (showingOverlay) {
        case "adjust":
            overlay =
                <Overlay>
                    <AdjustForm onReload={ reloadData }/>
                </Overlay>;
            break;

        case "confirmation":
            overlay =
                <Overlay>
                    <ConfirmationModal />
                </Overlay>;
            break;

        case "processing":
            overlay =
                <Overlay>
                    <ProcessingModal process={ process } />
                </Overlay>;
            break;

        case "error":
            overlay =
                <Overlay>
                    <ErrorModal process={ process } error={ apiError } />
                </Overlay>;
            break;

        default:
            overlay = null;
    }

    return <>
        { overlay }
        <Navigation
            onChangePeriod={handleChangePeriod}
        />
        <Body
            onAdjust={handleAdjustRequest} />
    </>
}
