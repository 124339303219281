import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {
    setSignUpPage,
    setSignUpPageSize
} from '../shared/store/slices/customers'
import 'antd/dist/antd.css';
import { Pagination } from 'antd';

export default function SignUpPaginationWidget() {
    const { customers, signUpPage, completedSignUpCount} = useSelector(state => state.customers);
    const dispatch = useDispatch();

    useEffect(() => {
    }, [dispatch]);
    
    function handlePage(page, pageSize){
        dispatch(setSignUpPage(page));
        dispatch(setSignUpPageSize(pageSize));
    }
    
    if(customers == null || customers.length === 0) return null;

    return <div className="signuppagination">
        <div className="totalsignups"><span style={{fontFamily: "NHU-Medium"}}>{completedSignUpCount}</span><span
            style={{fontSize: "1.1rem", paddingLeft: "0.6rem"}}>TOTAL SIGNUPS</span></div>
        
        <div className="pagination">
            <Pagination
                total={customers.length}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                defaultPageSize={20}
                defaultCurrent={1}
                current={signUpPage}
                onChange={handlePage}
            />
        </div>
    </div>
}
