import * as React from 'react';
import {createContext} from 'react';
import {HubConnectionBuilder} from "@microsoft/signalr";
import {useDispatch, useSelector} from "react-redux";
import {AdminNotificationReceived, setWSConnectionState} from "../../shared/store/slices/menu";

const AdminChannelContext = createContext(undefined);

export class AdminChannel {
    constructor(connection) {
        this.connection = connection;
    }
}

function AdminProvider({ children }) {
    let ws;
    const dispatch = useDispatch();
    const user = useSelector(state => state.oidc.user);
    const authenticated = user && !user.expired;

    if (!ws && authenticated) {
        const connection = new HubConnectionBuilder()
            .withUrl('/api/admin-hub', {
                accessTokenFactory: () => user.access_token
            })
            .withAutomaticReconnect()
            .build();
        connection.serverTimeoutInMilliseconds = 240000;
        connection.keepAliveIntervalInMilliseconds = 20000;

        connection.start()
            .then(() => {
                console.log("Admin Connection " + connection._connectionState);
                dispatch(setWSConnectionState(connection._connectionState));
            })
            .catch((ex) => {
                console.log("Failed to establish connection to admin.");
                console.log(ex);
            });

        connection.on("receiveNotification", notification => {
            dispatch(AdminNotificationReceived(notification));
        });

        connection.onclose(err => {
            console.log(err.message ? err.message : "Admin Connection closed");
        });

        ws = new AdminChannel(connection);
    }
    return (<AdminChannelContext.Provider value={ws}>{children}</AdminChannelContext.Provider>);
}

export default AdminProvider;

export {
    AdminChannelContext
}