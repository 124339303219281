import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {
    setSearchText,
    setSearchBy,
    searchUsers,
    setSignUpDateRange,
    setInRealm
} from '../shared/store/slices/customers'
import 'antd/dist/antd.css';
import { useHistory } from "react-router-dom"
import { DatePicker, Space } from 'antd';
import moment from 'moment';


export default function SignUpSearchWidget() {
    const { searchText, searchBy, signUpFromDate, signUpEndDate, inRealm} = useSelector(state => state.customers);
    const dispatch = useDispatch();
    const history = useHistory();
    const { RangePicker } = DatePicker;

    useEffect(() => {
        handleDefaultSearch();
    }, []);
    
    useEffect(() => {
    }, [dispatch]);

    function handleChangeText(event) {
        dispatch(setSearchText(event.target.value));
    }

    function handleChangeBy(event) {
        dispatch(setSearchBy(event.target.value));
    }

    function handleChangeDateRange(dates, dateStrings){
        dispatch(setSignUpDateRange(dateStrings));
    }

    function handleChangeInRealm(event){
        dispatch(setInRealm(event.target.value));
    }

    function handleSearch(event) {
        event.preventDefault();
        dispatch(searchUsers({
            searchText: searchText,
            searchBy: searchBy,
            fromDate: signUpFromDate,
            endDate: signUpEndDate,
            inRealm: inRealm == '' ? null : inRealm,
            orderBy: "createdTimestamp"
        })).then(() => {
            history.push('/security/signups');
        });
    }

    function handleDefaultSearch() {
        let date = new Date();
        const endDate = getDateString(date);
        
        date.setDate(date.getDate() - 6);
        const fromDate = getDateString(date);

        const dateStrings = [fromDate, endDate];
        dispatch(setSignUpDateRange(dateStrings));
        
        dispatch(searchUsers({
            searchText: "",
            searchBy: searchBy,
            fromDate: fromDate,
            endDate: endDate,
            orderBy: "createdTimestamp"
        })).then(() => {
            history.push('/security/signups');
        });
    }

    //YYYY-MM-DD
    function getDateString(date) {
        const y = date.getFullYear(), m = date.getMonth() + 1, d = date.getDate();
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
    }

    return <form onSubmit={ handleSearch }>
        <div id="transsearchnav" className="merchantsearch">
            <div className="navcell navcellsearchbar merchantsearch">
                <input type="text" id="transsearchbox" name="search" placeholder="Search for" value={ searchText } onChange={ handleChangeText } />
            </div>
    
            <div className="navcell  navcellparameter" style={{"display": "flex", "alignItems": "baseline"}}>
                <select name="parameter" id="parameter" value={ searchBy } onChange={ handleChangeBy }>
                    <option value="" disabled>In</option>
                    <option value="email">Email</option>
                    <option value="firstName">First Name</option>
                    <option value="lastName">Last Name</option>
                    <option value="country">Country</option>
                </select>
            </div>

            <div className="navcell navcellfilter" style={{ display:'flex', alignItems:'inherit' }}>
                <select name="realm" id="realm" value={ inRealm } onChange={ handleChangeInRealm }>
                    <option value="none" disabled>In Realm</option>
                    <option value="">All</option>
                    <option value="wallet88">Wallet88</option>
                    <option value="flypto">Flypto</option>
                </select>
            </div>

            <div className="navcell navcellrange" style={{"display": 'flex', "alignItems": 'inherit'}}>
                <Space direction="vertical" size={12}>
                    <RangePicker
                        ranges={{
                            '24 Hours': [moment(), moment()],
                            '1 Week': [moment().subtract(6,'day'), moment()],
                            '30 Days': [moment().subtract(29,'day'), moment()],
                            '1 Year': [moment().subtract(1,'year'), moment()],
                        }}
                        defaultValue={[moment().subtract(6,'day'), moment()]}
                        onChange={handleChangeDateRange}
                    />
                </Space>
            </div>

            <div className="navcellsearchbutton merchantsearch">
                <div className="navcellsearchbutton"><button className="buttonprimary primary navbutton" type="submit">Search</button></div>
            </div>
        </div>
    </form>
}
