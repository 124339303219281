import React, { useState} from 'react';
import { useDispatch } from 'react-redux';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
    uploadReturnFile
} from "../../shared/store/slices/payouts";
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";

export default function UploadReturnFilePage(props) {

    TitleEffect(PageTitle("Return Files | Accounting "));

    const [uploadResult, setUploadResult] = useState(false);
    const dispatch = useDispatch();
    const csvAccept = '.csv';

    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append('returnfile', file);

        dispatch(uploadReturnFile(formData))
            .then(response => {
                if(response.payload.status === "settled"){
                    setUploadResult(true);
                }
            });
    }

    return <div className="contentcolumn">
        <nav>
        </nav>
        <main>
            <div style={{textAlign: 'left'}}>
                <div className="adminblock">
                    <h3 className="adminheader">Upload a Return File</h3>
                    <div className="spacedrow"><label className="antselectlabel">Upload Return File</label></div>
                    <div>
                        <Upload accept={csvAccept} maxCount={1} beforeUpload={handleUpload} customRequest={({ onSuccess }) => setTimeout(() => { onSuccess("ok", null); }, 0)}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </div>
                    {uploadResult ?
                        <div className="spacedrow uploadresult success">Your file was successfully uploaded.</div> : ""}
                </div>
            </div>
        </main>
    </div>
}