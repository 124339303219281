import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import {
    updateSearchText,
    updateParameter,
    updateDateRange,
    updateAdvancedFilter,
    TransactionSearchQuery,
    AvailableActionsQuery,
    AvailableCurrenciesQuery,
    AvailableMethodsQuery,
    AvailableSitesQuery,
    AvailableStatusesQuery
} from '../../shared/store/slices/transactionSearch'
import InputDateRange from './InputDateRange';
import {DateRange, FormatIso8601} from '../../shared/helpers/TimeHelper';
import 'antd/dist/antd.css';
import { Popover } from 'antd';
import TransactionAdvancedFilterOptions from './TransactionAdvancedFilterOptions';

function TransactionFilterPane() {
    const {search, dateRange, sites, amount, currencies, actions, statuses, methods} = useSelector(state => state.transactionSearch.criteria);
    const { advancedFilterVisible, filtersDoSearch } = useSelector(state => state.transactionSearch);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AvailableActionsQuery());
        dispatch(AvailableCurrenciesQuery());
        dispatch(AvailableMethodsQuery());
        dispatch(AvailableSitesQuery());
        dispatch(AvailableStatusesQuery());
    }, [dispatch]);

    useEffect(() => {
        if (filtersDoSearch)
            performSearch();
    }, [filtersDoSearch]);

    function handleTextChange(event) {
        dispatch(updateSearchText(event.target.value));
    }

    function handleParameterChange(event) {
        dispatch(updateParameter(event.target.value));
    }

    function handleDateRangeChange(event) {
        dispatch(updateDateRange(event.detail));
    }

    function handleAdvancedFilterClick() {
        dispatch(updateAdvancedFilter());
    }

    function performSearch() {
        if (dateRange.type) {
            const range = DateRange(dateRange.type);
            const query = {
                from: FormatIso8601(range.start),
                to: FormatIso8601(range.end),
                page_size: 40,
                page: 0
            };

            if (search.parameter && search.text) {
                query[search.parameter] = search.text;
            }

            if (amount.operation && amount.value) {
                query['amount_operator'] = amount.operation;
                query['amount'] = amount.value;
            }

            if (sites) {
                query['sites'] = sites.map(s => parseInt(s.substring(1)));
            }

            if (currencies) {
                query['currencies'] = currencies;
            }

            if (actions) {
                query['actions'] = actions;
            }

            if (statuses) {
                query['statuses'] = statuses;
            }

            if (methods) {
                query['methods'] = methods;
            }

            dispatch(TransactionSearchQuery(query));
        }
    }
    
    function handleSubmit(event){
        event.preventDefault();
        performSearch();
    }

    return <form onSubmit={ handleSubmit }>
        <div id="transsearchnav">
            <div className="navcell navcellsearchbar">
                <input type="text" id="transsearchbox" name="search" placeholder="Search" value={search.text} onChange={handleTextChange} />
            </div>
    
            <div className="navcell  navcellparameter" style={{"display": "flex", "alignItems": "baseline"}}>
                <select name="parameter" id="parameter" value={search.parameter ?? ""} onChange={handleParameterChange}>
                    <option value="" disabled>Parameter</option>
                    <option value="transaction_id">Transaction ID (TXID)</option>
                    <option value="email">Email Address</option>
                    <option value="lastname">Last Name</option>
                    <option value="ip_address">IP Address</option>
                    <option value="account_number">Card Number</option>
                    <option value="merchant_reference">Merchant Reference(TID)</option>
                </select>
            </div>
    
            <div className="navcell navcellrange" style={{"display": 'flex', "alignItems": 'inherit'}}>
                <InputDateRange name="merchant" id="merchant" value={dateRange} onChange={handleDateRangeChange} />
            </div>
    
            <Popover content={<TransactionAdvancedFilterOptions />} title="Filter by" trigger="click" visible={advancedFilterVisible} onVisibleChange={handleAdvancedFilterClick}>
                <div className="navcell navcellfilter"><button className="buttonprimary secondary navbutton" type="button">Filters</button></div>
            </Popover>
    
            <div className="navcellsearchbutton"><button className="buttonprimary primary navbutton" type="submit">Search</button></div>
        </div>
    </form>
}

export default TransactionFilterPane;
