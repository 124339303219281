import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import userManager from '../shared/userManager';

function isAuthorized(user, requires) {
  if (!requires)
    return true;

  const ra = user.profile.resource_access ?? {};
  const roles = ra[window['w88.config']?.oauth.client_id]?.roles ?? [];
  if (typeof requires == 'string') {
    return roles.includes(requires);
  }

  if (Array.isArray(requires)) {
    return requires.every(e => roles.includes(e));
  }

  return false;
}

function isExpired(user) {
  return !user || user.expired;
}

export default function Protected(props) {
  const { requires, component } = props;
  const user = useSelector(state => state.oidc.user);
  const history = useHistory();

  useEffect(() => {
    if (isExpired(user)) {
      userManager.signinRedirect({
        data: {
          path: window.location.pathname + window.location.search
        }
      });
    } else if (!isAuthorized(user, requires)) {
      history.push("/401");
    }
  }, [user, requires, history]);

  if (isExpired(user) || !isAuthorized(user, requires)) {
      return <Fragment />
  }

  if (component) {
      return component(user);
  }

  return (<Fragment>{props.children}</Fragment>);
}
