import React from 'react';
import Modal from '../../components/Modal';


export default function ErrorModal(props) {
    const { process, error } = props;

    return <Modal icon="failed" title="Request failed." buttonCaption="Close">
        Your { process } failed. { error ? `${error}. ` : '' }Please resolve the issue and resubmit, or contact Support.
    </Modal>
}
