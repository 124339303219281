import React, { useState, useEffect } from "react";
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from 'react-redux';
import styles from './limits.module.css';
import { clearLimits,
         saveLimit,
         deleteLimit,
         deleteLimitsFromGroup,
         saveGroupLimit,
         loadLimits,
         getLimitByGroup,
         getGroups
} from "../../../shared/store/slices/limits";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Switch } from 'antd';
import { addSubMenuOptions } from "../../../shared/store/slices/menu";

function LimitsPage(props) {

    const { groupname } = useParams();
    const { limits } = useSelector(state => state.limits);
    const [ limitsGroup, SetLimitsGroup] = useState([]);
    const [ removed, setRemovedLimit ] = useState(undefined);
    const [ groupEnabled, setGroupEnabled ] = useState(true);
    const [ removeGroup, setRemoveGroup ] = useState(undefined);
    const [ strGroupName, setStrGroupName ] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();

    TitleEffect(PageTitle(groupname ? "Wallets | Limit | "+window.atob(groupname) : "Wallets | Limit"));
   
    useEffect(() => {
        if(groupname) {
            retrieveLimits();
            setStrGroupName(window.atob(groupname));
        }
    }, [groupname]);

    useEffect(() => {
        if(removed){
            let isExisting = limits.filter(l => l.name === removed[0].name);
            if(isExisting && isExisting.length > 0)
                dispatch(deleteLimit({ limit: removed[0] })).then(() => {
                    retrieveLimits();
                });
        }
    }, [removed])

    useEffect(() => {
        if(removeGroup){
            dispatch(deleteLimitsFromGroup({ group: groupname })).then(() => {
                retrieveGroups();
            });
        }
    }, [removeGroup]);

    useEffect(() => {
        if(limits && limits.length > 0) {
            var currentLimits = [...limits];
            currentLimits.unshift({
                id: null,
                category: 'Limits',
                groupname: strGroupName,
                name: '',
                description: '',
                text: '',
                active: true
            });
            SetLimitsGroup(currentLimits);
        }
    }, [limits]);

    const handleChange = (event) => {

        let entry = event.target.getAttribute("data-entry");
        let idx = event.target.getAttribute("data-idx");
        let value = event.target.value;

        let updatedSelectedLimit = [...limitsGroup];
        let existing = {...updatedSelectedLimit[idx]};

        console.log('entry', entry);
        console.log('idx', idx);
        console.log('value', value);
        console.log('existing', existing);

        if(existing) {

            if(entry === 'name'){
                existing.name = value;

                let alreadyIn = limits.find(st => st.name === value);
                if(alreadyIn) {
                    existing.name = existing.name+'_repeated';
                    event.target.value = existing.name+'_repeated';
                }
            }

            if(entry === 'description'){
                existing.description = value;
            }

            if(entry === 'text'){
                existing.text = value;
            }

            updatedSelectedLimit[idx] = existing;
            SetLimitsGroup(updatedSelectedLimit);
        }

        console.log('updatedSelectedLimit', updatedSelectedLimit);
    }

    const handleSave = (event) => {
        let changedLimit = limitsGroup.filter(l => l.id === null);
        if(changedLimit)
            dispatch(saveLimit({ limit: changedLimit[0] })).then(() => {
                retrieveLimits();
            });
    }

    const handleUpdate = (idx) => {

        let updatedSelectedLimit = [...limitsGroup];
        let updatedLimit = updatedSelectedLimit[idx];

        if(updatedLimit)
            dispatch(saveLimit({ limit: updatedLimit })).then(() => {
                retrieveLimits();
            });
    }

    const handleDelete = (index) => {

        let currentItems = [...limitsGroup];
        let removed = currentItems.splice(index, 1);
        currentItems = currentItems.filter(l => l.id !== removed.id);
        if(currentItems.length <= 1) {
            setRemoveGroup(window.btoa(removed.groupName));
        } else {
            SetLimitsGroup(currentItems);
            setRemovedLimit(removed);
        }
    }

    const handleGroupOnChange = (checked) => {

        let limitGroupItems = [];

        limitsGroup.forEach((limit, idx) => {
            let existing = {...limit};
            existing.active = checked;
            limitGroupItems.push(existing);
        });

        SetLimitsGroup(limitGroupItems);
        setGroupEnabled(checked);
    }

    const handleLimitOnChange = (checked, idx) => {

        let updatedSelectedLimit = [...limitsGroup];
        let existing = {...updatedSelectedLimit[idx]};

        if(existing) {
            existing.active = checked;
            updatedSelectedLimit[idx] = existing;
            SetLimitsGroup(updatedSelectedLimit);
        }
    }

    const handleSaveGroup = (event) => {
        dispatch(saveGroupLimit({ limit: limitsGroup })).then(() => {
            retrieveLimits();
        });
    }

    const handleDeleteGroup = (event) => {
        setRemoveGroup(groupname);
    }

    const retrieveLimits = () => {
        SetLimitsGroup([]);
        dispatch(getLimitByGroup({ group: groupname })).then((resp) => {
            if(resp && resp.payload) {
                let isGroupEnabled = resp.payload.filter(l => l.active === true);          
                setGroupEnabled(isGroupEnabled && isGroupEnabled.length > 0);
                dispatch(loadLimits(resp.payload));
            }
        });
    }

    const retrieveGroups = () => {
    
        dispatch(getGroups())
            .then(response => {
                if(response && response.payload) {
                    let subMenuOptions = {
                        option: "Main_Menu.Wallets.Limits",
                        items: []
                    };
                    response.payload.forEach((l, idx) => {
                        subMenuOptions.items.push({
                            name: l.name,
                            href: "/wallets/limits/" + l.id
                        });
                    });
        
                    dispatch(addSubMenuOptions(subMenuOptions));
                }
                history.push(`/wallets/limitsgroup/`);
            });
    }

    return (!removeGroup && 
        <>
            <main className={ "searchtransactions" }>
                <div id="breadcrumbs"><a href="">Main</a> {">"} <a href="">Wallets</a> {">"} Limits {">"} </div>
                <div className={ styles.alignLeft }>
                    <div className="adminblock">
                        <div>
                            <input id="regioninput" className="biginputttitle " placeholder="Group Name" defaultValue={strGroupName} />
                        </div>
                    </div>
                    {limitsGroup.map((l, idx) => {
                        return <>
                            <div className="adminblock limitblock" key={`group_${l.id}`}>
                                <div className="limitblockgrid">
                                    <div className="limitblockgrid1">
                                        <div className="spacedrow"><label className="antselectlabel">Name</label></div>
                                        <div><input type="text" className="antlike" placeholder="Value" defaultValue={l.name} data-key={l.name} data-entry="name" data-idx={idx} onChange={ handleChange }/></div>
                                    </div>
                                    <div className="limitblockgrid2">
                                        <div className="spacedrow"><label className="antselectlabel">Description</label></div>
                                        <div><input type="text" className="antlike" placeholder="Value" defaultValue={l.description} data-key={l.description} data-entry="description" data-idx={idx} onChange={ handleChange }/></div>
                                        <div className={`${styles.controlsBox} ${styles.marginTop5px}` }>
                                            <div>Off</div>
                                            <div className={ styles.padding08px }>
                                                <Switch checked={l.active} onChange={(event) => { handleLimitOnChange(event, idx) }} />
                                            </div>
                                            <div>On</div>
                                        </div>
                                    </div>
                                    <div className="limitblockgrid3">
                                        <div className="spacedrow"><label className="antselectlabel">Text</label></div>
                                        <div><textarea cols="50" rows="10" className={`antlike ${styles.textareaLimit}`} placeholder="Value" defaultValue={l.text} data-key={l.text} data-entry="text" data-idx={idx} onChange={ handleChange }></textarea></div>
                                    </div>
                                    <div className="limitblockgrid4">
                                        <div className={`${styles.paddingRight15px}`}>
                                            <button className={ `buttonsecondary primary ${ styles.widthAuto } ${ styles.marginRight5px }` } onClick={(event) => {!l.id ? handleSave(event) : handleUpdate(idx)} }>Save</button>
                                            {l.id && <>
                                                <button className={`buttonsecondary secondary ${styles.widthAuto}`} onClick={() => { handleDelete(idx) }}>Delete</button>
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    })}
                    <div className="adminblock">
                        <h3 className="adminheader">Controls</h3>
                        <div className={ styles.controlsBox }>
                            <div>Off</div>
                            <div className={ styles.padding08px }>
                                <Switch checked={groupEnabled} onChange={handleGroupOnChange} />
                            </div>
                            <div>On</div>
                        </div>
                    </div>
                    <div className="adminblock">
                        <button className={`buttonprimary primary ${ styles.widthAuto }`} onClick={handleSaveGroup}>Save</button>
                        <button className={`buttonprimary secondary ${ styles.widthAuto }`} onClick={handleDeleteGroup}>Delete</button>
                    </div>
                </div>
            </main>
        </>);
}

export default LimitsPage;
