import React from 'react';
import Modal from '../../components/Modal';


export default function ProcessingModal(props) {
    const { process } = props;

    return <Modal icon="approved" title="Request received." buttonCaption="Done">
        We're working on your { process } and will notify you by email when it's been processed.
    </Modal>
}
