import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    GetAvailableActions,
    GetAvailableCurrencies,
    GetAvailableMethods,
    GetAvailableSites,
    GetAvailableStatuses,
    FindTransactions
} from '../../GatewayAPI';
import {startLoading, stopLoading} from "./userInterface";
import { FindWalletTransactions } from '../../../domain/services/search.svc';

export const TransactionWalletSearchQuery = createAsyncThunk("transactionSearch/query", async (query, { getState, dispatch, requestId }) => {
    dispatch(startLoading(requestId));
    const user = getState().oidc.user;
    const response = await FindWalletTransactions(user, query)
        .finally(() => dispatch(stopLoading(requestId)));
    return response.data;
});

export const TransactionSearchQuery = createAsyncThunk("transactionSearch/query", async (query, { getState, dispatch, requestId }) => {
    dispatch(startLoading(requestId));

    const user = getState().oidc.user;
    const response = await FindTransactions(user, query)
        .finally(() => dispatch(stopLoading(requestId)));

    return response.data;
});

export const AvailableActionsQuery = createAsyncThunk("actions/query", async (params, { getState }) => {
    const user = getState().oidc.user;
    const response = await GetAvailableActions(user);
        return response.data;
});

export const AvailableCurrenciesQuery = createAsyncThunk("currencies/query", async (params, { getState }) => {
    const user = getState().oidc.user;
    const response = await GetAvailableCurrencies(user);

    return response.data;
});

export const AvailableMethodsQuery = createAsyncThunk("methods/query", async (params, { getState }) => {
    const user = getState().oidc.user;
    const response = await GetAvailableMethods(user);

    return response.data;
});

export const AvailableSitesQuery = createAsyncThunk("sites/query", async (params, { getState }) => {
    const user = getState().oidc.user;
    const response = await GetAvailableSites(user, query);

    return response.data;
});

export const AvailableStatusesQuery = createAsyncThunk("statuses/query", async (params, { getState }) => {
    const user = getState().oidc.user;
    const response = await GetAvailableStatuses(user);

    return response.data;
});

export const transactionSearchSlice = createSlice({
    name: 'transactionSearch',

    initialState: {
        criteria: {
            search: {
                text: "",
                parameter: ""
            },
            dateRange: {
                type: "today",
                from: null,
                to: null
            },
            sort: {
                column: "Created On",
                sortOrder: "desc"
            },
            amount: {
                operation: '=',
                value: ''
            },
            sites: [],
            currencies: [],
            actions: [],
            statuses: [],
            methods: [],
            types: []
        },
        actions: [],
        currencies: [],
        methods: [],
        sites: [],
        statuses: [],
        transactions: [],
        loading: undefined,
        advancedFilterVisible: false,
        filtersDoSearch: false,
        working: {
        },
        previousQuery: null,
        types: ['Credit', 'Debit']
    },
    reducers: {
        reset: (state, action) => {
            state.criteria = {
                search: {
                    text: "",
                    parameter: ""
                },
                dateRange: {
                    type: "today",
                    from: null,
                    to: null
                },
                sort: {
                    column: "Created On",
                    sortOrder: "desc"
                },
                amount: {
                    operation: '=',
                    value: ''
                },
                sites: [],
                currencies: [],
                actions: [],
                statuses: [],
                methods: [],
                types: []
            };
            state.actions = [];
            state.currencies = [];
            state.methods = [];
            state.sites = [];
            state.statuses = [];
            state.transactions = [];
            state.loading = undefined;
            state.advancedFilterVisible = false;
            state.filtersDoSearch = false;
            state.working = {};
            state.previousQuery = null;
            state.types = ['Credit', 'Debit'];
        },
        updateSearchText: (state, action) => {
            state.criteria.search.text = action.payload;
        },
        updateParameter: (state, action) => {
            state.criteria.search.parameter = action.payload;
        },
        updateCreatedOnSortOrder: (state, action) => {
            state.criteria.sort.column = 'Created On';
            state.criteria.sort.sortOrder = action.payload;
        },
        updateDateRange: (state, action) => {
            state.criteria.dateRange = action.payload
        },
        updateAdvancedFilter: (state, action) => {
            const visible = typeof action.payload == 'boolean' ? action.payload : !state.advancedFilterVisible;
            state.advancedFilterVisible = visible;
            state.filtersDoSearch = false;
            if (visible) {
                state.working = Object.assign(state.working, state.criteria);
            } else {
                state.filtersDoSearch = true;
            }
        },
        updateWorking: (state, action) => {
            state.working = Object.assign(state.working, action.payload);
        },
        applyWorking: (state, action) => {
            state.criteria = Object.assign(state.criteria, state.working);
        }
    },

    extraReducers: (builder) => {
        builder.addCase(TransactionWalletSearchQuery.pending || TransactionSearchQuery.pending, (state, action) => {
            if (action.meta.arg.page === 0) {
                state.transactions = [];
            }

            state.loading = true;
            state.previousQuery = action.meta.arg;
        });
        builder.addCase(TransactionWalletSearchQuery.fulfilled || TransactionSearchQuery.fulfilled, (state, action) => {
            state.transactions = state.transactions.concat(action.payload);
            state.loading = false;
        });
        builder.addCase(TransactionWalletSearchQuery.rejected || TransactionSearchQuery.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(AvailableActionsQuery.fulfilled, (state, action) => {
            state.actions = action.payload;
        });
        builder.addCase(AvailableCurrenciesQuery.fulfilled, (state, action) => {
            state.currencies = action.payload;
        });
        builder.addCase(AvailableMethodsQuery.fulfilled, (state, action) => {
            state.methods = action.payload;
        });
    }
})

export const {
    updateSearchText,
    updateExactMatch,
    updateParameter,
    updateCreatedOnSortOrder,
    updateDateRange,
    updateAdvancedFilter,
    updateWorking,
    applyWorking,
    reset
} = transactionSearchSlice.actions;

export default transactionSearchSlice.reducer;
