import React from 'react';
import { Switch, Route } from "react-router-dom";
import Protected from '../components/Protected';
import BlankPage from '../components/BlankPage';
import StandardPage from '../components/StandardPage';
import NotFound from '../components/404';
import NotAuthorizedPage from '../components/NotAuthorizedPage';
import DocumentsQueuePage from '../domain/documents/DocumentsQueuePage';
import MerchantSearchPage from '../domain/merchant/search';
import DescriptorPage from '../domain/settings/gateway/descriptorPage';
import CallbackPage from '../domain/auth/CallbackPage';
import SilentRenewPage from '../domain/auth/SilentRenewPage';
import WithdrawalsTransactionsPendingPage from '../domain/payouts/WithdrawalsTransactionsPendingPage';
import TransactionPage from '../domain/TransactionPage';
import TransactionDetails from '../domain/TransactionDetails/TransactionDetails';
import SettlementsPage from '../domain/SettlementsPage';
import ExchangesPage from '../domain/ExchangesPage';
import WithdrawalsBatchesForApprovalPage from '../domain/payouts/WithdrawalsBatchesForApprovalPage';
import BatchTransactionsPage from '../domain/payouts/BatchTransactionsPage';
import LiveBatchesPage from '../domain/payouts/LiveBatchesPage';
import ApprovedBatches from '../domain/payouts/ApprovedBatches';
import BatchesProcessingHistoryPage from '../domain/payouts/BatchesProcessingHistoryPage';
import CustomerAdjustmentsPage from '../domain/Customers/AdjustmentsPage';
import CustomerSearchPage from '../domain/Customers/SearchPage';
import CustomersPage from '../domain/Customers/CustomersList';
import MerchantAdjustmentsPage from '../domain/merchant/AdjustmentsPage';
import PeriodStatement from '../domain/PeriodStatement/PeriodStatement'
import GlobalSearchPage from '../domain/GlobalSearch/GlobalSearchPage';
import SignUpsPage from "../domain/SignUps";
import ActivityPage from '../domain/ActivityPage';
import CustomersProfilePage from '../domain/Customers/Profile';
import CustomerCommentsPage from '../domain/Comments';
import BuyAndSendPage from '../domain/ControlPanel/BuyAndSend/BuyAndSendPage';
import CountriesAllowed from '../domain/ControlPanel/Main/CountriesAllowed';
import TransactionWalletDetails from '../domain/TransactionWalletDetails/TransactionWalletDetails';
import LogOutPage from "../domain/auth/LogOutPage";
import GatewaySummary from '../domain/GatewaySummary/GatewaySummary';
import WalletAppSettings from '../domain/ControlPanel/WalletApp/WalletAppSettings';
import PendingAdjustments from '../domain/Adjustments/PendingAdjustments'
import ConfigurationPage from '../domain/Wallets/Configuration';
import LimitsPage from '../domain/Wallets/Limits';
import LimitsGroupPage from '../domain/Wallets/LimitsGroup';
import UploadReturnFilePage from '../domain/payouts/UploadReturnFilePage';

function HomePage() {
  return (<Protected><StandardPage><BlankPage /></StandardPage></Protected>);
}

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/callback"
                component={ CallbackPage }
            />

            <Route exact path="/silent_renew"
                component={ SilentRenewPage }
            />

            <Route exact path="/logged-out"
                component={ LogOutPage }
            />

            <Route exact path="/"
                component={ () =>
                    <Protected requires='query-users'>
                        <GlobalSearchPage />
                    </Protected>
                }
            />

            <Route exact path="/home"
                component={ () =>
                    <Protected requires='query-users'>
                        <GlobalSearchPage />
                    </Protected>
                }
            />

            <Route exact path="/customers"
                component={ () =>
                    <Protected requires='query-users'>
                        <CustomersPage />
                    </Protected>
                }
            />

            <Route exact path="/customer/search"
                component={ () =>
                    <Protected requires='query-users'>
                        <CustomerSearchPage />
                    </Protected>
                }
            />

            <Route exact path="/customer/action-log"
                component={ () =>
                    <Protected requires='view-customer-audit-logs'>
                        <ActivityPage />
                    </Protected>
                }
            />

            <Route exact path="/customer/profile"
                component={ () =>
                    <Protected>
                        <CustomersProfilePage />
                    </Protected>
                }
            />

            <Route exact path="/customer/transactions/gateway"
                component={ () =>
                    <Protected requires='query-transactions'>
                        <TransactionPage aspect="customer-tx-gateway"/>
                    </Protected>
                }
            />

            <Route exact path="/customer/transactions/wallet"
                component={ () =>
                    <Protected requires='query-transactions'>
                        <TransactionPage aspect="customer-tx-wallet"/>
                    </Protected>
                }
            />

            <Route exact path="/customer/adjustments"
                component={ () =>
                    <Protected requires='customer-adjustments-level-1'>
                        <CustomerAdjustmentsPage />
                    </Protected>
                }
            />

            <Route exact path="/customer/comments"
                component={ () =>
                    <Protected requires=''>
                        <CustomerCommentsPage />
                    </Protected>
                }
            />

            <Route exact path="/transactions/gateway"
                component={ () =>
                    <Protected requires='query-transactions'>
                        <TransactionPage aspect="tx-gateway" />
                    </Protected>
                }
            />

            <Route exact path="/transactions/wallet"
                component={ () =>
                    <Protected requires='query-transactions'>
                        <TransactionPage aspect="tx-wallet"/>
                    </Protected>
                }
            />

            <Route exact path="/transactions/:transactionId"
                component={ () =>
                    <Protected requires='view-transactions'>
                        <TransactionDetails />
                    </Protected>
                }
            />

            <Route exact path="/transactions/wallet/:transactionId"
                component={ () =>
                    <Protected requires='view-transactions'>
                        <TransactionWalletDetails />
                    </Protected>
                }
            />

            <Route exact path="/merchants"
                component={ () =>
                    <Protected requires='query-merchants'>
                        <MerchantSearchPage />
                    </Protected>
                }
            />

            <Route exact path="/merchants/:mid/:rid/:sid"
                component={ () =>
                    <Protected requires={ ['query-merchants', 'merchant-adjustments-level-1'] }>
                        <MerchantAdjustmentsPage />
                    </Protected>
                }
            />

            <Route exact path="/merchants/period-statement"
                component={ () =>
                    <Protected requires='query-merchants'>
                        <PeriodStatement />
                    </Protected>
                }
            />

            <Route exact path="/gateways"
                component={ () =>
                    <Protected requires='manage-gateway'>
                        <DescriptorPage />
                    </Protected>
                }
            />

            <Route exact path="/gateways/summary"
                component={ () =>
                    <Protected requires='manage-gateway'>
                        <GatewaySummary />
                    </Protected>
                }
            />

            <Route exact path="/security/signups"
                component={ () =>
                    <Protected requires=''>
                        <SignUpsPage />
                    </Protected>
                }
            />

            <Route exact path="/security/documents"
                component={ () =>
                    <Protected requires='approve-user-documents'>
                        <DocumentsQueuePage />
                    </Protected>
                }
            />

            <Route exact path="/security/documents/:documentId"
                   component={ () =>
                       <Protected requires='approve-user-documents'>
                           <DocumentsQueuePage />
                       </Protected>
                   }
            />

            <Route exact path="/security/withdrawals"
                component={ () =>
                    <Protected requires='approve-withdrawal-security'>
                        <WithdrawalsTransactionsPendingPage />
                    </Protected>
                }
            />

            <Route exact path="/accounting/pending-withdrawals-1"
                component={ () =>
                    <Protected requires='approve-withdrawal-batch-accounting'>
                        <WithdrawalsBatchesForApprovalPage />
                    </Protected>
                }
            />

            <Route exact path="/accounting/batch-transactions/:batchId"
                component={ () =>
                    <Protected requires='approve-withdrawal-batch-accounting'>
                        <BatchTransactionsPage />
                    </Protected>
                }
            />

            <Route exact path="/accounting/pending-withdrawals-2"
                component={ () =>
                    <Protected requires='send-withdrawal-batch-account'>
                        <ApprovedBatches />
                    </Protected>
                }
            />

            <Route exact path="/accounting/pending-adjustments"
                component={ () =>
                    <Protected requires='approve-adjustment'>
                        <PendingAdjustments />
                    </Protected>
                }
            />

            <Route exact path="/accounting/live-batches/:batchid"
                component={ () =>
                    <Protected requires='send-withdrawal-batch-account'>
                        <LiveBatchesPage />
                    </Protected>
                }
            />

            <Route exact path="/accounting/live-batches"
                component={ () =>
                    <Protected requires='send-withdrawal-batch-account'>
                        <LiveBatchesPage />
                    </Protected>
                }
            />

            <Route exact path="/accounting/pending-settlements"
                component={ () =>
                    <Protected requires='manage-settlements' >
                        <SettlementsPage />
                    </Protected>
                }
            />

            <Route exact path="/accounting/pending-exchanges"
                component={ () =>
                    <Protected requires='manage-exchanges' >
                        <ExchangesPage />
                    </Protected>
                }
            />

            <Route exact path="/accounting/withdrawal-batches-history"
                component={ () =>
                    <Protected requires='approve-withdrawal-batch-accounting'>
                        <BatchesProcessingHistoryPage />
                    </Protected>
                }
            />

            <Route exact path="/accounting/return-file"
                component={ () =>
                    <Protected requires='manage-settlements'>
                        <UploadReturnFilePage />
                    </Protected>
                }
            />

            <Route exact path="/controlpanel/buyandsend"
                component={ () =>
                    <Protected requires='manage-control-panel'>
                        <BuyAndSendPage />
                    </Protected>
                }
            />

            <Route exact path="/controlpanel/countries-allowed/:type"
                component={ () =>
                    <Protected requires='manage-control-panel'>
                        <CountriesAllowed />
                    </Protected>
                }
            />

            <Route exact path="/controlpanel/walletapp-settings"
                component={ () =>
                    <Protected requires='manage-control-panel'>
                        <WalletAppSettings />
                    </Protected>
                }
            />

            <Route exact path="/wallets/configuration"
                component={ () =>
                    <Protected>
                        <ConfigurationPage />
                    </Protected>
                }
            />

            <Route exact path="/wallets/limitsgroup"
                component={ () =>
                    <Protected>
                        <LimitsGroupPage />
                    </Protected>
                }
            />

            <Route exact path="/wallets/limits/:groupname"
                component={ () =>
                    <Protected>
                        <LimitsPage />
                    </Protected>
                }
            />

            <Route exact path ="/401"
                component={ () =>
                    <NotAuthorizedPage />
                }
            />

            <Route
                component={ () =>
                    <Protected>
                        <NotFound />
                    </Protected>
                }
            />
        </Switch>
    );
}