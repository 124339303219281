import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header';
import Listing from './Listing';
import {
    loadWalletTransactionDetails,
    reset,
    ready
} from '../../shared/store/slices/transactionDetails';

export default function TransactionWalletDetails() {
    const { transactionId } = useParams();
    const { walletTransactionDetails } = useSelector(state => state.transactionDetails);
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reset());
        document.title = "Transaction Details";
    }, []);

    useEffect(() => {
        dispatch(loadWalletTransactionDetails({txid: transactionId}))
            .then(() => {
                dispatch(ready());
            });
    }, [dispatch, transactionId]);

    return <>
        { walletTransactionDetails?.id !== null && walletTransactionDetails?.id !== undefined &&
            <>
            <main className="searchtransactions">
                <Header walletTransactionDetails={ walletTransactionDetails }/>
                <Listing walletTransactionDetails={ walletTransactionDetails }/>
            </main>
            </>}
    </>
}
