import React, { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    setText,
    setParameter,
    setShow,
    resetList,
    MerchantSearchQuery
} from '../shared/store/slices/merchants'
import 'antd/dist/antd.css';

export default function MerchantSearchWidget() {
    const { text, parameter, show } = useSelector(state => state.merchants.query);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
    }, [dispatch]);

    function handleTextChange(event) {
        dispatch(setText(event.target.value));
    }

    function handleParameterChange(event) {
        dispatch(setParameter(event.target.value));
    }

    function handleShowChange(event) {
        dispatch(setShow(event.target.value));
    }

    function performSearch(event) {
        event.preventDefault();
        batch(() => {
            localStorage.setItem('merchants.show', show);
            dispatch(MerchantSearchQuery({}));
            dispatch(resetList());
            history.push('/merchants');
        });
    }

    function performGo() {
    }

    return (<form onSubmit={ performSearch }>
        <div id="transsearchnav" className="merchantsearch">
            <div className="navcell navcellsearchbar merchantsearch">
                <input type="text" id="transsearchbox" name="search" placeholder="Search" value={ text } onChange={ handleTextChange } />
            </div>

            <div className="navcell  navcellparameter" style={{"display": "flex", "alignItems": "baseline"}}>
                <select name="parameter" id="parameter" value={ parameter } onChange={ handleParameterChange }>
                    <option value="" disabled>In</option>
                    <option value="merchant">Merchant</option>
                    <option value="mid">MID</option>
                    <option value="rid">RID</option>
                    <option value="sid">SID</option>
                    <option value="email">Email</option>
                    <option value="gateway">Gateway</option>
                    <option value="pgid">PGID</option>
                    <option value="tid">TID</option>
                    <option value="descriptor">Descriptor</option>
                </select>
            </div>

            <div className="navcell navcellrange" style={{"display": 'flex', "alignItems": 'inherit'}}>
                <select name="parameter" id="parameter" value={ show } onChange={ handleShowChange }>
                    <option value="" disabled>Show me</option>
                    <option value="merchants">Merchants</option>
                    <option value="retailers">Retailers</option>
                    <option value="sites">Sites</option>
                    <option value="gateways">Gateways</option>
                    <option value="descriptors">Descriptors</option>
                </select>
            </div>

            <div className="navcellsearchbutton merchantsearch">
                <div className="navcellsearchbutton"><button className="buttonprimary primary navbutton" type="submit">Search</button></div>
            </div>
            <div className="navcell navcellexport merchantsearch">
                <div className="navcellsearchbutton"><button className="buttonprimary secondary navbutton" onClick={ performGo }>Go</button></div>
            </div>
        </div>
    </form>);
}
