import { ApiGetRequestSync, ApiPostRequestSync } from "../../shared/API";

export const GetWithdrawalTransactionsPending = (user) => {
    return ApiGetRequestSync("/Withdrawals/witdrawal-transactions-pending", user);
}

export const ApproveTransactionsForBatch = (user, transactionsId) => {
    return ApiPostRequestSync("/Withdrawals/approve-transactions", user, { transactionsId });
}

export const DeclineTransactionsForBatch = (user, transactionsId) => {
    return ApiPostRequestSync("/Withdrawals/decline-transactions", user, { transactionsId });
}

export const GetWithdrawalBatchesForApproval = (user) => {
    return ApiGetRequestSync("/Withdrawals/witdrawal-batches-for-approval", user);
}

export const SplitBatch = (user, batchId, splitAmount) => {
    return ApiPostRequestSync("/Withdrawals/split-batch", user, { batchId, splitAmount });
}

export const ViewBatchTransations = (user, batchId) => {
    return ApiGetRequestSync("/Withdrawals/view-batch-transactions", user, { batchId });
}

export const ApproveBatch = (user, batchId) => {
    return ApiPostRequestSync("/Withdrawals/approve-batch", user, { batchId });
}

export const GetApprovedBatches = (user) => {
    return ApiGetRequestSync("/Withdrawals/approved-batches-list", user);
}

export const SendBatch = (user, batchId) => {
    return ApiPostRequestSync("/Withdrawals/send-batch", user, { batchId });
}

export const GetWithdrawalBatchTransactionsHistory = (user, filters) => {
    return ApiPostRequestSync("/Withdrawals/witdrawal-batch-transactions-history", user, filters);
}

export const GetAvailableCurrencies = (user) => {
    return ApiGetRequestSync("/Currencies", user);
}

export const GetAvailableMethods = (user) => {
    return ApiGetRequestSync("/Currencies/available-methods", user);
}

export const GetBatchesMethodsList = (user) => {
    return ApiGetRequestSync("/Withdrawals/batches-method-list", user);
}

export const GetApprovedByList = (user) => {
    return ApiGetRequestSync("/Withdrawals/approvedby-list", user);
}

export const GetSentByList = (user) => {
    return ApiGetRequestSync("/Withdrawals/sentby-list", user);
}
