import { ApiGetRequestSync, ApiPostRequestSync, ApiPutRequestSync } from "../../shared/API";

export const GetLimits = (user) => {
    return ApiGetRequestSync("/Limits", user);
}
export const GetLimitById = (user, limitId) => {
    return ApiGetRequestSync("/Limits/" + limitId, user);
}
export const SaveLimit = (user, limit) => {
    return ApiPutRequestSync("/Limits", user, limit);
}
export const DeleteLimit = (user, limit) => {
    return ApiPostRequestSync("/Limits/delete", user, limit);
}
export const SaveGroupLimits = (user, limit) => {
    return ApiPutRequestSync("/Limits/savegroup", user, limit);
}
export const DeleteLimitsFromGroup = (user, group) => {
    return ApiPostRequestSync("/Limits/delete/"+group, user);
}
export const GetGroups = (user) => {
    return ApiGetRequestSync("/Limits/groups", user);
}
export const GetLimitByGroup = (user, group) => {
    return ApiGetRequestSync("/Limits/get-by-group/"+group, user);
}