import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
    getWithdrawalBatchTransactionsHistory,
    updateDateRange,
    updateAdvancedFilter,
    getAvailableCurrencies,
    getAvailableBatchesMethods,
    getApprovedByList,
    getSentByList
} from "../../shared/store/slices/payouts";
import { Table, Popover, Alert } from 'antd';
import FormatTime from '../../shared/helpers/FormatTime';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import { DateRange } from '../../shared/helpers/TimeHelper';
import moment from "moment";
import BatchAdvancedFilterOptions from './BatchAdvancedFilterOptions';
import InputDateRange from '../TransactionPage/InputDateRange';
import NoData from '../../components/NoData';


function BatchesProcessingHistoryPage(props) {

    TitleEffect(PageTitle("Withdrawal Batch Historical View | Accounting "));
    const { dateRange, amount, currencies, methods, approvedby, sentby } = useSelector(state => state.payouts.filters);
    const { isLoading } = useSelector(state => state.userInterface);
    const { advancedFilterVisible, batchTransactionsHistory } = useSelector(state => state.payouts);
    const dispatch = useDispatch();

    const [isLoadingData, setIsLoadingData] = useState(true);

    const columns = [{
        className: 'th-legendlabel tdstyle transcell transtable',
        align: 'right',
        title: 'Timestamp',
        dataIndex: 'createdOn',
        render: (text, record) => {
            return FormatTime(record.createdOn);
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (dateA, dateB) => { return moment(dateA.createdOn).diff(moment(dateB.createdOn)) }
    }, {
        className: 'th-legendlabel tdstyle transcell transtable nobreak',
        align: 'right',
        title: 'Method / Gateway',
        dataIndex: 'paymentMethodCode',
        render: (text, record) => {
            return record.paymentMethod.name;
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => { return a.paymentMethodCode.localeCompare(b.paymentMethodCode) }
    }, {
        className: 'th-legendlabel tdstyle transcell transtable nobreak',
        align: 'right',
        title: 'Amount',
        dataIndex: 'amount',
        render: (text, record) => {
            return record.formattedAmount;
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => { return a.amount - b.amount }
    }, {
        className: 'th-legendlabel tdstyle transcell transtable',
        align: 'right',
        title: 'Currency',
        dataIndex: 'currency',
        render: (text, record) => {
            return record.currency;
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => { return a.currency.localeCompare(b.currency) }
    }, {
        className: 'th-legendlabel tdstyle transcell transtable',
        align: 'right',
        title: 'Approved By',
        dataIndex: 'approvedBy',
        render: (text, record) => {
            return record.approvedBy;
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => { return a.approvedBy.localeCompare(b.approvedBy) }
    }, {
        className: 'th-legendlabel tdstyle transcell transtable',
        align: 'right',
        title: 'Sent By',
        dataIndex: 'sentBy',
        render: (text, record) => {
            return record.sentBy;
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => { return a.sentBy.localeCompare(b.sentBy) }
    }, {
        className: 'th-legendlabel tdstyle transcell transtable',
        align: 'right',
        title: 'Status',
        dataIndex: 'status',
        render: (text, record) => {
            return record.statusDesc;
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => { return a.status.localeCompare(b.status) }
    }, {
        className: 'th-legendlabel tdstyle transcell transtable nobreak',
        align: 'right',
        title: 'Approved',
        dataIndex: 'transactionsApprovedAmount',
        render: (text, record) => {
            return record.formattedApprovedAmount;
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => { return a.transactionsApprovedAmount - b.transactionsApprovedAmount }
    }, {
        className: 'th-legendlabel tdstyle transcell transtable nobreak',
        align: 'right',
        title: 'Rejected',
        dataIndex: 'transactionsDeclinedAmount',
        render: (text, record) => {
            return record.formattedDeclinedAmount;
        },
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => { return a.transactionsDeclinedAmount - b.transactionsDeclinedAmount }
    }];

    const loadData = (type) => {

        if(type == null || type == undefined)
            type = "today";

        const range = DateRange(type);

        let filterObject = {
            dateRange: range,
            amount: {
                operation: null,
                value: null
            },
            currencies: [],
            methods: [],
            approvedby: [],
            sentby: []
        };
        
        if (amount.operation && amount.value) {
            filterObject.amount.operation = amount.operation;
            filterObject.amount.value = amount.value;
        }

        if (currencies) {
            filterObject.currencies = currencies;
        }

        if (methods) {
            filterObject.methods = methods;
        }

        if (approvedby) {
            filterObject.approvedby = approvedby;
        }

        if (sentby) {
            filterObject.sentby = sentby;
        }

        setIsLoadingData(true);
        dispatch(getWithdrawalBatchTransactionsHistory({ filters: filterObject }))
            .then((response => {
                batch(() => {
                    setIsLoadingData(false);
                })
            }));
    }

    useEffect(() => {
        dispatch(getAvailableCurrencies());
        dispatch(getAvailableBatchesMethods());
        dispatch(getApprovedByList());
        dispatch(getSentByList());
        loadData('today');
    }, [dispatch])

    const handleDateRangeChange = (e) => {
        dispatch(updateDateRange(e.detail));
    }

    const handleAdvancedFilterClick = () => {
        dispatch(updateAdvancedFilter());
    }

    const handleSearch = () => {
        if (dateRange.type) {
            loadData(dateRange.type);
        }
    }

    return <div>
        <nav>
            <div id="docsearch">
                <div className="navcell navcellrange" style={{"display": 'flex', "alignItems": 'inherit'}}>
                    <InputDateRange name="merchant" id="merchant" value={dateRange} onChange={handleDateRangeChange} />
                    <Popover content={<BatchAdvancedFilterOptions />} title="Filter by" trigger="click" visible={advancedFilterVisible} onVisibleChange={handleAdvancedFilterClick}>
                        <div className="navcell navcellfilter"><button className="buttonprimary secondary navbutton">Filters</button></div>
                    </Popover>
                    <div className="navcellsearchbutton"><button className="buttonprimary primary navbutton" onClick={handleSearch}>Search</button></div>
                </div>
            </div>
        </nav>
        <main className="searchtransactions">
            { batchTransactionsHistory?.length
                ? <>
                    <Table
                        loading={isLoading || isLoadingData}
                        rowKey={r => r.id}
                        columns={columns}
                        dataSource={batchTransactionsHistory}
                        pagination={false}
                        scroll={{x: "auto"}}
                    />
                    <div className="text-center"><p id="nomoretx">The end</p></div>
                </>
                : ( isLoadingData === false
                    ? <NoData />
                    : null
                  )
            }
        </main>
    </div>
}

export default BatchesProcessingHistoryPage;
