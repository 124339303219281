import React from "react";
import styles from './Table.wallet.module.css';

export default function Header(props) {
    const { walletTransactionDetails } = props;

    const handleSelectCustomer = (customerId) => {
        dispatch(getCustomerProfile({
            customerId: customerId
        }))
        .then((response) =>{
            dispatch(setCurrentCustomerProfile(response.payload));
            history.push("/customer/profile");
        });
    }
    
    const headers = [
        {title: "Created On",         align: 'right'},
        {title: "Wallet TXID",        align: 'right'},
        {title: "Parent Transaction", align: 'right'},
        {title: "Email",              align: 'right'},
        {title: "Account",            align: 'right'},
        {title: "Type",               align: 'right'},
        {title: "Method",             align: 'right'},
        {title: "Req. Amt.",          align: 'right'},
        {title: "Currency",           align: 'right'},
        {title: "Amount",             align: 'right'},
        {title: "Fee",                align: 'right'},
        {title: "Descriptor",         align: 'right'},
        {title: "Merchant",           align: 'right'},
        {title: "Created By",         align: 'right'}
    ];

    const { createdOn, descriptor, id, formattedAmount, account, currency,
        method, merchant, type, requestAmount, formattedFee, customerId,
        parentTransactionId, customerEmail, createdByEmail
    } = walletTransactionDetails;

    const columns = [
        { className: 'tdstyle ', name: 'created_on', children: createdOn },
        { className: 'tdstyle ', name: 'id', children: id },
        { className: 'tdstyle ', name: 'parentTransactionId', children: parentTransactionId && parentTransactionId.length > 0 ? <span className="pseudolink" data-id={ parentTransactionId } onClick={() => { window.open(`/transactions/wallet/${parentTransactionId}`) }}>{ parentTransactionId }</span> : "" },
        { className: 'tdstyle ', name: 'customerEmail', children: <span className="pseudolink" data-id={ customerId } onClick={()=> { handleSelectCustomer(customerId) }}>{ customerEmail }</span> },
        { className: 'tdstyle ', name: 'account', children: account },
        { className: 'tdstyle ', name: 'type', children: type },
        { className: 'tdstyle ', name: 'method', children: method },
        { className: 'tdstyle ', name: 'requestAmount', children: requestAmount },
        { className: 'tdstyle ', name: 'currency', children: currency?.Code },
        { className: 'tdstyle ', name: 'amount', children: formattedAmount },
        { className: 'tdstyle ', name: 'fee', children: formattedFee },
        { className: 'tdstyle ', name: 'descriptor', children: descriptor },
        { className: 'tdstyle ', name: 'merchant', children: merchant },
        { className: 'tdstyle ', name: 'createdByEmail', children: createdByEmail } 
    ]

    const data = walletTransactionDetails?.id !== null && walletTransactionDetails?.id !== undefined ? [{
        'key': id,
        'data': columns
    }] : []

    return walletTransactionDetails?.id !== null && walletTransactionDetails?.id !== undefined && 
    <div style={{width: "100%", overflowX: "auto", whiteSpace: "nowrap"}}>
        <table className={`${styles.table} ant-table-fixed`} style={{ tableLayout: "fixed" }}>
            <thead>
                <tr>
                    {headers.map((hd) => {
                        return <th key={hd.title}>{ hd.title }</th>   
                    })}
                </tr>
            </thead>
            <tbody>
                <tr key={walletTransactionDetails.id}>
                    <td>{walletTransactionDetails.createdOn}</td>
                    <td>{walletTransactionDetails.id}</td>
                    <td>{walletTransactionDetails.parentTransactionId && walletTransactionDetails.parentTransactionId.length > 0 ? <span className="pseudolink" data-id={ walletTransactionDetails.parentTransactionId } onClick={() => { window.open(`/transactions/wallet/${walletTransactionDetails.parentTransactionId}`) }}>{ walletTransactionDetails.parentTransactionId }</span> : ""}</td>
                    <td><span className="pseudolink" data-id={ walletTransactionDetails.customerId } onClick={()=> { handleSelectCustomer(walletTransactionDetails.customerId) }}>{ walletTransactionDetails.customerEmail }</span></td>
                    <td>{walletTransactionDetails.account}</td>
                    <td>{walletTransactionDetails.type}</td>
                    <td>{walletTransactionDetails.method}</td>
                    <td>{walletTransactionDetails.requestAmount}</td>
                    <td>{walletTransactionDetails?.currency?.Code}</td>
                    <td>{walletTransactionDetails.formattedAmount}</td>
                    <td>{walletTransactionDetails.formattedFee}</td>
                    <td>{walletTransactionDetails.descriptor}</td>
                    <td>{walletTransactionDetails.merchant}</td>
                    <td>{walletTransactionDetails.createdByEmail}</td>
                </tr>
            </tbody>
        </table>
    </div>
}
