export const regions = {
    AFR: "Africa",
    ASI: "Asia",
    EUR: "Europe",
    LAC: "Latin America and the Caribbean",
    NAM: "Northern America",
    OCE: "Oceania",
    EAF: "Eastern Africa",
    MAF: "Middle Africa",
    NAF: "Northern Africa",
    SAF: "Southern Africa",
    WAF: "Western Africa",
    CAS: "Central Asia",
    EAS: "Eastern Asia",
    SEA: "South-eastern Asia",
    SAS: "Southern Asia",
    WAS: "Western Asia",
    EEU: "Eastern Europe",
    NEU: "Northern Europe",
    SEU: "Southern Europe",
    WEU: "Western Europe",
    CAR: "Caribbean",
    CAM: "Central America",
    SAM: "South America",
    ANZ: "Australia and New Zealand",
    MEL: "Melanesia",
    MIC: "Micronesia",
    POL: "Polynesia"
};

export const countries = {
    'AF': {major: "ASI", minor: "SAS", name: "Afghanistan"},
    'AX': {major: "EUR", minor: "NEU", name: "Aland Islands"},
    'AL': {major: "EUR", minor: "SEU", name: "Albania"},
    'DZ': {major: "AFR", minor: "NAF", name: "Algeria"},
    'AS': {major: "OCE", minor: "POL", name: "American Samoa"},
    'AD': {major: "EUR", minor: "SEU", name: "Andorra"},
    'AO': {major: "AFR", minor: "MAF", name: "Angola"},
    'AI': {major: "LAC", minor: "CAR", name: "Anguilla"},
    'AG': {major: "LAC", minor: "CAR", name: "Antigua and Barbuda"},
    'AR': {major: "LAC", minor: "SAM", name: "Argentina"},
    'AM': {major: "ASI", minor: "WAS", name: "Armenia"},
    'AW': {major: "LAC", minor: "CAR", name: "Aruba"},
    'AU': {major: "OCE", minor: "ANZ", name: "Australia"},
    'AT': {major: "EUR", minor: "WEU", name: "Austria"},
    'AZ': {major: "ASI", minor: "WAS", name: "Azerbaijan"},
    'BS': {major: "LAC", minor: "CAR", name: "Bahamas"},
    'BH': {major: "ASI", minor: "WAS", name: "Bahrain"},
    'BD': {major: "ASI", minor: "SAS", name: "Bangladesh"},
    'BB': {major: "LAC", minor: "CAR", name: "Barbados"},
    'BY': {major: "EUR", minor: "EEU", name: "Belarus"},
    'BE': {major: "EUR", minor: "WEU", name: "Belgium"},
    'BZ': {major: "LAC", minor: "CAM", name: "Belize"},
    'BJ': {major: "AFR", minor: "WAF", name: "Benin"},
    'BM': {major: "NAM", minor: "NAM", name: "Bermuda"},
    'BT': {major: "ASI", minor: "SAS", name: "Bhutan"},
    'BO': {major: "LAC", minor: "SAM", name: "Bolivia"},
    'BQ': {major: "LAC", minor: "CAR", name: "Bonaire, Sint Eustatius and Saba"},
    'BA': {major: "EUR", minor: "SEU", name: "Bosnia and Herzegovina"},
    'BW': {major: "AFR", minor: "SAF", name: "Botswana"},
    'BV': {major: "LAC", minor: "SAM", name: "Bouvet Island"},
    'BR': {major: "LAC", minor: "SAM", name: "Brazil"},
    'IO': {major: "LAC", minor: "CAR", name: "British Indian Ocean Territory"},
    'BN': {major: "ASI", minor: "SEA", name: "Brunei Darussalam"},
    'BG': {major: "EUR", minor: "EEU", name: "Bulgaria"},
    'BF': {major: "AFR", minor: "WAF", name: "Burkina Faso"},
    'BI': {major: "AFR", minor: "EAF", name: "Burundi"},
    'KH': {major: "ASI", minor: "SEA", name: "Cambodia"},
    'CM': {major: "AFR", minor: "MAF", name: "Cameroon"},
    'CA': {major: "NAM", minor: "NAM", name: "Canada"},
    'CV': {major: "AFR", minor: "WAF", name: "Cape Verde"},
    'KY': {major: "LAC", minor: "CAR", name: "Cayman Islands"},
    'CF': {major: "AFR", minor: "MAF", name: "Central African Republic"},
    'TD': {major: "AFR", minor: "MAF", name: "Chad"},
    'CL': {major: "LAC", minor: "SAM", name: "Chile"},
    'CN': {major: "ASI", minor: "EAS", name: "China"},
    'CX': {major: "OCE", minor: "ANZ", name: "Christmas Island"},
    'CC': {major: "OCE", minor: "ANZ", name: "Cocos (Keeling) Islands"},
    'CO': {major: "LAC", minor: "SAM", name: "Colombia"},
    'KM': {major: "AFR", minor: "EAF", name: "Comoros"},
    'CD': {major: "AFR", minor: "MAF", name: "Congo"},
    'CG': {major: "AFR", minor: "MAF", name: "Congo, The Democratic Republic of the"},
    'CK': {major: "OCE", minor: "POL", name: "Cook Islands"},
    'CR': {major: "LAC", minor: "CAM", name: "Costa Rica"},
    'CI': {major: "AFR", minor: "WAF", name: "Côte D'Ivoire"},
    'HR': {major: "EUR", minor: "SEU", name: "Croatia"},
    'CU': {major: "LAC", minor: "CAR", name: "Cuba"},
    'CW': {major: "LAC", minor: "CAR", name: "Curaçao"},
    'CY': {major: "ASI", minor: "WAS", name: "Cyprus"},
    'CZ': {major: "EUR", minor: "EEU", name: "Czech Republic"},
    'DK': {major: "EUR", minor: "NEU", name: "Denmark"},
    'DJ': {major: "AFR", minor: "EAF", name: "Djibouti"},
    'DM': {major: "LAC", minor: "CAR", name: "Dominica"},
    'DO': {major: "LAC", minor: "CAR", name: "Dominican Republic"},
    'EC': {major: "LAC", minor: "SAM", name: "Ecuador"},
    'EG': {major: "AFR", minor: "NAF", name: "Egypt"},
    'SV': {major: "LAC", minor: "CAM", name: "El Salvador"},
    'GQ': {major: "AFR", minor: "MAF", name: "Equatorial Guinea"},
    'ER': {major: "AFR", minor: "EAF", name: "Eritrea"},
    'EE': {major: "EUR", minor: "NEU", name: "Estonia"},
    'SZ': {major: "AFR", minor: "SAF", name: "Eswatini"},
    'ET': {major: "AFR", minor: "EAF", name: "Ethiopia"},
    'FK': {major: "LAC", minor: "SAM", name: "Falkland Islands (Malvinas)"},
    'FO': {major: "EUR", minor: "NEU", name: "Faroe Islands"},
    'FJ': {major: "OCE", minor: "MEL", name: "Fiji"},
    'FI': {major: "EUR", minor: "NEU", name: "Finland"},
    'FR': {major: "EUR", minor: "WEU", name: "France"},
    'GF': {major: "LAC", minor: "SAM", name: "French Guiana"},
    'PF': {major: "OCE", minor: "POL", name: "French Polynesia"},
    'TF': {major: "AFR", minor: "SAF", name: "French Southern Territories"},
    'GA': {major: "AFR", minor: "MAF", name: "Gabon"},
    'GM': {major: "AFR", minor: "WAF", name: "Gambia"},
    'GE': {major: "ASI", minor: "WAS", name: "Georgia"},
    'DE': {major: "EUR", minor: "WEU", name: "Germany"},
    'GH': {major: "AFR", minor: "WAF", name: "Ghana"},
    'GI': {major: "EUR", minor: "SEU", name: "Gibraltar"},
    'GR': {major: "EUR", minor: "SEU", name: "Greece"},
    'GL': {major: "NAM", minor: "NAM", name: "Greenland"},
    'GD': {major: "LAC", minor: "CAR", name: "Grenada"},
    'GP': {major: "LAC", minor: "CAR", name: "Guadeloupe"},
    'GU': {major: "OCE", minor: "MIC", name: "Guam"},
    'GT': {major: "LAC", minor: "CAM", name: "Guatemala"},
    'GG': {major: "EUR", minor: "NEU", name: "Guernsey"},
    'GN': {major: "AFR", minor: "WAF", name: "Guinea"},
    'GW': {major: "AFR", minor: "WAF", name: "Guinea-Bissau"},
    'GY': {major: "LAC", minor: "SAM", name: "Guyana"},
    'HT': {major: "LAC", minor: "CAR", name: "Haiti"},
    'HM': {major: "AFR", minor: "SAF", name: "Heard Island and McDonald Islands"},
    'VA': {major: "EUR", minor: "SEU", name: "Holy See (Vatican City State)"},
    'HN': {major: "LAC", minor: "CAM", name: "Honduras"},
    'HK': {major: "ASI", minor: "EAS", name: "Hong Kong"},
    'HU': {major: "EUR", minor: "EEU", name: "Hungary"},
    'IS': {major: "EUR", minor: "NEU", name: "Iceland"},
    'IN': {major: "ASI", minor: "SAS", name: "India"},
    'ID': {major: "ASI", minor: "SEA", name: "Indonesia"},
    'IR': {major: "ASI", minor: "SAS", name: "Iran, Islamic Republic of"},
    'IQ': {major: "ASI", minor: "WAS", name: "Iraq"},
    'IE': {major: "EUR", minor: "NEU", name: "Ireland"},
    'IM': {major: "EUR", minor: "NEU", name: "Isle of Man"},
    'IL': {major: "ASI", minor: "WAS", name: "Israel"},
    'IT': {major: "EUR", minor: "SEU", name: "Italy"},
    'JM': {major: "LAC", minor: "CAR", name: "Jamaica"},
    'JP': {major: "ASI", minor: "EAS", name: "Japan"},
    'JE': {major: "EUR", minor: "NEU", name: "Jersey"},
    'JO': {major: "ASI", minor: "WAS", name: "Jordan"},
    'KZ': {major: "ASI", minor: "CAS", name: "Kazakhstan"},
    'KE': {major: "AFR", minor: "EAF", name: "Kenya"},
    'KI': {major: "OCE", minor: "MIC", name: "Kiribati"},
    'KP': {major: "ASI", minor: "EAS", name: "Korea, Democratic People's Republic of"},
    'KR': {major: "ASI", minor: "EAS", name: "Korea, Republic of"},
    'KW': {major: "ASI", minor: "WAS", name: "Kuwait"},
    'KG': {major: "ASI", minor: "CAS", name: "Kyrgyzstan"},
    'LA': {major: "ASI", minor: "SEA", name: "Lao People's Democratic Republic"},
    'LV': {major: "EUR", minor: "NEU", name: "Latvia"},
    'LB': {major: "ASI", minor: "WAS", name: "Lebanon"},
    'LS': {major: "AFR", minor: "SAF", name: "Lesotho"},
    'LR': {major: "AFR", minor: "WAF", name: "Liberia"},
    'LY': {major: "AFR", minor: "NAF", name: "Libya"},
    'LI': {major: "EUR", minor: "WEU", name: "Liechtenstein"},
    'LT': {major: "EUR", minor: "NEU", name: "Lithuania"},
    'LU': {major: "EUR", minor: "WEU", name: "Luxembourg"},
    'MO': {major: "ASI", minor: "EAS", name: "Macau"},
    'MK': {major: "EUR", minor: "SEU", name: "Macedonia"},
    'MG': {major: "AFR", minor: "EAF", name: "Madagascar"},
    'MW': {major: "AFR", minor: "EAF", name: "Malawi"},
    'MY': {major: "ASI", minor: "SEA", name: "Malaysia"},
    'MV': {major: "ASI", minor: "SAS", name: "Maldives"},
    'ML': {major: "AFR", minor: "WAF", name: "Mali"},
    'MT': {major: "EUR", minor: "SEU", name: "Malta"},
    'MH': {major: "OCE", minor: "MIC", name: "Marshall Islands"},
    'MQ': {major: "LAC", minor: "CAR", name: "Martinique"},
    'MR': {major: "AFR", minor: "WAF", name: "Mauritania"},
    'MU': {major: "AFR", minor: "EAF", name: "Mauritius"},
    'YT': {major: "AFR", minor: "SAF", name: "Mayotte"},
    'MX': {major: "LAC", minor: "CAM", name: "Mexico"},
    'FM': {major: "OCE", minor: "MIC", name: "Micronesia, Federated States of"},
    'MD': {major: "EUR", minor: "EEU", name: "Moldova, Republic of"},
    'MC': {major: "EUR", minor: "WEU", name: "Monaco"},
    'MN': {major: "ASI", minor: "EAS", name: "Mongolia"},
    'ME': {major: "EUR", minor: "SEU", name: "Montenegro"},
    'MS': {major: "LAC", minor: "CAR", name: "Montserrat"},
    'MA': {major: "AFR", minor: "NAF", name: "Morocco"},
    'MZ': {major: "AFR", minor: "EAF", name: "Mozambique"},
    'MM': {major: "ASI", minor: "SEA", name: "Myanmar"},
    'NA': {major: "AFR", minor: "SAF", name: "Namibia"},
    'NR': {major: "OCE", minor: "MIC", name: "Nauru"},
    'NP': {major: "ASI", minor: "SAS", name: "Nepal"},
    'NL': {major: "EUR", minor: "WEU", name: "Netherlands"},
    'NC': {major: "OCE", minor: "MEL", name: "New Caledonia"},
    'NZ': {major: "OCE", minor: "ANZ", name: "New Zealand"},
    'NI': {major: "LAC", minor: "CAM", name: "Nicaragua"},
    'NE': {major: "AFR", minor: "WAF", name: "Niger"},
    'NG': {major: "AFR", minor: "WAF", name: "Nigeria"},
    'NU': {major: "OCE", minor: "POL", name: "Niue"},
    'NF': {major: "OCE", minor: "ANZ", name: "Norfolk Island"},
    'MP': {major: "OCE", minor: "MIC", name: "Northern Mariana Islands"},
    'NO': {major: "EUR", minor: "NEU", name: "Norway"},
    'OM': {major: "ASI", minor: "WAS", name: "Oman"},
    'PK': {major: "ASI", minor: "SAS", name: "Pakistan"},
    'PW': {major: "OCE", minor: "MIC", name: "Palau"},
    'PS': {major: "ASI", minor: "WAS", name: "Palestinian Territory"},
    'PA': {major: "LAC", minor: "CAM", name: "Panama"},
    'PG': {major: "OCE", minor: "MEL", name: "Papua New Guinea"},
    'PY': {major: "LAC", minor: "SAM", name: "Paraguay"},
    'PE': {major: "LAC", minor: "SAM", name: "Peru"},
    'PH': {major: "ASI", minor: "SEA", name: "Philippines"},
    'PN': {major: "OCE", minor: "POL", name: "Pitcairn Islands"},
    'PL': {major: "EUR", minor: "EEU", name: "Poland"},
    'PT': {major: "EUR", minor: "SEU", name: "Portugal"},
    'PR': {major: "LAC", minor: "CAR", name: "Puerto Rico"},
    'QA': {major: "ASI", minor: "WAS", name: "Qatar"},
    'RE': {major: "AFR", minor: "EAF", name: "Reunion"},
    'RO': {major: "EUR", minor: "EEU", name: "Romania"},
    'RU': {major: "EUR", minor: "EEU", name: "Russian Federation"},
    'RW': {major: "AFR", minor: "EAF", name: "Rwanda"},
    'BL': {major: "LAC", minor: "CAR", name: "Saint Barthelemy"},
    'SH': {major: "AFR", minor: "WAF", name: "Saint Helena"},
    'KN': {major: "LAC", minor: "CAR", name: "Saint Kitts and Nevis"},
    'LC': {major: "LAC", minor: "CAR", name: "Saint Lucia"},
    'MF': {major: "LAC", minor: "CAR", name: "Saint Martin"},
    'PM': {major: "NAM", minor: "NAM", name: "Saint Pierre and Miquelon"},
    'VC': {major: "LAC", minor: "CAR", name: "Saint Vincent and the Grenadines"},
    'WS': {major: "OCE", minor: "POL", name: "Samoa"},
    'SM': {major: "EUR", minor: "SEU", name: "San Marino"},
    'ST': {major: "AFR", minor: "MAF", name: "Sao Tome and Principe"},
    'SA': {major: "ASI", minor: "WAS", name: "Saudi Arabia"},
    'SN': {major: "AFR", minor: "WAF", name: "Senegal"},
    'RS': {major: "EUR", minor: "SEU", name: "Serbia"},
    'SC': {major: "AFR", minor: "EAF", name: "Seychelles"},
    'SL': {major: "AFR", minor: "WAF", name: "Sierra Leone"},
    'SG': {major: "ASI", minor: "SEA", name: "Singapore"},
    'SX': {major: "LAC", minor: "CAR", name: "Sint Maarten (Dutch part)"},
    'SK': {major: "EUR", minor: "EEU", name: "Slovakia"},
    'SI': {major: "EUR", minor: "SEU", name: "Slovenia"},
    'SB': {major: "OCE", minor: "MEL", name: "Solomon Islands"},
    'SO': {major: "AFR", minor: "EAF", name: "Somalia"},
    'ZA': {major: "AFR", minor: "SAF", name: "South Africa"},
    'GS': {major: "LAC", minor: "SAM", name: "South Georgia and the South Sandwich Islands"},
    'SS': {major: "AFR", minor: "EAF", name: "South Sudan"},
    'ES': {major: "EUR", minor: "SEU", name: "Spain"},
    'LK': {major: "ASI", minor: "SAS", name: "Sri Lanka"},
    'SD': {major: "AFR", minor: "NAF", name: "Sudan"},
    'SR': {major: "LAC", minor: "SAM", name: "Suriname"},
    'SJ': {major: "EUR", minor: "NEU", name: "Svalbard and Jan Mayen"},
    'SE': {major: "EUR", minor: "NEU", name: "Sweden"},
    'CH': {major: "EUR", minor: "WEU", name: "Switzerland"},
    'SY': {major: "ASI", minor: "WAS", name: "Syrian Arab Republic"},
    'TW': {major: "ASI", minor: "EAS", name: "Taiwan"},
    'TJ': {major: "ASI", minor: "CAS", name: "Tajikistan"},
    'TZ': {major: "AFR", minor: "EAF", name: "Tanzania, United Republic of"},
    'TH': {major: "ASI", minor: "SEA", name: "Thailand"},
    'TL': {major: "ASI", minor: "SEA", name: "Timor-Leste"},
    'TG': {major: "AFR", minor: "WAF", name: "Togo"},
    'TK': {major: "OCE", minor: "POL", name: "Tokelau"},
    'TO': {major: "OCE", minor: "POL", name: "Tonga"},
    'TT': {major: "LAC", minor: "CAR", name: "Trinidad and Tobago"},
    'TN': {major: "AFR", minor: "NAF", name: "Tunisia"},
    'TR': {major: "ASI", minor: "WAS", name: "Turkey"},
    'TM': {major: "ASI", minor: "CAS", name: "Turkmenistan"},
    'TC': {major: "LAC", minor: "CAR", name: "Turks and Caicos Islands"},
    'TV': {major: "OCE", minor: "POL", name: "Tuvalu"},
    'UG': {major: "AFR", minor: "EAF", name: "Uganda"},
    'UA': {major: "EUR", minor: "EEU", name: "Ukraine"},
    'AE': {major: "ASI", minor: "WAS", name: "United Arab Emirates"},
    'GB': {major: "EUR", minor: "NEU", name: "United Kingdom"},
    'US': {major: "NAM", minor: "NAM", name: "United States"},
    'UM': {major: "OCE", minor: "MIC", name: "United States Minor Outlying Islands"},
    'UY': {major: "LAC", minor: "SAM", name: "Uruguay"},
    'UZ': {major: "ASI", minor: "CAS", name: "Uzbekistan"},
    'VU': {major: "OCE", minor: "MEL", name: "Vanuatu"},
    'VE': {major: "LAC", minor: "SAM", name: "Venezuela"},
    'VN': {major: "ASI", minor: "SEA", name: "Vietnam"},
    'VG': {major: "LAC", minor: "CAR", name: "Virgin Islands, British"},
    'VI': {major: "LAC", minor: "CAR", name: "Virgin Islands, U.S."},
    'WF': {major: "OCE", minor: "POL", name: "Wallis and Futuna"},
    'EH': {major: "AFR", minor: "WAF", name: "Western Sahara"},
    'YE': {major: "ASI", minor: "WAS", name: "Yemen"},
    'ZM': {major: "AFR", minor: "EAF", name: "Zambia"},
    'ZW': {major: "AFR", minor: "EAF", name: "Zimbabwe"}
}


var byRegion = [];

Object.entries(countries).map(([code, country]) => {
    if (byRegion[country.major] == undefined) {
        byRegion[country.major] = [];
    }

    if (byRegion[country.major][country.minor] == undefined) {
        byRegion[country.major][country.minor] = [];
    }

    byRegion[country.major][country.minor][code] = country.name;
});

export const countriesByRegion = byRegion;
