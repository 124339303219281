import React from 'react';
import { ReactComponent as IconApproved } from './images/icon-approved.svg';
import { ReactComponent as IconFailed } from './images/icon-failed.svg';
import { useDispatch } from 'react-redux';
import {
    hideOverlay
} from '../shared/store/slices/userInterface';

export default function Modal(props) {
    const { title, children, buttonCaption } = props;

    const dispatch = useDispatch();

    function handleDone() {
        dispatch(hideOverlay());
    }

    let icon = null;

    switch (props.icon) {
        case 'approved': icon = <IconApproved className="iconModal" />; break;
        case 'failed': icon = <IconFailed className="iconModal" />; break;
        default: icon = null; break;
    }

    return <>
        <div className="overlaydiv" style={{ textAlign: 'center' }}>
            { icon }
        </div>
        <div className="overlaydiv">
            <h3 className="overlay" style={{ textAlign: 'center' }}>{ title }</h3>
        </div>
        <div className="overlaydiv" style={{ textAlign: 'center' }}>
            <p className="wrap">{ children }</p>
        </div>
        <div>
            <button className="buttonprimary secondary singleline" onClick={ handleDone }>{ buttonCaption }</button>
        </div>
    </>
}
