import Axios from 'axios';

export function FindMerchants(user, query) {
  return ApiPostRequest('/merchant_search', query, user);
}

export function GetAvailableActions(user) {
  return ApiPostRequest('/action', null, user);
}

export function GetAvailableCurrencies(user) {
  return ApiPostRequest('/currency', null, user);
}

export function GetAvailableMethods(user) {
  return ApiPostRequest('/method', null, user);
}

export function GetAvailableSites(user, query) {
  return ApiPostRequest('/site', query, user);
}

export function GetAvailableStatuses(user) {
  return ApiPostRequest('/status', null, user);
}

export function FindTransactions(user, query) {
  return ApiPostRequest('/transaction_search', query, user);
}

export function GetTransactionDetails(user, query) {
  return ApiPostRequest('/transaction_details', query, user);
}

export function ViewLogFile(user, query) {
  return ApiPostRequest('/view_log_file', query, user);
}

export function RetrieveApiLogs(user, query) {
  return ApiPostRequest('/retrieve_api_logs', query, user);
}

export function Settlements(user, query) {
  return ApiPostRequest('/settlements', query, user);
}

export function SettlementApprove(user, params) {
  return ApiPostRequest('/settlements/approve', params, user);
}

export function SettlementDecline(user, params) {
  return ApiPostRequest('/settlements/decline', params, user);
}

export function Exchanges(user, query) {
  return ApiPostRequest('/exchanges', query, user);
}

export function ExchangeApprove(user, params) {
  return ApiPostRequest('/exchanges/approve', params, user);
}

export function ExchangeDecline(user, params) {
  return ApiPostRequest('/exchanges/decline', params, user);
}

export function SaveMerchantAdjustment(user, params) {
  return ApiPostRequest('/adjustments/save', params, user);
}

export function InitPeriodStatement(user, params) {
  return ApiPostRequest('/period_statement/init', params, user);
}

export function GetPeriodStatement(user, params) {
    return ApiPostRequest('/period_statement/get', params, user);
}

export function GetGateways(user, params) {
    return ApiPostRequest('/gateways/list', params, user);
}

export function GetGatewaySummary(user, params) {
    return ApiPostRequest('/gateways/summary', params, user);
}

export function GetDescriptors(user, params) {
    return ApiPostRequest('/descriptors/list', params, user);
}

export function GetDescriptorAdjustmentCounterAccounts(user, params) {
    return ApiPostRequest('/adjustments/descriptor/counteraccounts', params, user);
}

export function SaveDescriptorAdjustment(user, params) {
    return ApiPostRequest('/adjustments/descriptor', params, user);
}

export function GetLiveBatches(user) {
  return ApiPostRequest('/live_batches', null, user);
}

export function FetchLiveBatch(user, params) {
  return ApiPostRequest('/live_batches/fetch', params, user);
}

export function CloseLiveBatch(user, params) {
  return ApiPostRequest('/live_batches/close', params, user);
}

export function DownloadLiveBatch(user, params) {
  return ApiPostRequest('/live_batches/download', params, user);
}

export function MarkLiveBatchSent(user, params) {
  return ApiPostRequest('/live_batches/marksent', params, user);
}

export function UploadLiveBatchSettleFile(user, formData) {
  return ApiPostRequest('/live_batches/settle', formData, user, null, "multipart/form-data");
}

export function UploadReturnFile(user, formData) {
  return ApiPostRequest('/return_file', formData, user, null, "multipart/form-data");
}

export function GetMerchants(user) {
  return ApiPostRequest('/merchants', null, user);
}

export function GetMerchantTree(user) {
  return ApiPostRequest('/merchant_tree', null, user);
}

export function LoadAuditLogs(user, params) {
  return ApiPostRequest('/audits', params, user);
}

function BuildConfig(accept = "application/json", user = null) {
  const headers = {
    'Accept': accept
  };

  if (user && !user.expired) {
    const token = user.access_token;
    headers['Authorization'] = `Bearer ${token}`;
  }

  return {
    baseURL: window['w88.config'].gateway_api,
    headers: headers
  };
}


function ApiGetRequest(url, params = {}, user = null, accept = null) {
  const config = BuildConfig(accept, user);
  config['params'] = params;
  return Axios.get(url, config);
}


async function ApiPostRequest(url, data = {}, user = null, accept = null, contentType = "application/json; charset=utf8") {
  const config = BuildConfig(accept, user);
  config.headers['Content-Type'] = contentType;

  return Axios.post(url, data, config);
}