import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import styles from './TransactionAdvancedFilterOptions.module.css';
import AmountFilter from './AmountFilter';
import {
  updateAdvancedFilter,
  updateWorking,
  applyWorking
} from '../../shared/store/slices/transactionSearch';

const { SHOW_CHILD } = TreeSelect;

function CreateTreeNode(title, value) {
  return {
    title: title,
    value: value,
    key: value,
    children: []
  }
}

function SiteTreeData(ss) {
  const rt = [];
  if (!ss)
    return rt;

  let m = null;
  let r = null;
  ss.forEach(el => {
    const mid = 'm' + el.merchant_id;
    if (!m || m.value !== mid) {
      m = CreateTreeNode(el.merchant_name, mid);
      r = null;
      rt.push(m);
    }

    const rid = 'r' + el.retailer_id;
    if (!r || r.value !== rid) {
      r = CreateTreeNode(el.retailer_name, rid);
      m.children.push(r);
    }

    const sid = 's' + el.site_id;
    const s = CreateTreeNode(el.site_name, sid);
    r.children.push(s);
  });

  return rt;
}

function StringListTreeData(cs) {
  if (!cs)
    return [];

  return cs.map(el => {
    return {
      title: el,
      value: el,
      key: el
    };
  });
}

function ObjectIdNameTreeData(ms) {
  if (!ms)
    return [];

  return ms.map(el => {
    return {
      title: el.name,
      value: el.id,
      key: el.id
    }
  });
}

export default function TransactionAdvancedFilterOptions() {
  const { actions, currencies, statuses, methods, descriptors, sites, working } = useSelector(state => state.transactionSearch);
  const dispatch = useDispatch();

  function selectSites(ss) {
    dispatch(updateWorking({ sites: ss }));
  }

  function handleAmountChange(a) {
    dispatch(updateWorking({ amount: a }));
  }

  function selectCurrencies(cs) {
    dispatch(updateWorking({ currencies: cs }));
  }

  function selectActions(as) {
    dispatch(updateWorking({ actions: as }));
  }

  function selectStatuses(ss) {
    dispatch(updateWorking({ statuses: ss }));
  }

  function selectMethods(ms) {
    dispatch(updateWorking({ methods: ms }));
  }
  function handleCancel() {
    dispatch(updateAdvancedFilter(false));
  }

  function handleApply() {
    dispatch(applyWorking());
    dispatch(updateAdvancedFilter(false));
  }

  return (
    <div className={styles.advfilters}>
      <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Merchant details" treeData={SiteTreeData(sites)} onChange={selectSites} value={working.sites} style={{ width: '350px' }} />
      <AmountFilter value={working.amount} onChange={handleAmountChange} />
      <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Currency" treeData={StringListTreeData(currencies)} onChange={selectCurrencies} value={working.currencies} style={{ width: '350px' }} />
      <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Action" treeData={StringListTreeData(actions)} onChange={selectActions} value={working.actions} style={{ width: '350px' }} />
      <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Status" treeData={ObjectIdNameTreeData(statuses)} onChange={selectStatuses} value={working.statuses} style={{ width: '350px' }} />
      <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Payment method" treeData={ObjectIdNameTreeData(methods)} onChange={selectMethods} value={working.methods} style={{ width: '350px' }} />
      <button className="buttonprimary primary navbutton" onClick={handleApply}>Filter</button>
      <button className="buttonprimary secondary navbutton" onClick={handleCancel}>Cancel</button>
    </div>);
}