import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { loadPendingAdjustments, rejectAdjustment, approveAdjustment} from "../../shared/store/slices/adjustments";
import { Table } from 'antd';
import FormatTime from '../../shared/helpers/FormatTime';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import moment from "moment";
import NoData from '../../components/NoData';
import {Link} from "react-router-dom";


function PendingAdjustments(props) {

    TitleEffect(PageTitle("Adjustment Queue | Accounting "));
    const { isLoading } = useSelector(state => state.userInterface);
    const { pendingAdjustments } = useSelector(state => state.adjustments);
    const dispatch = useDispatch();

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [searchParameter, setSearchParameter] = useState('method');
    const [searchTerm, setSearchTerm] = useState('');

    const docparameterStyle = {
        display: 'flex',
        alignItems: 'baseline'
    }

    const [adjustmentList, setAdjustmentList] = useState([]);

    const columns = [{
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Timestamp', 
            dataIndex: 'CreatedOn',
            render: (text, record) => {
                return FormatTime(record.createdOn);
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (dateA,dateB) => { return moment(dateA.createdOn).diff(moment(dateB.createdOn)) }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Wallet TXID', 
            dataIndex: 'Wallet TXID',
            render: (text, record) => {
                return <Link to={`/transactions/wallet/${record.id}`} target={"_blank"}>{record.id}</Link>
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.id.localeCompare(b.id) }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Type',
            dataIndex: 'Type',
            render: (text, record) => {
                return record.type;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.paymentMethodCode.localeCompare(b.paymentMethodCode) }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Amount',
            dataIndex: 'Amount',
            render: (text, record) => {
                return record.formattedAmount;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.amount - b.amount }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Currency', 
            dataIndex: 'Currency',
            render: (text, record) => {
                return record.requestedCurrency;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.requestedCurrency.localeCompare(b.requestedCurrency) }
        }, {
            className: 'th-legendlabel tdstyle transtable transcell lastcell tableicons',
            align: 'center',
            title: '',
            render: (text, record) => {
                return <>
                    <Button
                        classes={"buttonprimary secondary navbutton tablebutton"}
                        TEXT={"REJECT"} 
                        onClick={() => { handleReject(record.id) }} />
                    <Button
                        classes={"buttonprimary primary navbutton tablebutton"}
                        TEXT={"APPROVE"}
                        onClick={() => { handleApprove(record.id) }} />
                </>;
            }
        }
    ];

    const handleSearchParameter = (event) => {
        setSearchParameter(event.target.value);
    }

    const handleSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleFilter = () => {
        setIsLoadingData(true);
        filterList();
        setIsLoadingData(false);
    }

    const handleReject = (transactionsId) => {
        dispatch(rejectAdjustment({
            transactionId: transactionsId
        })).then((response => {
            batch(()=>{
                setIsLoadingData(false);
                loadData();
            });
        })).catch(() => {
            setIsLoadingData(false);
        });
    }

    const handleApprove = (batchTransactionsId) => {
        setIsLoadingData(true);
        dispatch(approveAdjustment({ 
            transactionId: batchTransactionsId
        })).then((response => {
            batch(()=>{
                setIsLoadingData(false);
                loadData();
            });
        })).catch(() => {
            setIsLoadingData(false);
        });
    }

    const filterList = () => {
        if(searchTerm !== '') {
            setIsLoadingData(true);
            switch (searchParameter) {
                case 'currency':
                    setAdjustmentList(pendingAdjustments.filter(d => d.requestedCurrency.indexOf(searchTerm) >= 0));
                    break;
                case 'type':
                    setAdjustmentList(pendingAdjustments.filter(d => d.type.indexOf(searchTerm) >= 0));
                    break;
            }
        } else {
            setAdjustmentList(pendingAdjustments);
        }
    }

    const loadData = () => {
        setIsLoadingData(true);
        dispatch(loadPendingAdjustments())
            .then((response => {
                setAdjustmentList(response.payload);
                setIsLoadingData(false);
            }));
    }

    useEffect(()=> {
        loadData();
        filterList();
    }, [])

    return <div>
        <nav>
            <div id="docsearch">
                <div className="navcell docsearchbar">
                    <input type="text" id="transsearchbox" name="search" value={searchTerm} onChange={handleSearchTerm} />
                </div>
                <div className="navcell docparameter" style={docparameterStyle}>
                    <select name="parameter" id="parameter" value={searchParameter} onChange={handleSearchParameter}>
                        <option value="currency">Currency</option>
                        <option value="type">Type</option>
                    </select>
                </div>
                <div className="docsearchbutton">
                    <button className="buttonprimary primary navbutton" onClick={handleFilter}>Search</button>
                </div>
            </div>
        </nav>
        <main className="searchtransactions">
            { adjustmentList?.length
                ? <>
                    <Table
                        loading={isLoading || isLoadingData} 
                        rowKey={r => r.id} 
                        columns={columns} 
                        dataSource={adjustmentList} 
                        pagination={false}
                        scroll={{x : "auto"}}
                    />
                    <div className="text-center"><p id="nomoretx">The end</p></div>
                </>
                : ( isLoadingData === false
                    ? <NoData />
                    : null
                  )
            }
        </main>
    </div>
}

export default PendingAdjustments;
