import React, { useEffect, useState } from 'react';
import { Checkbox } from "antd";
import { UpdateCustomer, UpdateDocument } from "../services/documents.svc";
import { useAppSelector } from "../../hooks";
var initialState = {
    error: false,
    notifyCustomer: false,
    showFullImage: false
};
function DocumentDetails(_a) {
    var loader = _a.loader, onDocumentUpdated = _a.onDocumentUpdated;
    var user = useAppSelector(function (state) { return state.oidc.user; });
    var _b = useState(initialState), state = _b[0], setState = _b[1];
    var document = state.document, notifyCustomer = state.notifyCustomer, showFullImage = state.showFullImage;
    var customer = document === null || document === void 0 ? void 0 : document.customer;
    var address = customer === null || customer === void 0 ? void 0 : customer.address;
    var showApproveRejectButton = document !== undefined && document.decision === undefined;
    var showRequestNewDocButton = document !== undefined && !document.newRequested;
    useEffect(function () {
        loader().then(function (doc) {
            UpdateState(function (state) {
                state.document = doc;
            });
        });
    }, []);
    function UpdateState(updater) {
        var newState = Object.assign({}, state);
        updater(newState);
        setState(newState);
    }
    function handleChangeNotifyCustomer(evt) {
        UpdateState(function (state) {
            state.notifyCustomer = evt.target.checked;
        });
    }
    function handleShowFullImage() {
        UpdateState(function (state) {
            state.showFullImage = true;
        });
    }
    function handleHideFullImage() {
        UpdateState(function (state) {
            state.showFullImage = false;
        });
    }
    function submitOpenCustomerProfile() {
    }
    function updateDocument(decision) {
        var _a;
        UpdateDocument({
            accessToken: user === null || user === void 0 ? void 0 : user.access_token,
            documentId: (_a = state.document) === null || _a === void 0 ? void 0 : _a.id,
            decision: decision
        })
            .then(function () {
            if (document === undefined)
                return;
            document.decision = decision;
            if (onDocumentUpdated)
                onDocumentUpdated(document);
        })
            .catch(function () {
            UpdateState(function (state) {
                state.error = true;
            });
        });
    }
    function submitApproveDocument() {
        updateDocument("approved");
    }
    function submitRejectDocument() {
        updateDocument("rejected");
    }
    function submitRequestNewDocument() {
        var _a, _b, _c, _d;
        UpdateCustomer({
            accessToken: user === null || user === void 0 ? void 0 : user.access_token,
            customerId: (_b = (_a = state.document) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.id,
            documentId: (_c = state.document) === null || _c === void 0 ? void 0 : _c.id,
            documentType: (_d = state.document) === null || _d === void 0 ? void 0 : _d.type
        })
            .then(function () {
            if (document === undefined)
                return;
            document.newRequested = true;
            if (onDocumentUpdated)
                onDocumentUpdated(document);
        })
            .catch(function () {
            UpdateState(function (state) {
                state.error = true;
            });
        });
    }
    if (showFullImage)
        return React.createElement("div", { style: { textAlign: "center" } },
            React.createElement("img", { alt: "photo", style: { cursor: "pointer" }, src: document === null || document === void 0 ? void 0 : document.src, onClick: handleHideFullImage }));
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "overlaydiv" },
            React.createElement("div", { className: "docheader docusername" },
                React.createElement("div", { className: "docheader1" },
                    React.createElement("h3", { className: "overlay" }, customer === null || customer === void 0 ? void 0 : customer.name)),
                React.createElement("div", { className: "docheader2" },
                    React.createElement("a", { href: "#", onClick: submitOpenCustomerProfile }, customer === null || customer === void 0 ? void 0 : customer.email)))),
        React.createElement("div", { className: "docdata" },
            React.createElement("div", { className: "docdata1" },
                React.createElement("p", { className: "doctitle" }, "Date of Birth"),
                React.createElement("p", null, customer === null || customer === void 0 ? void 0 : customer.dob)),
            React.createElement("div", { className: "docdata2" },
                React.createElement("p", { className: "doctitle" }, "Address"),
                React.createElement("p", null, address === null || address === void 0 ? void 0 :
                    address.line1,
                    React.createElement("br", null), address === null || address === void 0 ? void 0 :
                    address.city,
                    ",", address === null || address === void 0 ? void 0 :
                    address.state,
                    React.createElement("br", null), address === null || address === void 0 ? void 0 :
                    address.country,
                    " ", address === null || address === void 0 ? void 0 :
                    address.postal_code))),
        React.createElement("div", null,
            React.createElement("img", { alt: "photo", src: document === null || document === void 0 ? void 0 : document.src, className: "idimage", onClick: handleShowFullImage })),
        React.createElement("div", { id: "overlaybuttons" },
            showApproveRejectButton &&
                React.createElement("div", null,
                    React.createElement("button", { className: "buttonprimary primary singleline", onClick: submitApproveDocument }, "Approve")),
            showRequestNewDocButton &&
                React.createElement("div", null,
                    React.createElement("button", { className: "buttonprimary primary singleline", onClick: submitRequestNewDocument }, "Request New")),
            showApproveRejectButton && React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement("h4", { className: "doctitle center" },
                        React.createElement(Checkbox, { onChange: handleChangeNotifyCustomer, defaultChecked: notifyCustomer }, "Should notify rejection to the customer?"))),
                React.createElement("div", null,
                    React.createElement("button", { className: "buttonprimary secondary singleline", onClick: submitRejectDocument }, "Reject")))));
}
export default DocumentDetails;
