import React from 'react';
import Spinner from "../assets/images/spinner.gif";

export default function Loading(props) {

    const className = "spinner" + (props.inline ? "" : " centered");
    return (
        <div id="loading-overlay">
            <div id="loader"><img src={Spinner} className={className} style={props.style}/></div>
        </div>
    );
}
