import React, {useState, useEffect} from "react";
import { ReactComponent as Logo } from "../assets/images/logo-wallet88.svg";
import { dayNames, monthNames } from "../shared/helpers/constants";
import DotsLoader from "./DotsLoader";

export default function Header() {
    const [serverTime, setServerTime] = useState("");

    var localOffsetSeconds = new Date().getTimezoneOffset() * 60;
    const timeOffset = (localOffsetSeconds);

    const handleLogoClick = () => {
        window.location.assign("/");
    }

    useEffect(() => {

        const tick = setInterval(() => {
            var date = new Date();
            var now = date.getTime();
            date = new Date(now + timeOffset * 1000);

            const dateStr = dayNames[date.getDay()] +
                ", " + date.getDate() +
                " " + monthNames[date.getMonth()] +
                " " + date.getFullYear() +
                " " + date.getHours() +
                ":" + date.getMinutes().toString().padStart(2, '0') +
                ":" + date.getSeconds().toString().padStart(2, '0');

            setServerTime(dateStr);
        }, 1000);

        return () => {
            clearInterval(tick);
        }
    }, [timeOffset]);

    return <header className={ "admin fixedheader withLoader" }>
            <div><Logo onClick={handleLogoClick} className="logo"/></div>
            <div id="admintag"><h3 className="admintag">ADMIN</h3></div>
            <DotsLoader />
            <div className="servertime">
                <span className="hide510">Server Time: </span>
                {serverTime}<span className="hide510"> (UTC, +0000)</span>
            </div>
    </header>;
}
