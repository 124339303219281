import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateQuery, reset, MerchantSearchQuery } from "../../../shared/store/slices/merchants";
import SearchBar from './SearchBar';
import SearchResults from './SearchResults'
import { useHistory } from "react-router-dom"
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";


export default function SearchPage(props) {
  TitleEffect(PageTitle("Merchants"));

  const { query, previousQuery, loading, list } = useSelector(state => state.merchants);
  const dispatch = useDispatch();
  const history = useHistory();
  const hasResults = (previousQuery !== null && !loading) || list?.length > 0;

  useEffect(() => {
    const show = localStorage.getItem('merchants.show') ?? '';
    dispatch(updateQuery({
      ...query,
      show: show
    }));
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);


  function handleChange(q) {
    dispatch(updateQuery(q));
  }

  function performSearch() {
    localStorage.setItem('merchants.show', query.show);
    dispatch(MerchantSearchQuery({}))
        .then(() => {
            history.push('/merchants');
        });
  }

  function handleLoadNextPage() {
    const nextQuery = Object.assign({}, previousQuery);
    nextQuery.page += 1;
    dispatch(MerchantSearchQuery(nextQuery));
  }

  return (<Fragment>
    <SearchBar query={query} showResults={hasResults || loading} onChange={handleChange} onSearch={performSearch} />
    {(hasResults || loading) ? <SearchResults loading={loading} show={query.show} feelingLucky={query.feelingLucky} dataSource={list} onLoadNextPage={handleLoadNextPage} /> : null }
  </Fragment>);
}
