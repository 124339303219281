// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kYYmNVIeja2yCAJeWA3W {
    border-spacing: 0;
    border-collapse: collapse;
    min-width: 100%;
    max-height: calc(100vh - 169px);
    overflow-y: auto;
    overflow-x: auto;
}

.kYYmNVIeja2yCAJeWA3W th {
    font-family: 'NHU-Bold';
    text-transform: uppercase;
    font-size: 1.2rem;
    border-bottom: 1px black solid;
    border-right: 1px black solid;
    padding: 6px 13px 3px;
    vertical-align: baseline;
    line-height: 1.9rem;
    overflow: hidden;
    white-space: nowrap;
    background: white;
    top: 0;
}

.kYYmNVIeja2yCAJeWA3W th:first-child {
    text-align: left;
}

.kYYmNVIeja2yCAJeWA3W th:last-child {
    border-right: none;
}

.kYYmNVIeja2yCAJeWA3W td {
    padding: 6px 13px 3px;
    cursor: pointer;
    word-break: normal;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: baseline;
    line-height: 1.9rem;
    border-right: 1px white solid;
}

.kYYmNVIeja2yCAJeWA3W td:first-child {
    text-align: left;
}

.kYYmNVIeja2yCAJeWA3W tr:nth-child(even) td {
    background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/domain/TransactionWalletDetails/Table.wallet.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,eAAe;IACf,+BAA+B;IAC/B,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;IACzB,iBAAiB;IACjB,8BAA8B;IAC9B,6BAA6B;IAC7B,qBAAqB;IACrB,wBAAwB;IACxB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,MAAM;AACV;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,wBAAwB;IACxB,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".table {\n    border-spacing: 0;\n    border-collapse: collapse;\n    min-width: 100%;\n    max-height: calc(100vh - 169px);\n    overflow-y: auto;\n    overflow-x: auto;\n}\n\n.table th {\n    font-family: 'NHU-Bold';\n    text-transform: uppercase;\n    font-size: 1.2rem;\n    border-bottom: 1px black solid;\n    border-right: 1px black solid;\n    padding: 6px 13px 3px;\n    vertical-align: baseline;\n    line-height: 1.9rem;\n    overflow: hidden;\n    white-space: nowrap;\n    background: white;\n    top: 0;\n}\n\n.table th:first-child {\n    text-align: left;\n}\n\n.table th:last-child {\n    border-right: none;\n}\n\n.table td {\n    padding: 6px 13px 3px;\n    cursor: pointer;\n    word-break: normal;\n    overflow: hidden;\n    white-space: nowrap;\n    vertical-align: baseline;\n    line-height: 1.9rem;\n    border-right: 1px white solid;\n}\n\n.table td:first-child {\n    text-align: left;\n}\n\n.table tr:nth-child(even) td {\n    background-color: #f5f5f5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `kYYmNVIeja2yCAJeWA3W`
};
export default ___CSS_LOADER_EXPORT___;
