import React from "react";
import { useSelector, useDispatch, batch } from 'react-redux';
import styles from './Table.module.css';
import { ReactComponent as IconRequest } from './images/txicon-request.svg';
import { ReactComponent as IconResponse } from './images/txicon-response.svg';
import { ReactComponent as IconLog } from './images/txicon-log.svg';
import { ReactComponent as IconApi } from './images/txicon-api-log.svg';
import { ucFirst } from '../../shared/utils';
import {
    showOverlay
} from '../../shared/store/slices/userInterface';
import {
    setOverlayContent,
    loadLogFile,
    loadApiLogs
} from '../../shared/store/slices/transactionDetails';
import AntdTable from '../../components/AntdTable';


function StyleForStatus(status) {
  switch(status) {
    case 'APPROVED':
      return styles.approved;

    case 'PENDING':
      return styles.pending;

    case 'DECLINED':
      return styles.declined;

    default:
      return '';
  }
}

export default function Listing(props) {
    const { tx } = useSelector(state => state.transactionDetails);
    const dispatch = useDispatch();

    const headers = [
        {title: "TX History", width: 100, sticky: 'left', align: 'left'},
        {title: "Status",     width: 100, sticky: 'left'},
        {title: "Timestamp",  width: 0},
        {title: "Amount",     width: 0},
        {title: "Site Gets",  width: 0},
        {title: "Fees",       width: 0},
        {title: "Reserve",    width: 0},
        {title: "FX",         width: 0},
        {title: "Comment",    width: 0},
        {title: "Reference",  width: 0},
        {title: "Data",       width: 0}
    ];
    
    const data = tx.map((transaction, index) => {
        const { tx_action, txid, response, timestamp,
            symbol, currency, amount, site_receive,
            fees, reserve, exchange_rate, comment,
            reference, logs } = transaction;
       
        function handleClickRequest() {
            batch(() => {
                dispatch(setOverlayContent(logs.request));
                dispatch(showOverlay("logs_request"));
            });
        }

        function handleClickResponse() {
            batch(() => {
                dispatch(setOverlayContent(logs.response));
                dispatch(showOverlay("logs_response"));
            });
        }

        function handleClickFile() {
            dispatch(loadLogFile({ directory: logs.file.dirname, file: logs.file.basename }))
                .then(() => {
                    dispatch(showOverlay("logs_file"));
                });
        }

        function handleClickApiLog() {
            dispatch(loadApiLogs({ transactionId: txid }))
                .then(() => {
                    dispatch(showOverlay("logs_api"));
                });
        }

        return {
            'key': txid,
            'data': [
                { className: 'nobreak', children: <>
                        <span className="txhistorytype">{ ucFirst(tx_action.toLowerCase()) }</span><br />
                        <span className="txhistoryid">{ txid }</span>
                    </> },
                { className: 'nobreak ' + StyleForStatus(response), children: ucFirst(response.toLowerCase()) },
                { className: 'nobreak', children: <>{timestamp.slice(0, 10) }<br />{ timestamp.slice(-8) }</> },
                { className: 'nobreak nowrap', children: <>{ symbol }{ amount } { currency }</> },
                { className: 'nobreak nowrap', children: <>{ symbol }{ site_receive } { currency }</> },
                { className: 'nobreak nowrap', children: <>{ symbol }{ fees } { currency }</> },
                { className: 'nobreak nowrap', children: <>{ symbol }{ reserve } { currency }</> },
                { className: 'nobreak', children: exchange_rate },
                { className: 'nobreak', children: comment },
                { className: '', children: reference },
                { className: 'txicons', children: <>
                        { logs.request ? <IconRequest className="txiconrequest" onClick={ handleClickRequest } /> : null }
                        { logs.response ? <IconResponse className="txiconresponse" onClick={ handleClickResponse } /> : null }
                        { logs.file ? <IconLog className="txiconlog" onClick={ handleClickFile } /> : null }
                        { logs.api ? <IconApi className="txiconlog" onClick={ handleClickApiLog } /> : null }
                        { !logs.request && !logs.response && !logs.file && !logs.api ? "No Information" : null }
                    </> },
            ]
        }
    });

    return <AntdTable width={ 1300 } headers={ headers } data={ data } />
}
