import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetTransactionDetails, ViewLogFile, RetrieveApiLogs } from '../../GatewayAPI.js';
import { startLoading, stopLoading } from './userInterface';
import { LoadWalletTransaction } from '../../../domain/services/search.svc';

export const loadTransactionDetails = createAsyncThunk(
    'transactionDetails/loadTransactionDetails',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await GetTransactionDetails(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const loadLogFile = createAsyncThunk(
    'transactionDetails/loadLogFile',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await ViewLogFile(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const loadApiLogs = createAsyncThunk(
    'transactionDetails/loadApiLogs',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await RetrieveApiLogs(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const loadWalletTransactionDetails = createAsyncThunk(
    'walletTransactionDetails/loadWalletTransactionDetails',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await LoadWalletTransaction(user, query.txid)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response;
    }
);

export const transactionDetailsSlice = createSlice({
    name: 'transactionDetails',

    initialState: {
        isReady: false,
        parent: {},
        details: {},
        access: {},
        items: [],
        tx: [],
        cardDetails: [],
        personalDetails: [],
        merchantDetails: [],
        payDetails: [],
        overlayContent: "",
        apiError: "",
        walletTransactionDetails: {},
        displayLog: false,
        logView: {}
    },

    reducers: {
        reset: (state) => {
            state.parent = {};
            state.details = {};
            state.access = {};
            state.items = [];
            state.tx = [];
            state.cardDetails = [];
            state.personalDetails = [];
            state.merchantDetails = [];
            state.payDetails = [];
            state.walletTransactionDetails = {};
            state.displayLog = false;
            state.logView = {};
        },
        setOverlayContent: (state, action) => {
            state.overlayContent = action.payload;
        },
        ready: (state) => {
            state.isReady = true;
        },
        setWalletDetailsLog(state, action) {
            state.logView = action.payload;
            state.displayLog = true;
        },
        hideLogView(state, action) {
            state.displayLog = false;
            state.logView = {};
        }
    },

    extraReducers: {
        [loadTransactionDetails.fulfilled]: (state, action) => {
            state.parent = action.payload.parent;
            state.details = action.payload.details;
            state.access = action.payload.access;
            state.items = action.payload.items;
            state.tx = action.payload.tx;
            state.cardDetails = action.payload.cardDetails;
            state.personalDetails = action.payload.personalDetails;
            state.merchantDetails = action.payload.merchantDetails;
            state.payDetails = action.payload.payDetails;
        },
        [loadTransactionDetails.rejected]: (state, action) => {
            state.parent = {};
            state.details = {};
            state.access = {};
            state.items = [];
            state.tx = [];
            state.cardDetails = [];
            state.personalDetails = [];
            state.merchantDetails = [];
            state.payDetails = [];
        },
        [loadWalletTransactionDetails.fulfilled] : (state, action) => {
            state.walletTransactionDetails = action.payload;
        },
        [loadWalletTransactionDetails.rejected] : (state, action) => {
            state.walletTransactionDetails = {};
        },
        [loadLogFile.fulfilled]: (state, action) => {
            state.overlayContent = action.payload;
        },
        [loadLogFile.rejected]: (state, action) => {
            state.overlayContent = "Log file load failed";
        },
        [loadApiLogs.fulfilled]: (state, action) => {
            state.overlayContent = action.payload !== "[]" ? action.payload : "No API Logs Found";
        },
        [loadApiLogs.rejected]: (state, action) => {
            state.overlayContent = "API Logs load failed";
        }
    }
})

// Action creators are generated for each case reducer function
export const { reset, setOverlayContent, ready, setWalletDetailsLog, hideLogView } = transactionDetailsSlice.actions;

export default transactionDetailsSlice.reducer;
