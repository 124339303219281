import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SaveCustomerAdjustment, LoadPendingAdjustments, RejectAdjustment, ApproveAdjustment } from "../../../domain/services/adjustments.svc";
import { SaveMerchantAdjustment, GetDescriptorAdjustmentCounterAccounts, SaveDescriptorAdjustment } from "../../../shared/GatewayAPI";
import { startLoading, stopLoading } from './userInterface';


export const saveCustomerAdjustment = createAsyncThunk(
    'adjustments/saveCustomerAdjustment',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        return await SaveCustomerAdjustment(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const saveMerchantAdjustment = createAsyncThunk(
    'adjustments/saveMerchantAdjustment',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        return await SaveMerchantAdjustment(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const loadDescriptorCounterAccounts = createAsyncThunk(
    'gatewaySummary/descriptorCounterAccounts',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        params.gatewayId = Number.parseInt(params.gatewayId?.replace("pgid", ""));
        params.descriptorId = Number.parseInt(params.descriptorId?.replace("plid", ""));
        const response = await GetDescriptorAdjustmentCounterAccounts(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const saveDescriptorAdjustment = createAsyncThunk(
    'adjustments/saveDescriptorAdjustment',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        return await SaveDescriptorAdjustment(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const loadPendingAdjustments = createAsyncThunk(
    'adjustments/loadPendingAdjustments',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await LoadPendingAdjustments(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const rejectAdjustment = createAsyncThunk(
    'adjustments/rejectAdjustment',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await RejectAdjustment(user, query.transactionId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const approveAdjustment = createAsyncThunk(
    'adjustments/approveAdjustment',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await ApproveAdjustment(user, query.transactionId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);


export const adjustmentsSlice = createSlice({
    name: 'adjustments',

    initialState: {
        type: 'Credit',
        amount: '',
        currency: '',
        reason: '',
        counterAccounts: {},
        counterAccount: "",
        overlayContent: "",
        apiError: "",
        isLoading: false,
        pendingAdjustments: []
    },

    reducers: {
        reset: (state) => {
            state.counterAccount = '';
            state.type = 'Credit';
            state.amount = '';
            state.reason = '';
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setCurrency: (state, action) => {
            state.currency = action.payload;
        },
        setReason: (state, action) => {
            state.reason = action.payload;
        },
        setCounterAccount: (state, action) => {
            state.counterAccount = action.payload;
        },
        setOverlayContent: (state, action) => {
            state.overlayContent = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        ready: (state) => {
            state.isReady = true;
        }
    },

    extraReducers: {
        [saveCustomerAdjustment.fulfilled]: (state, action) => {
            state.type = 'Credit';
            state.amount = '';
            state.reason = '';
        },
        [saveCustomerAdjustment.rejected]: (state, action) => {
        },
        [saveMerchantAdjustment.fulfilled]: (state, action) => {
            state.type = 'Credit';
            state.amount = '';
            state.reason = '';
        },
        [loadDescriptorCounterAccounts.fulfilled]: (state, action) => {
            state.counterAccounts = action.payload;
        },
        [loadDescriptorCounterAccounts.rejected]: (state, action) => {
            state.counterAccounts = {};
        },
        [saveMerchantAdjustment.rejected]: (state, action) => {
        },
        [saveDescriptorAdjustment.fulfilled]: (state, action) => {
            state.type = 'Credit';
            state.amount = '';
            state.reason = '';
        },
        [saveDescriptorAdjustment.rejected]: (state, action) => {
        },
        [loadPendingAdjustments.fulfilled]: (state, action) => {
            state.pendingAdjustments = action.payload;
        },
        [loadPendingAdjustments.rejected]: (state, action) => {
            state.pendingAdjustments = [];
        },
    }
})

// Action creators are generated for each case reducer function
export const { reset, setType, setAmount, setCurrency, setReason, setCounterAccount, setOverlayContent, setLoading, ready } = adjustmentsSlice.actions;

export default adjustmentsSlice.reducer;
