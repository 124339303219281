// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vWhZp3CU1lv1_CCSogcq {
    text-align: left;
}
.eif6K81FpNGRE0T1siR0 {
    padding-right: 15px;
}
.kQjfeqk21eiDpFAcWqVa {
    margin-top: 16px;
}
.b96bYwayKk_ma3HmF6ph {
    width: auto;
}
.wXZ_cxSq1WeVP9y1z5o7 {
    margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/domain/Wallets/Configuration/configuration.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;AACf;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".alignLeft {\n    text-align: left;\n}\n.paddingRight15px {\n    padding-right: 15px;\n}\n.marginTop16px {\n    margin-top: 16px;\n}\n.widthAuto {\n    width: auto;\n}\n.marginRight5px {\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignLeft": `vWhZp3CU1lv1_CCSogcq`,
	"paddingRight15px": `eif6K81FpNGRE0T1siR0`,
	"marginTop16px": `kQjfeqk21eiDpFAcWqVa`,
	"widthAuto": `b96bYwayKk_ma3HmF6ph`,
	"marginRight5px": `wXZ_cxSq1WeVP9y1z5o7`
};
export default ___CSS_LOADER_EXPORT___;
