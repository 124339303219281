import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import styles from './TransactionAdvancedFilterOptions.module.css';
import AmountFilter from './AmountFilter';
import {
  updateAdvancedFilter,
  updateWorking,
  applyWorking
} from '../../shared/store/slices/transactionSearch';

const { SHOW_CHILD } = TreeSelect;

function StringListTreeData(cs) {
  if (!cs)
    return [];

  return cs.map(el => {
    return {
      title: el,
      value: el,
      key: el
    };
  });
}

function ObjectIdNameTreeData(ms) {
  if (!ms)
    return [];

  return ms.map(el => {
    return {
      title: el.name,
      value: el.id,
      key: el.id
    }
  });
}

function StringCurrencyTreeData(cs) {
    if (!cs)
    return [];

  return cs.map(el => {
    return {
      title: el.code,
      value: el.code,
      key: el.code
    };
  });
}

export default function TransactionWalletAdvancedFilterOptions() {
  const { types, working } = useSelector(state => state.transactionSearch);
  const { currenciesList, availableMethodsList } = useSelector(state => state.payouts)

  const dispatch = useDispatch();

  function handleAmountChange(a) {
    dispatch(updateWorking({ amount: a }));
  }

  function selectCurrencies(cs) {
    dispatch(updateWorking({ currencies: cs }));
  }

  function selectTypes(ts) {
    dispatch(updateWorking({ types: ts }));
  }

  function selectMethods(ms) {
    dispatch(updateWorking({ methods: ms }));
  }

  function handleCancel() {
    dispatch(updateAdvancedFilter(false));
  }

  function handleApply() {
    dispatch(applyWorking());
    dispatch(updateAdvancedFilter(false));
  }

  return (
    <div className={styles.advfilters}>
      <AmountFilter value={working.amount} onChange={handleAmountChange} />
      <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Currency" treeData={StringCurrencyTreeData(currenciesList)} onChange={selectCurrencies} value={working.currencies} style={{ width: '350px' }} />
      <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Type" treeData={StringListTreeData(types)} onChange={selectTypes} value={working.types} style={{ width: '350px' }} />
      <TreeSelect treeCheckable="true" showCheckedStrategy={SHOW_CHILD} placeholder="Payment method" treeData={ObjectIdNameTreeData(availableMethodsList)} onChange={selectMethods} value={working.methods} style={{ width: '350px' }} />
      <button className="buttonprimary primary navbutton" onClick={handleApply}>Filter</button>
      <button className="buttonprimary secondary navbutton" onClick={handleCancel}>Cancel</button>
    </div>);
}