import React from 'react';
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import { useSelector } from 'react-redux';
import LogOverlayView from '../domain/TransactionWalletDetails/LogOverlayView';

export default function StandardPage(props) {
    const { children } = props;
    const user = useSelector(state => state.oidc.user);
    const authenticated = user && !user.expired;
    const { currentMenu } = useSelector(state => state.menu);

    return <>
        {authenticated && <div><LogOverlayView/></div>}
        <Header />
        <div className={ "menugrid" + (currentMenu !== 'none' ? "" : " slideclosed") }>
            <Menu />
            <div className="contentWrapper">
                { children }
            </div>
        </div>
        <Footer/>
    </>
}