import { ApiDeleteAsync, ApiGetRequestSync, ApiPostRequestSync, ApiPutRequestSync } from "../../shared/API";

export const GetSetting = (user, settingname) => {
    return ApiGetRequestSync("ControlPanel/"+settingname, user);
}

export const SaveSetting = (user, id, setting) => {
    return ApiPutRequestSync("ControlPanel/"+id, user, setting);
}

export const GetAllSettings = (user) => {
    return ApiGetRequestSync("ControlPanel", user);
}

export const SaveAllSettings = (user, settings) => {
    return ApiPutRequestSync("ControlPanel", user, settings);
}

export const DeleteSetting = (user, setting) => {
    return ApiPostRequestSync("ControlPanel/delete", user, setting);
}