import { createSlice, current } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
    name: 'menu',

    initialState: {
        Main_Menu: {
            Search: {
                href: "/"
            },
            Transactions: {
                subMenu: {
                    Transactions_Gateway: {
                        href: "/transactions/gateway"
                    },
                    Transactions_Wallet: {
                        href: "/transactions/wallet"
                    },
                    Sign_Out: {
                        className: 'boldtext',
                        signOut: true
                    }
                }
            },
            Customers: {
                subMenu: {
                    Current_Customer: {
                        href: "/customer/profile/view"
                    },
                    Search: {
                        href: "/customer/search",
                        altRoutes: ["/customers"]
                    },
                    Profile: {
                        href: "/customer/profile"
                    },
                    Action_Log: {
                        href: "/customer/action-log"
                    },
                    Comments: {
                        href: "/customer/comments"
                    },
                    TX_History_Gateway: {
                        href: "/customer/transactions/gateway"
                    },
                    TX_History_Wallet: {
                        href: "/customer/transactions/wallet"
                    },
                    Adjustments: {
                        href: "/customer/adjustments"
                    },
                    Sign_Out: {
                        className: 'boldtext',
                        signOut: true
                    }
                }
            },
            Merchants: {
                subMenu: {
                    Profile: {
                        href: "/merchants/profile"
                    },
                    Load_Balancing: {
                        href: "/merchants/load-balancing"
                    },
                    Transactions: {
                        href: "/merchants/transactions"
                    },
                    Period_Statement: {
                        href: "/merchants/period-statement"
                    },
                    Sign_Out: {
                        className: 'boldtext',
                        signOut: true
                    }
                }
            },
            Gateways: {
                subMenu: {
                    Summary: {
                        href: "/gateways/summary"
                    },
                    Configuration: {
                        href: "/gateways/configuration"
                    },
                    Sign_Out: {
                        className: 'boldtext',
                        signOut: true
                    }
                }
            },
            Security: {
                actionCount: null,
                subMenu: {
                    Sign_Ups:{
                        href: "/security/signups"
                    },
                    Documents_Queue: {
                        href: "/security/documents",
                        actionCount: null
                    },
                    Withdrawals: {
                        href: "/security/withdrawals",
                        actionCount: null
                    },
                    Fraud_Control: {
                        href: "/security/fraud-control"
                    },
                    Sign_Out: {
                        className: 'boldtext',
                        signOut: true
                    }
                }
            },
            Accounting: {
                actionCount: null,
                subMenu: {
                    Pending_Withdrawals_1: {
                        href: "/accounting/pending-withdrawals-1",
                        actionCount: null
                    },
                    Pending_Withdrawals_2: {
                        href: "/accounting/pending-withdrawals-2",
                        actionCount: null
                    },
                    Live_Batches: {
                        href: "/accounting/live-batches",
                        subRoutes: '\/accounting\/live-batches\/\\d+',
                    },
                    Pending_Settlements: {
                        href: "/accounting/pending-settlements"
                    },
                    Pending_Exchanges: {
                        href: "/accounting/pending-exchanges"
                    },
                    Pending_Adjustments: {
                        href: "/accounting/pending-adjustments",
                        actionCount: null
                    },
                    History: {
                        href: "/accounting/withdrawal-batches-history"
                    },
                    Return_File: {
                        href: "/accounting/return-file"
                    },
                    Sign_Out: {
                        className: 'boldtext',
                        signOut: true
                    }
                }
            },
            Control_Panel: {
                subMenu: {
                    'Buy+Send_Global': {
                        href: "/controlpanel/buyandsend"
                    },
                    'Countries Allowed Buy+Send': {
                        href: "/controlpanel/countries-allowed/buynsend"
                    },
                    Countries_Allowed_Deposits: {
                        href: "/controlpanel/countries-allowed/deposits"
                    },
                    Countries_Allowed_Withdrawals: {
                        href: "/controlpanel/countries-allowed/withdrawals"
                    },
                    Wallet_App_Settings: {
                        href: "/controlpanel/walletapp-settings"
                    },
                    Sign_Out: {
                        className: 'boldtext',
                        signOut: true
                    }
                }
            },
            Wallets: {
                subMenu: {
                    Configuration: {
                        href: "/wallets/configuration"
                    },
                    Limits: {},
                }
            },
            Sign_Out: {
                className: 'boldtext',
                signOut: true
            }
        },
        notifications: {
            currentNotification: null,
            totalNotificationsReceived: 0
        },
        currentMenu: 'Main_Menu',
        menuOnOpen: 'Main_Menu',
        stowedPositions: {},
        ws_connectionState: undefined
    },

    reducers: {
        switchMenu: (state, action) => {
            state.currentMenu = action.payload;
            //return state;
        },
        setMenuOnOpen: (state, action) => {
            state.menuOnOpen = action.payload;
            //return state;
        },
        setStowedPosition: (state, action) => {
            const stowedPosition = {};
            stowedPosition[action.payload.menu] = action.payload.position;

            state.stowedPositions = Object.assign({}, state.stowedPositions, stowedPosition);
            //return state;
        },
        AdminNotificationReceived: (state, action) => {
            state.notifications.currentNotification = action.payload;
            state.notifications.totalNotificationsReceived = state.notifications.totalNotificationsReceived+1;
            return state;
        },
        setActionCount: (state, action) => {
            switch (action.payload.ActionCategory){
                case "SecurityPendingDocumentReview": {
                    state.Main_Menu.Security.subMenu.Documents_Queue.actionCount = action.payload.CountRemaining;
                    state.Main_Menu.Security.actionCount = action.payload.CountRemaining +
                        (state.Main_Menu.Security.subMenu.Withdrawals.actionCount ?? 0);
                    break;
                }
                case "SecurityPendingWalletWithdrawals":{
                    state.Main_Menu.Security.subMenu.Withdrawals.actionCount = action.payload.CountRemaining;
                    state.Main_Menu.Security.actionCount = action.payload.CountRemaining +
                        (state.Main_Menu.Security.subMenu.Documents_Queue.actionCount ?? 0);
                    break;
                }
                case "AccountingPendingWithdrawalQueueLevelOne":{
                    state.Main_Menu.Accounting.subMenu.Pending_Withdrawals_1.actionCount = action.payload.CountRemaining;
                    state.Main_Menu.Accounting.actionCount = action.payload.CountRemaining +
                        (state.Main_Menu.Accounting.subMenu.Pending_Withdrawals_2.actionCount ?? 0) +
                        (state.Main_Menu.Accounting.subMenu.Pending_Adjustments.actionCount ?? 0);
                    break;
                }
                case "AccountingPendingWithdrawalQueueLevelTwo":{
                    state.Main_Menu.Accounting.subMenu.Pending_Withdrawals_2.actionCount = action.payload.CountRemaining;
                    state.Main_Menu.Accounting.actionCount = action.payload.CountRemaining +
                        (state.Main_Menu.Accounting.subMenu.Pending_Withdrawals_1.actionCount ?? 0) +
                        (state.Main_Menu.Accounting.subMenu.Pending_Adjustments.actionCount ?? 0);
                    break;
                }
                case "AccountingPendingAdjustments":{
                    state.Main_Menu.Accounting.subMenu.Pending_Adjustments.actionCount = action.payload.CountRemaining;
                    state.Main_Menu.Accounting.actionCount = action.payload.CountRemaining +
                        (state.Main_Menu.Accounting.subMenu.Pending_Withdrawals_1.actionCount ?? 0) +
                        (state.Main_Menu.Accounting.subMenu.Pending_Withdrawals_2.actionCount ?? 0);
                    break;
                }
                default: {
                    break;
                }
            }
            return state;
        },
        setWSConnectionState: (state, action) => {
            state.ws_connectionState = action.payload;
            return state;
        },
        addSubMenuOptions: (state, action) => {
            switch (action.payload.option) {
                case "Main_Menu.Wallets.Limits":
                    state.Main_Menu.Wallets.subMenu.Limits.subMenu = {};

                    if(action.payload?.items) {
                        let items = [...action.payload.items];

                        items.sort(function (a, b) {
                            if (a.name > b.name) {
                              return 1;
                            }
                            if (a.name < b.name) {
                              return -1;
                            }
                            // a must be equal to b
                            return 0;
                        });

                        items.unshift({
                            name: 'Add New Limit',
                            href: '/wallets/limitsgroup/'
                        });

                        items.forEach((s, i) => {
                            state.Main_Menu.Wallets.subMenu.Limits.subMenu[s.name] = {
                                href: s.href
                            };
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const { switchMenu, setMenuOnOpen, setStowedPosition, AdminNotificationReceived, setWSConnectionState, setActionCount, addSubMenuOptions } = menuSlice.actions;

export default menuSlice.reducer;