import React, { useState, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useSelector, useDispatch, batch } from 'react-redux';
import { getWithdrawalBatchesForApproval, 
         loadWithdrawalsBatchesForApproval,
         splitBatch,
         approveBatch } from "../../shared/store/slices/payouts";
import { Alert, Popconfirm, Table } from 'antd';
import FormatTime from '../../shared/helpers/FormatTime';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import moment from "moment";
import Button from "../../components/Button";
import { ReactComponent as TableSplitIcon } from "../../assets/images/icon-split.svg";
import NoData from '../../components/NoData';


function WithdrawalsBatchesForApprovalPage(props) {

    TitleEffect(PageTitle("Withdrawal For Approval | Accounting"));
    const { isLoading } = useSelector(state => state.userInterface);
    const { withdrawalsListChanged, withdrawalsBatchesForApproval} = useSelector(state => state.payouts);
    const dispatch = useDispatch();

    const [isLoadingData, setIsLoadingData] = useState(undefined);
    const [searchParameter, setSearchParameter] = useState('method');
    const [searchTerm, setSearchTerm] = useState('');
    const [batchIdSelected, setBatchIdSelected] = useState('');
    const [splitAmount, setSplitAmount] = useState(0);
    const [maxAmount, setMaxAmout] = useState(0);
    const [showMessage, setShowMessage] = useState(false);

    const docparameterStyle = {
        display: 'flex',
        alignItems: 'baseline'
    }

    const [withdrawalList, setWithdrawalList] = useState([]);

    const columns = [{ 
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'left',
            title: 'Timestamp',
            dataIndex: 'record.checkedAtDate',
            render: (text, record) => {
                return record.isOpen ? 
                'OPEN' : FormatTime(record.checkedAtDate);
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (dateA,dateB) => { return moment(dateA.checkedAtDate).diff(moment(dateB.checkedAtDate)) }
        },{ 
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Method',
            dataIndex: 'method',
            render: (text, record) => {
                return record.method;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.method.localeCompare(b.method) }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Amount',
            dataIndex: 'amount',
            render: (text, record) => {
                return <div className="editable-cell-value-wrap" onClick={() => { handleIsEditing(record.id, record.amount) }}>
                        { batchIdSelected != record.id || record.transactionsQty <= 1 ? 
                            record.formattedAmount : 
                            <><CurrencyInput
                                    id={"cur_ipt_" + record.id}
                                    autoFocus={true}
                                    className="editintable" 
                                    onValueChange={handleAmountChange}
                                    placeholder={record.formattedAmount}
                                    decimalScale={2}
                                    allowNegativeValue={false}
                                    decimalSeparator="."
                                    disableGroupSeparators={true}
                                /> 
                                <Popconfirm 
                                    title="Are you sure you want to split this batch?"
                                    placement="right"
                                    onConfirm={handleSplit}>
                                    <TableSplitIcon className="tablesplit" />
                                </Popconfirm></>
                        }
                        { batchIdSelected == record.id && showMessage && 
                            <Alert
                                message="Warning!"
                                description={ `The value cannot be greater than the ${ record.formattedAmount } and less than $1.00` }
                                type="warning"
                                showIcon
                                closable
                                onClose={() => { handleAlertClose }}
                            />}
                </div>
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.amount - b.amount }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Currency', 
            dataIndex: 'currency',
            render: (text, record) => {
                return record.currency;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.currency.localeCompare(b.currency) }
        }, { 
            className: 'th-legendlabel tdstyle transtable transcell lastcell tableicons',
            align: 'center',
            title: '',
            render: (text, record) => {
                return <>
                    <Button 
                        classes={"buttonprimary secondary navbutton tablebutton"} 
                        TEXT={"View"} 
                        onClick={() => { handleViewTransactions(record.id) }} />
                    {record.isApproved != true ? 
                    <Button 
                        classes={"buttonprimary primary navbutton tablebutton"} 
                        TEXT={"Approve"} 
                        onClick={() => { handleApprove(record.id) }} /> : ''}
                </>;
            }
        }

    ];

    const handleIsEditing = (batchId, mAmount) => {
        setBatchIdSelected(batchId);
        setMaxAmout(mAmount);
    }
    
    const handleAmountChange = function (value) {
        try { 
            const amount = parseInt(value);
            setSplitAmount(amount*100);
        } catch (err) {
            setSplitAmount(0);
        }        
    }

    const handleAlertClose = () => {
        setShowMessage(false);
    }

    const handleSplit = () => {
        if(splitAmount > maxAmount || splitAmount < 1) {
            setShowMessage(true);
            return false;
        } else {
            dispatch(splitBatch({ 
                batchId: batchIdSelected,
                splitAmount: splitAmount }))
            .then((response => {
                batch(()=>{
                    setBatchIdSelected('');
                    setMaxAmout(0);
                    loadData();
                })
            }));
        }  
    }

    const handleSearchParameter = (event) => {
        setSearchParameter(event.target.value);
    }

    const handleSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleFilter = () => {
        setIsLoadingData(true);
        filterList();
        setIsLoadingData(false);
    }

    const handleApprove = (batchTransactionsId) => {
        if(batchTransactionsId !== null && 
           batchTransactionsId !== undefined) {
            setIsLoadingData(true);
            dispatch(approveBatch({ 
                batchId: batchTransactionsId
            })).then((response => {
                batch(()=>{
                    setIsLoadingData(false);
                    loadData();
                });
            })).catch(() => {
                setIsLoadingData(false);
            });
        }
    }

    const handleViewTransactions = (batchTransactionsId) => {
        const url = `/accounting/batch-transactions/${batchTransactionsId}`;
        window.open(url, '_blank');
    }

    const filterList = () => {
        if(searchTerm !== '') {
            setIsLoadingData(true);
            switch (searchParameter) {
                case 'currency':
                    setWithdrawalList(withdrawalsBatchesForApproval.filter(d => d.currency.indexOf(searchTerm) >= 0));
                    break;
                case 'method':
                    setWithdrawalList(withdrawalsBatchesForApproval.filter(d => d.method.indexOf(searchTerm) >= 0));
                    break;
            }
        } else {
            setWithdrawalList(withdrawalsBatchesForApproval);
        }
    }

    const loadData = () => {
        setIsLoadingData(true);
        dispatch(getWithdrawalBatchesForApproval())
        .then((response => {
            batch(()=>{
                dispatch(loadWithdrawalsBatchesForApproval(response.payload));
                setWithdrawalList(response.payload);
                setIsLoadingData(false);
            })
            
        }));
    }

    useEffect(()=> {
        loadData();
        filterList();
    }, [withdrawalsListChanged])

    return <div>
        <nav>
            <div id="docsearch">
                <div className="navcell docsearchbar">
                    <input type="text" id="transsearchbox" name="search" value={searchTerm} onChange={handleSearchTerm} />
                </div>
                <div className="navcell docparameter" style={docparameterStyle}>
                    <select name="parameter" id="parameter" value={searchParameter} onChange={handleSearchParameter}>
                        <option value="currency">Currency</option>
                        <option value="method">Method</option>
                    </select>
                </div>
                <div className="docsearchbutton">
                    <button className="buttonprimary primary navbutton" onClick={() => { handleFilter }}>Search</button>
                </div>
            </div>
        </nav>
        <main className="searchtransactions">
            { withdrawalList?.length
                ? <>
                    <Table
                        rowClassName={(record, index) => record.isOpen ? 'opentx' : '' } 
                        loading={isLoading || isLoadingData} 
                        rowKey={r => r.id} 
                        columns={columns} 
                        dataSource={withdrawalList} 
                        pagination={false}
                        scroll={{x: "auto"}}
                    />
                    <div className="text-center"><p id="nomoretx">The end</p></div>
                </>
                : ( isLoadingData === false
                    ? <NoData />
                    : null
                  )
            }
        </main>
    </div>
}

export default WithdrawalsBatchesForApprovalPage;
