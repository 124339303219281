import { ApiGetRequestSync, ApiPostRequestSync } from "../../shared/API";

export const GetCustomerBalance = (user, params) => {
    return ApiGetRequestSync("/Balance/" + params.userId, user);
}

export const SaveCustomerAdjustment = (user, params) => {
    params.adminUserId = user?.profile?.sub;
    return ApiPostRequestSync("/Adjustments/Save", user, params);
}

export const LoadPendingAdjustments = (user) => {
    return ApiPostRequestSync("/Adjustments/adjustment-transactions-pending", user);
}

export const RejectAdjustment = (user, transactionId) => {
    return ApiPostRequestSync("/Adjustments/reject", user, {id: transactionId, adminUserId: user?.profile?.sub});
}

export const ApproveAdjustment = (user, transactionId) => {
    return ApiPostRequestSync("/Adjustments/approve", user, {id: transactionId, adminUserId: user?.profile?.sub});
}
