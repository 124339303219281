import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  client_id: window['w88.config'].oauth.client_id,
  redirect_uri: `${window['w88.config'].base_url}/callback`,
  response_type: 'token id_token',
  scope: 'openid',
  authority: window['w88.config'].oauth.authority,
  silent_redirect_uri: `${window['w88.config'].base_url}/silent_renew`,
  post_logout_redirect_uri: `${window['w88.config'].base_url}/logged-out`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true,
  "extraQueryParams": {
    "kc_idp_hint": window['w88.config'].oauth.idp_hint
  }
};

const userManager = createUserManager(userManagerConfig);

export default userManager;