import React, { useState, useEffect } from "react";
import Navigation from './Navigation';
import Body from './Body';
import Buttons from './Buttons';
import { noop, buildDateString } from '../../shared/utils';
import { useSelector, useDispatch } from 'react-redux';
import {
    init,
    ready,
    busy,
    clearData,
    loadPeriodData,
    changePeriod
} from '../../shared/store/slices/periodStatement';
import { loadMerchantTree } from '../../shared/store/slices/merchants';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";


export default function PeriodStatement(props) {
    TitleEffect(PageTitle("Period Statement"));

    const { site } = useSelector(state => state.periodStatement);
    const { periodStart, periodEnd, periodData, isReady } = useSelector(state => state.periodStatement);

    const dispatch = useDispatch();

    const maxDate = new Date();  //Today

    const periodMaxYr = maxDate.getUTCFullYear();
    const periodMaxMo = maxDate.getUTCMonth();
    const periodMaxDy = maxDate.getUTCDate();

    const maxUTCDate = (periodMaxMo + 1) + `/${periodMaxDy}/${periodMaxYr}`;

    //Initialize period statement with period number, start and end dates, and max period index.  Do this only once.
    useEffect(() => {
        dispatch(busy());
        dispatch(init())
            .then(() => {
                dispatch(loadMerchantTree())
                    .then(() => {
                        dispatch(ready());
                    });
            });
    }, [dispatch]);

    useEffect(() => {
        if (isReady && site && periodStart && periodEnd) {
            const sid = Number.parseInt(site.replace("sid", ""));

            let params = {
                sid: sid,
                periodStart: buildDateString(new Date(periodStart)),
                periodEnd: buildDateString(new Date(periodEnd))
            }

            dispatch(clearData());
            dispatch(loadPeriodData(params));
        }
    }, [isReady, site, periodStart, periodEnd, dispatch]);

    function datesToStrings(periodStart, periodEnd) {
        const perStart = new Date(periodStart);
        const perEnd = new Date(periodEnd);

        const periodStartYr = perStart.getUTCFullYear();
        const periodStartMo = perStart.getUTCMonth();
        const periodStartDy = perStart.getUTCDate();

        const periodEndYr = perEnd.getUTCFullYear();
        const periodEndMo = perEnd.getUTCMonth();
        const periodEndDy = perEnd.getUTCDate();

        const periodStartValue = `${periodStartYr}-` + (periodStartMo + 1).toString().padStart(2, '0') + `-${periodStartDy}`;
        let periodEndValue;

        if (periodEnd > maxDate.toISOString()) {
            periodEndValue = `${periodMaxYr}-` + (periodMaxMo + 1).toString().padStart(2, '0') + `-${periodMaxDy}`;
        }
        else {
            periodEndValue = `${periodEndYr}-` + (periodEndMo + 1).toString().padStart(2, '0') + `-${periodEndDy}`;
        }

        return {
            periodStart: periodStartValue,
            periodEnd: periodEndValue
        }
    }

    function handleChangePeriod(dates) {
        const range = datesToStrings(dates[0], dates[1] || dates[0]);
        dispatch(changePeriod({period_start: range.periodStart, period_end: range.periodEnd}));
    }

    function handleStageRequest() {
        console.log("stage");
    }

    function handlePublishRequest() {
        console.log("publish");
    }

    return (
        <>
            <Navigation
                onChangePeriod={handleChangePeriod}
            />
            <Body />
            <Buttons onStage={handleStageRequest} onPublish={handlePublishRequest} />
        </>
    );
}
