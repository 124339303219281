import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { getWithdrawalTransactionsPending,
         loadWithdrawalTransactionsPending,
        approveTransactionsForBatch,
        declineTransactionsForBatch } from "../../shared/store/slices/payouts";
import { Table } from 'antd';
import { ReactComponent as TableFlagIcon } from "../../assets/images/icon-table-flag.svg";
import { ReactComponent as TableFlagRedIcon } from "../../assets/images/icon-table-flag-red.svg";
import { ReactComponent as TableStarIcon } from "../../assets/images/icon-star.svg"
import FormatTime from '../../shared/helpers/FormatTime';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import moment from "moment";
import { getCustomerProfileByEmail, setCurrentCustomerProfile } from '../../shared/store/slices/customers';
import { useHistory } from 'react-router-dom';

function WithdrawalsTransactionsPendingPage(props) {

    TitleEffect(PageTitle("Withdrawal Queue | Security"));
    const { isLoading } = useSelector(state => state.userInterface);
    const { withdrawalsListChanged, withdrawalsTransactionsPending} = useSelector(state => state.payouts);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [searchParameter, setSearchParameter] = useState('email');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const docparameterStyle = {
        display: 'flex',
        alignItems: 'baseline'
    }

    const [withdrawalList, setWithdrawalList] = useState([]);

    const goToCustomerProfile = (realm, email) => {
        dispatch(getCustomerProfileByEmail({
            realm: realm || "wallet88",
            email: email
        }))
        .then((response) =>{
            dispatch(setCurrentCustomerProfile(response.payload));
            history.push("/customer/profile");
        });
    }

    const columns = [{
            className: 'th-legendlabel tdstyle transcell transtable tableicons',
            align: 'right',
            title: () => { return <TableStarIcon className="tablestar" /> },
            dataIndex: 'isVip',
            render: (text, record) => {
                return record.isVip === true ? <TableStarIcon className="tablestar" /> : null;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => {
                return (a.isVip < b.isVip) ? -1 :
                        (b.isVip < a.isVip ? 1 : 0)
            }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable tableicons',
            align: 'right',
            title: () => { return <TableFlagIcon className="tableflag" /> },
            dataIndex: 'isARedFlag',
            render: (text, record) => {
                return record.isARedFlag === true ? <TableFlagRedIcon className="tableflag" /> : null;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => {
                return (a.isARedFlag < b.isARedFlag) ? -1 :
                        (b.isARedFlag < a.isARedFlag ? 1 : 0)
            }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: '#',
            dataIndex: 'TransactionsCount',
            render: (text, record) => {
                return record.transactionsCount
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.transactionsCount - b.transactionsCount }
        },{
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Email',
            dataIndex: 'Email',
            render: (text, record) => {
                return <a href="#" className="emaillink" onClick={() => { goToCustomerProfile(record.realm, record.email); }}>{ record.email }</a>
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.email.localeCompare(b.email) }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Name',
            dataIndex: 'Name',
            render: (text, record) => {
                return <a href="#" className="emaillink" onClick={() => { goToCustomerProfile(record.realm, record.email); }}>{ record.name }</a>
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.name.localeCompare(b.name) }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Amount',
            dataIndex: 'Amount',
            render: (text, record) => {
                return record.formattedAmount;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.amount - b.amount }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Currency',
            dataIndex: 'Currency',
            render: (text, record) => {
                return record.currency;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.currency.localeCompare(b.currency) }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Method',
            dataIndex: 'Method',
            render: (text, record) => {
                return record.method;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.method.localeCompare(b.method) }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Country',
            dataIndex: 'Country',
            render: (text, record) => {
                return record.country;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.country.localeCompare(b.country) }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Timestamp',
            dataIndex: 'CreatedOn',
            render: (text, record) => {
                return FormatTime(record.timeStamp);
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (dateA,dateB) => { return moment(dateA.createdOn).diff(moment(dateB.createdOn)) }
        },
    ];

    const onSelectChange = (selecteds) => {
        setSelectedRowKeys(selecteds);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    const handleSearchParameter = (event) => {
        setSearchParameter(event.target.value);
    }

    const handleSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleFilter = () => {
        setIsLoadingData(true);
        filterList();
        setIsLoadingData(false);
    }

    const handleApprove = () => {
        if(selectedRowKeys && selectedRowKeys?.length > 0)
            dispatch(approveTransactionsForBatch({ transactionsId: selectedRowKeys }))
            .then((response => {
                batch(()=>{
                    loadData();
                })

            }));
    }

    const handleDecline = () => {
        if(selectedRowKeys && selectedRowKeys?.length > 0)
            dispatch(declineTransactionsForBatch({ transactionsId: selectedRowKeys }))
            .then((response => {
                batch(()=>{
                    loadData();
                })

            }));
    }

    const filterList = () => {
        if(searchTerm !== '') {
            setIsLoadingData(true);
            switch (searchParameter) {
                case 'email':
                    setWithdrawalList(withdrawalsTransactionsPending.filter(d => d.userEmail.indexOf(searchTerm) >= 0));
                    break;
                case 'ctry':
                    setWithdrawalList(withdrawalsTransactionsPending.filter(d => d.userCountry.indexOf(searchTerm) >= 0));
                    break;
                case 'name':
                    setWithdrawalList(withdrawalsTransactionsPending.filter(d => d.userName.indexOf(searchTerm) >= 0));
                    break;
            }
        } else {
            setWithdrawalList(withdrawalsTransactionsPending);
        }
    }

    const loadData = () => {
        setIsLoadingData(true);
        dispatch(getWithdrawalTransactionsPending())
        .then((response => {
            batch(()=>{
                dispatch(loadWithdrawalTransactionsPending(response.payload));
                setWithdrawalList(response.payload);
                setIsLoadingData(false);
            })

        }));
    }

    useEffect(()=> {
        loadData();
        filterList();
    }, [withdrawalsListChanged])

    return <div>
        <nav>
            <div id="docsearch">
                <div className="navcell docsearchbar">
                    <input type="text" id="transsearchbox" name="search" value={searchTerm} onChange={handleSearchTerm} />
                </div>
                <div className="navcell docparameter" style={docparameterStyle}>
                    <select name="parameter" id="parameter" value={searchParameter} onChange={handleSearchParameter}>
                        <option value="email">Email</option>
                        <option value="name">Name</option>
                        <option value="ctry">Country</option>
                    </select>
                </div>
                <div className="docsearchbutton">
                    <button className="buttonprimary primary navbutton" onClick={handleFilter}>Search</button>
                </div>
            </div>
        </nav>
        <main className="searchtransactions">
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                loading={isLoading || isLoadingData}
                rowKey={r => r.id}
                columns={columns}
                dataSource={withdrawalList}
                pagination={false}
                scroll={{x : "auto"}}
            />
            <div className="text-center"><p id="nomoretx">The end</p></div>
            <div  className="buttons-bottom">
				<div className="leftbuttons">
					<button className="buttonprimary primary accountbuttons" onClick={handleApprove}>Approve</button>
					<button className="buttonprimary primary accountbuttons" onClick={handleDecline}>Decline</button>
				</div>
				<div className="selecttotals"></div>
			</div>
        </main>
    </div>
}

export default WithdrawalsTransactionsPendingPage;
