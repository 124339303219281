import React from 'react';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import styles from '../assets/css/CurrencyAmountFilter.module.css';
import CurrencyInput from 'react-currency-input-field';

const { Option } = Select;

export default function CurrencyAmountFilter(props) {
  const { value, onChange } = props;
  
  function fireOnChange(event) {
    if (!onChange)
      return;

    onChange(event);
  }

  function handleOperationChange(event) {
    const nv = Object.assign({}, value, { operation: event })
    fireOnChange(nv);
  }

  function handleValueChange(val) {
    let nvalue = val * 100;
    const nv = Object.assign({}, value, { value: nvalue })
    fireOnChange(nv);
  }

  return (<div className={styles.AmountFilter}>
    <Select defaultValue="=" value={value ? value.operation : null} onChange={handleOperationChange}>
        <Option value="=">=</Option>
        <Option value="&gt;">&gt;</Option>
        <Option value="&lt;">&lt;</Option>
    </Select>
    <CurrencyInput
        id={"cur_ipt_amount_filter"}
        autoFocus={true}
        className="editintable" 
        onValueChange={handleValueChange}
        placeholder="Amount"
        decimalScale={2}
        allowNegativeValue={false}
        decimalSeparator="."
        disableGroupSeparators={true}
    />
  </div>);
}

