import React from "react";
import { useSelector } from 'react-redux';

export default function AvailableBalance(props) {
    const data = useSelector(state => state.gatewaySummary.summaryData);
    const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: data.currency.minorUnits ?? 2, maximumFractionDigits: data.currency.minorUnits ?? 2 });

    return <tr>
        <td>Available balance</td>

        <td>{ data.currency.symbol }{ formatter.format(Math.abs(data.available_balance) >= 0.01 ? data.available_balance : 0) }</td>
    </tr>
}
