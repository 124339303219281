import React from 'react';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import styles from './AmountFilter.module.css';

const { Option } = Select;

export default function AmountFilter(props) {
  const { value, onChange } = props;
  
  function fireOnChange(event) {
    if (!onChange)
      return;

    onChange(event);
  }

  function handleOperationChange(event) {
    const nv = Object.assign({}, value, { operation: event })
    fireOnChange(nv);
  }

  function handleValueChange(event) {
    const nv = Object.assign({}, value, { value: event.target.value })
    fireOnChange(nv);
  }

  return (<div className={styles.AmountFilter}>
    <Select defaultValue="=" value={value ? value.operation : null} onChange={handleOperationChange}>
      <Option value="=">=</Option>
      <Option value="&gt;">&gt;</Option>
      <Option value="&lt;">&lt;</Option>
    </Select>
    <input type="text" id="amount" name="amount" placeholder="Amount" value={value ? value.value : ""} onChange={handleValueChange} />
  </div>);
}