import React from "react";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../shared/userManager";
import { useHistory } from "react-router-dom";

export default function CallbackPage() {
    const history = useHistory();

    return <CallbackComponent
        userManager={userManager}
        successCallback={(user) => history.push(user.state.path)}
        errorCallback={() => history.push('/401')} >
        <div style={{ margin:"30px" }}>Redirecting...</div>
    </CallbackComponent>
}
