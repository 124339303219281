import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {FindMerchants, GetMerchants, GetMerchantTree} from '../../GatewayAPI';
import {startLoading, stopLoading} from "./userInterface";

export const MerchantSearchQuery = createAsyncThunk("merchants/query", async (queryOverride, { getState, dispatch, requestId }) => {
  dispatch(startLoading(requestId));
  const user = getState().oidc.user;
  const query = Object.assign({}, getState().merchants.query, queryOverride);
  const q = {
    page: query.page,
    page_size: query.feelingLucky ? 1 : 40
  };

  if (query.parameter && query.text) {
    q[query.parameter] = query.text;
  }
  const response = await FindMerchants(user, q)
    .finally(() => dispatch(stopLoading(requestId)));
  return response.data;
});

export const loadMerchants = createAsyncThunk(
    'merchants/loadMerchants',
    async (params, { rejectWithValue, dispatch, requestId, getState}) => {
      dispatch(startLoading(requestId));
      const user = getState().oidc.user;
      const response = await GetMerchants(user)
          .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
          .finally(() => dispatch(stopLoading(requestId)));
      return response.data;
    }
);

export const loadMerchantTree = createAsyncThunk(
    'merchant/loadMerchantTree',
    async (params, { rejectWithValue, dispatch, requestId, getState}) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        const response = await GetMerchantTree(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);


export const slice = createSlice({
    name: 'merchants',

    initialState: {
        query: {
            text: '',
            parameter: '',
            show: '',
            feelingLucky: false,
            page: 0
        },
        loading: false,
        previousQuery: null,
        list: [],
        tree: {},
        currentSite: {},
        currentMerchant: '',
        lastPayloadSize: 0
    },

    reducers: {
        reset: (state, action) => {
            state.query.feelingLucky = false;
        },
        resetList: (state, action) => {
            state.list = [];
            state.currentSite = {};
        },
        setText: (state, action) => {
            state.query.text = action.payload;
        },
        setParameter: (state, action) => {
            state.query.parameter = action.payload;
        },
        setShow: (state, action) => {
            state.query.show = action.payload;
        },
        setCurrentSite: (state, action) => {
            const id = action.payload;
            const site = state.list.filter(site => site.site_id == id);

            state.currentSite = site[0];
        },
        updateQuery: (state, action) => {
            state.query = action.payload;
        }
    },

    extraReducers: {
        [MerchantSearchQuery.pending]: (state, action) => {
            state.loading = true;
            state.previousQuery = Object.assign({}, state.query, action.meta.arg);
        },
        [MerchantSearchQuery.fulfilled]: (state, action) => {
            state.loading = false;
            let originalList = [];
            if (state.previousQuery.page != 0) {
                originalList = state.list;
            }

            state.lastPayloadSize = action.payload?.length;
            state.list = originalList.concat(action.payload);
        },
        [MerchantSearchQuery.rejected]: (state, action) => {
            state.loading = false;
        },
        [loadMerchants.fulfilled]: (state, action) => {
            state.merchants = action.payload;
        },
        [loadMerchants.rejected]: (state, action) => {
            state.merchants = ["Loading failed"];
        },
        [loadMerchantTree.fulfilled]: (state, action) => {
            state.tree = action.payload;
        },
        [loadMerchantTree.rejected]: (state, action) => {
            state.tree = ["Loading failed"];
        }
    }
});

export const { updateQuery, setText, setParameter, setShow, setCurrentSite, reset, resetList } = slice.actions;
export default slice.reducer;
