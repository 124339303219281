import Axios from "axios"

function Handler(promise) {
    return promise
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

function BuildConfig(contentType = "application/json; charset=utf8",
                     accept = "application/json",
                     user = null) {

    const headers = {
        'Content-Type': contentType,
        'Accept': accept
    };

    if (user && !user.expired) {
        const token = user.access_token;
        headers['Authorization'] = `Bearer ${token}`;
    }

    return {
        baseURL: "/api",
        headers: headers
    };
}

export const ApiGetRequestSync = async (url,
                                        user = null,
                                        params = {},
                                        accept = "application/json") => {
    const config = Object.assign({}, BuildConfig('', accept, user), {params: params});
    return Handler(Axios.get(url, config));
}

export const ApiPostRequestSync = async (url,
                                         user = null,
                                         data = {},
                                         contentType = "application/json; charset=utf8",
                                         accept = "application/json") => {
    const config = BuildConfig(contentType, accept, user);
    return Handler(Axios.post(url, data, config));
}

export const ApiPutRequestSync = async (url,
                                        user = null,
                                        data = {},
                                        contentType = "application/json; charset=utf8",
                                        accept = "application/json") => {
    const config = BuildConfig(contentType, accept, user);
    return Handler(Axios.put(url, data, config));
}

export const ApiDeleteAsync = async (url,
                                    user = null,
                                    contentType = "application/json; charset=utf8",
                                    accept = "application/json") => {
    const config = BuildConfig(contentType, accept, user);
    return Handler(Axios.delete(url, config));
}