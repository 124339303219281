import React, { useEffect } from 'react';
import MerchantSearchWidget from '../../../components/MerchantSearchWidget';
import {
    setType,
    setAmount,
    setCurrency,
    setReason,
    saveMerchantAdjustment
} from '../../../shared/store/slices/adjustments'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom";
import { setCurrentSite } from "../../../shared/store/slices/merchants";

export default function AdjustmentsPage() {
    const { mid, rid, sid } = useParams();
    const dispatch = useDispatch();
    const { type, amount, currency, reason } = useSelector(state => state.adjustments);
    const site = useSelector(state => state.merchants.currentSite);
    const history = useHistory();

    useEffect(() => {
        if (!site || !site.site_id) {
            history.push("/merchants");
        }
    }, [site]);

    function handleChangeType(event) {
        dispatch(setType(event.target.value));
    }

    function handleChangeAmount(event) {
        dispatch(setAmount(event.target.value));
    }

    function handleChangeReason(event) {
        dispatch(setReason(event.target.value));
    }

    function handleAdjust(event) {
        dispatch(saveMerchantAdjustment({
            sid: site.site_id,
            type: type,
            amount: amount,
            reason: reason
        }))
            .then(() => {
                history.push("/merchants");
            });
    }

    function handleCancel() {
        dispatch(setCurrentSite(undefined));
        history.push("/merchants");
    }

    return <>
        <nav><MerchantSearchWidget /></nav>
        <main className="pendingexchanges">
            <div className="merchantlegend">
                { /* Group: APG / */ }Merchant: { site?.merchant_name }<span className="midlabel1">MID:</span><span className="midlabel2">{ site?.merchant_id }</span>
                { " / Retailer: " }{ site?.retailer_name }<span className="midlabel1">RID:</span><span className="midlabel2">{ site?.retailer_id }</span>
                { " / Site: " }{ site?.site_name }<span className="midlabel1">SID:</span><span className="midlabel2">{ site?.site_id }</span>
            </div>
            <div className="adjustmentgrid">
                <div className="adjustmentgrid1">
                    <select id="adjustmenttype" value={ type } onChange={ handleChangeType }>
                        <option value="Credit">+</option>
                        <option value="Debit">-</option>
                    </select>
                </div>
                <div className="adjustmentgrid2">
                    <input id="adjustmentamount" placeholder="Enter amount" onChange={ handleChangeAmount }/><span className="adjustmentamount">{ site?.site_currency }</span>
                </div>
                <div className="adjustmentgrid3">
                    <select id="adjustmentreason" value={ reason } onChange={ handleChangeReason }>
                        <option value="" disabled>Reason</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className="adjustmentgrid4">
                    <button className={ "buttonprimary primary savecancel" + ((!amount || !reason) ? " inactive" : "" ) } disabled={ !amount || !reason } onClick={ handleAdjust }>Adjust</button>
                    <button className={ "buttonprimary secondary savecancel cancel" } onClick={ handleCancel }>Cancel</button>
                </div>
            </div>
        </main>
    </>
}
