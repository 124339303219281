import React from "react";

export default function AntdTable(props) {
    const { width, headers, data } = props;
    const widths = [];

    headers.map((header, idx) => {
        widths[idx] = header.width;

        if ((header.sticky == 'left') && headers[idx + 1] && (headers[idx + 1].sticky != 'left')) {
            header.last = true;
        }

        if ((header.sticky == 'right') && headers[idx - 1] && (headers[idx - 1].sticky != 'right')) {
            header.first = true;
        }

        if (!headers[idx + 1]) {
            header.lastcell = true;
        }
    });

    var leftOffset = 0;
    headers.map((header) => {
        if (header.sticky == 'left') {
            header.left = leftOffset;
        }
        leftOffset+= header.width;
    });

    var rightOffset = 0;
    headers.slice(0).reverse().map((header) => {
        if (header.sticky == 'right') {
            header.right = rightOffset;
        }
        rightOffset+= header.width;
    });

    const headerRow = headers.map((header, idx) => {
        const style = {};

        if (header.align) {
            style.textAlign = header.align;
        }

        if (header.sticky == 'left') {
            style.position = "sticky";
            style.left = `${header.left}px`;
        }

        if (header.sticky == 'right') {
            style.position = "sticky";
            style.right = `${header.right}px`;
        }

        return <th key={ `header_${idx}` }
            className={ "legendlabel tdstyle transtable ant-table-cell"
                + (header.sticky == 'left' ? ' ant-table-cell-fix-left' : '')
                + (header.last ? ' ant-table-cell-fix-left-last' : '')
                + (header.sticky == 'right' ? ' ant-table-cell-fix-right' : '')
                + (header.first ? ' ant-table-cell-fix-right-first' : '')
                + (header.lastcell ? ' lastcell' : '') }
            style={ style }>
            { header.title }
        </th>
    });

    const cols = widths.map((width, index) => width ? <col style={{ width:`${width}px` }} key={ `width_${index}` } /> : <col key={ `width_${index}` } />);
    const measureCells = widths.map((ignore, index) => <td style={{ padding: "0px", border: "0px", height: "0px" }} key={ `measureCell_${index}` }>
                                                         <div style={{ height: "0px", overflow: "hidden" }}>&nbsp;</div>
                                                       </td>);

    const dataRows = data.map((rowItem, rowIndex) => {
        const { key, data } = rowItem;

        const dataCols = data.map((colItem, colIndex) => {
            const style = {};

            if (headers[colIndex].align) {
                style.textAlign = headers[colIndex].align;
            }

            if (headers[colIndex].sticky == 'left') {
                style.position = "sticky";
                style.left = `${headers[colIndex].left}px`;
            }

            if (headers[colIndex].sticky == 'right') {
                style.position = "sticky";
                style.right = `${headers[colIndex].right}px`;
            }

            return <td key={ `${key}_${colIndex}` }
                className={ "tdstyle transcell ant-table-cell"
                    + (headers[colIndex].sticky == 'left' ? ' ant-table-cell-fix-left' : '')
                    + (headers[colIndex].last ? ' ant-table-cell-fix-left-last' : '')
                    + (headers[colIndex].sticky == 'right' ? ' ant-table-cell-fix-right' : '')
                    + (headers[colIndex].first ? ' ant-table-cell-fix-right-first' : '')
                    + (headers[colIndex].lastcell ? ' lastcell' : '')
                    + ` ${colItem.className}` }
                style={ style }>
                { colItem.children }
            </td>
        });

        return <tr key={ key } className="ant-table-row ant-table-row-level-0">
            { dataCols }
        </tr>
    });

    return <div className="ant-table-wrapper" style={{ padding:"0 0 20px 0" }}>
        <div className="ant-spin-nested-loading">
            <div className="ant-spin-container">
                <div className="ant-table ant-table-fixed-column ant-table-scroll-horizontal ant-table-has-fix-left ant-table-has-fix-right">
                    <div className="ant-table-container">
                        <div style={{ overflow: "auto hidden" }} className="ant-table-content">
                            <table className="tablestyle txdetailstable" style={{ width:!isNaN(width) ? `${width}px` : 'auto', minWidth: "100%", tableLayout:"fixed" }}>
                                <colgroup>
                                    { cols }
                                </colgroup>
                                <thead className="ant-table-head">
                                    <tr>
                                        { headerRow }
                                    </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                    <tr aria-hidden="true" className="ant-table-measure-row" style={{ height: "0px", fontSize: "0px" }}>
                                        { measureCells }
                                    </tr>
                                    { dataRows }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
