import React from 'react';
import ActivitySearchWidget from './ActivitySearchWidget';
import ActivityListing from './ActivityListing';

export default function AdminActivityPage() {
    return <>
        <nav><ActivitySearchWidget /></nav>
        <main>
            <ActivityListing />
        </main>
    </>
}
