import React, {useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Table } from 'antd';
import { setCurrentSite } from "../../../shared/store/slices/merchants";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"
import NoData from '../../../components/NoData';

function MerchantLink(row) {
  return `/merchants/${row.merchant_id}`;
}

function MerchantCell(value, row, index) {
  return (<><a target='blank' href={MerchantLink(row)}>{row.merchant_name}</a><span className="midlabel1">MID:</span><span className="midlabel2">{row.merchant_id}</span></>);
}

function RetailerLink(row) {
  return `/merchants/${row.merchant_id}/${row.retailer_id}`;
}

function RetailerCell(value, row, index) {
  return (<><a target='blank' href={RetailerLink(row)}>{row.retailer_name}</a><span className="midlabel1">RID:</span><span className="midlabel2">{row.retailer_id}</span></>);
}

function SiteLink(row) {
  return `/merchants/${row.merchant_id}/${row.retailer_id}/${row.site_id}`;
}

function SiteCell(value, row, index, onClick) {
  return (<><a href="" onClick={ onClick } data-sid={ row.site_id }>{row.site_name}</a><span className="midlabel1">SID:</span><span className="midlabel2">{row.site_id}</span></>);
}

function GatewayLink(row) {
  return `/gateways/${row.gateway_id}`;
}

function GatewayCell(value, row) {
  if (!row.gateway_name)
    return null;

  return (<><a target='blank' href={GatewayLink(row)}>{row.gateway_name}</a><span className="midlabel1">PGID:</span><span className="midlabel2">{row.gateway_id}</span></>);
}

function Truncate(str) {
  if (!str || str.length < 15)
    return str;

  return str.substring(0, 6) + '...' + str.substring(str.length - 4);
}

function DescriptorLink(row) {
  return `/gateways/${row.gateway_id}/${row.descriptor_id}`;
}

function DescriptorCell(value, row) {
  if (!row.descriptor)
    return null;

  return (<><a target='blank' href={DescriptorLink(row)}>{row.descriptor}</a><span className="midlabel1">TID:</span><span className="midlabel2">{Truncate(row.tid)}</span></>)
}

function PrepareData(dataSource) {
  const ds = dataSource.flatMap(m => {
    const mm = { key: m.site_id, ...m };
    if (!mm.gateways)
      return mm;

    return mm.gateways.flatMap(g => {
      const gm = { ...mm, key: mm.key + '_' + g.id, gateway_name: g.name, gateway_id: g.id };
      if (!g.descriptors)
        return gm;

      return g.descriptors.flatMap(d => {
        return { ...gm, key: gm.key + '_' + d.id, gateway_span: g.descriptors.length, descriptor_id: d.id, tid: d.tid, descriptor: d.descriptor, currency: d.currency };
      })
    });
  });

  ds.sort((o1, o2) => {
    const fields = ['merchant_name', 'merchant_id', 'retailer_name', 'retailer_id', 'site_name', 'site_id', 'gateway_name', 'gateway_id', 'descriptor', 'tid'];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const left = o1[field];
      const right = o2[field];
      if (left < right)
        return -1;

      if (left > right)
        return 1;
    }

    return 0;
  });

  return ds;
}

function ResultTable({ loading, dataSource, onLoadNextPage, onSiteClick, lastPayloadSize}) {
  const ds = PrepareData(dataSource);

  function MergeRows({render, rowComparer}) {
    function _count (row, index) {
      if (index >= 1 && rowComparer(row, ds[index - 1])) {
        return 0;
      }

      let i = 1;
      while (ds.length > index + i && rowComparer(row, ds[index + i])) {
        i++;
      }
      return i;
    }

    return function(value, row, index) {
      return {
        children: render(value, row, index),
        props: {
          rowSpan: _count(row, index)
        }
      }
    }
  }

  const columns = [{
    className: 'th-legendlabel tdstyle transcell transtable',
    title: 'Merchant',
    dataIndex: 'merchant_id',
    key: 'merchant_id',
    align: 'left',
    width: '12%',
    render: MergeRows({
      render: MerchantCell,
      rowComparer: (r1, r2) => r1.merchant_id == r2.merchant_id
    })
  }, {
    className: 'th-legendlabel tdstyle transcell transtable',
    title: 'Retailer',
    dataIndex: 'retailer_id',
    key: 'retailer_id',
    align: 'right',
    width: '15%',
    render: MergeRows({
      render: RetailerCell,
      rowComparer: (r1, r2) => r1.retailer_id == r2.retailer_id
    })
  }, {
    className: 'th-legendlabel tdstyle transcell transtable',
    title: 'Site',
    dataIndex: 'site_id',
    key: 'site_id',
    align: 'right',
    width: '25%',
    render: MergeRows({
      render: (value, row, index) => SiteCell(value, row, index, onSiteClick),
      rowComparer: (r1, r2) => r1.site_id == r2.site_id
    })
  }, {
    className: 'th-legendlabel tdstyle transcell transtable',
    title: 'Gateway',
    dataIndex: 'gateway_id',
    key: 'gateway_id',
    align: 'right',
    width: '15%',
    render: MergeRows({
      render: GatewayCell,
      rowComparer: (r1, r2) => r1.gateway_id && r2.gateway_id && r1.site_id == r2.site_id && r1.gateway_id == r2.gateway_id
    })
  }, {
    className: 'th-legendlabel tdstyle transcell transtable',
    title: 'Descriptor',
    dataIndex: 'descriptor_id',
    key: 'descriptor_id',
    align: 'right',
    width: '25%',
    render: DescriptorCell
  }, {
    className: 'th-legendlabel tdstyle transcell transtable lastcell',
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    align: 'right',
    width: '7%'
  }];

  useEffect(() => {
    var tableContent = document.querySelector('.ant-table-body');
    const handler = (evt) => {
      const table = evt.target;
      if (table.scrollTop + table.clientHeight > table.scrollHeight * 0.8) {
        if (!loading && lastPayloadSize > 0 && onLoadNextPage) {
          onLoadNextPage();
        }
      }
    };

    tableContent.addEventListener('scroll', handler, {passive: false});
    return () => tableContent.removeEventListener('scroll', handler);
  }, [loading]);

  return (<Table
    loading={loading && ds?.length <= 0}
    showHeader={!loading || ds?.length > 0}
    className="tablestyle"
    pagination={false}
    dataSource={ds}
    columns={columns}
    scroll={{ x: "auto", y: 'calc(100vh - 194px)'}}
  />);
}

function FeelingLuckyLink(show, row) {
  switch (show) {
    case 'sites':
      return SiteLink(row);

    case 'retailers':
      return RetailerLink(row);

    case 'merchants':
      return MerchantLink(row);
  }

  return null;
}

export default function SearchResults({ loading, feelingLucky, show, dataSource, onLoadNextPage}) {
  const dispatch = useDispatch();
  const hasResults = Array.isArray(dataSource) && dataSource?.length > 0;
  const history = useHistory();
  const { currentSite, lastPayloadSize } = useSelector(state => state.merchants);

  if (!loading && hasResults && feelingLucky) {
    const link = FeelingLuckyLink(show, dataSource[0]);
    if (link) {
      return (<Redirect to={link} />);
    }
  }

  useEffect(() => {
      if (currentSite && currentSite.merchant_id && currentSite.retailer_id && currentSite.site_id) {
          history.push(`/merchants/${currentSite.merchant_id}/${currentSite.retailer_id}/${currentSite.site_id}`);
      }
  }, [currentSite]);

  function handleSiteClick(event) {
      event.preventDefault();
      const sid = event.target.getAttribute('data-sid');
      dispatch(setCurrentSite(sid));
  }

  return <main className="searchtransactions">
    { hasResults
        ? <ResultTable loading={loading} dataSource={dataSource} onLoadNextPage={onLoadNextPage} onSiteClick={handleSiteClick} lastPayloadSize={lastPayloadSize} />
        : ( loading === false
            ? <NoData />
            : null
          )
    }
  </main>
}
