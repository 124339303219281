import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Exchanges, ExchangeApprove, ExchangeDecline } from '../../GatewayAPI.js';
import { startLoading, stopLoading } from './userInterface';


export const loadExchanges = createAsyncThunk(
    'exchanges/loadExchanges',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await Exchanges(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const exchangeApprove = createAsyncThunk(
    'exchanges/exchangeApprove',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await ExchangeApprove(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const exchangeDecline = createAsyncThunk(
    'exchanges/exchangeDecline',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await ExchangeDecline(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);


export const exchangesSlice = createSlice({
    name: 'exchanges',

    initialState: {
        exchanges: [],
        overlayContent: "",
        apiError: "",
        loading: undefined
    },

    reducers: {
        reset: (state) => {
            state.exchanges = [];
        },
        setOverlayContent: (state, action) => {
            state.overlayContent = action.payload;
        },
        setPostFxAmount: (state, action) => {
            state.exchanges[action.payload.index].postFxAmount = action.payload.value;
        },
        ready: (state) => {
            state.isReady = true;
        }
    },

    extraReducers: {
        [loadExchanges.pending]: (state) => {
            state.loading = true;
        },
        [loadExchanges.fulfilled]: (state, action) => {
            state.exchanges = action.payload || [];
            state.loading = false;
        },
        [loadExchanges.rejected]: (state, action) => {
            state.exchanges = [];
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { reset, setOverlayContent, setPostFxAmount, ready } = exchangesSlice.actions;

export default exchangesSlice.reducer;
