import React from "react";
import { ReactComponent as ButtonX } from '../../assets/images/button-x.svg';
function Overlay(_a) {
    var children = _a.children, onClose = _a.onClose, visible = _a.visible;
    if (!visible)
        return React.createElement(React.Fragment, null);
    return React.createElement("div", { id: "overlay" },
        React.createElement("div", null),
        React.createElement("div", { id: "closeoverlay", onClick: onClose },
            React.createElement(ButtonX, { id: "button-x" })),
        React.createElement("div", { id: "overlaycontent" }, children));
}
export default Overlay;
