import React from "react";
import Header from './Header';
import Buttons from './Buttons';
import AvailableBalance from './AvailableBalance';
import { useSelector } from 'react-redux';
import { isObject } from '../../shared/utils';
import css from './index.module.css';

export default function Body(props) {
    const { onAdjust, onSettle, onTransfer } = props;

    const data = useSelector(state => state.gatewaySummary.summaryData);
    const { isLoading } = useSelector(state => state.userInterface);
    const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: data?.currency?.minorUnits ?? 2, maximumFractionDigits: data?.currency?.minorUnits ?? 2 });

    const titles = {
        'opening_balance': "Opening Balance",
        'payments_in': "Payments In",
        'payments_out': "Payments Out",
        'adjustments_in': "Adjustments In",
        'adjustments_out': "Adjustments Out",
        'settlements_in': "Settlements In",
        'settlements_out': "Settlements Out",
        'transfers_in': "Transfers In (from other descriptor)",
        'transfers_out': "Transfers Out (to other descriptor)",
        'reserve': "Reserve"
    };

    return <main className={ `${css.gatewaySummary} wallet ${css.nopadding}` }>
        { isObject(data) && Object.entries(data).length

            ? <>
                <div className={ css.tablecontainer }>
                    <table style={{ width:"100%", borderSpacing:0 }}>
                        <tbody>
                            <Header />
                            { Object.entries(titles).map(([title, caption]) =>

                                <tr key={ `row_${title}` }>
                                    <td key={ `entry_${title}` }>{ titles[title] }</td>

                                    <td key={ `data_${title}` }>{ data.currency.symbol }{ formatter.format(data[title]) }</td>
                                </tr>)
                            }
                            <AvailableBalance />
                        </tbody>
                    </table>
                </div>
            </>

            : <div className={ css.noData }>
                { isLoading
                    ? "Loading..."
                    : "No data to show for the selected gateway, descriptor and period"
                }
            </div>
        }

        <Buttons onAdjust={ onAdjust } onSettle={ onSettle } onTransfer={ onTransfer } />
    </main>;
}
