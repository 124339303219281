import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import {
    getSetting,
    saveSetting
} from '../../../shared/store/slices/controlpanel'
import { useDispatch, useSelector } from 'react-redux';
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import MerchantSearchWidget from '../../../components/MerchantSearchWidget';
import CountryPicker from "../../../components/CountryPicker";


function titleForType(type) {
    switch (type) {
        case 'buynsend': return "Buy+Send";
        case 'deposits': return "Deposits";
        case 'withdrawals': return "Withdrawals";
        default: return "";
    }
}

function settingForType(type) {
    switch (type) {
        case 'buynsend': return "Buy+Send:EnabledCountries";
        case 'deposits': return "TransactionAuthorization:DepositEnabledCountries";
        case 'withdrawals': return "TransactionAuthorization:WithdrawalEnabledCountries";
        default: return "";
    }
}

export default function CountriesAllowed() {
    const { type } = useParams();

    TitleEffect(PageTitle("Countries Allowed "+titleForType(type)));

    const setting = settingForType(type);

    const [ countriesAllowed, setCountriesAllowed ] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSetting({ settingname: setting }))
            .then((response) => {
                const value = response?.payload?.value || "";
                const countries = value.split(",");

                setCountriesAllowed(countries);
            });
    }, [type]);

    function handleSave(countriesAllowed) {
        dispatch(saveSetting({ id: "countries-allowed", setting: { name: setting, value: countriesAllowed.join(",") }}));
    }

    return <>
        <nav><MerchantSearchWidget /></nav>
        <main>
            <CountryPicker selectedCountries={ countriesAllowed }
                onSave={ handleSave } saveButtonClassName="buttonprimary primary navbutton"
                showSaveButtonInAllMajorRegions={ true }/>
        </main>
    </>
}
