import React from 'react';
import TitleEffect from "./TitleEffect";
import PageTitle from "./PageTitle";

function NotFoundPage() {
    TitleEffect(PageTitle("NotFound"));

    return (<main className="not-found centeredcontent">
        <div>
            <h1>401 - Not Authorized</h1>
        </div>
    </main>);
}

export default NotFoundPage;