import moment from 'moment';

/**
 *
 * @param {Date|Moment} date 
 * @returns {string}
 */
export function FormatIso8601(date) {
  return date.toISOString().split('.')[0] + 'Z';
}

/**
 * @param {string} range
 * @returns {object} 
 */
export function DateRange(range) {
  const now = () => moment().utc();
  const startOfToday = () => now().startOf('day');
  const startOfCurrentMonth = () => now().startOf('month');
  switch(range) {
    case 'today':
      return { type: range, start: startOfToday(), end: startOfToday().add(1, 'd') };

    case 'yesterday':
      return { type: range, start: startOfToday().add(-1, 'd'), end: startOfToday() };

    case 'last7':
      return { type: range, start: startOfToday().add(-6, 'd'), end: startOfToday().add(1, 'd') };

    case 'last14':
      return { type: range, start: startOfToday().add(-13, 'd'), end: startOfToday().add(1, 'd') };

    case 'last30':
      return { type: range, start: startOfToday().add(-29, 'd'), end: startOfToday().add(1, 'd') };

    case 'last90':
      return { type: range, start: startOfToday().add(-89, 'd'), end: startOfToday().add(1, 'd') };

    case 'currentMonth':
      return { type: range, start: startOfCurrentMonth(), end: startOfCurrentMonth().add(1, 'M') };

    case 'lastMonth':
      return { type: range, start: startOfCurrentMonth().add(-1, 'M'), end: startOfCurrentMonth() };

    case 'last3Months':
      return { type: range, start: startOfCurrentMonth().add(-2, 'M'), end: startOfCurrentMonth().add(1, 'M') };

    default: 
      return { type: range, start: this, end: this};
  }
}