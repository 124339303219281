import React from "react";
import { ReactComponent as TableYesIcon } from "../../assets/images/icon-table-yes.svg";
import { ReactComponent as TableNoIcon } from "../../assets/images/icon-table-no.svg";
function RiskIcon(_a) {
    var value = _a.value;
    switch (value) {
        case "fraud":
            return React.createElement(TableNoIcon, { className: "tableno" });
        case "accepted":
            return React.createElement(TableYesIcon, { className: "tableyes" });
        default:
            return React.createElement(React.Fragment, null);
    }
}
export default RiskIcon;
