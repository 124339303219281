import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { getApprovedBatches, 
         loadApprovedBatches, 
         sendBatch} from "../../shared/store/slices/payouts";
import { Table } from 'antd';
import FormatTime from '../../shared/helpers/FormatTime';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import moment from "moment";
import NoData from '../../components/NoData';


function ApprovedBatches(props) {

    TitleEffect(PageTitle("Withdrawal Queue | Accounting "));
    const { isLoading } = useSelector(state => state.userInterface);
    const { approvedBatchesList } = useSelector(state => state.payouts);
    const dispatch = useDispatch();

    const [isLoadingData, setIsLoadingData] = useState(true);
    const [searchParameter, setSearchParameter] = useState('method');
    const [searchTerm, setSearchTerm] = useState('');

    const docparameterStyle = {
        display: 'flex',
        alignItems: 'baseline'
    }

    const [batchesList, setBatchesList] = useState([]);

    const columns = [{ 
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Timestamp', 
            dataIndex: 'CreatedOn',
            render: (text, record) => {
                return FormatTime(record.checkedAtDate);
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (dateA,dateB) => { return moment(dateA.checkedAtDate).diff(moment(dateB.checkedAtDate)) }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Method', 
            dataIndex: 'Method',
            render: (text, record) => {
                return record.method;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.method.localeCompare(b.method) }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Amount',
            dataIndex: 'Amount',
            render: (text, record) => {
                return record.formattedAmount;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.amount - b.amount }
        }, { 
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Currency', 
            dataIndex: 'Currency',
            render: (text, record) => {
                return record.currency;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => { return a.currency.localeCompare(b.currency) }
        }, { 
            className: 'th-legendlabel tdstyle transtable transcell lastcell tableicons',
            align: 'center',
            title: '',
            render: (text, record) => {
                return <>
                    <Button 
                        classes={"buttonprimary secondary navbutton tablebutton"} 
                        TEXT={"View"} 
                        onClick={() => { handleViewTransactions(record.id) }} />
                    {record.isApproved == true ? 
                    <Button 
                        classes={"buttonprimary primary navbutton tablebutton"} 
                        TEXT={"Send"} 
                        onClick={() => { handleSend(record.id) }} /> : ''}
                </>;
            }
        }
    ];

    const handleSearchParameter = (event) => {
        setSearchParameter(event.target.value);
    }

    const handleSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleFilter = () => {
        setIsLoadingData(true);
        filterList();
        setIsLoadingData(false);
    }

    const handleSend = (batchTransactionsId) => {
        if(batchTransactionsId !== null && 
           batchTransactionsId !== undefined) {
            setIsLoadingData(true);
            dispatch(sendBatch({ 
                batchId: batchTransactionsId
            })).then((response => {
                batch(()=>{
                    setIsLoadingData(false);
                    loadData();
                });
            })).catch(() => {
                setIsLoadingData(false);
            });
        }
    }

    const handleViewTransactions = (batchTransactionsId) => {
        const url = `/accounting/batch-transactions/${batchTransactionsId}`;
        window.open(url, '_blank');
    }

    const filterList = () => {
        if(searchTerm !== '') {
            setIsLoadingData(true);
            switch (searchParameter) {
                case 'currency':
                    setBatchesList(approvedBatchesList.filter(d => d.currency.indexOf(searchTerm) >= 0));
                    break;
                case 'method':
                    setBatchesList(approvedBatchesList.filter(d => d.method.indexOf(searchTerm) >= 0));
                    break;
            }
        } else {
            setBatchesList(approvedBatchesList);
        }
    }

    const loadData = () => {
        setIsLoadingData(true);
        dispatch(getApprovedBatches())
        .then((response => {
            batch(()=>{
                dispatch(loadApprovedBatches(response.payload));
                setBatchesList(response.payload);
                setIsLoadingData(false);
            })
            
        }));
    }

    useEffect(()=> {
        loadData();
        filterList();
    }, [])

    return <div>
        <nav>
            <div id="docsearch">
                <div className="navcell docsearchbar">
                    <input type="text" id="transsearchbox" name="search" value={searchTerm} onChange={handleSearchTerm} />
                </div>
                <div className="navcell docparameter" style={docparameterStyle}>
                    <select name="parameter" id="parameter" value={searchParameter} onChange={handleSearchParameter}>
                        <option value="currency">Currency</option>
                        <option value="method">Method</option>
                    </select>
                </div>
                <div className="docsearchbutton">
                    <button className="buttonprimary primary navbutton" onClick={handleFilter}>Search</button>
                </div>
            </div>
        </nav>
        <main className="searchtransactions">
            { batchesList?.length
                ? <>
                    <Table 
                        loading={isLoading || isLoadingData} 
                        rowKey={r => r.id} 
                        columns={columns} 
                        dataSource={batchesList} 
                        pagination={false}
                        scroll={{x : "auto"}}
                    />
                    <div className="text-center"><p id="nomoretx">The end</p></div>
                </>
                : ( isLoadingData === false
                    ? <NoData />
                    : null
                  )
            }
        </main>
    </div>
}

export default ApprovedBatches;
