import React from "react";
import { isArray } from "../../shared/utils";

export default function Section(props) {
    const { subSections, id, currency, totalFn } = props;
    
    let dataRows = [];
    let subTotal = [];
    let total = 0;
    let amount = 0;

    for (var i=0; i<subSections.length; i++) {
        let subSection = subSections[i];

        dataRows[i] = [];
        subTotal[i] = 0;

        if (isArray(subSection.data.rows)) {
            for (var j=0; j<subSection.data.rows.length; j++) {
                let row = subSection.data.rows[j];

                dataRows[i].push(<tr className={j % 2 === 1 ? "grey" : ""} key={`${id}_row_${i}_${j}`}>
                    <td>{row.description}</td>
                    <td>
                        {((typeof row.rateFee == "string") || (typeof row.rateFee == "undefined"))
                            ? row.rateFee
                            : row.rateFee.toFixed(2) + " " + currency
                        }
                    </td>
                    <td>{row.txCount}</td>
                    <td>
                        {row.message ? row.message : (amount = subSection.amountFn(row)) !== undefined ? amount.toFixed(2) + " " + currency : null}
                    </td>
                    <td></td>
                </tr>);

                subTotal[i] = subSection.subTotalFn(subTotal[i], row);
            }
        }
    }

    return (
        <div id={id} className="statementSection">

            { subSections.map((subSection, index) =>
                <div className="statementSubsection" key={`${id}_subsection_${index}`}>
                    <div className="statementHeader"><h2>{subSection.title}</h2></div>

                    <table className="statementSectionTable">
                        {subSection.data.summary !== undefined
                            ? <thead>
                                <tr className="sectionHeader">
                                    <td>{subSection.data.summary.description}</td>
                                    <td>{subSection.data.summary.rateFee}</td>
                                    <td>{subSection.data.summary.txCount}</td>
                                    <td className={ (amount = Number.parseFloat(subSection.data.summary.amount)) < 0 ? "blue negative" : "blue" }>{ amount.toFixed(2) } { currency }</td>
                                    <td></td>
                                </tr>
                                <tr className="spacer">
                                    <td colSpan="5">&nbsp;</td>
                                </tr>
                            </thead>
                            : null
                        }
                        <tbody>
                            {dataRows[index]}
                            {subTotal[index] > 0
                                ? <>
                                    <tr className="subSectionTotal">
                                        <td colSpan="3">{subSection.subTotalTitle}</td>
                                        <td className="blue">{subTotal[index].toFixed(2)} {currency}</td>
                                        <td></td>
                                    </tr>
                                    <tr className="spacer">
                                        <td colSpan="5">&nbsp;</td>
                                    </tr>
                                </>
                                : null
                            }
                            {index === subSections.length - 1   //Last subsection
                                ? <tr className="sectionTotal">
                                    <td colSpan="4">{subSection.totalTitle}</td>
                                    <td className={(total = totalFn(subTotal)) < 0 ? "blue negative" : "blue"}>{total.toFixed(2)} {currency}</td>
                                </tr>
                                : null
                            }
                        </tbody>
                    </table>
                </div>
            ) }
        </div>
    );
}
