import React, { useEffect, useState } from 'react';
import { Col, InputNumber, Row, Slider, Space } from 'antd';

import {
    saveSetting,
    reset,
    getSetting
} from '../../../shared/store/slices/controlpanel'
import { useDispatch, useSelector } from 'react-redux';
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import MerchantSearchWidget from '../../../components/MerchantSearchWidget';

export default function WalletAppSettings() {
    TitleEffect(PageTitle("Wallet App Settings"));

    const [checkUserProgressInterval, setCheckUserProgressInterval] = useState(0);
    const [imageConversionQuality, setImageConversionQuality] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSetting({ settingname: 'App:CheckUserProgressInterval' }))
            .then((response) => {
                if(response?.payload !== null && response?.payload !== undefined)
                    setCheckUserProgressInterval(parseInt(response.payload.value));
            });  
        
        dispatch(getSetting({ settingname: 'App:ImageConversionQuality' }))
            .then((response) => {
                if(response?.payload !== null && response?.payload !== undefined)
                    setImageConversionQuality(parseFloat(response.payload.value));
            });  
    }, []);
    
    const saveTimer = (setting) => { 
        clearTimeout(saveTimer);
        setTimeout(() => {
            dispatch(saveSetting({ id: "wallet-settings", setting: setting })).then(() => {
                dispatch(reset);
            });
        }, 2500)
    };

    const handleChangeCheckUserProgressInterval = (value) => {
        setCheckUserProgressInterval(value);
        saveTimer({ 
            name: 'App:CheckUserProgressInterval', 
            value: value.toString()
        });
    }

    const handleChangeImageConversionQuality = (value) => {
        setImageConversionQuality(value);      
        saveTimer({ 
            name: 'App:ImageConversionQuality', 
            value: value.toString()
        });
    }

    return <>
        <nav><MerchantSearchWidget /></nav>
        <main>    
            <div style={{"textAlign": "left"}}>
                <h1 className="controlpanelheader">Control panel</h1>
                <div className="controlpanel">
                    <div className="controlpanellabel">Check User Progress Interval (seconds)</div>
                    <div>
                        <InputNumber min={0}
                                    max={3600}
                                    onChange={handleChangeCheckUserProgressInterval}
                                    value={checkUserProgressInterval} />
                    </div>
                </div>
                <div className="controlpanel">
                    <div className="controlpanellabel">Image Conversion Quality</div>
                    <div>
                        <InputNumber min={0}
                                    max={10}
                                    onChange={handleChangeImageConversionQuality}
                                    value={imageConversionQuality} 
                                    step={0.1} />
                    </div>
                </div>
            </div>
        </main>
    </>
}