import React from "react";
import Section from "./Section";
import AdjustmentSection from "./AdjustmentSection";
import Total from './Total';
import { noop, isObject, isArray } from '../../shared/utils';
import { useSelector } from 'react-redux';

export default function Body(props) {
    const data = useSelector(state => state.periodStatement.periodData);
    const { sites, site } = useSelector(state => state.periodStatement);
    const currency = site ? sites[site].currency : '';
    const adjustments = data.adjustments || [];

    const transactionSummary = data?.summary ? data.summary.transactionSummary : {};
    const reserves = data?.summary ? data.summary.reserves : {};
    const transactionFees = data?.summary ? data.summary.transactionFees : {};
    const miscellaneousFees = data?.summary ? data.summary.miscellaneousFees : {};

    var transactionSummaryTotal = 0;
    var transactionFeesTotal = 0;
    var miscFeesTotal = 0;
    var adjustmentsTotal = 0;
    var reservesTotal = 0;

    function getStatementTotal() {
        return transactionSummaryTotal + transactionFeesTotal + miscFeesTotal + adjustmentsTotal + reservesTotal;
    }

    const calc = {  //Calculator functions (for amounts, subtotals and totals)
        transactionSummary: {
            amount:   row => Number.parseFloat(row.amount),
            subTotal: (subTotal, row) => subTotal+= Number.parseFloat(row.amount),
            total:    subTotals => (transactionSummaryTotal = ((transactionSummary.summary && transactionSummary.summary.amount) || 0) - subTotals.reduce((total, subTotal) => total + subTotal)),
        },
        transactionFees: {
            amount:   row => {
                if (typeof row.rateFee == "undefined") {
                    return undefined;
                }
                else {
                    const matches = row.rateFee.toString().match(/(\d+(\.\d+)?)%/);

                    if (matches) {
                        return (matches[1] / 100) * transactionSummaryTotal;
                    }
                    else {
                        return row.rateFee * row.txCount;
                    }
                }
            },
            subTotal: (subTotal, row) => {
                let amount = calc.transactionFees.amount(row);

                return subTotal+= amount !== undefined ? amount : 0;
            }
        },
        miscFees: {
            amount:   row => Number.parseFloat(row.amount),
            subTotal: (subTotal, row) => subTotal+= Number.parseFloat(row.amount || 0),
            total:    subTotals => (miscFeesTotal = -subTotals.reduce((total, subTotal) => total + (!isNaN(subTotal) ? subTotal : 0))),
        },
        adjustment: {
            amount:   row => Number.parseFloat(row.amount),
            total:    subTotals => (adjustmentsTotal = subTotals.reduce((total, subTotal) => total + subTotal)),
        },
        reserve: {
            amount:   row => Number.parseFloat(row.amount),
            subTotal: (subTotal, row) => subTotal+= Number.parseFloat(row.amount || 0),
            total:    subTotals => (reservesTotal = reserves.rows[0].amount - subTotals.reduce((total, subTotal) => total + subTotal)),
        }
    }

    let summarySubSections = [];
    let showSummary = isObject(transactionSummary)
                      && (isObject(transactionSummary.summary) || isArray(transactionSummary.rows));

    if (showSummary) {
        summarySubSections.push({
            title: "Transaction Summary",
            data: transactionSummary,
            subTotalTitle: "Total Outgoing Payments",
            totalTitle: "Net Transaction Amount",
            amountFn: calc.transactionSummary.amount,
            subTotalFn: calc.transactionSummary.subTotal
        });
    }

    let feesSubSections = [];

    let showTransactionFees = isObject(transactionFees) && isArray(transactionFees.rows);
    let showMiscellaneousFees = isObject(miscellaneousFees) && isArray(miscellaneousFees.rows);

    let showFees = showTransactionFees || showMiscellaneousFees;

    if (showFees) {
        if (showTransactionFees) {
            feesSubSections.push({
                title: "Transaction Fees",
                data: transactionFees,
                subTotalTitle: "Total Transaction Fees",
                amountFn: calc.transactionFees.amount,
                subTotalFn: calc.transactionFees.subTotal,
            });
        }

        if (showMiscellaneousFees) {
            feesSubSections.push({
                title: "Miscellaneous Fees",
                data: miscellaneousFees,
                subTotalTitle: "Total Misc. Fees",
                totalTitle: "Total Fees",
                amountFn: calc.miscFees.amount,
                subTotalFn: calc.miscFees.subTotal,
            });
        }
    }


    let reservesSubSections = [];
    let showReserves = isObject(reserves) && isArray(reserves.rows);

    if (showReserves) {
        reservesSubSections.push({
            title: "Reserves (180 days)",
            data: reserves,
            subTotalTitle: "Closing reserves",
            totalTitle: "Change in reserves",
            amountFn: calc.reserve.amount,
            subTotalFn: calc.reserve.subTotal
        });
    }

    return <main className="periodStatement">
        <div id="statementtablelegend">
            <div className="desc cell borderright legendlabel">Description</div>
            <div className="cell borderright legendlabel">Rate/Fee</div>
            <div className="cell borderright legendlabel">TX Count</div>
            <div className="cell borderright legendlabel">Amount</div>
            <div className="cell legendlabel">Total</div>
        </div>

        { showSummary ? <Section id="statementtranssummary" currency={ currency }
            totalFn={ calc.transactionSummary.total }

            subSections={ summarySubSections }
        /> : null }

        { showFees ? <Section id="statementfees" currency={ currency }
            totalFn={ calc.miscFees.total }

            subSections={ feesSubSections }
        /> : null }

        <AdjustmentSection id="statementadjustments" currency={ currency }
            totalFn={ calc.adjustment.total }

            subSections={[{
                title: "Adjustments",
                data: adjustments,
                totalTitle: "Total Adjustments",
                amountFn: calc.adjustment.amount,
            }]}
        />

        { showReserves ? <Section id="statementreserves" currency={ currency }
            totalFn={ calc.reserve.total }

            subSections={ reservesSubSections }
        /> : null }

        <Total title="Statement total:" currency={ currency } total={ getStatementTotal }/>
    </main>;
}
