import React from "react";
import { useSelector } from 'react-redux';

export default function Header() {
    const data = useSelector(state => state.gatewaySummary.summaryData);
    const { gateway, gateways, descriptor, descriptors } = useSelector(state => state.gatewaySummary);

    const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: data.currency.minorUnits, maximumFractionDigits: data.currency.minorUnits });

    return <tr>
        <th colSpan="2">
            { gateways && gateways[gateway]
                ? <div><h1>{ gateways[gateway] }</h1> ({ data.currency.symbol }{ formatter.format(data.gateway_total) })</div>
                : null
            }
            { descriptors && descriptors[descriptor]
                ? <div><h1>{ descriptors[descriptor] }</h1> ({ data.currency.symbol }{ formatter.format(data.descriptor_total) })</div>
                : null
            }
        </th>
    </tr>
}
