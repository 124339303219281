import { createSlice } from '@reduxjs/toolkit'

export const userInterfaceSlice = createSlice({
    name: 'userInterface',

    initialState: {
        isLoading: false,
        loadingRequests: {},
        currentView: '',
        overlay: '',
        modalTitle: '',
        modalContent: '',
        runningCriticalProcess: false
    },

    reducers: {
        switchView: (state, action) => {
            state.currentView = action.payload;
        },
        startLoading: (state, action) => {
            state.loadingRequests[action.payload] = action.payload;
            state.isLoading = Object.entries(state.loadingRequests).length > 0;
        },
        stopLoading: (state, action) => {
            delete state.loadingRequests[action.payload];
            state.isLoading = Object.entries(state.loadingRequests).length > 0;
        },
        showOverlay: (state, action) => {
            state.overlay = action.payload;
        },
        hideOverlay: (state, action) => {
            state.overlay = '';
        },
        setRunningCriticalProcess: (state, action) => {
            state.runningCriticalProcess = action.payload;
        },
        setModalTitle: (state, action) => {
            state.modalTitle = action.payload;
        },
        setModalContent: (state, action) => {
            state.modalContent = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { switchView, startLoading, stopLoading, showOverlay, hideOverlay, setRunningCriticalProcess, setModalTitle, setModalContent } = userInterfaceSlice.actions;

export default userInterfaceSlice.reducer;
