import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    GetWithdrawalTransactionsPending,
    GetWithdrawalBatchesForApproval,
    ApproveTransactionsForBatch,
    DeclineTransactionsForBatch,
    SplitBatch,
    ViewBatchTransations,
    ApproveBatch,
    GetApprovedBatches,
    SendBatch,
    GetWithdrawalBatchTransactionsHistory,
    GetAvailableCurrencies,
    GetBatchesMethodsList,
    GetAvailableMethods,
    GetApprovedByList,
    GetSentByList
} from "../../../domain/services/payouts.svc";
import {
    GetLiveBatches,
    FetchLiveBatch,
    CloseLiveBatch,
    DownloadLiveBatch,
    MarkLiveBatchSent,
    UploadLiveBatchSettleFile,
    UploadReturnFile
} from '../../GatewayAPI';
import { startLoading, stopLoading } from './userInterface';

export const getWithdrawalTransactionsPending = createAsyncThunk(
    'payouts/getWithdrawalTransactionsPending',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await GetWithdrawalTransactionsPending(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const approveTransactionsForBatch = createAsyncThunk(
    'payouts/approveTransactionsForBatch',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await ApproveTransactionsForBatch(user, params.transactionsId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const declineTransactionsForBatch = createAsyncThunk(
    'payouts/declineTransactionsForBatch',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await DeclineTransactionsForBatch(user, params.transactionsId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getWithdrawalBatchesForApproval = createAsyncThunk(
    'payouts/getWithdrawalBatchesForApproval',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await GetWithdrawalBatchesForApproval(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const splitBatch = createAsyncThunk(
    'payouts/splitBatch',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await SplitBatch(user, params.batchId, params.splitAmount)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getBatchTransactions = createAsyncThunk(
    'payouts/getBatchTransactions',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await ViewBatchTransations(user, params.batchId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const approveBatch = createAsyncThunk(
    'payouts/approveBatch',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await ApproveBatch(user, params.batchId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getApprovedBatches = createAsyncThunk(
    'payouts/getApprovedBatches',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await GetApprovedBatches(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const sendBatch = createAsyncThunk(
    'payouts/sendBatch',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await SendBatch(user, params.batchId)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getWithdrawalBatchTransactionsHistory = createAsyncThunk(
    'payouts/getWithdrawalBatchTransactionsHistory',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await GetWithdrawalBatchTransactionsHistory(user, params.filters)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getAvailableCurrencies = createAsyncThunk(
    'batchHistorySearch/getAvailableCurrencies',
    async (params, { getState, rejectWithValue }) => {
        const user = getState().oidc.user;
        return await GetAvailableCurrencies(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) });
    }
);

export const getAvailableMethods = createAsyncThunk(
    'transactionWalletSearch/getAvailableMethods',
    async (params, { getState, rejectWithValue }) => {
        const user = getState().oidc.user;
        return await GetAvailableMethods(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) });
    }
);

export const getAvailableBatchesMethods = createAsyncThunk(
    'batchHistorySearch/getAvailableMethods',
    async (params, { getState, rejectWithValue }) => {
        const user = getState().oidc.user;
        return await GetBatchesMethodsList(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) });
    }
);

export const getApprovedByList = createAsyncThunk(
    'batchHistorySearch/getApprovedByList',
    async (params, { getState, rejectWithValue }) => {
        const user = getState().oidc.user;
        return await GetApprovedByList(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) });
    }
);

export const getSentByList = createAsyncThunk(
    'batchHistorySearch/getSentByList',
    async (params, { getState, rejectWithValue }) => {
        const user = getState().oidc.user;
        return await GetSentByList(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) });
    }
);

export const getLiveBatches = createAsyncThunk(
    'payouts/getLiveBatches',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        const response = await GetLiveBatches(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);

export const fetchLiveBatch = createAsyncThunk(
    'payouts/fetchLiveBatch',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        const response = await FetchLiveBatch(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);

export const closeLiveBatch = createAsyncThunk(
    'payouts/closeLiveBatch',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        const response = await CloseLiveBatch(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);

export const downloadLiveBatch = createAsyncThunk(
    'payouts/downloadLiveBatch',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        const response = await DownloadLiveBatch(user, params)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', response.headers['x-suggested-filename']);
                link.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);

export const markLiveBatchSent = createAsyncThunk(
    'payouts/markLiveBatchSent',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        const response = await MarkLiveBatchSent(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);

export const uploadLiveBatchSettleFile = createAsyncThunk(
    'payouts/uploadLiveBatchSettleFile',
    async (formData, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        const response = await UploadLiveBatchSettleFile(user, formData)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);

export const uploadReturnFile = createAsyncThunk(
    'payouts/uploadReturnFile',
    async (formData, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        const response = await UploadReturnFile(user, formData)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
        return response.data;
    }
);

export const payoutsSlice = createSlice({
    name: 'payouts',
    initialState: {
        withdrawalsTransactionsPending: [],
        withdrawalsBatchesForApproval: [],
        withdrawalsListChanged: [],
        batchTransactions: [],
        approvedBatchesList: [],
        batchTransactionsHistory: [],
        filters: {
            dateRange: {
                type: "",
                from: null,
                to: null
            },
            amount: {
                operation: null,
                value: null
            },
            currencies: [],
            methods: [],
            approvedby: [],
            sentby: []
        },
        currenciesList: [],
        availableMethodsList: [],
        methodsList: [],
        approvedByList: [],
        sentByList: [],
        liveBatches: [],
        liveBatch: [],
        liveBatchSettleFile: "",
        loading: false,
        advancedFilterVisible: false,
        working: {}
    },

    reducers: {
        loadWithdrawalTransactionsPending: (state, action) => {
            state.withdrawalsTransactionsPending = action.payload;
        },
        loadWithdrawalsBatchesForApproval: (state, action) => {
            state.withdrawalsBatchesForApproval = action.payload;
        },
        loadBatchTransactions: (state, action) => {
            state.batchTransactions = action.payload;
        },
        loadApprovedBatches: (state, action) => {
            state.approvedBatchesList = action.payload;
        },
        updateDateRange: (state, action) => {
            state.filters.dateRange = action.payload
        },
        updateAdvancedFilter: (state, action) => {
            const visible = typeof action.payload == 'boolean' ? action.payload : !state.advancedFilterVisible;
            state.advancedFilterVisible = visible;
            if (visible) {
                state.working = Object.assign(state.working, state.filters);
            }
        },
        updateWorking: (state, action) => {
            state.working = Object.assign(state.working, action.payload);
        },
        applyWorking: (state, action) => {
            state.filters = Object.assign(state.filters, state.working);
        },
        clearLiveBatch: (state) => {
            state.liveBatch = [];
        },
        setLiveBatchSettleFile: (state, action) => {
            state.liveBatchSettleFile = action.payload;
        }
    },

    extraReducers: {
        [getWithdrawalBatchTransactionsHistory.pending]: (state, action) => {
            if (action.meta.arg.page === 0) {
                state.batchTransactionsHistory = [];
            }

            state.loading = true;
        },
        [getWithdrawalBatchTransactionsHistory.fulfilled]: (state, action) => {
            state.batchTransactionsHistory = action.payload;
            state.loading = false;
        },
        [getWithdrawalBatchTransactionsHistory.rejected]: (state, action) => {
            state.loading = false;
        },
        [getAvailableCurrencies.fulfilled]: (state, action) => {
            state.currenciesList = action.payload;
        },
        [getAvailableMethods.fulfilled]: (state, action) => {
            state.availableMethodsList = action.payload;
        },
        [getAvailableBatchesMethods.fulfilled]: (state, action) => {
            state.methodsList = action.payload;
        },
        [getApprovedByList.fulfilled]: (state, action) => {
            state.approvedByList = action.payload;
        },
        [getSentByList.fulfilled]: (state, action) => {
            state.sentByList = action.payload;
        },
        [getLiveBatches.fulfilled]: (state, action) => {
            state.liveBatches = action.payload;
        },
        [getLiveBatches.rejected]: (state) => {
            state.liveBatches = [];
        },
        [fetchLiveBatch.fulfilled]: (state, action) => {
            state.liveBatch = action.payload;
        },
        [fetchLiveBatch.rejected]: (state) => {
            state.liveBatch = [];
        }
    }
})

// Action creators are generated for each case reducer function
export const { loadWithdrawalTransactionsPending,
               loadWithdrawalsBatchesForApproval,
               loadBatchTransactions,
               loadApprovedBatches,
               updateDateRange,
               updateAdvancedFilter,
               updateWorking,
               applyWorking,
               clearLiveBatch,
               setLiveBatchSettleFile } = payoutsSlice.actions;

export default payoutsSlice.reducer;
