import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { comparePeriods, getPrevPeriod, getNextPeriod } from '../../utils.js';
import { GetGateways, GetDescriptors, GetGatewaySummary } from '../../GatewayAPI.js';
import { startLoading, stopLoading } from './userInterface';


export const loadGateways = createAsyncThunk(
    'gatewaySummary/gateways',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const response = await GetGateways(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const loadDescriptors = createAsyncThunk(
    'gatewaySummary/descriptors',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        params.gatewayId = Number.parseInt(params.gatewayId?.replace("pgid", ""));
        const response = await GetDescriptors(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);

export const loadGatewaySummary = createAsyncThunk(
    'gatewaySummary/load',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        params.gatewayId = Number.parseInt(params.gatewayId?.replace("pgid", ""));
        params.descriptorId = Number.parseInt(params.descriptorId?.replace("plid", ""));
        const response = await GetGatewaySummary(user, params)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
		return response.data;
    }
);



export const gatewaySummarySlice = createSlice({
    name: 'accountSummary',

    initialState: {
        period: {
            'year': (new Date()).getUTCFullYear().toString(),
            'month': ((new Date()).getUTCMonth() + 1).toString().padStart(2, '0')
        },
        periodMin: {
            'year': "2019",
            'month': "01"
        },
        periodMax: {
            'year': (new Date()).getUTCFullYear().toString(),
            'month': ((new Date()).getUTCMonth() + 1).toString().padStart(2, '0')
        },
        isReady: false,
        gateways: {},
        gateway: "",
        descriptors: {},
        descriptor: "",
        summaryData: {},
        process: "",
        apiError: ""
    },

    reducers: {
        changePeriod: (state, action) => {
            state.period = action.payload.period;
        },
        previousPeriod: (state) => {
            if (comparePeriods(state.periodMin, state.period) < 0) {
                state.period = getPrevPeriod(state.period);
            }
        },
        nextPeriod: (state, action) => {
            if (comparePeriods(state.period, state.periodMax) < 0) {
                state.period = getNextPeriod(state.period);
            }
        },
        busy: (state) => {
            state.isReady = false;
        },
        ready: (state) => {
            state.isReady = true;
        },
        selectGateway: (state, action) => {
            state.gateway = action.payload;
            state.descriptor = "";
            state.period = state.periodMax;
            state.summaryData = {};
        },
        selectDescriptor: (state, action) => {
            state.descriptor = action.payload;
            state.period = state.periodMax;
            state.summaryData = {};
        },
        setProcess: (state, action) => {
            state.process = action.payload;
        },
        setApiError: (state, action) => {
            state.apiError = action.payload;
        }
    },

    extraReducers: {
        [loadGateways.fulfilled]: (state, action) => {
            state.gateways = action.payload;
            state.descriptors = {};
            state.descriptor = "";
        },
        [loadGateways.rejected]: (state, action) => {
            state.gateways = {};
            state.descriptors = {};
            state.descriptor = "";
        },
        [loadDescriptors.fulfilled]: (state, action) => {
            state.descriptors = action.payload;
        },
        [loadDescriptors.rejected]: (state, action) => {
            state.descriptors = {};
        },
        [loadGatewaySummary.fulfilled]: (state, action) => {
            state.summaryData = action.payload;
        },
        [loadGatewaySummary.rejected]: (state, action) => {
            state.summaryData = {};
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    changePeriod,
    previousPeriod,
    nextPeriod,
    busy,
    ready,
    selectGateway,
    selectDescriptor,
    setProcess,
    setApiError
} = gatewaySummarySlice.actions;

export default gatewaySummarySlice.reducer;
