import React from "react";

export default function Buttons(props) {
    const { onStage, onPublish } = props;

    return <div id="buttons">
        <button className="buttonprimary secondary" onClick={ onStage }>Stage</button>
        <button className="buttonprimary primary inactive" onClick={ onPublish }>Publish</button>
    </div>;
}
