import React from 'react';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import SignUpSearchWidget from "../../components/SignUpSearchWidget";
import SignUpSearchListing from "../Customers/CustomersList/SearchListing";
import SignUpPaginationWidget from "../../components/SignUpPaginationWidget";


export default function SignUpsPage() {
    TitleEffect(PageTitle("SignUps"));

    return <>
        <nav><SignUpSearchWidget /></nav>
        <main className="pendingsettlements">
            <SignUpSearchListing isSignupPage={true} />
            <SignUpPaginationWidget />
        </main>
    </>
}
