import React, { useEffect } from 'react';
import {
    reset,
    setSearchText,
    setSearchBy,
    setShowMe,
    searchUsers
} from '../../../shared/store/slices/customers'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom"
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";


export default function CustomerSearchPage() {
    TitleEffect(PageTitle("Customers"));

    const dispatch = useDispatch();
    const { searchText, searchBy, showMe } = useSelector(state => state.customers);
    const history = useHistory();

    function handleChangeText(event) {
        dispatch(setSearchText(event.target.value));
    }

    function handleChangeBy(event) {
        dispatch(setSearchBy(event.target.value));
    }

    function handleChangeShowMe(event) {
        dispatch(setShowMe(event.target.value));
    }

    function handleSearch(event) {
        event.preventDefault();
        dispatch(searchUsers({
            searchText: searchText,
            searchBy: searchBy,
            showMe: showMe
        }))
            .then(() => {
                history.push('/customers');
            });
    }

    return <div className="contentcolumn">
        <nav>
            <form onSubmit={ handleSearch }>
                <div id="transsearchnav" className="freshsearch adminhome">
                    <div className="navcell navcellsearchbar freshsearch adminhome">
                        <input type="text" id="transsearchbox" className="freshsearch" name="search" placeholder="Search" value={ searchText } onChange={ handleChangeText } />
                    </div>
                    <div className="navcell navcellparameter freshsearch adminhome" style={{ display:'flex', alignItems:'baseline' }}><span className="searchmenulabel">By</span>
                        <select name="parameter" id="parameter" value={ searchBy } onChange={ handleChangeBy }>
                            <option value="email">Email Address</option>
                            <option value="lastName">Last Name</option>
                            <option value="phoneNumber">Mobile</option>
                            <option value="street">Street Address</option>
                            <option value="ip_address">IP Address</option>
                            <option value="deviceId">Device ID</option>
                            <option value="country">Country Code (2)</option>
                        </select>
                    </div>
                    <div className="navcell navcellrange freshsearch adminhome" style={{ display:'flex', alignItems:'inherit' }}><span className="searchmenulabel">Show me</span>
                        <select name="merchant" id="merchant" value={ showMe } onChange={ handleChangeShowMe }>
                            <option value="customers">Customers</option>
                            <option value="agents">Agents</option>
                        </select>
                    </div>
                    <div className="navcellbuttons freshsearch adminhome">
                        <button className="buttonprimary primary savecancel" type="submit">Search</button>
                        <button className="buttonprimary secondary savecancel cancel">Go</button>
                    </div>
                </div>
            </form>
        </nav>
    </div>
}
