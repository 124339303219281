import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Form } from 'antd';
const EditableContext = React.createContext(null);

function EditableRow ({ index, ...props }){
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  function toggleEdit () {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  }

  async function save() {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0
        }}
        name={dataIndex}
        rules={[]}
      >
        <Input style={{textAlign: 'right', border: 'none'}} ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          width: '100%',
          height: '20px'
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default function EditableTable(props) {
  const columns = [
      {
        title: '',
        dataIndex: 'property',
        width: '300px',
        editable: false
      },
      {
        title: 'All',
        dataIndex: 'all',
        width: '90px',
        editable: true
      },
      {
        title: 'Mastercard',
        dataIndex: 'mastercard',
        width: '90px',
        editable: true
      },
      {
        title: 'Visa',
        dataIndex: 'visa',
        width: '90px',
        editable: true
      }
    ];

  const [dataSource, setDataSource] = useState([
      {
        key: '0',
        property: 'Transaction Minimum',
        all: '-1',
        mastercard: null,
        visa: null
      },
      {
        key: '1',
        property: 'Transaction Maximum',
        all: null,
        mastercard: null,
        visa: null
      },
      {
        key: '2',
        property: 'Daily Maximum',
        all: null,
        mastercard: null,
        visa: null
      },
      {
        key: '3',
        property: '7 Day Maximum',
        all: null,
        mastercard: null,
        visa: null
      },
      {
        key: '4',
        property: '30 Day Maximum',
        all: null,
        mastercard: null,
        visa: null
      }
    ]);

  function handleSave(row) {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };


  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const c = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <Table
      style={{width: '570px'}}
      components={components}
      rowClassName={() => 'editable-row'}
      pagination={false}
      bordered
      dataSource={dataSource}
      columns={c}
    />
  );
}