import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
    GetDocumentsPendingQueue,
    GetDocumentDetails,
    Approve,
    Reject,
    RequestNewDocument
} from "../../../domain/services/documents.svc";
import { startLoading, stopLoading } from './userInterface';

export const documentsSlice = createSlice({
    name: 'documents',

    initialState: {
        documentsQueue: [],
        listChanged: false,
        documentDetails: {},
        display: false,
        showRequestNewDocButton: false,
        showApproveRejectButton: false
    },

    reducers: {
        loadPendingDocumentsQueue: (state, action) => {
            state.documentsQueue = action.payload;
        },
        loadDocumentDetails: (state, action) => {
            state.documentDetails = action.payload;
            state.display = true;
        },
        closeDocumentDetails: (state, action) => {
            state.documentDetails = action.payload;
            state.display = false;
        },
        approveDocument: (state) => {
            state.listChanged = !state.listChanged;
        },
        rejectDocument: (state) => {
            state.listChanged = !state.listChanged;
        },
        newDocumentRequired: (state) => {
            state.listChanged = !state.listChanged;
        },
        setShowRequestNewDocButton: (state, action) => {
            state.showRequestNewDocButton = action.payload;
        },
        setShowApproveRejectButton: (state, action) => {
            state.showApproveRejectButton = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { 
    loadPendingDocumentsQueue, 
    loadDocumentDetails, 
    closeDocumentDetails, 
    approveDocument, 
    rejectDocument,
    newDocumentRequired,
    setShowRequestNewDocButton,
    setShowApproveRejectButton } = documentsSlice.actions;

export default documentsSlice.reducer;