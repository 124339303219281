import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from './userInterface';
import { DeleteSetting, GetAllSettings, GetSetting, SaveAllSettings, SaveSetting } from "../../../domain/services/controlpanel.svc";

export const getSetting = createAsyncThunk(
    'controlpanel/getSetting',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await GetSetting(user, params.settingname)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const saveSetting = createAsyncThunk(
    'controlpanel/saveSetting',
    async (params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await SaveSetting(user, params.id, params.setting)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const getAllSettings = createAsyncThunk(
    'controlpanel/getAllSettings',
    async (params, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
        const user = getState().oidc.user;
        return await GetAllSettings(user)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const saveAllSettings = createAsyncThunk(
    'controlpanel/saveAllSettings',
    async(params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await SaveAllSettings(user, params.settings)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
)

export const deleteSetting = createAsyncThunk(
    'controlpanel/deleteSetting',
    async(params, { getState, rejectWithValue, dispatch, requestId }) => {
        const user = getState().oidc.user;
        dispatch(startLoading(requestId));
        return await DeleteSetting(user, params.setting)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
)

export const controlpanelSlice = createSlice({
    name: 'controlpanel',

    initialState: {
        current: {
            name: '',
            value: ''
        },
        allSettings: [],
        selectedSettings: []
    },

    reducers: {
        reset: (state) => {
            state.current = {};
        },
        setCurrent: (state, action) => {
            state.current = action.payload;
        },
        loadSettings: (state, action) => {
            state.allSettings = action.payload;
        },
        setSelectedSettings: (state, action) => {
            state.selectedSettings = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    reset,
    setCurrent,
    loadSettings,
    setSelectedSettings } = controlpanelSlice.actions;

export default controlpanelSlice.reducer;
