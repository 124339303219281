import React from 'react';
import TitleEffect from "../../../components/TitleEffect";
import PageTitle from "../../../components/PageTitle";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { setIsEditing } from '../../../shared/store/slices/customers'
import CustomerDocumentsList from './CustomerDocumentsList';

function AgentProfileViewPage(props) {

    TitleEffect(PageTitle("Agent Profile"));

    const profiledatabigStyle = {
        display: "flex",
        alignItems: "center",
        marginTop: "-1px"
    };

    const customer = useSelector(state => state.customers.current);
    const href = "mailto:" + customer?.email;

    const handleCopy = () => {
        navigator.clipboard.writeText(customer.email);
        return true;
    }

    return <>
        <div className="profilegrid">
            <div  className="profilegrid1">
                <div className="profilelabel">Email</div>
                <div className="profiledatabig" style={profiledatabigStyle}>
                    <a href={href}>{customer?.email}</a>
                    <button className="copybutton" onClick={() => { handleCopy() }}>Copy</button>
                </div>
                <div className="profilelabel">Name</div>
                <div className="profiledatabig">{customer?.givenName + ' ' + customer?.familyName}</div>
            </div>
            <div className="profilegrid3">
                <div className="profilelabel">Account Status</div>
                <div className={customer?.enabled ? "green" : "red"}>
                    { customer?.enabled ? "Active" : "Deactivated" }
                </div>
                <div className="profilelabel">Sign Up Date</div>
                {customer?.registrationDate &&
                    <div>{moment(customer?.registrationDate).format("YYYY-MM-DD HH:mm:ss")}</div>
                }
            </div>
        </div>
    </>
}

export default AgentProfileViewPage;
