import React from "react";
import { useSelector } from "react-redux";

export default function DotsLoader(props) {
    const { isLoading } = useSelector(state => state.userInterface);

    if (!isLoading)
        return <div></div>;
    return <div className="loaderWrapper">
        <svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 30 20" enableBackground="new 0 0 0 0" space="preserve" className="loader">
          <circle fill="#E02A00" stroke="none" cx="6" cy="10" r="2.8">
            <animateTransform
               attributeName="transform"
               dur="1s"
               type="translate"
               values="0 7 ; 0 -7; 0 7"
               repeatCount="indefinite"
                calcMode="spline"
                keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
               begin="0.1"/>
          </circle>
          <circle fill="#E02A00" stroke="none" cx="15" cy="10" r="2.8">
            <animateTransform
               attributeName="transform"
               dur="1s"
               type="translate"
               values="0 7 ; 0 -7; 0 7"
               repeatCount="indefinite"
                calcMode="spline"
                keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
               begin="0.2"/>
          </circle>
          <circle fill="#E02A00" stroke="none" cx="24" cy="10" r="2.8">
            <animateTransform
               attributeName="transform"
               dur="1s"
               type="translate"
               values="0 7 ; 0 -7; 0 7"
               repeatCount="indefinite"
                calcMode="spline"
                keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
               begin="0.3"/>
          </circle>
        </svg>
    </div>
}
