import React, { useState, useEffect, useRef, createRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useParams, Link, useHistory } from "react-router-dom";
import {
    clearLiveBatch,
    getLiveBatches,
    fetchLiveBatch,
    closeLiveBatch,
    downloadLiveBatch,
    markLiveBatchSent,
    setLiveBatchSettleFile,
    uploadLiveBatchSettleFile,
    loadBatchTransactions
} from "../../shared/store/slices/payouts";
import { Table } from 'antd';
import FormatTime from '../../shared/helpers/FormatTime';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import moment from "moment";
import { formatter } from '../../shared/utils';
import { getCustomerProfileByEmail, setCurrentCustomerProfile } from '../../shared/store/slices/customers';
import {decode} from "html-entities";

export default function LiveBatchesPage(props) {

    TitleEffect(PageTitle("Live Batches | Accounting "));

    const { batchid } = useParams();
    const { isLoading } = useSelector(state => state.userInterface);
    const { liveBatches, liveBatch } = useSelector(state => state.payouts);
    const dispatch = useDispatch();
    const history = useHistory();

    const [searchParameter, setSearchParameter] = useState(batchid ? 'email' : 'batchid');
    const [searchTerm, setSearchTerm] = useState('');

    const docparameterStyle = {
        display: 'flex',
        alignItems: 'baseline'
    }

    const loadData = () => {
        dispatch(batchid ? fetchLiveBatch({ batchid: batchid }) : getLiveBatches());
    }

    useEffect(() => {
        dispatch(clearLiveBatch());
        loadData();
    }, []);

    function handleViewTransactions(batchid) {
        history.push(`/accounting/live-batches/${batchid}`);
    }

    function handleCloseBatch(batchid) {
        dispatch(closeLiveBatch({ batchid: batchid }))
            .then(() => {
                loadData();
            });
    }

    function handleDownloadBatch(batchid) {
        dispatch(downloadLiveBatch({ batchid: batchid }));
    }

    function handleMarkBatchSent(batchid) {
        dispatch(markLiveBatchSent({ batchid: batchid }))
            .then(() => {
                loadData();
            });
    }

    function handleFileDragOver(event) {
        event.stopPropagation();
        event.preventDefault();

        event.dataTransfer.dropEffect = 'copy';
    }

    function handleFileDrop(event) {
        event.stopPropagation();
        event.preventDefault();

        //This is not working
        dispatch(setLiveBatchSettleFile(event.dataTransfer.files[0].name));
        //  and neither is this
        //dispatch(setLiveBatchSettleFile(event.target.value));
    }

    function handleFileChange(event) {
        dispatch(setLiveBatchSettleFile(event.target.value));
    }

    var filteredLiveBatches = [];
    var filteredLiveBatch = [];

    if (batchid) {
        switch (searchParameter) {
            case 'email':
                filteredLiveBatch = liveBatch.filter(row => row.email.indexOf(searchTerm) >= 0);
                break;
            case 'name':
                filteredLiveBatch = liveBatch.filter(row => (row.firstName.indexOf(searchTerm) >= 0) || (row.lastName.indexOf(searchTerm) >= 0));
                break;
            case 'country':
                filteredLiveBatch = liveBatch.filter(row => row.country.indexOf(searchTerm) >= 0);
                break;
            case 'method':
                filteredLiveBatch = liveBatch.filter(row => row.method.indexOf(searchTerm) >= 0);
                break;
            case 'txid':
                filteredLiveBatch = liveBatch.filter(row => row.txid.indexOf(searchTerm) >= 0);
                break;
            default:
                filteredLiveBatch = liveBatch;
                break;
        }
    } else {
        switch (searchParameter) {
            case 'batchid':
                filteredLiveBatches = liveBatches.filter(row => row.batchid.toString().indexOf(searchTerm) >= 0);
                break;
            case 'sid':
                filteredLiveBatches = liveBatches.filter(row => row.sid.toString().indexOf(searchTerm) >= 0);
                break;
            case 'currency':
                filteredLiveBatches = liveBatches.filter(row => row.currency.indexOf(searchTerm) >= 0);
                break;
            default:
                filteredLiveBatches = liveBatches;
                break;
        }
    }

    const refLen = liveBatches?.length;
    const refs = useRef([]);

    if (refs?.current?.length !== refLen) {
        refs.current = Array(refLen).fill().map((_, i) => refs.current[i] || createRef());
    }

    function handleSubmitFile(event) {
        event.stopPropagation();
        event.preventDefault();

        const idx = event.target.getAttribute('data-index');
        const batchid = event.target.getAttribute('data-batchid');

        const formData = new FormData();
        formData.append('batchid', batchid);
        formData.append('settlefile', refs.current[idx].current.files[0]);

        dispatch(uploadLiveBatchSettleFile(formData))
            .then(() => {
                loadData();
            });
    }

    function nullToEmpty(string) {
        return string === null ? "" : string;
    }

    const goToCustomerProfile = (realm, email) => {
        //TODO Ticket 4041 - Return values from batch queries to submit a find profile (userID or email + realm)
        dispatch(getCustomerProfileByEmail({
            realm: realm || "wallet88",
            email: email
        }))
        .then((response) =>{
            dispatch(setCurrentCustomerProfile(response.payload));
            history.push("/customer/profile");
        });
    }

    const batchColumns = [{
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Batch Id',
            dataIndex: 'batchid',
            render: (text, row) => {
                return <Link to={ `/accounting/live-batches/${row.batchid}` }>{row.batchid}</Link>;
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.batchid - b.batchid
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Sid',
            dataIndex: 'sid',
            render: (text, row) => {
                return row.name + " (SID " + row.sid + ")";
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.sid - b.sid
        }, {
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Currency',
            dataIndex: 'currency',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.currency.localeCompare(b.currency)
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Lines',
            dataIndex: 'lines',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.lines - b.lines
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Total Value',
            dataIndex: 'totalvalue',
            render: (text, row) => {
                return decode(row.symbol) + formatter.format(row.totalvalue);
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.totalvalue - b.totalvalue
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Started',
            dataIndex: 'date_submitted',
            render: (text, row) => {
                return FormatTime(row.date_submitted.replace(' ', 'T'));
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => moment(a.date_submitted).diff(moment(b.date_submitted))
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Status',
            dataIndex: 'status',
            render: (text, row) => {
                return {
                    'queueing': "Open",
                    'closed': "Closed",
                    'sent': "Sent",
                    'partial': "Partially Completed"
                }[row.status];
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.status.localeCompare(b.status)
        }, {
            className: 'th-legendlabel tdstyle transtable transcell lastcell tableicons',
            align: 'center',
            title: '',
            render: (text, row, idx) => {
                return <>
                    {row.status != 'sent' && row.status != 'partial'
                        ? <Button TEXT="View"
                            classes="buttonprimary secondary navbutton tablebutton"
                            onClick={() => { handleViewTransactions(row.batchid) }} />
                        : null
                    }
                    {row.status == 'queueing'
                        ? <Button TEXT={"Close"}
                            classes="buttonprimary primary navbutton tablebutton"
                            onClick={() => { handleCloseBatch(row.batchid) }} />
                        : null
                    }
                    {row.status == 'closed' || row.status == 'sent'
                        ? <Button TEXT={"Download"}
                            classes="buttonprimary primary navbutton tablebutton"
                            onClick={() => { handleDownloadBatch(row.batchid) }} />
                        : null
                    }
                    {row.status == 'closed'
                        ? <Button TEXT={"Mark as Sent"}
                            classes="buttonprimary primary navbutton tablebutton"
                            onClick={() => { handleMarkBatchSent(row.batchid) }} />
                        : null
                    }
                    {row.status == 'sent' || row.status == 'partial'
                        ? <form onSubmit={ handleSubmitFile } className="uploadForm" data-index={ idx } data-batchid={ row.batchid }>
                            <div id="target" onDragOver={ handleFileDragOver } onDrop={ handleFileDrop }>
                                Upload a settlements .csv file here
                                <div id="imagechooser">
                                    <input id="file-upload" ref={ refs.current[idx] } onChange={ handleFileChange } type="file" accept=".csv" />
                                </div>
                            </div>
                            <Button TEXT="Upload" type="submit"
                            classes="buttonprimary secondary navbutton tablebutton" />
                        </form>
                        : null
                    }
                </>;
            }
        }
    ];

    const dataColumns = [{
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Email',
            dataIndex: 'Email',
            render: (text, row) => <a href="#" className="emaillink" onClick={() => { goToCustomerProfile(row.realm, row.email); }}>{ row.email }</a>,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.email.localeCompare(b.email)
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Name',
            dataIndex: 'Name',
            render: (text, row) => <span>{ `${nullToEmpty(row.firstName)} ${nullToEmpty(row.lastName)}` }</span>,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.lastName.localeCompare(b.lastName)
        }, {
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Bank',
            dataIndex: 'bank',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.bank.localeCompare(b.bank)
        }, {
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Routing',
            dataIndex: 'routing',
            render: (text, row) => {
                switch (row.method) {
                    case 'payid': return nullToEmpty(row.account); break;
                    case 'bsb': return `${nullToEmpty(row.bankCode)} ${nullToEmpty(row.account)}`; break;
                    case 'banktransfer':
                    default: return `${nullToEmpty(row.bankCode)} ${nullToEmpty(row.routing)} ${nullToEmpty(row.account)}`; break;
                }
            }
        }, {
            className: 'th-legendlabel tdstyle transcell transtable nobreak',
            align: 'right',
            title: 'Amount',
            dataIndex: 'Amount',
            render: (text, row) => decode(row.symbol) + formatter.format(row.amount),
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.amount - b.amount
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Currency',
            dataIndex: 'currency',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.currency.localeCompare(b.currency)
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Method',
            dataIndex: 'Method',
            render: (text, row) => {
                return {
                    'payid': "PayID",
                    'bsb': "BSB",
                    'banktransfer': "Bank Transfer"
                }[row.method];
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a,b) => a.method.localeCompare(b.method)
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'Country',
            dataIndex: 'country',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.country.localeCompare(b.country)
        }, {
            className: 'th-legendlabel tdstyle transcell transtable',
            align: 'right',
            title: 'TXID',
            dataIndex: 'txid',
            render: (text, row) => {
                return <Link to={ `/transactions/${row.txid}` } target="_blank">{row.txid}</Link>
            },
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.txid - b.txid
        },
    ];

    const handleSearchParameter = (event) => {
        setSearchParameter(event.target.value);
    }

    const handleSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleFilter = () => {
    }

    return <div>
        <nav>
            <div id="docsearch">
                <div className="navcell docsearchbar">
                    <input type="text" id="transsearchbox" name="search" value={searchTerm} onChange={handleSearchTerm} />
                </div>
                <div className="navcell docparameter" style={docparameterStyle}>
                    <select name="parameter" id="parameter" value={searchParameter} onChange={handleSearchParameter}>
                        {batchid
                            ? <>
                                <option value="email">Email</option>
                                <option value="name">Name</option>
                                <option value="country">Country</option>
                                <option value="method">Method</option>
                                <option value="txid">TXID</option>
                            </>
                            : <>
                                <option value="batchid">BatchId</option>
                                <option value="sid">SID</option>
                                <option value="currency">Currency</option>
                            </>
                        }
                    </select>
                </div>
                <div className="docsearchbutton">
                    <button className="buttonprimary primary navbutton" onClick={handleFilter}>Search</button>
                </div>
            </div>
        </nav>
        <main className="searchtransactions">
            <Table
                rowKey={r => r.batchid || r.lineid}
                columns={batchid ? dataColumns : batchColumns}
                dataSource={batchid ? filteredLiveBatch : filteredLiveBatches}
                pagination={false}
                scroll={{x: "auto"}}
            />
            <div className="text-center"><p id="nomoretx">The end</p></div>
        </main>
    </div>
}
