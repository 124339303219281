import React from 'react';
import CustomerSearchWidget from '../../components/CustomerSearchWidget';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import { useSelector } from 'react-redux';
import CommentsPage from './CommentsPage';

export default function CustomerCommentsPage() {
    TitleEffect(PageTitle("Customer Comments"));
    const customer = useSelector(state => state.customers.current);
    return <>
        <nav><CustomerSearchWidget /></nav>
        <main className={customer === null || customer === undefined || customer?.id === undefined ? "not-found centeredcontent" : ""}>
            { customer === null || customer === undefined || customer?.id === undefined ? 
                <div><h1>Customer not Selected.</h1></div> : <CommentsPage />
            }
        </main>
    </>
}
