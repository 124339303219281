import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoadAuditLogs as LoadGatewayAuditLogs } from '../../GatewayAPI.js';
import { LoadAuditLogs, LoadAdminEventLogs } from "../../../domain/services/auditLogs.svc";
import { SearchProfiles } from "../../../domain/services/search.svc";
import { startLoading, stopLoading } from './userInterface';

export const searchProfiles = createAsyncThunk(
    'auditLogs/searchProfiles',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        return await SearchProfiles(user, query)
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));
    }
);

export const loadAdminAuditLogs = createAsyncThunk(
    'auditLogs/loadAdminAuditLogs',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        const gatewayResponse = await LoadGatewayAuditLogs(user, { userid: query.username })
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) });

        const walletResponse = await LoadAuditLogs(user, { userId: query.userid })
            .catch(error => { if (!error.response) throw error; return rejectWithValue(error.response.data) })
            .finally(() => dispatch(stopLoading(requestId)));

        const result = [];
        
        gatewayResponse.data.map((log) => result.push(log));
        walletResponse.map((log) => {
            const _log = log;
            _log.timestamp = _log.timestamp.replace('T', ' ');
            result.push(_log)
        });

        result.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1);

        return result;
    }
);

export const loadCustomerOrAgentAuditLogs = createAsyncThunk(
    'auditLogs/loadCustomerOrAgentAuditLogs',
    async (query, { rejectWithValue, dispatch, requestId, getState }) => {
        dispatch(startLoading(requestId));
		const user = getState().oidc.user;
        let result = [];

        let includeAdminEvents = (query.includeAdminEvents
                                && (query.eventType.startsWith("KEYCLOAK_ADMIN_")
                                        || query.eventType === "" || query.eventType ==="ALL"));

        //NOTE: KK User Event Logs query has been removed as those of value are now captured in Audit Logs
        if (includeAdminEvents) {
            const adminEventLogs = await LoadAdminEventLogs(user, {userId: query.userId, agentId: query.agentId,
                                               startDate: new Date(query.startDate), endDate: new Date(query.endDate)})
                                        .catch(error => {
                                            if (!error.response) throw error;
                                            return rejectWithValue(error.response.data)
                                        });
            adminEventLogs.map((log) => result.push(log));

            if (query.eventType?.startsWith("KEYCLOAK_ADMIN_")){
                result = result.filter(a => a.event === query.eventType.replace("KEYCLOAK_ADMIN_", ""));
            }
        }

        let includeUserEvents = (query.includeUserEvents
                                && (!query.eventType?.startsWith("KEYCLOAK_ADMIN_")
                                    || query.eventType === "" || query.eventType === "ALL"));

        if (includeUserEvents) {
            const auditLogs = await LoadAuditLogs(user, {userId: query.userId, agentId: query.agentId,
                                                startDate: new Date(query.startDate), endDate: new Date(query.endDate),
                                                orderBy: query.orderBy, eventType: query.eventType})
                                            .catch(error => {
                                                if (!error.response) throw error;
                                                return rejectWithValue(error.response.data)
                                            });
            auditLogs.map((log) => result.push(log));
        }
        dispatch(stopLoading(requestId));

        if (query.orderBy === "ASC")
            result.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1);
        else
            result.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1);

        return result;
    }
);

export const auditLogsSlice = createSlice({
    name: 'auditLogs',

    initialState: {
        searchStartDate: new Date(new Date().toDateString()).toISOString(),
        searchEndDate: new Date(new Date().toDateString()).toISOString(),
        searchIncludeAdminEvents: true,
        searchIncludeUserEvents: true,
        searchMostRecentFirst: true,
        searchEventType: "ALL",
        auditLogs: [],
        overlayContent: "",
        apiError: "",
        loading: undefined
    },

    reducers: {
        reset: (state) => {
            state.searchStartDate = new Date(new Date().toDateString()).toISOString(),
            state.searchEndDate = new Date(new Date().toDateString()).toISOString(),
            state.searchIncludeAdminEvents = true,
            state.searchIncludeUserEvents = true,
            state.searchMostRecentFirst = true,
            state.searchEventType = "",
            state.auditLogs = [];
        },
        setSearchStartDate: (state, action) => {
            state.searchStartDate = action.payload;
        },
        setSearchEndDate: (state, action) => {
            state.searchEndDate = action.payload;
        },
        setSearchIncludeAdminEvents: (state, action) => {
            state.searchIncludeAdminEvents = action.payload;
        },
        setSearchIncludeUserEvents: (state, action) => {
            state.searchIncludeUserEvents = action.payload;
        },
        setSearchMostRecentFirst: (state, action) => {
            state.searchMostRecentFirst = action.payload;
        },
        setSearchEventType: (state, action) => {
            state.searchEventType = action.payload;
        },
        clear: (state) => {
            state.auditLogs = [];
        },
        ready: (state) => {
            state.isReady = true;
        }
    },

    extraReducers: {
        [loadAdminAuditLogs.pending]: (state) => {
            state.loading = true;
        },
        [loadAdminAuditLogs.fulfilled]: (state, action) => {
            state.auditLogs = action.payload || [];
            state.loading = false;
        },
        [loadAdminAuditLogs.rejected]: (state) => {
            state.auditLogs = [];
            state.loading = false;
        },
        [loadCustomerOrAgentAuditLogs.pending]: (state) => {
            state.loading = true;
        },
        [loadCustomerOrAgentAuditLogs.fulfilled]: (state, action) => {
            state.auditLogs = action.payload || [];
            state.loading = false;
        },
        [loadCustomerOrAgentAuditLogs.rejected]: (state) => {
            state.auditLogs = [];
            state.loading = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const { reset, setSearchStartDate, setSearchEndDate, setSearchIncludeAdminEvents, setSearchIncludeUserEvents,
    setSearchMostRecentFirst, setSearchEventType, clear, ready } = auditLogsSlice.actions;

export default auditLogsSlice.reducer;
