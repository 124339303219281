import React, { useEffect } from 'react';
import {
    reset,
    setSearchText,
    setSearchBy,
    setShowMe,
    setShowInRealm,
    setInRealm
} from '../../shared/store/slices/globalSearch'
import {
    searchUsers
} from '../../shared/store/slices/customers'
import {
    updateQuery,
    MerchantSearchQuery
} from "../../shared/store/slices/merchants";
import {
    updateParameter,
    updateSearchText,
    updateDateRange,
    TransactionSearchQuery,
} from '../../shared/store/slices/transactionSearch';
import { DateRange, FormatIso8601 } from '../../shared/helpers/TimeHelper';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useHistory } from "react-router-dom"
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";


export default function GlobalSearchPage() {
    TitleEffect(PageTitle("Search"));

    const dispatch = useDispatch();
    const { searchText, searchBy, showMe, inRealm, showInRealm } = useSelector(state => state.globalSearch);
    const { criteria } = useSelector(state => state.transactionSearch);
    const history = useHistory();

    function handleChangeText(event) {
        dispatch(setSearchText(event.target.value));
    }

    function handleChangeBy(event) {
        dispatch(setSearchBy(event.target.value));
    }

    function handleChangeInRealm(event) {
        dispatch(setInRealm(event.target.value));
    }

    function handleChangeShowMe(event) {
        dispatch(setShowMe(event.target.value));
        switch (event.target.value){
            case "customers": {
                batch(() => {
                    dispatch(setInRealm(''));
                    dispatch(setShowInRealm(true));
                });
                break;
            }
            case "agents": {
                dispatch(setInRealm('master'));
            }
            default: {
                dispatch(setShowInRealm(false));
            }
        }
        if (event.target.value === "customers"){
            batch(() => {
                dispatch(setInRealm(''));
                dispatch(setShowInRealm(true));
            });
        } else {
            dispatch(setShowInRealm(false));
        }
    }

    function handleSearch(event) {
        event.preventDefault();
        switch (showMe) {
            case 'transactions': {
                const range = DateRange('last3Months');
                const dateRange = {
                    type: range.type,
                    from: FormatIso8601(range.start),
                    to: FormatIso8601(range.end)
                };

                batch(() => {
                    dispatch(updateSearchText(searchText));
                    dispatch(updateParameter(searchBy));
                    dispatch(updateDateRange(dateRange));
                });

                const query = {
                    from: dateRange.from,
                    to: dateRange.to,
                }

                if (searchBy && searchText) {
                    query[searchBy] = searchText;
                }

                dispatch(TransactionSearchQuery(query))
                    .then(() => {
                            history.push('/transactions');
                        });
                break;
            }

            case 'agents':
            case 'customers': {
                dispatch(searchUsers({
                    searchText: searchText,
                    searchBy: searchBy,
                    showMe: showMe,
                    inRealm: inRealm == '' ? null : inRealm
                }))
                    .then(() => {
                        history.push('/customers');
                    });
                //TODO We could move agents to an agent specific route at some point.
                break;
            }

            case 'merchants':
            case 'sites': {
                dispatch(updateQuery({
                    text: searchText,
                    parameter: searchBy,
                    show: showMe,
                    feelingLucky: false,
                    page: 0
                }));

                dispatch(MerchantSearchQuery({}))
                    .then(() => {
                        history.push('/merchants');
                    });

                break;
            }
        }
    }

    return <div className="contentcolumn">
        <nav>
            <form onSubmit={ handleSearch }>
                <div id="transsearchnav" className="freshsearch adminhome">
                    <div className="navcell navcellsearchbar freshsearch adminhome">
                        <input type="text" id="transsearchbox" className="freshsearch" name="search" placeholder="Search" value={ searchText } onChange={ handleChangeText } />
                    </div>
                    <div className="navcell navcellparameter freshsearch adminhome" style={{ display:'flex', alignItems:'baseline' }}><span className="searchmenulabel">By</span>
                        <select name="parameter" id="parameter" value={ searchBy } onChange={ handleChangeBy }>
                            <option value="email">Email Address</option>
                            <option value="lastName">Last Name</option>
                            <option value="phoneNumber">Mobile</option>
                            <option value="merchant_name">Merchant</option>
                            <option value="sid">Site</option>
                            <option value="transaction_id">TXID</option>
                            <option value="account_number">Card number</option>
                            <option value="street">Street Address</option>
                            <option value="ip_address">IP Address</option>
                            <option value="deviceId">Device ID</option>
                            <option value="country">Country Code (2)</option>
                        </select>
                    </div>
                    <div className="navcell navcellrange freshsearch adminhome" style={{ display:'flex', alignItems:'inherit' }}><span className="searchmenulabel">Show me</span>
                        <select name="merchant" id="merchant" value={ showMe } onChange={ handleChangeShowMe }>
                            <option value="transactions">Transactions</option>
                            <option value="customers">Customers</option>
                            
                            <option value="merchants">Merchants</option>
                            <option value="sites">Sites</option>
                            <option value="agents">Agents</option>
                        </select>
                    </div>
                    { showInRealm ?
                    <div className="navcell navcellfilter freshsearch adminhome" style={{ display:'flex', alignItems:'inherit' }}><span className="searchmenulabel">Realm</span>
                        <select name="realm" id="realm" value={ inRealm } onChange={ handleChangeInRealm }>
                            <option value="">All</option>
                            <option value="wallet88">Wallet88</option>
                            <option value="flypto">Flypto</option>
                        </select>
                    </div> : null}
                    <div className="navcellbuttons freshsearch adminhome">
                        <button className="buttonprimary primary savecancel" type="submit">Search</button>
                        <button className="buttonprimary secondary savecancel cancel">Go</button>
                    </div>
                </div>
            </form>
        </nav>
    </div>
}
