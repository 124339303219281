import React, { useState, useEffect } from 'react';
import TitleEffect from "../../components/TitleEffect";
import PageTitle from "../../components/PageTitle";
import { useDispatch, useSelector } from 'react-redux';
import { Comment, Form, Button, List, Input } from 'antd';
import {
    getCustomerComments,
    saveCustomerComment,
    loadComments
} from '../../shared/store/slices/customers';
import TreeCommentComponent from '../../components/TreeCommentComponent';

function CommentsPage(props) {

    TitleEffect(PageTitle("Customer Comments"));

    const customer = useSelector(state => state.customers.current);
    const comments = useSelector(state => state.customers.comments);
    const [submitting, setSubmitting] = useState(false);
    const [commentsData, setCommentsData] = useState([]);
    const [isReplying, setIsReplying] = useState('');
    const [submittingReply, setSubmittingReply] = useState(false);

    const [commObj, setCommObj] = useState({
        customerId: customer.id,
        comment: ''
    });

    const [replyObj, setReplyObj] = useState({
        customerId: customer.id,
        comment: '',
        parentId: '0'
    });

    const dispatch = useDispatch();
    
    const handleChange = (event) => {
        event.preventDefault();
        setCommObj({...commObj, comment: event.target.value});
    }

    const handleChangeReply = (event) => {
        event.preventDefault();
        setReplyObj({...replyObj, comment: event.target.value});
    }

    const addComment = (event) => {
        event.preventDefault();

        if(commObj?.comment !== '' && commObj?.comment !== null && commObj?.comment !== undefined) {
            setSubmitting(true);

            if(commObj?.customerId === '' || commObj?.customerId === null || commObj?.customerId === undefined) {
                setCommObj({...commObj, customerId: customer.id});
            }

            dispatch(saveCustomerComment(commObj))
            .then(() => {
                setCommObj({...commObj, comment: ''});
                getComments();
                setSubmitting(false);
            }).catch((err) => {
                setSubmitting(false);
            });   
        }
    }

    const openReply = (parentId) => {
        setIsReplying(parentId);
        setReplyObj({...replyObj, comment: ''});
        setReplyObj({...replyObj, parentId: parentId}); 
    }

    const closeReply = () => {
        setIsReplying('');
        setReplyObj({
            customerId: customer.id,
            comment: '',
            parentId: '0'
        });
    }

    const replyComment = () => {

        if(replyObj?.comment !== '' && replyObj?.comment !== null && replyObj?.comment !== undefined) {
            setSubmittingReply(true);

            if(replyObj?.customerId === '' || replyObj?.customerId === null || replyObj?.customerId === undefined) {
                setCommObj({...replyObj, customerId: customer.id});
            }
            dispatch(saveCustomerComment(replyObj))
            .then(() => {
                setReplyObj({
                    customerId: customer.id,
                    comment: '',
                    parentId: '0'
                });
                getComments();
                setSubmittingReply(false);
                setIsReplying('');
            }).catch((err) => {
                setSubmittingReply(false);
            });   
        }
    }

    const getComments = () => {
        if(customer?.id !== '' && customer?.id !== null && customer?.id !== undefined) {
            dispatch(getCustomerComments({customerId: customer.id}))
            .then((response => {
                dispatch(loadComments(response.payload));
            }));
        }
    };

    useEffect(()=> {
        getComments();
    }, []);

    useEffect(()=> {
        if(comments?.length > 0)
            setCommentsData(getChildrenData());
    }, [comments]);

    const getChildrenData = () => {
        return comments.map((comment) => ({
            ...comment,
            hasChildren:
                comments.filter((i) => i?.parentId === comment.id)?.length > 0
        }));
    }

    const { TextArea } = Input;

    return <>
        <div className="comments-container">
            <Comment content = {
                <>
                <Form.Item>
                    <TextArea rows={4} onChange={handleChange} value={commObj.comment} />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" loading={submitting} onClick={addComment} className="buttonprimary primary navbutton">Add Comment</Button>
                </Form.Item>
                </>
            } />
            {comments?.length > 0 && 
             <TreeCommentComponent
                data={commentsData} 
                openReply={openReply} 
                onchangeReply={handleChangeReply} 
                isReplying={isReplying} 
                replyCommentObj={replyObj} 
                submittingReply={submittingReply} 
                addReply={replyComment}
                closeReply={closeReply}/>}
        </div>
    </>
}

export default CommentsPage;