// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KTUQ7eDQy0ebtLhHvtG9{
    height: auto; 
    padding: 11px 11px;
}
.z23Unfqu4PrlcEWuZgo4{
    width: auto;
}
.A7cke5YUcCsowSBRfwKs{
    padding-right: 15px;
}
.w3nwb4DBcOJz3x_mOw4d{
    padding: 0 8px;
}
.XuLPthkFo1ANXwI7rzAD{
    display: flex; align-items: center; line-height: 2.8rem;
}
.M_AzXEnikt9CScVcChae {
    margin-top: 16px;
}
.Vd_FwjgGdTR9UdK_IKkk {
    margin-right: 5px;
}
.sK2xzRchlx7BWmyQCJgj {
    margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/domain/Wallets/Limits/limits.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa,EAAE,mBAAmB,EAAE,mBAAmB;AAC3D;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB","sourcesContent":[".textareaLimit{\n    height: auto; \n    padding: 11px 11px;\n}\n.widthAuto{\n    width: auto;\n}\n.paddingRight15px{\n    padding-right: 15px;\n}\n.padding08px{\n    padding: 0 8px;\n}\n.controlsBox{\n    display: flex; align-items: center; line-height: 2.8rem;\n}\n.marginTop16px {\n    margin-top: 16px;\n}\n.marginRight5px {\n    margin-right: 5px;\n}\n.marginTop5px {\n    margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textareaLimit": `KTUQ7eDQy0ebtLhHvtG9`,
	"widthAuto": `z23Unfqu4PrlcEWuZgo4`,
	"paddingRight15px": `A7cke5YUcCsowSBRfwKs`,
	"padding08px": `w3nwb4DBcOJz3x_mOw4d`,
	"controlsBox": `XuLPthkFo1ANXwI7rzAD`,
	"marginTop16px": `M_AzXEnikt9CScVcChae`,
	"marginRight5px": `Vd_FwjgGdTR9UdK_IKkk`,
	"marginTop5px": `sK2xzRchlx7BWmyQCJgj`
};
export default ___CSS_LOADER_EXPORT___;
