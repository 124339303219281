import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styles from './Table.module.css';
import {
    updateParameter,
    updateSearchText,
    updateDateRange,
    TransactionSearchQuery
} from '../../shared/store/slices/transactionSearch';
import {Link, useHistory} from 'react-router-dom';
import NoData from '../../components/NoData';
import {DateRange, FormatIso8601} from '../../shared/helpers/TimeHelper';


function StyleForStatus(status) {
    switch (status) {
        case 'Approved':
            return styles.approved;

        case 'Pending':
            return styles.pending;

        case 'Declined':
            return styles.declined;

        default:
            return '';
    }
}

function TransactionHeaderRow(props) {
    const {aspect} = props;

    if (aspect === "customer-tx-gateway") {
        return (
            <tr>
                <th>Timestamp</th>
                <th>TXID</th>
                <th>Amount</th>
                <th>Cur.</th>
                <th>Type</th>
                <th>Method</th>
                <th>Payee</th>
                <th>Account</th>
                <th>Gateway</th>
                <th>Merchant</th>
                <th>Status</th>
            </tr>
        );
    } else {
        return (
            <tr>
                <th>Timestamp</th>
                <th>TXID</th>
                <th>Amount</th>
                <th>Cur.</th>
                <th>Type</th>
                <th>Method</th>
                <th>Payee</th>
                <th>Ref. #</th>
                <th>SID</th>
                <th>Status</th>
            </tr>
        );
    }
}

function TransactionRow(props) {
    const {
        created_on,
        id,
        amount,
        currency,
        tx_action,
        method,
        payee,
        merchant_reference,
        sid,
        status,
        account,
        gateway,
        merchant,
        aspect
    } = props;

    if (aspect === "customer-tx-gateway") {
        return (
            <tr key={id}>
                <td>{created_on}</td>
                <td><Link to={`/transactions/${id}`} target={"_blank"}>{id}</Link></td>
                <td>{amount}</td>
                <td>{currency}</td>
                <td>{tx_action}</td>
                <td>{method}</td>
                <td>{payee}</td>
                <td>{account}</td>
                <td>{gateway}</td>
                <td>{merchant}</td>
                <td className={StyleForStatus(status)}>{status}</td>
            </tr>
        );
    } else {
        return (
            <tr key={id}>
                <td>{created_on}</td>
                <td><Link to={`/transactions/${id}`} target={"_blank"}>{id}</Link></td>
                <td>{amount}</td>
                <td>{currency}</td>
                <td>{tx_action}</td>
                <td>{method}</td>
                <td>{payee}</td>
                <td>{merchant_reference}</td>
                <td>{sid}</td>
                <td className={StyleForStatus(status)}>{status}</td>
            </tr>
        );
    }
}

function TransactionListing(props) {
    const {aspect} = props;
    const {transactions, loading, previousQuery} = useSelector(state => state.transactionSearch);
    const currentCustomer = useSelector(state => state.customers.current);
    const dispatch = useDispatch();
    const rows = transactions.map((transaction) => TransactionRow(Object.assign({}, transaction, {aspect: aspect})));
    const history = useHistory();

    useEffect(() => {
        if (aspect === "customer-tx-gateway") {
            if (currentCustomer?.email) {
                const range = DateRange('today');
                const query = {
                    from: FormatIso8601(range.start),
                    to: FormatIso8601(range.end),
                    page_size: 40,
                    page: 0,
                    email: currentCustomer.email
                };

                dispatch(TransactionSearchQuery(query));
                dispatch(updateParameter('email'));
                dispatch(updateSearchText(currentCustomer.email));
                dispatch(updateDateRange({type: 'today'}));
            } else {
                history.push("/transactions");
            }
        }
    }, [currentCustomer, aspect]);

    function handleScroll(evt) {
        const table = evt.target;
        if (table.scrollTop + table.clientHeight > table.scrollHeight * 0.8) {
            if (!loading && previousQuery) {
                const query = Object.assign({}, previousQuery);
                query.page += 1;
                dispatch(TransactionSearchQuery(query));
            }
        }
    }

    return rows?.length
        ? <table
            className={`${styles.table} ` + (aspect === "customer-tx-gateway" ? `${styles.customerTransactionListing}` : `${styles.transactionListing}`)}
            onScroll={handleScroll}>
            <thead><TransactionHeaderRow aspect={aspect}/></thead>
            <tbody>{rows}</tbody>
          </table>
        : (loading === false
            ? <NoData/>
            : null
        );
}

export default TransactionListing;
