import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from './userInterface';

export const globalSearchSlice = createSlice({
    name: 'globalSearch',

    initialState: {
        searchText: '',
        searchBy: 'email',
        showMe: 'customers',
        showInRealm: true,
        inRealm: '',
        loading: undefined
    },

    reducers: {
        reset: (state) => {
            state.searchText = '';
            state.searchBy = 'email';
            state.showMe = 'customers';
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload;
        },
        setSearchBy: (state, action) => {
            state.searchBy = action.payload;
        },
        setShowMe: (state, action) => {
            state.showMe = action.payload;
        },
        setShowInRealm: (state, action) => {
            state.showInRealm = action.payload;
        },
        setInRealm: (state, action) => {
            state.inRealm = action.payload;
        },
        ready: (state) => {
            state.isReady = true;
        }
    },

    extraReducers: {
    }
})

// Action creators are generated for each case reducer function
export const { reset, setSearchText, setSearchBy, setShowMe, setShowInRealm, setInRealm, ready } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
